import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CartService } from '@modules/cart/cart.service';
import { ConstructionCategory } from '@shared/models/construction';
import { DeliveryMethod } from '@shared/models/delivery-method';

@Component({
  selector: 'packex-cart-delivery',
  templateUrl: './cart-delivery.component.html',
  styleUrls: ['./cart-delivery.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartDeliveryComponent implements OnInit, OnChanges {
  @Input() productType?: ConstructionCategory;
  @Input() deliveryMethods?: DeliveryMethod[];
  @Input() current?: string | null;
  @Input() disabled = false;
  @Output() deliveryMethodChanged = new EventEmitter<string>();

  form = new FormGroup({
    delivery_method: new FormControl<string>(''),
  });

  constructor(private readonly cartService: CartService) {}

  ngOnInit() {
    this.form.controls.delivery_method.patchValue(this.current || '');

    this.form.controls.delivery_method.valueChanges.subscribe((value) => {
      this.deliveryMethodChanged.emit(value || '');
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['current']) {
      this.form.controls.delivery_method.patchValue(
        changes['current'].currentValue || '',
      );
    }

    if (changes['disabled']) {
      changes['disabled'].currentValue
        ? this.form.controls.delivery_method.disable()
        : this.form.controls.delivery_method.enable();
    }
  }
}
