import { Component, Input } from '@angular/core';
import { IconColor } from '@shared/components/icon/icon.component';

@Component({
  selector: 'packex-tooltip',
  template: `<span [matTooltip]="text || ''" [matTooltipPosition]="'right'">
    <packex-icon icon="info_circle" [color]="color" size="s"></packex-icon>
  </span>`,
})
export class TooltipComponent {
  @Input() text?: string;
  @Input() color: IconColor = 'primary';
}
