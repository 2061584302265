import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { InventoryItem } from '@shared/models/inventory-item';

@UntilDestroy()
@Component({
  selector: 'packex-create-label-modal',
  templateUrl: './create-label-modal.component.html',
  styleUrls: ['./create-label-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateLabelModalComponent extends ModalComponent {
  labels: string[] = [];

  constructor(
    dialogRef: MatDialogRef<CreateLabelModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      inventoryItem?: InventoryItem;
    },
  ) {
    super(dialogRef);
  }

  override onConfirm(): void {
    this.dialogRef.close(Array.from(new Set([...this.labels])));
  }

  labelsChanged(labels: string[]) {
    this.labels = labels;
  }
}
