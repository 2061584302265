import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { IconColor, IconSize } from '@shared/components/icon/icon.component';

export type PackedButtonType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'icon'
  | 'clear-icon';
export type ButtonType = 'button' | 'submit';

@Component({
  selector: 'packex-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent {
  @Input() icon?: string;
  @Input() disabledIconColor?: IconColor;
  @Input() iconSize: IconSize = 'm';
  @Input() faIcon?: string;
  @Input() type: PackedButtonType = 'primary';
  @Input() color?: string = 'primary';
  @Input() background?: string;
  @Input() disabled = false;
  @Input() buttonType: ButtonType = 'button';
  @Input() hideTextDown = '';
  @Input() buttonPadding = 'p-2';

  @Output() buttonClick = new EventEmitter();

  hover = false;
  focus = false;

  onFocus() {
    this.focus = true;
  }

  onBlur() {
    this.focus = false;
  }

  @HostListener('mouseover')
  onMouseOver() {
    this.hover = true;
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.hover = false;
  }

  get iconColor(): IconColor {
    switch (this.type) {
      case 'icon':
      case 'clear-icon':
        if (this.disabled) {
          return 'grey';
        }

        if (this.color) {
          return this.color as IconColor;
        }
        return 'primary';
      case 'secondary':
        if (this.disabled) {
          return this.disabledIconColor ? this.disabledIconColor : 'white';
        }
        if (this.hover || this.focus) {
          return 'white';
        }
        return 'secondary';
      case 'tertiary':
        if (this.disabled) {
          return this.disabledIconColor ? this.disabledIconColor : 'white';
        }
        return this.iconColorByTextColor(this.color || '');
      default:
        return 'white';
    }
  }

  iconColorByTextColor(textColor: string): IconColor {
    switch (textColor) {
      case 'primary-dark':
      case 'primary':
        return 'primary';
      case 'secondary':
        return 'secondary';
      default:
        return 'white';
    }
  }

  get isIconButton(): boolean {
    return ['icon', 'clear-icon'].includes(this.type);
  }

  public onButtonClick(): void {
    if (!this.disabled) this.buttonClick.emit();
  }
}
