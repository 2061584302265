import {
  ChangeDetectionStrategy,
  Component,
  EnvironmentInjector,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InventoryItemsService } from '@app/core/services/inventory-items.service';
import { VariantsService } from '@app/core/services/variants.service';
import { formatDate } from '@app/utils/format';
import {
  getAuthorFromVariant,
  getVariantName,
  variantHasError,
  variantHasStatus,
  variantHasWarning,
  variantInProgress,
} from '@app/utils/variant-helper';
import { EditVariantComponent } from '@modules/inventory/components/edit-variant/edit-variant.component';
import { translate } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { ItemsDataSource } from '@shared/models/print-data-manager-item-source';
import { Variant } from '@shared/models/variant';
import { orderBy } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'packex-print-data-items-list',
  templateUrl: './print-data-items-list.component.html',
  styleUrls: ['./print-data-items-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PrintDataItemsListComponent implements OnChanges {
  @Input() inventoryItemId?: string;
  @Input() variants: Variant[] = [];
  @Output() addVariantClicked = new EventEmitter();

  displayedColumns: string[] = [
    'position',
    'name',
    'file',
    'release',
    'upload',
    'action',
  ];
  dataSource?: ItemsDataSource[];

  protected readonly getVariantName = getVariantName;
  protected readonly formatDate = formatDate;

  constructor(
    private readonly dialog: MatDialog,
    private readonly inventoryItemsService: InventoryItemsService,
    private readonly variantsService: VariantsService,
    public injector: EnvironmentInjector,
    private readonly snackbarService: SnackBarService,
  ) {}

  ngOnChanges(): void {
    this.dataSource =
      orderBy(this.variants, 'updatedAt', 'desc')?.map(
        (variant: Variant, index) => ({
          ...variant,
          position: index + 1,
          hasError: variantHasError(variant),
          hasWarning: variantHasWarning(variant),
          inProgress: variantInProgress(variant),
          completed: variantHasStatus(variant, 'complete'),
          manufacturerApproval: variantHasStatus(
            variant,
            'pending_manufacturer_approval',
          ),
          user: getAuthorFromVariant(variant),
          updatedAt: variant.updatedAt,
        }),
      ) || [];
  }

  public addVariant(): void {
    this.addVariantClicked.next(true);
  }

  public renameVariant(variant: Variant) {
    this.dialog
      .open(EditVariantComponent, {
        id: 'EditVariant',
        data: { variant: variant },
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((variantChanged: Variant) => {
        if (variantChanged) {
          this.inventoryItemsService.forceUpdate();
        }
      });
  }

  public deleteVariant(variant: Variant) {
    this.dialog
      .open(ConfirmDialogComponent, {
        id: 'DeleteCartItem',
        data: {
          title: 'DELETE_VARIANT.DIALOG_TITLE',
          content: 'DELETE_VARIANT.DIALOG_TEXT',
          primaryButtonText: 'COMMON.DELETE',
        },
      })
      .afterClosed()
      .subscribe((variantDeleted: boolean) => {
        if (variantDeleted) {
          this.variantsService.deleteVariant(variant.id).subscribe(() => {
            this.snackbarService.show({
              message: '',
              rawMessage: translate('DELETE_VARIANT.SUCCESS', {
                variantName: variant.name,
              }),
            });
            this.inventoryItemsService.forceUpdate();
          });
        }
      });
  }
}
