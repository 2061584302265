import {
  AfterContentInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BreakpointObserverService } from '@app/core/services/breakpoint-observer.service';
import { InventoryItemsService } from '@app/core/services/inventory-items.service';
import { ROUTES } from '@app/routes';
import { FilterService } from '@modules/filter/services/filter.service';
import { BatchDeleteInventoryItemsDialogComponent } from '@modules/inventory/components/batch-delete-inventory-items-dialog/batch-delete-inventory-items-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CreateLabelModalComponent } from '@shared/components/create-label-modal/create-label-modal.component';
import { OnboardingDialogService } from '@shared/components/onboarding-dialog/onboarding-dialog.service';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import {
  PaginationChangedEvent,
  PaginationComponent,
} from '@shared/components/ui/pagination/pagination.component';
import { InventoryItem } from '@shared/models/inventory-item';
import { PageQuery } from '@shared/models/page-meta';
import { PagedResult } from '@shared/models/paged-result';
import { PreviousRouteService } from '@shared/services/previous-route.service';
import { isEqual } from 'lodash';
import { distinctUntilChanged, Subject, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'packex-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss'],
})
export class InventoryListComponent
  implements OnInit, AfterContentInit, OnDestroy
{
  inventoryItems?: InventoryItem[];
  currentPage = 1;
  totalItems?: number;
  itemsPerPage: number = PaginationComponent.getDefaultItemsPerPage();
  loading = false;

  selectedItems: string[] = [];

  private loadInventoryItemsSubject = new Subject<number>();
  private inventoryItemsSubscription: any;

  @ViewChild('scrollContainer') scrollContainer?: ElementRef;
  @ViewChild('inventoryItemsContainer') inventoryItemsContainer?: ElementRef;

  constructor(
    private inventoryItemsService: InventoryItemsService,
    private router: Router,
    private breakpointService: BreakpointObserverService,
    private readonly filterService: FilterService,
    private readonly dialog: MatDialog,
    private readonly snackbarService: SnackBarService,
    private readonly onboardingService: OnboardingDialogService,
    private readonly previousRouteService: PreviousRouteService,
  ) {}

  ngOnInit(): void {
    this.inventoryItemsSubscription = this.loadInventoryItemsSubject
      .pipe(switchMap((page) => this.reload(page)))
      .subscribe({
        next: (data: PagedResult<InventoryItem> | undefined) => {
          if (data) {
            this.inventoryItems = data?.items.map(
              (inventoryItem: InventoryItem) => ({
                ...inventoryItem,
                selected: this.isSelected(inventoryItem),
              }),
            );
            this.totalItems = data?.page.totalItems;
          }
          this.loading = false;

          if (this.inventoryItemsContainer) {
            this.inventoryItemsContainer.nativeElement.scrollTo(0, {
              behaviour: 'smooth',
            });
          }

          document.body.scrollIntoView();
        },
        error: () => (this.loading = false),
      });
  }

  ngAfterContentInit() {
    if (this.previousRouteService.getPreviousUrl.includes('order')) {
      this.filterService.resetInventoryItemFilter();
    }

    this.inventoryItemsService
      .getDataUpdatedEmitter()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.loadInventoryItemsSubject.next(1));

    this.filterService.inventoryItemFilter
      .pipe(untilDestroyed(this), distinctUntilChanged(isEqual))
      .subscribe(() => this.loadInventoryItemsSubject.next(1));

    this.onboardingService.open();
  }

  ngOnDestroy() {
    this.filterService.resetInventoryItemFilter();

    if (this.inventoryItemsSubscription) {
      this.inventoryItemsSubscription.unsubscribe();
    }
  }

  public get isFilterSet(): boolean {
    return this.filterService.isFilterSet;
  }

  public loadInventoryItems(page: number, limit?: number) {
    this.loading = true;

    return this.inventoryItemsService
      .findAll(<PageQuery>{ page, limit })
      .pipe(untilDestroyed(this));
  }

  public paginationChanged(event: PaginationChangedEvent): void {
    this.itemsPerPage = event.itemsPerPage;
    this.loadInventoryItemsSubject.next(event.page);
  }

  public createProduct() {
    this.router.navigateByUrl(`/${ROUTES.CONSTRUCTIONS}`).then();
  }

  public handleEmptyScreen(): void {
    if (this.isFilterSet) {
      this.filterService.resetInventoryItemFilter();
    } else {
      this.createProduct();
    }
  }

  public onSelectionChanged(
    selected: boolean,
    inventoryItem: InventoryItem,
  ): void {
    inventoryItem.selected = selected;

    if (selected) {
      this.selectedItems = Array.from(
        new Set<string>([...this.selectedItems, inventoryItem.id]),
      );
    } else {
      this.selectedItems = this.selectedItems.filter(
        (inventoryItemId) => inventoryItemId !== inventoryItem.id,
      );
    }
  }

  public isSelected(inventoryItem: InventoryItem): boolean {
    return this.selectedItems.includes(inventoryItem.id);
  }

  public onSelectAll(allSelected: boolean): void {
    if (allSelected) {
      this.selectedItems =
        this.inventoryItems?.map(
          (inventoryItem: InventoryItem) => inventoryItem.id,
        ) || [];
    } else {
      this.selectedItems = [];
    }

    this.inventoryItems =
      this.inventoryItems?.map((inventoryItem: InventoryItem) => ({
        ...inventoryItem,
        selected: this.isSelected(inventoryItem),
      })) || [];
  }

  public get allSelected(): boolean {
    return (
      this.selectedItems.length === this.inventoryItems?.length &&
      this.inventoryItems?.length > 0
    );
  }

  get selectedInventoryItems(): InventoryItem[] {
    return (
      this.inventoryItems?.filter(
        (inventoryItem: InventoryItem) => inventoryItem.selected,
      ) || []
    );
  }

  public deleteSelectedItems(): void {
    this.dialog
      .open(BatchDeleteInventoryItemsDialogComponent, {
        data: {
          inventoryItems: this.selectedInventoryItems,
        },
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.inventoryItemsService
            .batchDelete(this.selectedInventoryItems)
            .subscribe(() => {
              this.snackbarService.showSimpleSuccess(
                'INVENTORY.LIST.ITEM.PRODUCTS_DELETED',
              );
              this.inventoryItemsService.forceUpdate();
            });
        }
      });
  }

  public createLabels() {
    this.dialog
      .open(CreateLabelModalComponent, {
        data: {},
      })
      .afterClosed()
      .subscribe((labels) => {
        if (labels && labels.length) {
          this.inventoryItemsService
            .batchUpdateLabels(this.selectedInventoryItems, labels)
            .subscribe(() => this.inventoryItemsService.forceUpdate());
        }
      });
  }

  private reload(page = 1) {
    this.currentPage = page;
    this.selectedItems = [];
    this.inventoryItems = [];

    return this.loadInventoryItems(this.currentPage, this.itemsPerPage);
  }

  public trackByFn(index: number, inventoryItem: InventoryItem): string {
    return inventoryItem.id;
  }
}
