<div class="packex-expansion-panel-container"
     [ngClass]="{'box-shadow': shadowed, 'mb-3': true, 'has-topnav':showTopnav}">
  <ng-container *ngIf="showTopnav">
    <div class="packex-expansion-panel-topnav">
      <mat-card class="mat-elevation-z0">
        <ng-content select="[topnav]"></ng-content>
      </mat-card>
    </div>
  </ng-container>
  <mat-expansion-panel (opened)="panelOpened()" (closed)="panelClosed()" hideToggle
                       [expanded]="expanded"
                       class="mat-elevation-z0 packex-expansion-panel">
    <mat-expansion-panel-header class="packex-expansion-panel-header" [ngClass]="{'mb-3': !expanded}">
      <mat-panel-title [hidden]="!showTitle">
        <ng-content select="[title]"></ng-content>
      </mat-panel-title>
      <mat-panel-description [hidden]="!showDescription">
        <ng-content select="[description]"></ng-content>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-action-row class="packex-expansion-panel-body"
                    [ngClass]="{'packex-expansion-panel-body-divider-hide': bodyDividerHide}">
      <ng-content select="[body]"></ng-content>
    </mat-action-row>
    <mat-action-row [hidden]="!showAction">
      <ng-content select="[action]"></ng-content>
    </mat-action-row>
  </mat-expansion-panel>
</div>
