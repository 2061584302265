import { ValidatorFn } from '@angular/forms';
import validator from 'validator';

export const validateEmail: ValidatorFn = (control) => {
  if (!control.value) return null;

  return validator.isEmail(control.value)
    ? null
    : {
        validateEmail: true,
      };
};

export const validatePhoneNumber: ValidatorFn = (control) => {
  if (!control.value) return null;

  const REGEXP = /^[+]*[(]?\d{1,4}[)]?[-\s\\./\d]*$/g;

  return REGEXP.test(control.value)
    ? null
    : {
        validatePhoneNumber: {
          valid: false,
        },
      };
};

export const validatePassword: ValidatorFn = (control) => {
  if (!control.value) return null;

  const REGEXP =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*"'()+,-./:;<=>?[\]^_`{|}~])(?=.{8,})/g;

  return REGEXP.test(control.value)
    ? null
    : {
        validatePassword: {
          valid: false,
        },
      };
};
