import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CartService } from '@modules/cart/cart.service';

@Component({
  selector: 'packex-cart-voucher',
  templateUrl: './cart-voucher.component.html',
  styleUrls: ['./cart-voucher.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartVoucherComponent {
  voucherCodeControl = new FormControl('', [Validators.required]);

  constructor(private readonly cartService: CartService) {}

  get cart() {
    return this.cartService.cart$.getValue();
  }

  public redeemVoucher(): void {
    const code = this.voucherCodeControl.value;

    if (code) {
      this.cartService.redeemVoucher(code).subscribe({
        next: () => {
          this.cartService.loadCart();
        },
        error: (response) => {
          if (response.statusCode === 404 || response.statusCode === 400) {
            this.voucherCodeControl.setErrors({
              invalid: true,
            });
          }
        },
      });
    }
  }

  public removeVoucher(): void {
    const { voucher } = this.cart;

    if (voucher) {
      this.cartService.deleteVoucher(voucher.code).subscribe(() => {
        this.cartService.loadCart();
      });
    }
  }
}
