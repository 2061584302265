import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { ROUTES } from '@app/routes';
import { CartPageComponent } from '@modules/cart/cart-page/cart-page.component';
import { OrderReceivedComponent } from '@modules/cart/order-received/order-received.component';
import { PaymentProcessingComponent } from '@modules/cart/payment-processing/payment-processing.component';

export type CartStep =
  | 'cart'
  | 'address-and-reference-informations'
  | 'payment'
  | 'order'
  | 'order-received';

export const CART_STEPS: { [k: string]: CartStep } = {
  CART: 'cart',
  ADDRESS_AND_COMMENTS: 'address-and-reference-informations',
  PAYMENT: 'payment',
  CHECK_AND_ORDER: 'order',
  ORDER_RECEIVED: 'order-received',
};

const cartRoutes: Routes = [
  {
    path: '',
    redirectTo: `/${ROUTES.CART}/${CART_STEPS['CART']}`,
    pathMatch: 'full',
  },
  {
    path: 'payment/:paymentId',
    canActivate: [AuthGuard],
    component: PaymentProcessingComponent,
  },
  {
    path: 'order-received/:id',
    component: OrderReceivedComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'PAGES.ORDER_RECEIVED',
    },
  },
  {
    path: ':step',
    component: CartPageComponent,
    canActivate: [AuthGuard],
    data: {
      hideHeader: true,
      headerLink: ROUTES['INVENTORY'],
      breadcrumb: {
        skip: true,
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(cartRoutes)],
  exports: [RouterModule],
})
export class CartRoutingModule {}
