<packex-page class="user-card-page">
  <packex-card class="h-auto">
    <h2 class="text-uppercase">{{ 'USER.YOU_GOT_MAIL.TITLE' | transloco}}</h2>
    <packex-divider></packex-divider>

    <p [innerHTML]="'USER.YOU_GOT_MAIL.CONTENT' | transloco"></p>
    <p class="mt-6">{{ 'USER.YOU_GOT_MAIL.MISSING_EMAIL' | transloco }}
      <a class="link" (click)="resend()">{{ 'USER.YOU_GOT_MAIL.RESEND_EMAIL' | transloco }}</a>
    </p>
  </packex-card>
</packex-page>
