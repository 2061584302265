import { Component, EventEmitter, Input, Output } from '@angular/core';

export type Step = {
  label?: string;
  icon?: string;
};

@Component({
  selector: 'packex-progress-stepper',
  templateUrl: './progress-stepper.component.html',
  styleUrls: ['./progress-stepper.component.scss'],
})
export class ProgressStepperComponent {
  @Input() steps: Step[] = [];
  @Input() currentStep = 0;
  @Input() iconMode = false;
  @Input() latestCompletedStep = 0;
  @Output() stepClicked = new EventEmitter<number>();

  stepIsCompleted(index: number): boolean {
    return index < this.latestCompletedStep || index < this.currentStep;
  }

  getStepNumberByIndex(index: number): number {
    return index + 1;
  }

  isCurrentStep(index: number) {
    return index === this.currentStep;
  }

  getIconColor(i: number) {
    const isCurrentStep = this.isCurrentStep(i);
    const isFutureStep = i > this.currentStep;

    if (isCurrentStep || isFutureStep) return 'white';

    return 'secondary';
  }
}
