<packex-modal (onDismiss)="dismiss()">
  <h5 class="modal-title">{{ dialogData.title | transloco }}</h5>

  <div class="modal-body">
    <p class="mb-4" *ngIf="dialogData.description" [innerHTML]="dialogData.description | transloco"></p>

    <form [formGroup]="form">
      <mat-form-field appearance="outline" class="w-100 mb-3" *ngIf="!dialogData.notification">
        <input matInput type="text" formControlName="email" [placeholder]="'USER.MODEL.EMAIL' | transloco" />
        <mat-error *ngIf="form.get('email')?.hasError('validateEmail')">{{'FORM.ERROR.EMAIL' | transloco}}</mat-error>
        <mat-error *ngIf="form.get('email')?.hasError('exists')">
          <div [innerHtml]="'ORGANIZATION.INVITE_COLLEAGUES.EXISTS_ERROR' | transloco"></div>
        </mat-error>
      </mat-form-field>

      <ng-container *ngIf="!dialogData.invite">
        <ng-container *ngIf="dialogData.notification">
          <packex-user-info [user]="dialogData.notification.user" *ngIf="dialogData.notification.user"
            [subtitle]="dialogData.notification.user.email" class="d-block mb-4"></packex-user-info>

        </ng-container>

        <div class="subtitle-1 mb-4" *ngIf="!dialogData.notification">{{ 'ORGANIZATION.STATUS_SETTINGS' | transloco }}
        </div>
        <p class="mb-2" [ngClass]="{'mb-6': !dialogData.settingsHint}"
          *ngIf="dialogData.settingsDescription && !dialogData.notification"
          [innerHTML]="dialogData.settingsDescription | transloco"></p>
        <p class="mb-2" [ngClass]="{'mb-6': !dialogData.settingsHint}"
          *ngIf="dialogData.settingsDescription && dialogData.notification"
          [innerHTML]="dialogData.settingsDescription | transloco: {email: dialogData.notification.email }"></p>
        <div class="subtitle-2 mb-6" *ngIf="dialogData.settingsHint">{{ dialogData.settingsHint | transloco }}</div>

        <div class="d-flex flex-column">
          <mat-slide-toggle color="accent" [checked]="allStatusChecked" (toggleChange)="onToggle()" class="mb-4 ms-3">
            <span class="subtitle-2 ms-2">{{ 'COMMON.SELECT_ALL' | transloco }}</span>
          </mat-slide-toggle>
          <mat-checkbox *ngFor="let topic of topics" class="ms-3" [formControlName]="topic">
            <span class="subtitle-2">{{ getStatusLabel(topic) | transloco }}</span>
          </mat-checkbox>
        </div>
      </ng-container>
    </form>
  </div>

  <packex-button [disabled]="form.invalid" (buttonClick)="submit()" class="w-auto">{{ dialogData.button | transloco
    }}</packex-button>
</packex-modal>