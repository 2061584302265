import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  Event,
  IsActiveMatchOptions,
  NavigationEnd,
  Router,
} from '@angular/router';
import { IMPERSONATE_KEY } from '@app/core/services/api.service';
import { BreakpointObserverService } from '@app/core/services/breakpoint-observer.service';
import { LanguageService } from '@app/core/services/language.service';
import { RoutingDataUtilityService } from '@app/core/services/routing-data-utility.service';
import { UserService } from '@app/core/services/user.service';
import { ROUTES, USER_ROUTES } from '@app/routes';
import { loginWithKeycloak } from '@app/utils/keycloak-helper';
import { CartService } from '@modules/cart/cart.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeactivateGuard } from '@shared/guards/deactivate.guard';
import { User } from '@shared/models/user';
import { DrawerService } from '@shared/services/drawer.service';
import { KeycloakService } from 'keycloak-angular';
import { filter, map, Observable } from 'rxjs';

type HeaderElements = {
  user: boolean;
  product: boolean;
  notifications: boolean;
  menu: boolean;
};

const initialHeaderElementsVisible: HeaderElements = {
  user: false,
  product: false,
  notifications: false,
  menu: false,
};

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  showHeader: boolean | undefined = true;
  loggedIn = false;

  readonly routerActiveMatchOptions: IsActiveMatchOptions = {
    queryParams: 'ignored',
    matrixParams: 'exact',
    paths: 'subset',
    fragment: 'exact',
  };

  public headerElementsVisible = initialHeaderElementsVisible;

  link = `${ROUTES.CONSTRUCTIONS}`;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingDataUtility: RoutingDataUtilityService,
    private keycloakService: KeycloakService,
    private readonly drawerService: DrawerService,
    private readonly breakpointService: BreakpointObserverService,
    private readonly userService: UserService,
    private readonly cartService: CartService,
    private readonly deactivateGuard: DeactivateGuard,
    public readonly dialog: MatDialog,
    private readonly languageService: LanguageService,
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event: Event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        map((route: any) => {
          if (route.data) {
            this.link = route.data.headerLink || this.link;
            this.showHeader = !route.data.hideHeader;
          } else {
            this.showHeader = !this.routingDataUtility.getSnapshotData(
              'hideHeader',
              this.route,
            );
          }
        }),
      )
      .subscribe(() => {
        this.handleRouteChange();
      });

    this.checkLogin();

    window.addEventListener('resize', () => {
      this.closeAllFoldouts();
    });
  }

  get user(): Observable<User | null> {
    return this.userService.user$.asObservable();
  }

  get numberOfProductsInCart$() {
    return this.cartService.cart$.pipe(map((cart) => cart.items.length));
  }

  get isUserImpersonated() {
    return !!sessionStorage.getItem(IMPERSONATE_KEY);
  }

  get showMenu(): boolean {
    return (
      Boolean(this.showHeader) &&
      !this.headerElementsVisible.user &&
      !this.headerElementsVisible.notifications
    );
  }

  public closeAllFoldouts() {
    this.patchElementVisibility({ ...initialHeaderElementsVisible });
  }

  private async checkLogin() {
    this.loggedIn = await this.keycloakService.isLoggedIn();
  }

  async login() {
    await loginWithKeycloak(
      this.keycloakService,
      this.languageService.currentLanguage(),
    );
  }

  public async openCartFlyout() {
    this.closeAllFoldouts();

    if (this.breakpointService.isMobile()) {
      await this.router.navigate(['/cart']);
    } else {
      await this.drawerService.open();
    }
  }

  public toggleMobileMenu(): void {
    this.patchElementVisibility({
      ...initialHeaderElementsVisible,
      menu: !this.headerElementsVisible.menu,
    });
  }

  public toggleUserFoldout(): void {
    this.patchElementVisibility({
      ...initialHeaderElementsVisible,
      user: !this.headerElementsVisible.user,
    });
  }

  public openUserFoldout(): void {
    this.patchElementVisibility({
      ...initialHeaderElementsVisible,
      user: true,
    });
  }

  public toggleProductsFoldout(): void {
    this.patchElementVisibility({
      ...initialHeaderElementsVisible,
      product: !this.headerElementsVisible.product,
    });
  }

  public openProductsFoldout(): void {
    this.patchElementVisibility({
      ...initialHeaderElementsVisible,
      product: true,
    });
  }

  public toggleNotificationFoldout() {
    this.patchElementVisibility({
      ...initialHeaderElementsVisible,
      notifications: !this.headerElementsVisible.notifications,
    });
  }

  public register(): void {
    this.router.navigateByUrl(`${ROUTES.USER}/${USER_ROUTES.REGISTRATION}`);
  }

  public onFilterClicked(): void {
    this.deactivateGuard.resetFilter.next(false);
    this.router.navigateByUrl(`${ROUTES['CONSTRUCTIONS']}`);
    this.patchElementVisibility({ product: false });
  }

  public unImpersonate() {
    sessionStorage.removeItem(IMPERSONATE_KEY);
    window.location.href = window.location.href.split('#')[0];
  }

  public patchElementVisibility(patch: Partial<HeaderElements>) {
    this.headerElementsVisible = {
      ...this.headerElementsVisible,
      ...patch,
    };
  }

  private handleRouteChange() {
    this.cartService.loadCart();
    this.closeAllFoldouts();
  }
}
