import { Component, Input } from '@angular/core';

@Component({
  selector: 'packex-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent {
  @Input() icon = 'check_circle';
  @Input() attributes: string[] | undefined;
}
