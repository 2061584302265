import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvitationService } from '@app/core/services/invitation.service';
import {
  KeyCloakTokenResponse,
  UserService,
} from '@app/core/services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EmptyScreenConfig } from '@shared/components/empty-screen/empty-screen.component';
import { Invitation } from '@shared/models/invitation';
import { User } from '@shared/models/user';
import { firstValueFrom, forkJoin } from 'rxjs';
import { KEYCLOAK_LS_KEY } from '@app/utils/keycloak-helper';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';

@UntilDestroy()
@Component({
  selector: 'packex-user-accept-invitation',
  templateUrl: './user-accept-invitation.component.html',
  styleUrls: ['./user-accept-invitation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserAcceptInvitationComponent implements OnInit {
  invitation?: Invitation;

  invitationInvalid = false;

  emptyScreen: EmptyScreenConfig = {
    title: 'USER.ACCEPT_INVITATION.INVITATION_INVALID',
    content: 'USER.ACCEPT_INVITATION.INVITATION_INVALID_DESCRIPTION',
    asset: 'invitation_invalid.svg',
  };

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly invitationService: InvitationService,
    private readonly userService: UserService,
    private readonly snackService: SnackBarService,
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(untilDestroyed(this))
      .subscribe(({ invitationId }) => {
        forkJoin([
          this.userService.load(),
          this.loadInvitation(invitationId),
        ]).subscribe();
      });
  }

  get isLoggedIn(): boolean {
    return !!this.userService.user;
  }

  private loadInvitation(invitationId: string) {
    this.invitationInvalid = false;

    return this.invitationService.loadInvitation(invitationId).subscribe({
      next: (invitation) => {
        if (
          this.isLoggedIn &&
          invitation.email !== this.userService.user?.email
        ) {
          this.invitationInvalid = true;
          return;
        }
        this.invitation = invitation;
      },
      error: () => {
        this.invitationInvalid = true;
      },
    });
  }

  public inviteUser(): string {
    return `${this.invitation?.invitedBy.lastName}, ${this.invitation?.invitedBy.firstName}`;
  }

  public accept() {
    if (this.invitation) {
      this.invitationService.accept(this.invitation.id).subscribe({
        next: () => {
          this.redirect();
        },
        error: () => {
          this.snackService.showSimpleError('USER.ACCEPT_INVITATION.ERROR');
          this.loadInvitation(String(this.invitation?.id));
        },
      });
    }
  }

  public decline() {
    if (this.invitation) {
      this.invitationService
        .decline(this.invitation.id)
        .subscribe(() => this.redirect());
    }
  }

  public async registrationProcessDone(
    userRegistration: User & { password: string },
  ): Promise<void> {
    const keycloakResponse = await firstValueFrom<KeyCloakTokenResponse>(
      this.userService.keyCloakAuth(
        userRegistration.email as string,
        userRegistration.password,
      ),
    );

    if (keycloakResponse) {
      try {
        localStorage.setItem(
          KEYCLOAK_LS_KEY,
          JSON.stringify({
            token: keycloakResponse.access_token,
            refreshToken: keycloakResponse.refresh_token,
            idToken: keycloakResponse.id_token,
          }),
        );

        await this.router.navigate(['/user/verify-email'], {
          state: { data: { userRegistration } },
        });
      } catch (e) {
        console.error(e);
      }
    } else {
      await this.router.navigate(['/'], {
        state: { data: { userRegistration } },
      });
    }
  }

  private redirect() {
    this.router.navigateByUrl('/user/complete').then();
  }
}
