import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { ROUTES } from '@app/routes';
import {
  mapProductFilterLocking,
  mapProductFilterTopic,
  mapProductFilterType,
} from '@app/utils/productFilter.helper';
import {
  FilterCategory,
  FilterType,
} from '@modules/filter/models/filter-category';
import { FilterItem } from '@modules/filter/models/filter-item';
import {
  InventoryFilterType,
  InventoryItemsFilter,
} from '@modules/filter/models/inventory-items-filter';
import { AppConfig } from '@shared/models/app-config';
import { ConstructionType } from '@shared/models/construction';
import { Label } from '@shared/models/label';
import {
  defaultInventoryItemSort,
  SortOption,
} from '@shared/models/sort-option';
import { AppConfigService } from '@shared/services/app-config.service';
import { isEqual } from 'lodash';
import { BehaviorSubject } from 'rxjs';

export const initialInventoryItemsFilter: InventoryItemsFilter = {
  labels: [],
  productFilter: 'all',
  sortOption: defaultInventoryItemSort,
  searchTerm: '',
};

export const filterCategories: FilterCategory[] = [
  {
    name: 'PRODUCT_FILTER.CATEGORY.IS_PRIVATE',
    isCategory: true,
    active: false,
    items: [],
    type: 'isPrivate',
  },
  {
    name: 'PRODUCT_FILTER.CATEGORY.CATEGORY',
    isCategory: true,
    active: true,
    items: [],
    type: 'type',
  },
  {
    name: 'PRODUCT_FILTER.CATEGORY.TOPIC',
    isCategory: false,
    active: false,
    type: 'topics',
    items: [],
  },
  {
    name: 'PRODUCT_FILTER.CATEGORY.FOLDING',
    isCategory: false,
    active: false,
    type: 'lockingType',
    items: [],
  },
];

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  selectedFilters: string[] = [];

  inventoryItemFilter = new BehaviorSubject<InventoryItemsFilter>(
    initialInventoryItemsFilter,
  );

  filterCategories$ = new BehaviorSubject<FilterCategory[]>([]);

  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly userService: UserService,
    private readonly router: Router,
  ) {
    this.appConfigService.appConfig$.subscribe((appConfig) => {
      if (appConfig) {
        this.applyAppConfig(appConfig);
      }
    });
  }

  get isFilterSet(): boolean {
    return !isEqual(
      this.inventoryItemFilter.getValue(),
      initialInventoryItemsFilter,
    );
  }

  get inventoryItemFilterValue(): InventoryItemsFilter {
    return this.inventoryItemFilter.getValue();
  }

  public applyAppConfig(appConfig: AppConfig) {
    this.mapProductFilters(appConfig);
  }

  public addFilter(item: FilterItem): void {
    if (item.name === 'Alle') {
      this.removeAllFilters();
    } else {
      item.active = !item.active;

      if (item.active && !this.selectedFilters.includes(item.name)) {
        this.selectedFilters.push(item.name);
        this.setFilterActive();
      } else if (!item.active) {
        this.removeFilter(item.name);
      }
    }
  }

  public removeFilter(filter: string): void {
    const index = this.selectedFilters.indexOf(filter);

    if (index >= 0) {
      this.selectedFilters.splice(index, 1);
    }
    this.setFilterActive();
  }

  public removeAllFilters(): void {
    this.selectedFilters = [];
    this.setFilterActive();
  }

  public setFilterActive(): void {
    const filters = this.selectedFilters;

    const filterCategories = this.filterCategories$
      .getValue()
      .map((filterCategory) => {
        return {
          ...filterCategory,
          items: filterCategory.items.map((item) => {
            item.active = filters.includes(item.name);
            return item;
          }),
        };
      });

    this.filterCategories$.next(filterCategories);
  }

  public getActiveFilterNameByType(type: FilterType): string[] {
    return (
      this.filterCategories$
        .getValue()
        .find((c) => c.type === type)
        ?.items.filter((item) => item.active)
        .map((item) => item.type) || []
    );
  }

  public setCategoryActive(name: string): void {
    const filterCategories = this.filterCategories$
      .getValue()
      .map((filterCategory) => ({
        ...filterCategory,
        active: filterCategory.name === name,
      }));

    this.filterCategories$.next(filterCategories);
  }

  public toggleCategoryActive(name: string): void {
    const filterCategories = this.filterCategories$
      .getValue()
      .map((filterCategory) => ({
        ...filterCategory,
        active:
          filterCategory.name === name
            ? !filterCategory.active
            : filterCategory.active,
      }));

    this.filterCategories$.next(filterCategories);
  }

  public getFilterCards(): FilterCategory | undefined {
    const filterCategory = this.filterCategories$
      .getValue()
      .find((category) => category.type === 'type');

    if (filterCategory) {
      return {
        ...filterCategory,
        items: filterCategory.items.filter(
          (item) => item.name !== 'COMMON.UNKNOWN',
        ),
      };
    }
    return;
  }

  public countActiveCategoryItems(items: any[]) {
    return items.filter((item) => item.active === true).length;
  }

  public activateLabelFilter(label: Label): void {
    const filter: InventoryItemsFilter = {
      ...this.inventoryItemFilterValue,
      labels: [
        ...this.inventoryItemFilterValue.labels,
        {
          ...label,
          active: true,
        },
      ],
    };

    this.inventoryItemFilter.next(filter);
  }

  public deActivateLabelFilter(label: Label): void {
    const filter: InventoryItemsFilter = {
      ...this.inventoryItemFilterValue,
      labels: [
        ...this.inventoryItemFilterValue.labels.filter(
          (x) => x.title !== label.title,
        ),
      ],
    };

    this.inventoryItemFilter.next(filter);
  }

  public setProductFilter(filter: InventoryFilterType): void {
    this.inventoryItemFilter.next({
      ...this.inventoryItemFilter.getValue(),
      productFilter: filter,
    });
  }

  public resetProductFilter() {
    this.inventoryItemFilter.next({
      ...this.inventoryItemFilter.getValue(),
      productFilter: 'all',
      labels: [],
    });
  }

  public setSearch(searchTerm: string): void {
    this.inventoryItemFilter.next({
      ...this.inventoryItemFilter.getValue(),
      searchTerm,
    });
  }

  public updateSort(value: SortOption): void {
    const filter: InventoryItemsFilter = {
      ...this.inventoryItemFilter.getValue(),
      sortOption: value,
    };

    this.inventoryItemFilter.next(filter);
  }

  public resetInventoryItemFilter(): void {
    this.inventoryItemFilter.next(initialInventoryItemsFilter);
  }

  public getIsPrivateFilter() {
    return this.filterCategories$
      .getValue()
      .find((x) => x.type === 'isPrivate');
  }

  private mapProductFilters(appConfig: AppConfig) {
    const mappedFilterCategories = filterCategories
      .filter((filterCategory) =>
        !this.userService.user ? filterCategory.type !== 'isPrivate' : true,
      )
      .map((filterCategory) => {
        switch (filterCategory.type) {
          case 'type':
            return {
              ...filterCategory,
              items: appConfig.productFilters.type.map((type) =>
                mapProductFilterType(type, this.selectedFilters),
              ),
            };
          case 'topics':
            return {
              ...filterCategory,
              items: appConfig.productFilters.topics.map((topic) =>
                mapProductFilterTopic(topic, this.selectedFilters),
              ),
            };
          case 'lockingType':
            return {
              ...filterCategory,
              items: appConfig.productFilters.lockingType
                .filter((type) => type !== 'unknown')
                .map((lockingType) => mapProductFilterLocking(lockingType)),
            };
          default:
            return filterCategory;
        }
      });

    this.filterCategories$.next(mappedFilterCategories);
  }

  public async addMarketingFilter(type: ConstructionType) {
    this.addFilter({
      name: `PRODUCT_FILTER.TYPE.${type}`,
      type,
      active: false,
    });

    await this.router.navigateByUrl(`${ROUTES['CONSTRUCTIONS']}/type/${type}`, {
      replaceUrl: true,
      onSameUrlNavigation: 'reload',
    });
  }
}
