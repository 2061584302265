import { AfterViewInit, Component, Inject } from '@angular/core';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { WINDOW } from '@shared/window.token';
import { Router } from '@angular/router';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';

export type CrossSellingCategory = {
  title: string;
  description: string;
  destination: string;
  image: string;
  backgroundColor: string;
};

@Component({
  selector: 'packex-cross-selling-dialog',
  templateUrl: './cross-selling-dialog.component.html',
  styleUrls: ['./cross-selling-dialog.component.scss'],
})
export class CrossSellingDialogComponent
  extends ModalComponent
  implements AfterViewInit
{
  categories: CrossSellingCategory[] = [
    {
      title: 'leaflet',
      description: 'LEAFLETS_DESCRIPTION',
      destination: '/constructions/type/leaflet',
      image: 'assets/images/cross-selling/beipackzettel.png',
      backgroundColor: '#8E87A5',
    },
    {
      title: 'label',
      description: 'LABELS_DESCRIPTION',
      destination: '/constructions/type/label',
      image: 'assets/images/cross-selling/etiketten.png',
      backgroundColor: '#90CEED',
    },
    {
      title: 'shipping_box',
      description: 'SHIPPING_BOXES_DESCRIPTION',
      image: 'assets/images/cross-selling/versandkarton.png',
      destination: '/constructions/type/shipping_box',
      backgroundColor: '#FDCB68',
    },
  ];

  constructor(
    dialogRef: MatDialogRef<CrossSellingDialogComponent>,
    public translateService: TranslocoService,
    @Inject(WINDOW) private window: Window,
    private readonly router: Router,
    private readonly gtm: GoogleTagService,
  ) {
    super(dialogRef);
  }

  ngAfterViewInit(): void {
    this.showButtons = false;
  }

  openCategory(category: CrossSellingCategory): void {
    this.router.navigateByUrl(category.destination);
    this.gtm.push('cross_selling_click', { type: category.title });
    this.dismiss();
  }
}
