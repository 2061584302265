<ng-container *ngIf="totalItems && totalItems > 0">
  <div class="packex-pagination-container" [class.disabled]="loading">
    <pagination-template [maxSize]="totalItems"
                         class="d-flex flex-row justify-content-between align-items-center" #p="paginationApi"
                         (pageBoundsCorrection)="pageChanged($event)"
                         (pageChange)="pageChanged($event)">

      <div class="pagination-items-per-page d-flex align-items-center">
        <div class="pagination-items-per-page-label text-primary-medium d-none d-md-flex caption">
          {{itemsPerPageLabel ?? 'PAGINATION.ITEMS_PER_PAGE' | transloco}}
        </div>
        <mat-form-field class="no-subscription">
          <mat-select [(ngModel)]="itemsPerPage" (selectionChange)="onItemsPerPageChanged()">
            <mat-option *ngFor="let option of ITEMS_PER_PAGE_OPTIONS"
                        [value]="option">{{option}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="caption text-primary-dark">
        {{ 'PAGINATION.PAGE_INFO' | transloco : {current: p.getCurrent(), total: p.getLastPage()} }}
      </div>
      <div class="pagination-control d-flex flex-row">
        <packex-button class="icon-only me-3"
                       type="clear-icon"
                       icon="first_page"
                       iconSize="s"
                       (buttonClick)="p.setCurrent(1)"
                       [disabled]="p.isFirstPage()">
        </packex-button>
        <packex-button class="icon-only me-3"
                       type="clear-icon"
                       icon="left"
                       iconSize="s"
                       (buttonClick)="p.previous()"
                       [disabled]="p.isFirstPage()">
        </packex-button>
        <packex-button class="icon-only me-3"
                       type="clear-icon"
                       icon="right"
                       iconSize="s"
                       (buttonClick)="p.next()"
                       [disabled]="p.isLastPage()">
        </packex-button>
        <packex-button class="icon-only"
                       type="clear-icon"
                       icon="last_page"
                       iconSize="s"
                       (buttonClick)="p.setCurrent(p.getLastPage())"
                       [disabled]="p.isLastPage()">
        </packex-button>
      </div>
    </pagination-template>
  </div>
</ng-container>
