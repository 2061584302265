import { Injectable } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { SESSION_LANGUAGE } from '@app/utils/language.helper';
import { TranslocoService } from '@ngneat/transloco';
import { filter, Subject } from 'rxjs';

export const AVAILABLE_LANGUAGES = ['de', 'en'];
export type LANGUAGE = (typeof AVAILABLE_LANGUAGES)[number];

export const LANGUAGE_LOCALE_MAPPING: Record<LANGUAGE, string> = {
  de: 'de-DE',
  en: 'en-EN',
};

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  storage = window.sessionStorage;

  public availableLanguages = AVAILABLE_LANGUAGES.map((language) => ({
    title: `LANGUAGES.${language.toUpperCase()}`,
    value: language as LANGUAGE,
    icon: 'language',
  }));

  languageChanged$ = new Subject<LANGUAGE>();

  constructor(
    private readonly userService: UserService,
    private readonly translocoService: TranslocoService,
  ) {
    this.switchLanguage(this.currentLanguage());

    this.getUserLanguage();
  }

  get browserLanguage(): LANGUAGE {
    const userLang = navigator.language.toLowerCase();

    return userLang.includes('de') ? 'de' : 'en';
  }

  public getSessionLanguage(): LANGUAGE {
    const sessionLanguage = this.storage.getItem(SESSION_LANGUAGE) as LANGUAGE;

    if (!sessionLanguage) {
      const language = this.browserLanguage;
      this.saveSessionLanguage(language);
      return language;
    } else {
      return sessionLanguage;
    }
  }

  private saveSessionLanguage(language: LANGUAGE) {
    this.storage.setItem(SESSION_LANGUAGE, language);
  }

  public currentLanguage(): LANGUAGE {
    return this.userService.user
      ? this.userService.user.preferredLanguage
      : this.getSessionLanguage();
  }

  public switchLanguage(language: LANGUAGE) {
    this.saveSessionLanguage(language);

    if (
      this.userService.user &&
      language !== this.userService.user.preferredLanguage
    ) {
      this.userService
        .updateUser({
          id: this.userService.user.id,
          preferredLanguage: language,
        })
        .subscribe(() => {
          this.languageChanged$.next(language);
        });
    } else if (!this.userService.user) {
      this.languageChanged$.next(language);
    } else {
      this.translocoService.setActiveLang(this.currentLanguage());
    }
  }

  private getUserLanguage() {
    this.userService.user$
      .pipe(filter((user) => !!user))
      .subscribe(
        (user) => user && this.switchLanguage(user?.preferredLanguage),
      );
  }

  get localeString() {
    return LANGUAGE_LOCALE_MAPPING[this.currentLanguage()];
  }
}
