<div class="products-foldout bg-white p-6 d-flex">
  <div class="w-25">
    <p class="button-navigation text-uppercase mb-3" *ngIf="!mobileView">{{ 'COMMON.PRODUCTS' | transloco }}</p>
    <p class="text-primary-dark mb-2 cursor-pointer" *ngFor="let category of categories"
       (click)="addFilter('type', category)">{{ category.name | transloco }}</p>
  </div>
  <div class="w-25" [ngClass]="{'border-top border-grey-medium': mobileView}">
    <p class="button-navigation text-uppercase mb-3 header">{{ 'COMMON.TOPICS' | transloco }}</p>
    <p class="text-primary-dark mb-2 cursor-pointer" *ngFor="let topic of topics"
       (click)="addFilter('topics', topic)">{{ topic.name | transloco }}</p>
  </div>
  <div class="border-start border-grey-medium d-flex w-50" *ngIf="!mobileView">
    <div class="w-50 ms-3 ms-xl-10">
      <div class="wrapper cursor-pointer" (click)="openMusterboxLink()">
        <p class="button-navigation text-uppercase mb-3">{{ 'PRODUCT_FOLDOUT.SAMPLE_BOX' | transloco }}</p>
        <div class="img-wrapper">
          <img alt="Musterbox" class="product-foldout-image" src="assets/images/menu/musterbox.jpg"/>
        </div>
        <p class="text-primary-medium mt-3">{{ 'PRODUCT_FOLDOUT.SAMPLE_BOX_TEXT' | transloco }}</p>
      </div>
    </div>
    <div class="w-50 ms-3 ms-xl-9">
      <div class="wrapper cursor-pointer" (click)="openRequestConstructionModal()">
        <p class="button-navigation text-uppercase mb-3">{{ 'PRODUCT_FOLDOUT.CUSTOM_CONSTRUCTION' | transloco }}</p>
        <div class="img-wrapper">
          <img alt="Eigene Konstruktion" class="product-foldout-image" src="assets/images/menu/marketing.jpg"/>
        </div>
        <p class="text-primary-medium mt-3">{{ 'PRODUCT_FOLDOUT.CUSTOM_CONSTRUCTION_TEXT' | transloco }}</p>
      </div>
    </div>
  </div>
</div>
