<packex-card class="w-100 mb-4">
  <h4 class="mb-6">{{ 'ORGANIZATION.STATUS_RECEIVER.USER_MANAGEMENT' | transloco }}</h4>
  <div class="subtitle-1 mb-4">{{ 'ORGANIZATION.STATUS_RECEIVER.COLLEAGUES' | transloco }}
    ({{ notificationsWithUsers?.length }})
  </div>
  <p class="mb-4">{{ 'ORGANIZATION.STATUS_RECEIVER.COLLEAGUES_DESCRIPTION' | transloco }}</p>
  <packex-button icon="add_circle" class="w-auto mb-4"
                 (buttonClick)="inviteColleague()">{{ 'ORGANIZATION.STATUS_RECEIVER.INVITE_COLLEAGUES' | transloco }}</packex-button>

  <packex-organization-status-receiver [notifications]="notificationsWithUsers" *ngIf="notificationsWithUsers"
                                       (notificationsChanged)="loadNotifications()"></packex-organization-status-receiver>

  <packex-invitation-list [invitations]="invitations" (invitationsChanged)="loadInvitations()"></packex-invitation-list>
  <div class="subtitle-1 mt-6 mb-4">{{ 'ORGANIZATION.STATUS_RECEIVER.ADDITIONAL_RECEIVER' | transloco }}
    ({{ notificationsWithOutUsers?.length }})
  </div>
  <p class="mb-4">{{ 'ORGANIZATION.STATUS_RECEIVER.ADDITIONAL_RECEIVER_DESCRIPTION' | transloco }}</p>
  <packex-button type="secondary" icon="add_circle" class="w-auto mb-4"
                 (buttonClick)="inviteStatusReceiver()">{{ 'ORGANIZATION.STATUS_RECEIVER.INVITE_RECEIVER' | transloco }}</packex-button>
  <ng-container *ngIf="notificationsWithOutUsers">
    <packex-organization-status-receiver [notifications]="notificationsWithOutUsers"
                                         *ngIf="notificationsWithOutUsers?.length"
                                         [displayedColumns]="['email', 'topics', 'action']"
                                         [showResendButton]="false"
                                         (notificationsChanged)="loadNotifications()"
    ></packex-organization-status-receiver>
  </ng-container>
</packex-card>
