<packex-modal  (onDismiss)="dismiss()">
  <h5 class="modal-title">
    {{ (this.dialogData?.address ? 'CART.DELIVERY.EDIT_DELIVERY_ADDRESS' : 'CART.DELIVERY.CREATE_DELIVERY_ADDRESS') | transloco }}
  </h5>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="d-flex flex-column">
        <mat-form-field appearance="outline" class="w-100">
          <input
            matInput
            type="text"
            [attr.data-testid]="'input-company'"
            [placeholder]="('ADDRESS.COMPANY' | transloco) + '*'"
            formControlName="company"
          />
          <mat-error *ngIf="form.get('company')?.invalid">{{ 'FORM.ERROR.COMPANY' | transloco }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
          <input
            matInput
            type="text"
            [attr.data-testid]="'input-recipient'"
            [placeholder]="('ADDRESS.RECIPIENT' | transloco) + '*'"
            formControlName="recipient"
          />
          <mat-error *ngIf="form.get('recipient')?.invalid">{{ 'FORM.ERROR.RECIPIENT' | transloco }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
          <input
            matInput
            type="text"
            [attr.data-testid]="'input-street'"
            [placeholder]="('ADDRESS.STREET' | transloco) + '*'"
            formControlName="street"
          />
          <mat-error *ngIf="form.get('street')?.invalid">{{ 'FORM.ERROR.STREET' | transloco }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
          <input
            matInput
            type="text"
            [attr.data-testid]="'input-additional'"
            [placeholder]="'ADDRESS.ADDITIONAL' | transloco"
            formControlName="additionalAddress"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
          <input
            matInput
            type="text"
            [attr.data-testid]="'input-postalcode'"
            [placeholder]="('ADDRESS.POSTAL_CODE' | transloco) + '*'"
            formControlName="postalCode"
          />
          <mat-error *ngIf="form.get('postalCode')?.invalid">{{ 'FORM.ERROR.POSTALCODE' | transloco }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
          <input
            matInput
            type="text"
            [attr.data-testid]="'input-city'"
            [placeholder]="('ADDRESS.CITY' | transloco) + '*'"
            formControlName="city"
          />
          <mat-error *ngIf="form.get('city')?.invalid">{{ 'FORM.ERROR.CITY' | transloco }}</mat-error>
        </mat-form-field>
        <packex-countries-select [options]="countries" [label]="('ADDRESS.COUNTRY' | transloco) + '*'" class="mb-3"
                                 [current]="currentCountry"
                                 [showAutocomplete]="true"
                                 [attr.data-testid]="'select-country'"
                                 (currentChange)="countryChanged($event)">
        </packex-countries-select>
        <mat-form-field appearance="outline" class="w-100">
          <input
            matInput
            type="text"
            [attr.data-testid]="'input-phone'"
            [placeholder]="('ADDRESS.PHONE' | transloco) + '*'"
            formControlName="phone"
          />
          <mat-error *ngIf="form.get('phone')?.invalid">
            {{ 'FORM.ERROR.PHONE' | transloco }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>

  <packex-button (buttonClick)="save()"
                 class="w-auto"
                 [attr.data-testid]="'create-address-button-save'"
                 [disabled]="!form.valid">{{ 'COMMON.SAVE_AND_CONTINUE' | transloco }}</packex-button>
</packex-modal>
