<packex-page class="user-card-page">
  <packex-card class="h-auto">
    <h2 class="text-uppercase">{{ 'USER.SET_PASSWORD.TITLE' | transloco}}</h2>
    <packex-divider></packex-divider>

    <p class="mb-4">{{ 'USER.SET_PASSWORD.CONTENT' | transloco}}</p>

    <div class="subtitle-2 my-4">{{email}}</div>

    <packex-change-password-form [form]="form"></packex-change-password-form>

    <p class="mb-4" [innerHTML]="'USER.SET_PASSWORD.ACCEPTANCE' | transloco"></p>

    <mat-checkbox [formControl]="marketingAcceptance" color="primary"
                  class="mr-1 caption mt-3 mb-6">{{'USER.CONFRIM_MARKETING_HINT' | transloco }}</mat-checkbox>

    <packex-button (buttonClick)="submit()" class="mt-3"
                   [disabled]="!formIsValid">{{'USER.SET_PASSWORD.BUTTON' | transloco}}</packex-button>
  </packex-card>
</packex-page>
