import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LanguageService } from '@app/core/services/language.service';
import { OnboardingDialogComponent } from '@shared/components/onboarding-dialog/onboarding-dialog.component';

const ONBOARDING_KEY = 'onboarding_seen';

@Injectable({
  providedIn: 'root',
})
export class OnboardingDialogService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly languageService: LanguageService,
  ) {}

  public getSeen(): boolean {
    return localStorage && localStorage.getItem(ONBOARDING_KEY) === 'true';
  }

  public setSeen() {
    localStorage && localStorage.setItem(ONBOARDING_KEY, 'true');
  }

  public open(): void {
    if (!this.getSeen()) {
      this.dialog
        .open(OnboardingDialogComponent)
        .afterOpened()
        .subscribe(() => {
          this.setSeen();
        });
    }
  }
}
