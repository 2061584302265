import { Component, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeactivateGuard implements CanDeactivate<any> {
  public canNavigate = false;

  public navigationCancelled = new BehaviorSubject<boolean>(this.canNavigate);
  public next = new BehaviorSubject<string>('');
  public resetFilter = new BehaviorSubject<boolean>(true);

  canDeactivate(
    component: Component,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    next?: RouterStateSnapshot,
  ) {
    this.next.next(next?.url || '');

    if (!this.canNavigate) {
      this.navigationCancelled.next(this.canNavigate);
    }
    return this.canNavigate;
  }
}
