import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { InventoryItemsService } from '@app/core/services/inventory-items.service';
import { VariantsService } from '@app/core/services/variants.service';
import {
  variantHasError,
  variantHasStatus,
  variantHasWarning,
} from '@app/utils/variant-helper';
import { ModalCartService } from '@modules/cart/modal-cart.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Variant } from '@shared/models/variant';
import { BehaviorSubject } from 'rxjs';

export type PrintDataUploadResultState = {
  loading: boolean;
  loaded: boolean;
  variants: Variant[];
};

const initialState: PrintDataUploadResultState = {
  loading: false,
  loaded: false,
  variants: [],
};

@UntilDestroy()
@Component({
  selector: 'packex-print-data-upload-result',
  templateUrl: './print-data-upload-result.component.html',
  styleUrls: ['./print-data-upload-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintDataUploadResultComponent implements OnInit {
  @Input() inventoryItemId?: string;
  @Output() addVariantClicked = new EventEmitter();

  state$ = new BehaviorSubject<PrintDataUploadResultState>(initialState);

  constructor(
    private readonly inventoryItemsService: InventoryItemsService,
    private readonly variantsService: VariantsService,
    private readonly modalCartService: ModalCartService,
  ) {}

  ngOnInit() {
    if (this.inventoryItemId) {
      this.state$.next({
        ...this.state$.getValue(),
        loading: true,
      });
    }

    this.loadVariants();

    this.inventoryItemsService
      .getDataUpdatedEmitter()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.loadVariants());
  }

  private loadVariants() {
    if (this.inventoryItemId) {
      this.variantsService
        .getVariants(this.inventoryItemId)
        .subscribe((response) => {
          this.state$.next({
            loading: false,
            loaded: true,
            variants: response,
          });
        });
    }
  }

  public addVariant(): void {
    this.addVariantClicked.next(true);
  }

  get variants(): Variant[] {
    return this.state$.getValue().variants;
  }

  get hasCompletedItems(): boolean {
    return (
      this.variants.some(
        (variant: Variant) =>
          variantHasStatus(variant, 'complete') && variant.orderable,
      ) || false
    );
  }

  get hasErrors(): boolean {
    return (
      this.variants.some((variant: Variant) => variantHasError(variant)) ||
      false
    );
  }

  get hasWarnings(): boolean {
    return (
      this.variants.some((variant: Variant) => variantHasWarning(variant)) ||
      false
    );
  }

  public async addProductToCart(): Promise<void> {
    if (this.inventoryItemId) {
      this.inventoryItemsService
        .findOne(this.inventoryItemId)
        .subscribe((inventoryItem) => {
          this.modalCartService.addVariantToCartAndOpenCartModal(inventoryItem);
        });
    }
  }
}
