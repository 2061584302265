import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FilterService } from '@modules/filter/services/filter.service';
import { ModalComponent } from '@shared/components/modal/modal.component';

@Component({
  selector: 'packex-inventory-item-mobile-filter',
  templateUrl: './inventory-item-mobile-filter.component.html',
  styleUrls: ['./inventory-item-mobile-filter.component.scss'],
})
export class InventoryItemMobileFilterComponent extends ModalComponent {
  constructor(
    dialogRef: MatDialogRef<InventoryItemMobileFilterComponent>,
    private readonly filterService: FilterService,
  ) {
    super(dialogRef);
  }

  public resetFilter(): void {
    this.filterService.resetProductFilter();
  }
}
