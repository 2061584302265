import { Component, OnInit } from '@angular/core';
import { ChooseAddressModalComponent } from '@modules/cart/choose-address-modal/choose-address-modal.component';
import { deliveryAddressConfig } from '@shared/address-config';
import { Address } from '@shared/models/address';

@Component({
  selector: 'packex-choose-delivery-address-modal',
  templateUrl: '../choose-address-modal.component.html',
  styleUrls: ['../choose-address-modal.component.scss'],
})
export class ChooseDeliveryAddressModalComponent
  extends ChooseAddressModalComponent
  implements OnInit
{
  override ngOnInit(): void {
    super.ngOnInit();

    this.setConfig(deliveryAddressConfig);
  }

  public override chooseAddress(address: Address): void {
    this.onConfirm(address);
  }
}
