<packex-page class="py-4">
  <h3>{{ 'USER.ORGANIZATION.PAGE_TITLE' | transloco }}</h3>

  <packex-divider></packex-divider>

  <packex-organization-name></packex-organization-name>

  <packex-organization-colleagues></packex-organization-colleagues>

  <packex-organization-addresses></packex-organization-addresses>
</packex-page>
