import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private readonly api: ApiService) {}

  public setDefaultDeliveryAddress(defaultDeliveryAddressId: string) {
    return this.api.patch('organizations/me', {
      defaultDeliveryAddressId,
    });
  }

  public setDefaultBillingAddress(defaultBillingAddressId: string) {
    return this.api.patch('organizations/me', {
      defaultBillingAddressId,
    });
  }
}
