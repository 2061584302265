<packex-page>
  <packex-loading-component *ngIf="(loading$ | async) === true" message="ORDER.CREATING"></packex-loading-component>
  <div class="content-wrapper py-4" *ngIf="(loading$ | async) === false">
    <h3 class="text-primary" #scrollTo [attr.data-testid]="'cart-page-title'">{{ cartTitle | transloco }}</h3>
    <packex-divider></packex-divider>

    <packex-progress-stepper [steps]="steps" class="d-flex mt-3 mb-8"
                             [currentStep]="currentStep"
                             [latestCompletedStep]="cartService.nextStep"
                             (stepClicked)="onStepClicked($event)"></packex-progress-stepper>

    <div class="col-12 col-md-3 d-flex justify-flex-start mb-4">
      <packex-button type="tertiary" (buttonClick)="back()"
                     class="w-auto"
                     color="secondary" icon="left">
        <ng-container *ngIf="cartService.isCheckoutStep; else backText">
          {{ 'CART.DETAILS.SHOP_MORE' | transloco }}
        </ng-container>
        <ng-template #backText>
          {{ 'COMMON.BACK' | transloco }}
        </ng-template>
      </packex-button>
    </div>

    <div class="d-flex justify-content-between pb-6 flex-column flex-lg-row">
      <ng-container [ngSwitch]="currentStep">
        <packex-cart-item-list *ngSwitchCase="0" class="w-100 w-lg-66"></packex-cart-item-list>
        <packex-cart-address-and-informations *ngSwitchCase="1" class="w-100 w-lg-66"></packex-cart-address-and-informations>
        <packex-cart-payment *ngSwitchCase="2" class="w-100 w-lg-66"></packex-cart-payment>
        <packex-cart-order-summary *ngSwitchCase="3" class="w-100 w-lg-66"></packex-cart-order-summary>
      </ng-container>
      <div class="mt-4 mt-lg-0 ms-0 ms-lg-4 w-100 w-lg-33">
        <packex-cart-price-overview class="box-shadow"></packex-cart-price-overview>
        <packex-carousel class="mt-3 d-none d-lg-block" size="s"></packex-carousel>
        <packex-carousel class="mt-3 d-block d-lg-none" size="l"></packex-carousel>
      </div>
    </div>
  </div>

</packex-page>
