<mat-expansion-panel [expanded]="expanded">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span class="subtitle-2">
        <ng-content select=".title"></ng-content>
      </span>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <p class="content text-primary-medium w-75">
    <ng-content></ng-content>
  </p>
</mat-expansion-panel>
