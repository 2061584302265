import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { xmlResponseToSafeSvgUrl } from '@app/utils/svgXMLToSvgUrl';
import { CartService } from '@modules/cart/cart.service';
import { CartItem } from '@shared/models/cart';
import { BehaviorSubject, catchError, of } from 'rxjs';

@Component({
  selector: 'packex-cart-item-thumbnail',
  templateUrl: './cart-item-thumbnail.component.html',
  styleUrls: ['./cart-item-thumbnail.component.scss'],
})
export class CartItemThumbnailComponent implements OnChanges {
  @Input() cartItem!: CartItem;
  imageThumbnail$ = new BehaviorSubject<SafeResourceUrl>('');

  emptyThumbnail = 'assets/icons/primary/canceled_primary.svg';

  constructor(
    private readonly cartService: CartService,
    private readonly sanitizer: DomSanitizer,
  ) {}

  ngOnChanges(): void {
    this.cartService
      .getCartItemThumbnail(this.cartItem)
      .pipe(
        catchError(() => {
          this.imageThumbnail$.next(this.emptyThumbnail);
          return of(null);
        }),
      )
      .subscribe((response) => {
        if (response) {
          const image = this.sanitizer.bypassSecurityTrustResourceUrl(
            xmlResponseToSafeSvgUrl(response),
          );

          this.imageThumbnail$.next(image);
        }
      });
  }
}
