<packex-modal (onDismiss)="dismiss()" size="lg">
  <h5 class="modal-title d-flex">{{ cartTitle | transloco }}</h5>

  <div class="modal-body" [attr.data-testid]="'cart-modal'">
    <p class="text-primary-dark mb-4">{{ cartDescription | transloco }}</p>

    <div *ngIf="dialogData.inventoryItem" class="border border-1 border-grey-medium p-3 d-flex flex-row">
      <packex-inventory-item-dieline-svg [inventoryItem]="dialogData.inventoryItem"
                                         class="col-2 me-3"></packex-inventory-item-dieline-svg>
      <packex-inventory-item-header [inventoryItem]="dialogData.inventoryItem"></packex-inventory-item-header>
    </div>

    <ng-container *ngIf="modalCartService.cart">
      <div class="cart-items-list mt-5" *ngIf="modalCartService.cart.items.length > 0">
        <div class="subtitle-2 text-primary-medium mb-2">{{ 'CART.MODAL.VARIANTS' | transloco }}</div>

        <packex-modal-cart-item [item]="item" *ngFor="let item of modalCartService.cart.items; let index = index;"
                                (itemRemoved)="onItemRemoved()"
                                [index]="(index + 1)" [loading]="loading"></packex-modal-cart-item>
      </div>
    </ng-container>

    <packex-modal-cart-item-select label="CART.MODAL.SELECT_VARIANT" *ngIf="selectOptions.length"
                                   [attr.data-testid]="'modal-item-select'"
                                   [disabled]="selectDisabled || loading"
                                   (currentChange)="addItem($event)"
                                   [showPlaceholder]="true"
                                   [options]="selectOptions" class="d-block mt-4"></packex-modal-cart-item-select>

    <packex-cart-delivery (deliveryMethodChanged)="onDeliveryMethodChanged($event)"
                          [deliveryMethods]="getAvailableDeliveryMethods()"
                          [current]="modalCartService.cart.deliveryMethodId"
                          [productType]="dialogData.inventoryItem.construction.category"
                          [disabled]="loading || !modalCartService.cart.items.length"></packex-cart-delivery>

    <packex-cart-total [items]="modalCartService.cart.items" [loading]="loading"></packex-cart-total>
  </div>

  <packex-button type="primary" [disabled]="cartDisabled" icon="cart"
                 [attr.data-testid]="'cart-modal-cart-button'"
                 class="w-auto"
                 [matTooltip]="!modalCartService.cart.items.length ? ('CART.MODAL.BUTTON_TOOLTIP' | transloco) : ''"
                 (buttonClick)="addToCart()">{{ 'CART.ADD_TO_CART' | transloco }}</packex-button>
</packex-modal>
