<div class="inventory-item-details">
  <div class="col-12 pb-xl-0 pt-xl-2 order-1">
    <div *ngIf="inventoryItem" class="d-flex flex-row flex-wrap">
      <div class="col-6 col-lg-3 pb-4 detail-col detail-col-dimensions pe-3">
        <div class="subtitle-2 text-primary-medium mb-2">{{ 'INVENTORY.LIST.ITEM.DETAILS.DIMENSIONS' | transloco }}
        </div>
        <ul class="inventory-item-dimension-details">
          <li *ngIf="inventoryItem.a">
            <span class="detail-title subtitle-2">{{ 'CONFIGURATION.FORMAT.WIDTH' | transloco }}:</span>
            <span
                class="caption text-primary-medium" [attr.data-testid]="'dimension-a'">{{ inventoryItem.a }} mm</span>
          </li>
          <li *ngIf="inventoryItem.b && inventoryItem.construction.category !== 'label'">
            <span class="detail-title subtitle-2">{{ 'CONFIGURATION.FORMAT.DEPTH' | transloco }}:</span>
            <span class="caption text-primary-medium" [attr.data-testid]="'dimension-b'">{{ inventoryItem.b }} mm</span>
          </li>
          <li *ngIf="inventoryItem.h"><span
              class="detail-title subtitle-2">{{ 'CONFIGURATION.FORMAT.HEIGHT' | transloco }}:</span><span
              class="caption text-primary-medium" [attr.data-testid]="'dimension-h'">{{ inventoryItem.h }} mm</span>
          </li>
        </ul>
      </div>
      <div class="col-6 col-lg-3 pb-4 detail-col detail-col-material pe-3 d-flex flex-column">
        <div class="subtitle-2 text-primary-medium mb-2" *ngIf="inventoryItem.construction.category !== 'shipping_box'">
          {{ 'INVENTORY.LIST.ITEM.DETAILS.MATERIAL' | transloco }}
        </div>
        <div class="subtitle-2 text-primary-medium mb-2" *ngIf="inventoryItem.construction.category === 'shipping_box'">
          {{ 'CONFIGURATION.COLOR.TITLE' | transloco }}
        </div>
        <ng-container *ngIf="inventoryItem.material">
          <span class="subtitle-2"
                [attr.data-testid]="'inventory-item-material-group'">{{ inventoryItem.material.materialGroup.name }}</span>
          <span class="caption text-primary-medium" *ngIf="!hideGrammage"
                [attr.data-testid]="'inventory-item-material-grammage'">{{ inventoryItem.material.grammage }}&nbsp;<span
              [innerHTML]="'CONFIGURATION.MATERIAL.UNIT' | transloco"></span></span>
        </ng-container>
      </div>
      <div class="col-12 col-lg-3 pb-4 detail-col detail-col-services pe-3"
           *ngIf="inventoryItem.productOptions.length">
        <div class="subtitle-2 text-primary-medium mb-2">{{ 'INVENTORY.LIST.ITEM.DETAILS.SERVICES' | transloco }}</div>
        <ng-container>
          <div class="detail-title" *ngFor="let productOption of inventoryItem.productOptions">
            <span class="subtitle-2 text-primary-dark"
                  [attr.data-testid]="'inventory-item-product-option-'+ productOption.name">{{ productOption.name }}</span>
          </div>
        </ng-container>
      </div>
      <div class="d-none d-xl-block w-25 ms-auto" *ngIf="inventoryItem && inventoryItem.lastChangedBy">
        <div class="user-info-item align-middle">
          <packex-user-info [user]="inventoryItem.lastChangedBy"
                            class="d-flex justify-content-end"
                            [subtitle]="formatDate(injector, inventoryItem.updatedAt)"></packex-user-info>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row flex-wrap" *ngIf="inventoryItem">
      <div class="col-6 col-lg-3 pb-4 detail-col detail-col-material pe-3 d-flex flex-column"
           *ngIf="getCoating()">
        <div class="subtitle-2 text-primary-medium mb-2">{{ getCoating()?.attribute?.name ?? '-' }}</div>
        <span class="subtitle-2" [attr.data-testid]="'inventory-item-material-group'">
            {{ getCoating()?.name ?? '-' }}
          </span>
      </div>
      <div class="col-6 col-lg-3 pb-4 detail-col detail-col-material pe-3 d-flex flex-column"
           *ngIf="inventoryItem.winding">
        <div class="subtitle-2 text-primary-medium mb-2">{{ 'CONFIGURATION.WINDING.TITLE' | transloco }}</div>
        <span class="subtitle-2" [attr.data-testid]="'inventory-item-material-group'">
          {{ 'CONFIGURATION.WINDING.' + inventoryItem.winding.toUpperCase() | transloco }}
        </span>
        <span class="caption text-primary-medium" *ngIf="inventoryItem.rollDiameter">
          {{ inventoryItem.rollDiameter }}mm ({{ 'CONFIGURATION.WINDING.ROLL' | transloco }})
        </span>
      </div>
    </div>
  </div>
  <div class="col-12 order-2 order-xl-3 inventory-print-data-table mb-3">
    <ng-template [ngTemplateOutlet]="variantsTemplate"
                 [ngTemplateOutletContext]="{variants: variants}"></ng-template>
  </div>
</div>
<ng-template #variantsTemplate let-variants="variants">
  <table mat-table [dataSource]="variants" *ngIf="variants && variants.length > 0">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef
          class="subtitle-2 text-primary-medium"> {{ 'INVENTORY.LIST.ITEM.DETAILS.PRINT_DATA_GRID.COL_TITLE' | transloco }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="inventory-print-data-row">
          <div class="col-2 inventory-print-data-index position">
            <span class="subtitle-2">{{ element.position }}</span>
          </div>
          <div class="col-10 inventory-print-data-name subtitle-2 text-break"
               [attr.data-testid]="'variant-'+ element.name"
               [innerHTML]="getVariantName(element)  | highlightSearch: searchTerm">
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="subtitle-2 text-primary-medium">
          <span
              class="d-none d-md-block">{{ 'INVENTORY.LIST.ITEM.DETAILS.PRINT_DATA_GRID.COL_STATUS' | transloco }}</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="getVariantStatus(element) | async as status">
          <ng-container *ngIf="!status.orderable; else orderable">
            <button (click)="openPrintDataManager.emit()">
              <packex-icon icon="warning"
                           class="d-inline-block"
                           [matTooltip]="orderButtonTooltip(element)"
              />
            </button>
          </ng-container>
          <ng-template #orderable>
            <packex-icon icon="check" class="d-inline-block"
                         [matTooltip]="'VARIANT.STATUS_COMPLETE_TEXT' | transloco"
                         *ngIf="status.status === 'complete'"></packex-icon>
            <packex-button icon="technical_printdata_editing" type="clear-icon" class="d-inline-block"
                           [matTooltip]="'VARIANT.STATUS_PENDING_ARTWORK_TEXT' | transloco"
                           *ngIf="status.inProgress"
                           (buttonClick)="openInProgressDialog()"></packex-button>
            <packex-button icon="error" color="info" type="clear-icon" class="d-inline-block"
                           [matTooltip]="'VARIANT.STATUS_ERROR_TEXT' | transloco"
                           *ngIf="status.hasError"
                           (buttonClick)="openPreflightModal(element)"></packex-button>
            <packex-button icon="warning" type="clear-icon" class="d-inline-block"
                           [matTooltip]="'VARIANT.STATUS_WARNING_TEXT' | transloco"
                           *ngIf="status.hasWarning"
                           (buttonClick)="openPreflightModal(element)"></packex-button>
          </ng-template>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="cart">
      <th mat-header-cell *matHeaderCellDef class="subtitle-2 text-primary-medium">
        <span class="d-none d-md-block">{{ 'COMMON.CART' | transloco }}</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <packex-button (buttonClick)="addVariantToCart(element)" [disabled]="cartButtonIsDisabled(element)"
                       type="clear-icon"
                       [attr.data-testid]="'variant-cart-button-'+ element.name"
                       icon="cart" class="d-flex justify-content-center"
                       [matTooltip]="orderButtonTooltip(element)">
        </packex-button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="printDataHeaders"></tr>
    <tr mat-row *matRowDef="let row; columns: printDataColumns;"></tr>
  </table>
  <div class="col-12 d-block d-xl-none user-info-col" *ngIf="inventoryItem && inventoryItem.lastChangedBy">
    <div class="user-info-item align-middle">
      <packex-user-info [user]="inventoryItem.lastChangedBy"
                        [subtitle]="formatDate(injector, inventoryItem.updatedAt)"></packex-user-info>
    </div>
  </div>
</ng-template>
