import { HttpResponse } from '@angular/common/http';
import { Buffer } from 'buffer';

export const xmlResponseToSafeSvgUrl = (response: HttpResponse<string>) => {
  const el = document.createElement('div');

  if (typeof response.body === 'string') {
    el.innerHTML = response.body;
  }

  const svg = el.querySelector('svg') as unknown as string;

  return svgXMLToSafeSvgUrl(svg);
};

export const svgXMLToSafeSvgUrl = (svg: string) => {
  const xml = new XMLSerializer().serializeToString(svg as unknown as Node);
  const svg64 = Buffer.from(xml).toString('base64');
  const b64start = 'data:image/svg+xml;base64,';

  return b64start + svg64;
};
