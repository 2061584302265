<form [class]="'packex-form'" [formGroup]="form" (ngSubmit)="submit()">
  <p [innerHTML]="'USER.REGISTRATION.COMPLETE.COMPLETE_HINT' | transloco" class="mb-4"></p>

  <mat-form-field appearance="outline" class="w-100 mb-3">
    <input [readonly]="loading" matInput placeholder="{{'USER.MODEL.FIRST_NAME' | transloco}}*" formControlName="firstName"
           name="firstName" required>
    <mat-error
      *ngIf="form.controls['firstName'].invalid">{{'FORM.ERROR.FIRST_NAME' | transloco}}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-100 mb-3">
    <input [readonly]="loading" matInput placeholder="{{'USER.MODEL.LAST_NAME' | transloco}}*" formControlName="lastName"
           name="lastName" required>
    <mat-error
      *ngIf="form.controls['lastName'].invalid">{{'FORM.ERROR.LAST_NAME' | transloco}}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-100 mb-3">
    <input [readonly]="loading" matInput placeholder="{{'USER.MODEL.COMPANY' | transloco}}*" formControlName="organization"
           name="organization" required>
    <mat-error
      *ngIf="form.controls['organization'].invalid">{{'FORM.ERROR.COMPANY' | transloco}}</mat-error>
  </mat-form-field>

  <packex-button [disabled]="loading || form.invalid" type="primary" buttonType="submit">{{'COMMON.FORWARD' | transloco}}</packex-button>
</form>
