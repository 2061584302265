<div *ngIf="product" class="d-flex flex-column">
  <div class="d-flex flex-row product-header" [ngClass]="{  'pb-3': !reducedView}">
    <packex-cart-item-thumbnail [cartItem]="product.items[0]" class="w-25"></packex-cart-item-thumbnail>
    <div class="d-flex flex-column w-100">
      <packex-inventory-item-header
        [reducedView]="true"
        [cartProduct]="product"
        [inventoryItem]="product.items[0].variant.inventoryItem"></packex-inventory-item-header>
      <packex-button type="tertiary" (click)="editProduct()" color="secondary"
                     buttonPadding="p-0"
                     class="w-auto">{{ 'COMMON.EDIT' | transloco }}</packex-button>
    </div>
    <packex-button *ngIf="!reducedView" icon="delete"
                   (buttonClick)="deleteAllItemsForProduct(product)"
                   type="clear-icon" class="align-self-start"></packex-button>
  </div>
  <packex-cart-flyout-item *ngFor="let item of product.items; let i = index"
                           [attr.data-testid]="'cart-flyout-item'"
                           [id]="item.id"
                           [reducedView]="reducedView"
                           [item]="item" [index]="(i + 1)" class="mt-3"></packex-cart-flyout-item>
</div>
