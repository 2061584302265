import { AbstractControl } from '@angular/forms';

export const fieldHasError = (field: AbstractControl | null): boolean => {
  return Boolean(
    field &&
      field.invalid &&
      (field.dirty || field.touched) &&
      Object.keys(field.errors || {}).length,
  );
};

export const splitByNewline = (value: string) => {
  return value.split(/\n/g).filter((x) => !!x);
};
