import { ComponentType } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@app/core/services/user.service';
import { CartService } from '@modules/cart/cart.service';
import { CreateBillingAddressModalComponent } from '@modules/cart/create-billing-address-modal/create-billing-address-modal.component';
import { CreateDeliveryAddressModalComponent } from '@modules/cart/create-delivery-address-modal/create-delivery-address-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { Address } from '@shared/models/address';
import { Cart } from '@shared/models/cart';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';
import { BehaviorSubject, combineLatestWith, filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'packex-cart-address-and-informations',
  templateUrl: './cart-address-and-informations.component.html',
  styleUrls: ['./cart-address-and-informations.component.scss'],
})
export class CartAddressAndInformationsComponent implements OnInit {
  billingAddressData = new BehaviorSubject<Partial<Cart> | null>(null);
  deliveryAddressData = new BehaviorSubject<Partial<Cart> | null>(null);

  constructor(
    private readonly userService: UserService,
    private readonly cartService: CartService,
    private readonly dialog: MatDialog,
    private readonly snackbarService: SnackBarService,
    public readonly gtm: GoogleTagService,
  ) {}

  ngOnInit() {
    this.billingAddressData
      .pipe(
        combineLatestWith(this.deliveryAddressData),
        filter(
          ([billingData, deliveryData]) => !!billingData && !!deliveryData,
        ),
        untilDestroyed(this),
      )
      .subscribe(([billing, delivery]) => {
        if (billing?.billingAddress && delivery?.deliveryAddress) {
          this.cartService.update({
            billingAddress: billing.billingAddress,
            deliveryAddress: delivery.deliveryAddress,
            statusUpdateEmails: delivery.statusUpdateEmails || [],
            invoiceRecipientEmails: billing.invoiceRecipientEmails,
            step: this.cartService.nextStep,
          });
        }
      });
  }

  public saveBillingAddress($event: Partial<Cart>): void {
    const { billingAddress } = $event;

    if (billingAddress?.billingAddressRequiresCompletion) {
      this.openModal(
        billingAddress,
        CreateBillingAddressModalComponent,
      ).subscribe((billingAddress) => {
        if (billingAddress) {
          this.billingAddressData.next({
            billingAddress,
            invoiceRecipientEmails: $event.invoiceRecipientEmails,
          });
        }
      });
    } else {
      this.billingAddressData.next($event);
    }
  }

  public saveDeliveryAddress($event: Partial<Cart>): void {
    const { deliveryAddress } = $event;

    if (deliveryAddress?.deliveryAddressRequiresCompletion) {
      this.openModal(
        deliveryAddress,
        CreateDeliveryAddressModalComponent,
      ).subscribe((deliveryAddress) => {
        if (deliveryAddress) {
          this.gtm.push('add_shipping_info');
          this.deliveryAddressData.next({
            deliveryAddress,
            statusUpdateEmails: $event.statusUpdateEmails,
          });
        }
      });
    } else {
      this.gtm.push('add_shipping_info');
      this.deliveryAddressData.next($event);
    }
  }

  private openModal(
    address: Address,
    component: ComponentType<
      CreateDeliveryAddressModalComponent | CreateBillingAddressModalComponent
    >,
  ) {
    return this.dialog
      .open(component, {
        data: {
          address,
        },
      })
      .afterClosed();
  }

  public billingAddressCreated(address: Address): void {
    const obs = this.cartService.update(
      {
        billingAddress: address,
      },
      true,
    );

    if (obs) {
      obs.subscribe({
        error: (e) => {
          this.snackbarService.showSimpleError(
            'CART.REFERENCE_INFORMATIONS.SAVE_ERROR',
          );
          return e;
        },
        next: () => {
          this.snackbarService.showSimpleSuccess('CART.BILLING.CREATE_SUCCESS');
        },
      });
    }
  }

  public deliveryAddressCreated(address: Address): void {
    this.gtm.push('add_shipping_info');
    const obs = this.cartService.update(
      {
        deliveryAddress: address,
      },
      true,
    );

    if (obs) {
      obs.subscribe({
        error: (e) => {
          this.snackbarService.showSimpleError(
            'CART.REFERENCE_INFORMATIONS.SAVE_ERROR',
          );
          return e;
        },
        next: () => {
          this.snackbarService.showSimpleSuccess(
            'CART.DELIVERY.CREATE_SUCCESS',
          );
        },
      });
    }
  }
}
