<div *ngIf="element" class="d-flex flex-row align-items-center">
  <ng-container *ngIf="!variantIsOrderable(element) && !element.hasError && !element.hasWarning && !element.inProgress; else approvalNotPending">
    <packex-icon icon="warning" class="me-2"></packex-icon>
    <div class="d-flex flex-column d-none d-xl-flex" [matTooltip]="getTooltipForNotOrderableVariant(element)">
      <span class="subtitle-2">{{ 'INVENTORY.PRINT_DATA_UPLOAD.ITEMS_LIST.NOT_ORDERABLE' | transloco }}</span>
    </div>
  </ng-container>
  <ng-template #approvalNotPending>
    <ng-template [ngIf]="element.hasError">
      <packex-icon icon="error" class="me-2" (click)="openPreflightModal()"></packex-icon>
      <div class="d-flex flex-column text-error d-none d-xl-flex">
        <span class="subtitle-2 text-error">{{ 'INVENTORY.PRINT_DATA_UPLOAD.ITEMS_LIST.ERROR' | transloco }}</span>
        <packex-button type="tertiary" class="w-auto" color="error" (buttonClick)="openPreflightModal()" buttonPadding="py-2">
          {{ 'COMMON.DETAILS' | transloco }}
        </packex-button>
      </div>
    </ng-template>
    <ng-template [ngIf]="element.hasWarning">
      <packex-icon icon="warning" class="me-2" (click)="openPreflightModal()"></packex-icon>
      <div class="d-flex flex-column text-warning d-none d-xl-flex">
        <span class="subtitle-2 text-warning">{{ 'INVENTORY.PRINT_DATA_UPLOAD.ITEMS_LIST.WARNING' | transloco }}</span>
        <packex-button type="tertiary" class="w-auto" color="warning" (buttonClick)="openPreflightModal()" buttonPadding="py-2">
          {{ 'COMMON.DETAILS' | transloco }}
        </packex-button>
      </div>
    </ng-template>
    <ng-template [ngIf]="element.inProgress">
      <packex-icon icon="technical_printdata_editing" class="me-2"  (click)="openInProgressDialog()"></packex-icon>
      <div class="d-flex flex-column d-none d-xl-flex">
        <span class="subtitle-2">{{ 'INVENTORY.PRINT_DATA_UPLOAD.ITEMS_LIST.PROCESSING' | transloco }}</span>
        <packex-button type="tertiary" class="w-auto" (buttonClick)="openInProgressDialog()" buttonPadding="py-2">
          {{ 'COMMON.DETAILS' | transloco }}
        </packex-button>
      </div>
    </ng-template>
    <ng-template [ngIf]="element.completed">
      <packex-icon icon="check" class="me-2"></packex-icon>
      <div class="d-flex flex-column d-none d-xl-flex">
        <span class="subtitle-2">{{ 'INVENTORY.PRINT_DATA_UPLOAD.ITEMS_LIST.COMPLETE' | transloco }}</span>
      </div>
    </ng-template>
  </ng-template>
</div>
