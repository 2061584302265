<form [class]="'packex-form'" [formGroup]="registrationForm" (ngSubmit)="submit()" *ngIf="!created">
  <p class="mt-2">
    <mat-form-field appearance="outline" class="w-100" *ngIf="!email">
      <input [readonly]="loading" type="email" matInput [placeholder]="'USER.MODEL.EMAIL' | transloco" formControlName="email"
             name="email" required>
      <mat-error *ngIf="registrationForm.controls['email']?.errors?.['validateEmail']">{{'FORM.ERROR.EMAIL' | transloco}}</mat-error>
      <mat-error *ngIf="registrationForm.controls['email']?.errors?.['exists']">{{'USER.REGISTRATION.ERROR_ACCOUNT_EXISTS' | transloco}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
      <input [readonly]="loading" matInput [type]="hidePass ? 'password' : 'text'"
             [placeholder]="'USER.MODEL.PASSWORD' | transloco" formControlName="password" type="password"
             name="password"
             required>
      <packex-icon class="hovered-pointer me-2" [ngClass]="hidePass ? 'color-gray-light' : 'color-gray'" matSuffix
                   (click)="hidePass = !hidePass" [icon]="hidePass ? 'hide' : 'show'"></packex-icon>
      <mat-error
        *ngIf="registrationForm.controls['password'].invalid">{{'FORM.ERROR.PASSWORD_STRENGTH' | transloco}}</mat-error>
    </mat-form-field>
  </p>

  <p [innerHTML]="'USER.REGISTRATION.LANGUAGE_INVOICE_HINT' | transloco" class="mb-2"></p>

  <packex-language-select></packex-language-select>

  <p class="mt-3 text-primary-medium" [innerHTML]="'USER.REGISTRATION.ACCEPT_PRIVACY' | transloco"></p>
  <mat-checkbox formControlName="marketingAcceptance" [color]="'primary'"
                class="mr-1 caption mt-3 mb-6">{{'USER.CONFRIM_MARKETING_HINT' | transloco }}</mat-checkbox>
  <packex-button [disabled]="loading || registrationForm.invalid"
                 [buttonType]="'submit'">{{'USER.REGISTRATION.CREATE_ACCOUNT' | transloco}}</packex-button>
</form>
