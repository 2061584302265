import { Component, ViewEncapsulation } from '@angular/core';
import { InventoryBatchOperation } from '@app/utils/inventory-items.helper';
import {
  PackexSelectComponent,
  SelectOption,
} from '@shared/components/packex-select/packex-select.component';

export type BatchOperationSelectOption = {
  type: InventoryBatchOperation;
} & SelectOption;

@Component({
  selector: 'packex-inventory-batch-operations',
  templateUrl:
    '../../../../shared/components/packex-select/packex-select.component.html',
  styleUrls: [
    '../../../../shared/components/packex-select/packex-select.component.scss',
    './inventory-batch-operations.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class InventoryBatchOperationsComponent extends PackexSelectComponent<BatchOperationSelectOption> {}
