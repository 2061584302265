import {
  EnvironmentInjector,
  inject,
  runInInjectionContext,
} from '@angular/core';
import {
  LANGUAGE,
  LANGUAGE_LOCALE_MAPPING,
  LanguageService,
} from '@app/core/services/language.service';

export const formatPrice = (value: number, language: LANGUAGE = 'de') => {
  return new Intl.NumberFormat(LANGUAGE_LOCALE_MAPPING[language], {
    style: 'currency',
    currency: 'EUR',
  }).format(value);
};

export const roundToHalf = (value: string) => {
  const converted = parseFloat(value); // Make sure we have a number
  let decimal = converted - parseInt(value, 10);
  decimal = Math.round(decimal * 10);

  if (decimal == 5) {
    return parseInt(value, 10) + 0.5;
  }
  if (decimal <= 4 || decimal >= 6) {
    return Math.round(parseFloat(value));
  } else {
    return parseInt(value, 10) + 0.5;
  }
};

export const isNumberInput = (event: any) => {
  const charCode = event.which ? event.which : event.keyCode;

  return (
    charCode < 31 ||
    (charCode >= 48 && charCode <= 57) ||
    (charCode >= 96 && charCode <= 105)
  );
};

export const formatDate = (
  injector: EnvironmentInjector,
  date: string,
  formatOptions: Intl.DateTimeFormatOptions = {
    dateStyle: 'short',
  },
) => {
  return runInInjectionContext<string>(injector, () => {
    const languageService = inject(LanguageService);

    return new Date(date).toLocaleString(
      languageService.localeString,
      formatOptions,
    );
  });
};
