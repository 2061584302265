<packex-cart-address-card *ngIf="cart | async as cart"
                          [address]="address"
                          [recipients]="cart.invoiceRecipientEmails"
                          [emails]="emails"
                          header="CART.BILLING.BILLING_ADDRESS"
                          recipientsTitle="CART.BILLING.BILLING_RECIPIENTS"
                          recipientsPlaceholder="CART.BILLING.RECIPIENT_PLACEHOLDER"
                          recipientTooltip="CART.BILLING.RECIPIENT_TOOLTIP"
                          editButtonTooltip="CART.BILLING.EDIT_BUTTON_TOOLTIP"
                          createButtonTitle="CART.BILLING.CREATE_BILLING_ADDRESS"
                          (onSave)="save($event)"
                          (onEdit)="edit()"
                          (onCreate)="create()">
  <p *ngIf="showMissingAddressError" class="text-error">{{ 'CART.BILLING.MISSING_ADDRESS_ERROR' | transloco }}</p>
</packex-cart-address-card>
