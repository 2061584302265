import { Component, OnInit } from '@angular/core';
import { ROUTES } from '@app/routes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'packex-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  title?: string;
  hint?: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        this.title = params['title'];
        this.hint = params['hint'];
      });
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/' + ROUTES.CONSTRUCTIONS]);
  }
}
