<packex-page>
  <packex-card class="m-auto h-auto registration-card mt-3">
    <h2 class="text-primary-dark text-uppercase">{{ 'USER.REGISTRATION.TITLE' | transloco }}</h2>
    <packex-divider></packex-divider>

    <packex-registration-form
        (onSuccess)="registrationProcessDone($event)"
        [loading]="loading"
    ></packex-registration-form>
    <p class="mt-3"
       [innerHTML]="'USER.REGISTRATION.ACCOUNT_EXISTS' | transloco: {uriLogin:'user/registration/login'}"></p>

    <packex-divider></packex-divider>

    <div class="social">
      <packex-button faIcon="windows"
                     (buttonClick)="navigateToIdentityProvider('microsoft')">Mit Microsoft anmelden
      </packex-button>
      <packex-button faIcon="google"
                     (buttonClick)="navigateToIdentityProvider('google')">Mit Google anmelden
      </packex-button>
    </div>
  </packex-card>
</packex-page>
