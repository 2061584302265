export interface ProductOption {
  id: string;
  name: string;
  nameDe: string;
  nameEn: string;
  sku: string;
  imageUrl: string;
  description: string;
  priceType: string;
  price: number;
  createdAt: string;
  updatedAt: string;
  mandatory: boolean;
  preselected: boolean;
  enabled: boolean;
  makesVariantOrderable: boolean;
  completedInPreviousOrder: boolean;
  showHintIfCompletedInPreviousOrder: boolean;
  extraProductionDays: number;
}

export const BRAILLE = 'braille';
export const FSC = 'fsc';
export const REWORK = 'rework';
export const PREPRESS_CHECK = 'prepress_check';
export const QUALITY_CERTIFICATE = 'quality_certificate';

export type ProductOptionType = 'braille' | 'fsc' | 'rework' | 'prepress_check';
