import { Component, ViewEncapsulation } from '@angular/core';
import { LANGUAGE, LanguageService } from '@app/core/services/language.service';
import { UserService } from '@app/core/services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  PackexSelectComponent,
  SelectOption,
} from '@shared/components/packex-select/packex-select.component';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'packex-organization-language-switch',
  templateUrl: './packex-select.component.html',
  styleUrls: ['./packex-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrganizationLanguageSwitchComponent extends PackexSelectComponent<
  { value: LANGUAGE } & SelectOption
> {
  constructor(
    private readonly userService: UserService,
    private readonly languageService: LanguageService,
  ) {
    super();

    this.options$.next(this.languageService.availableLanguages);
  }

  override ngOnInit() {
    super.ngOnInit();

    this.userService.user$
      .pipe(
        filter((user) => !!user),
        untilDestroyed(this),
      )
      .subscribe((user) => {
        this.current = this.languageService.availableLanguages.find(
          (option) => option.value === user?.organization.preferredLanguage,
        );
      });
  }
}
