export const scrollIntoViewWithOffset = (
  selector: HTMLElement,
  offset: number,
  behavior: 'smooth' | 'auto' = 'smooth',
) => {
  window.scrollTo({
    behavior,
    top:
      selector.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      offset,
  });
};
