<div class="d-flex align-items-center"
     [ngClass]="{'flex-column flex-md-row': hasMultipleButtons, 'flex-row': hasOneButton}">
  <div class="message w-100">
    <div class="d-flex">
      <packex-icon *ngIf="data.icon" [icon]="data.icon" class="me-2" [color]="'white'"></packex-icon>
      <span [innerHtml]="data.rawMessage || (data.message | transloco)"></span>
    </div>
  </div>
  <div class="ms-0 ms-md-8 d-flex w-md-auto justify-content-end" *ngIf="data.buttons" [ngClass]="{' w-100': hasMultipleButtons}">
    <packex-button *ngFor="let button of data.buttons; let last = last" (buttonClick)="button.cb && button.cb()"
                   [color]="button.color || 'white'"
                   [ngClass]="{ 'ms-5': last }"
                   class="w-auto"
                   type="tertiary">
      {{ button.label | transloco }}
    </packex-button>
  </div>
</div>
