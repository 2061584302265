<div class="d-flex flex-column align-items-center text-center">
  <img class="mb-4" src="assets/images/{{config.asset}}" [alt]="config.title | transloco"/>

  <h2 class="text-uppercase text-secondary-medium">{{ config.title | transloco }}</h2>
  <packex-divider colorClass="secondary-medium"></packex-divider>
  <p class="text-secondary-medium mb-3" [innerHTML]="config.rawContent || (config.content | transloco)"></p>

  <packex-button (buttonClick)="onButtonClick.emit()" class="w-auto" *ngIf="config.button"
                 [icon]="config.buttonIcon || ''">{{ config.button | transloco }}</packex-button>
</div>
