<div class="mt-7">
  <div class="subtitle-1 mb-2">{{ 'FILTER.FILTER_BY_LABEL' | transloco }}</div>
  <div class="caption mb-4 pe-9">
    <packex-custom-translate key="FILTER.FILTER_LABEL_DESCRIPTION" params="icon">
        <span data-param="icon">
          <packex-icon icon="label" class="d-inline-flex" color="primary" size="s"></packex-icon>
        </span>
    </packex-custom-translate>
  </div>

  <div class="d-flex flex-wrap">
    <mat-chip-listbox aria-label="Label Filter">
      <mat-chip-option *ngFor="let label of labels$ | async" color="primary" [selected]="label.active || false" (click)="toggleLabel(label)">
        {{ label.title }}
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</div>
