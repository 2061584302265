<mat-table class="items-list" [dataSource]="dataSource" *ngIf="dataSource">
  <ng-container matColumnDef="position">
    <mat-header-cell *matHeaderCellDef>&nbsp;</mat-header-cell>
    <mat-cell *matCellDef="let element" class="position">
      <span class="subtitle-2">{{element.position}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>
      <span
        class="subtitle-2 text-primary-medium">{{ 'INVENTORY.PRINT_DATA_UPLOAD.ITEMS_LIST.COLUMN_TITLE' | transloco }}</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" class="text-break caption"> {{getVariantName(element)}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="file">
    <mat-header-cell *matHeaderCellDef class="d-none d-md-flex">
      <span
        class="subtitle-2 text-primary-medium">{{ 'INVENTORY.PRINT_DATA_UPLOAD.ITEMS_LIST.COLUMN_FILE' | transloco }}</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" class="d-none d-md-flex justify-content-start align-items-center">
      <packex-download-button [url]="element.artworkUrl" *ngIf="element.artworkUrl && element.originalArtworkFilename"
                              class="display-contents">{{element.originalArtworkFilename}}</packex-download-button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="release">
    <mat-header-cell *matHeaderCellDef class="justify-content-center justify-content-lg-start">
      <span
        class="subtitle-2 text-primary-medium">{{ 'INVENTORY.PRINT_DATA_UPLOAD.ITEMS_LIST.COLUMN_RELEASE' | transloco }}</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" class="justify-content-center justify-content-lg-start">
      <packex-print-data-item-status [element]="element"></packex-print-data-item-status>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="upload">
    <mat-header-cell *matHeaderCellDef>
      <span
        class="subtitle-2 text-primary-medium">{{ 'INVENTORY.PRINT_DATA_UPLOAD.ITEMS_LIST.COLUMN_UPLOAD' | transloco }}</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <packex-user-info [firstName]="element.user.firstName" [lastName]="element.user.lastName"
                        [hideProfilePicture]="true"
                        [subtitle]="formatDate(injector, element.updatedAt)" class="d-none d-md-block"></packex-user-info>
      <packex-user-info [firstName]="element.user.firstName" [lastName]="element.user.lastName"
                        [onlyInitials]="true"
                        [subtitle]="formatDate(injector, element.updatedAt)" class="d-block d-md-none"></packex-user-info>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef>&nbsp;</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button [matMenuTriggerFor]="menu" [attr.data-testid]="'kebab-menu-button'">
        <packex-icon icon="kebab"></packex-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="renameVariant(element)" disableRipple [attr.data-testid]="'action-rename'">{{'ACTIONS.RENAME' | transloco}}</button>
        <button mat-menu-item disableRipple *ngIf="element.artworkUrl">
          <packex-download-button [url]="element.artworkUrl" color="primary-dark" textType="paragraph">
            {{'ACTIONS.DOWNLOAD' | transloco}}
          </packex-download-button>
        </button>
        <button mat-menu-item (click)="deleteVariant(element)" disableRipple>{{'ACTIONS.DELETE' | transloco}}</button>

      </mat-menu>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<div class="add-variant">
  <packex-button type="tertiary" color="primary-dark" class="clear justify-content-center"
                 icon="add_circle"
                 [attr.data-testid]="'add-variant-button'"
                 (buttonClick)="addVariant()">
    {{ 'INVENTORY.PRINT_DATA_UPLOAD.ITEMS_LIST.ADD_VARIANT' | transloco }}
  </packex-button>

</div>
