import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChooseAddressConfig } from '@modules/cart/choose-address-modal/choose-address-modal.component';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { Address } from '@shared/models/address';
import { AddressService } from '@shared/services/address.service';
import { catchError } from 'rxjs';

@Component({
  selector: ' packex-address-choose-list',
  templateUrl: './address-choose-list.component.html',
  styleUrls: ['./address-choose-list.component.scss'],
})
export class AddressChooseListComponent {
  @Input() config: ChooseAddressConfig | null = null;
  @Input() current?: Address;
  @Input() addresses: Address[] = [];

  @Output() onLoad = new EventEmitter();
  @Output() onCreate = new EventEmitter<Address>();
  @Output() onChoose = new EventEmitter<Address>();
  @Output() onSetStandard = new EventEmitter<Address>();

  constructor(
    private readonly dialog: MatDialog,
    private readonly addressService: AddressService,
    private readonly snackbarService: SnackBarService,
  ) {}

  private deleteAddress(address: Address): void {
    this.addressService
      .deleteAddress(address)
      .pipe(catchError(async (response) => response))
      .subscribe((response: HttpErrorResponse | null) => {
        if (!response) {
          this.onDeleteSuccess();
          this.onLoad.emit();
        } else {
          this.onDeleteError();
        }
      });
  }

  public chooseAddress(address: Address): void {
    this.onChoose.emit(address);
  }

  public create(): void {
    if (this.config?.component) {
      this.dialog
        .open(this.config?.component)
        .afterClosed()
        .subscribe((address?: Address) => {
          if (address) {
            this.config?.snackBarCreateSuccess &&
              this.snackbarService.showSimpleSuccess(
                this.config?.snackBarCreateSuccess,
              );
            this.onCreate.emit(address as Address);
            this.onLoad.emit();
          }
        });
    }
  }

  public edit(address: Address): void {
    if (this.config?.component) {
      this.dialog
        .open(this.config.component, {
          data: {
            address,
          },
        })
        .afterClosed()
        .subscribe(() => this.onLoad.emit());
    }
  }

  public delete(address: Address): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: this.config?.confirmDialog.title,
          content: this.config?.confirmDialog.content,
          primaryButtonText: 'COMMON.DELETE',
        },
      })
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.deleteAddress(address);
        }
      });
  }

  public onDeleteError(): void {
    this.config?.snackBarError &&
      this.snackbarService.showSimpleError(this.config?.snackBarError);
  }

  public onDeleteSuccess(): void {
    this.config?.snackBarSuccess &&
      this.snackbarService.showSimpleSuccess(this.config?.snackBarSuccess);
  }

  public setStandard(address: Address): void {
    this.onSetStandard.emit(address);
  }
}
