import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConstructionsPageComponent } from './pages/constructions-page/constructions-page.component';

export const ROUTES_CONSTRUCTIONS = {
  CONFIGURATOR: 'configurator',
};

const routes: Routes = [
  {
    path: '',
    component: ConstructionsPageComponent,
    data: {
      breadcrumb: 'BREADCRUMB.PRODUCTS',
      pageTitle: 'PAGES.CONSTRUCTIONS',
    },
  },
  {
    path: ROUTES_CONSTRUCTIONS.CONFIGURATOR + '/:configurationId',
    loadChildren: () =>
      import('../configurator/configurator.module').then(
        (m) => m.ConfiguratorModule,
      ),
  },
  {
    path: ':type/:filter',
    component: ConstructionsPageComponent,
    data: {
      breadcrumb: 'BREADCRUMB.PRODUCTS',
      pageTitle: 'PAGES.CONSTRUCTIONS',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConstructionsRoutingModule {}
