import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterBarComponent } from '@modules/filter/components/filter-bar/filter-bar.component';
import { FilterChipComponent } from '@modules/filter/components/filter-chip/filter-chip.component';
import { SharedModule } from '@shared/shared.module';
import { FilterCardsComponent } from '@modules/filter/components/filter-cards/filter-cards.component';
import { FilterCardComponent } from './components/filter-card/filter-card.component';
import { ConstructionFilterCategoriesComponent } from './components/construction-filter-categories/construction-filter-categories.component';

@NgModule({
  declarations: [
    FilterCardsComponent,
    FilterBarComponent,
    FilterChipComponent,
    FilterCardComponent,
    ConstructionFilterCategoriesComponent,
  ],
  imports: [CommonModule, SharedModule, FormsModule],
  exports: [
    FilterCardsComponent,
    FilterBarComponent,
    FilterChipComponent,
    ConstructionFilterCategoriesComponent,
  ],
})
export class FilterModule {}
