import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  constructor(
    private readonly title: Title,
    private readonly translocoService: TranslocoService,
  ) {}

  public setTitle(title: string) {
    this.translocoService.selectTranslate(title).subscribe((translation) => {
      this.title.setTitle(`${translation} - PackEx Portal`);
    });
  }
}
