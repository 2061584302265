import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { translate } from '@ngneat/transloco';
import { SnackBarProperties } from '@shared/components/snackbar/snackbar.component';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable, switchMap, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

const DEFAULT_ERROR_MESSAGE = 'ERROR.UNKNOWN';

export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private snackbarService: SnackBarService,
    private readonly keycloakService: KeycloakService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.handle403(request, next);
          return throwError(() => error);
        }

        const showSnackbar = ![400, 404, 409, 500].includes(error.status);
        const snackbarData = <SnackBarProperties>{
          icon: 'error',
          buttons: [
            {
              label: 'COMMON.CLOSE',
              cb: () => this.snackbarService.dismiss(),
            },
          ],
          message: DEFAULT_ERROR_MESSAGE,
        };

        if (showSnackbar) {
          if (error.status === 0) {
            this.snackbarService.showError({
              ...snackbarData,
              rawMessage: translate('COMMON.NO_INTERNET'),
            });
          } else if (error.message && error.status !== 500) {
            this.snackbarService.show({
              ...snackbarData,
              rawMessage: error.message,
            });
          }
        }

        return throwError(() => error);
      }),
    );
  }

  private handle403(request: HttpRequest<any>, next: HttpHandler) {
    if (this.keycloakService.isTokenExpired(20)) {
      return from(
        this.keycloakService
          .updateToken(20)
          .catch(() => this.keycloakService.logout()),
      ).pipe(
        switchMap(() =>
          this.keycloakService.addTokenToHeader(request.headers).pipe(
            mergeMap((headersWithBearer) => {
              const kcReq = request.clone({ headers: headersWithBearer });
              return next.handle(kcReq);
            }),
          ),
        ),
      );
    } else {
      this.keycloakService.logout();
      return;
    }
  }
}
