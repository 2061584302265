import { Component, Input } from '@angular/core';

@Component({
  selector: 'packex-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
})
export class DividerComponent {
  @Input() label?: string;
  @Input() spaceOnly = false;
  @Input() colorClass: string | undefined;
  @Input() gridClass = 'col-sm-12';
}
