import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { hasReferenceCode } from '@app/utils/cart-item-helper';
import { CartReferencesComponent } from '@modules/cart/cart-references/cart-references.component';
import { CartService } from '@modules/cart/cart.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CartItem } from '@shared/models/cart';
import { orderBy } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'packex-cart-reference-informations',
  templateUrl: './cart-reference-informations.component.html',
  styleUrls: ['./cart-reference-informations.component.scss'],
})
export class CartReferenceInformationsComponent implements OnInit {
  items: CartItem[] = [];

  constructor(
    private readonly dialog: MatDialog,
    private readonly cartService: CartService,
  ) {}

  ngOnInit() {
    this.cartService.cart$.pipe(untilDestroyed(this)).subscribe((cart) => {
      this.items = orderBy(cart.items, 'sortingPosition', 'desc').filter(
        (cartItem: CartItem) => hasReferenceCode(cartItem),
      );
    });
  }

  public get cartHasProducts(): boolean {
    return !!this.cartService.cartValue.items.length;
  }

  public addInformation(): void {
    this.dialog.open(CartReferencesComponent, {
      data: {
        items: this.items,
      },
    });
  }

  public editReference(item: CartItem): void {
    this.dialog.open(CartReferencesComponent, {
      data: {
        item,
      },
    });
  }
}
