<packex-exit-intent-modal-trigger key="exit-intent-constructions"></packex-exit-intent-modal-trigger>
<packex-carousel size="xl" [slides]="carouselSlides" class="no-shadow" [borderRadius]="false"></packex-carousel>
<packex-page class="size-xl">
  <div class="m-0 mt-5" #categoriesHeadline>
    <div class="d-none d-md-block mb-2 mb-md-5">
      <h2 class="text-center text-uppercase">{{ 'CONSTRUCTIONS.CATEGORIES.HEADLINE' | transloco }}</h2>
    </div>
    <div class="filter-cards-container w-100">
      <packex-filter-cards (filterApplied)="scrollToCategories()"></packex-filter-cards>
    </div>
  </div>
  <div class="d-flex flex-column flex-md-row m-0 mt-4 mt-md-5">
    <packex-filter-bar class="col-12 col-md-3 mb-3 mb-md-0 me-3"></packex-filter-bar>

    <div>
      <div class="filter-chip-container d-flex align-items-center">
        <packex-filter-chip></packex-filter-chip>
      </div>
      <div class="card-row">
        <div class="card-item" *ngFor="let item of items">
          <packex-construction-card *ngIf="!item.isMarketingCard" [construction]="item" [detailsOpen]="item.detailsOpen"
                                    [attr.data-testid]="item.catalogCode"
                                    (detailsOpened)="onDetailsOpen($event)"></packex-construction-card>
          <packex-marketing-card *ngIf="item.isMarketingCard"
                                 [attr.data-testid]="'marketing-card-{{item.id}}'"
                                 (onButtonClick)="marketingCardClicked(item)"
                                 [marketingCard]="item"></packex-marketing-card>
        </div>
      </div>
    </div>
  </div>
</packex-page>
