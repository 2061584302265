import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '@app/core/services/language.service';
import { loginWithKeycloak } from '@app/utils/keycloak-helper';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private readonly languageService: LanguageService,
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed() {
    // TODO: Add additional role/scope checks if required.
    if (await this.keycloak.isLoggedIn()) {
      return true;
    } else {
      await loginWithKeycloak(
        this.keycloak,
        this.languageService.currentLanguage(),
      );

      return false;
    }
  }
}
