import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { AppConfig } from '@shared/models/app-config';
import { sortBy } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  appConfig$ = new BehaviorSubject<AppConfig | null>(null);

  constructor(private readonly apiService: ApiService) {
    this.loadAppConfig().subscribe({
      next: (appConfig: AppConfig) => {
        this.appConfig$.next({
          ...appConfig,
          productFilters: this.mapAndSortProductFilters(
            appConfig.productFilters,
          ),
        });
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  loadAppConfig(): Observable<AppConfig> {
    return this.apiService.get('app-config');
  }

  // @Todo: remove when api delivers the array sorted
  private mapAndSortProductFilters(
    productFilters: AppConfig['productFilters'],
  ) {
    return {
      ...productFilters,
      type: sortBy(productFilters.type, (type) => {
        const sortArray = [
          'folding_box',
          'leaflet',
          'shipping_box',
          'label',
          'carding_box',
          'pouch_box',
          'display',
          'slipcase',
          'pillow_box',
          'bottle_box',
          'calendar',
        ];

        return sortArray.indexOf(type);
      }),
    };
  }
}
