import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModalCartItem } from '@shared/models/cart';

@UntilDestroy()
@Component({
  selector: 'packex-cart-total',
  templateUrl: './cart-total.component.html',
  styleUrls: ['./cart-total.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartTotalComponent {
  @Input() items!: ModalCartItem[];
  @Input() loading!: boolean;

  get totalPrice(): number {
    return this.items
      .map((item: ModalCartItem) => item.price || 0)
      .reduce((value: number, sum: number) => sum + value, 0);
  }
}
