<div *ngIf="address">
  <span class="subtitle-2">{{ address.company }}</span>
  <p class="mb-0">{{ address.recipient }}</p>
  <p class="mb-0">{{ address.street }}</p>
  <p class="mb-0">{{ address.additionalAddress }}</p>
  <p class="mb-0">{{ address.postalCode }} {{ address.city }}</p>
  <p class="mb-0">{{ address.country.name || address.country }}</p>
  <p class="mb-0">{{ address.phone }}</p>
  <p class="mb-0" *ngIf="showVatId && address?.vatId">{{ 'COMMON.VAT_ID' | transloco }} {{ address.vatId }}</p>
</div>
