import { Component, Input, ViewEncapsulation } from '@angular/core';

export type IconColor = 'grey' | 'primary' | 'secondary' | 'white';
export type IconSize = 's' | 'm' | 'l' | 'xl';

const ERROR_ICONS = [
  'error',
  'error_white',
  'info_circle_error',
  'info_circle_error_white',
  'cancelled',
];
const WARNING_ICONS = [
  'error_warning',
  'warning',
  'info_circle_warning',
  'warning_primary',
];

@Component({
  selector: 'packex-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IconComponent {
  @Input() icon?: string;
  @Input() faIcon?: string;
  @Input() color: IconColor = 'primary';
  @Input() size: IconSize = 'm';

  iconPath = 'assets/icons';

  get assetPath() {
    if (this.icon && [...ERROR_ICONS, ...WARNING_ICONS].includes(this.icon)) {
      return `${this.iconPath}/${this.icon}.svg`;
    }

    return `${this.iconPath}/${this.color}/${this.icon}_${this.color}.svg`;
  }
}
