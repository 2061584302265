<div class="notifications-foldout bg-white">
  <div class="d-flex flex-row p-3 border-bottom border-grey-medium user-notification"
       *ngFor="let notification of notifications">
    <img ngSrc="assets/images/packex_favicon.svg" alt="" class="me-3" width="40" height="40"/>

    <div class="d-flex flex-column" (click)="handleNotificationClick(notification)">
      <div class="subtitle-2 mb-2">{{ notification.title || 'ORDER_SERVICE_APPROVAL.ACTION_NEEDED' | transloco }}</div>
      <div class="text-primary-medium mb-1">{{ notification.message }}</div>
      <span class="caption text-primary-medium">{{ 'COMMON.DAYS_PASSED' | transloco : {amount: notification.daysPassed}  }}</span>
    </div>
  </div>

</div>
