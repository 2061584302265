import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Address, CreateAddress } from '@shared/models/address';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private readonly apiService: ApiService) {}

  public createAddress(address: CreateAddress): Observable<Address> {
    return this.apiService.post('addresses', {
      ...address,
    });
  }

  public updateAddress(
    id: string,
    address: CreateAddress,
  ): Observable<Address> {
    return this.apiService.patch(`addresses/${id}`, {
      ...address,
    });
  }

  public load(): Observable<Address[]> {
    return this.apiService.get('addresses');
  }

  public deleteAddress(address: Address) {
    return this.apiService.delete(`addresses/${address.id}`);
  }
}
