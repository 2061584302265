<packex-card class="p-4 w-100 h-auto position-relative">
  <h4 class="card-title mb-7">{{ header | transloco }}</h4>

  <ng-content></ng-content>

  <div class="d-flex justify-content-between align-items-center border border-grey-medium px-3 py-2"
       *ngIf="showAddress && address" [attr.data-testid]="'address'">
    <packex-address [address]="address" [showVatId]="showVatId"></packex-address>

    <packex-button type="clear-icon" icon="right" (buttonClick)="onEdit.emit()"
                   [matTooltip]="(editButtonTooltip | transloco)"></packex-button>
  </div>


  <ng-content select=".custom-content"></ng-content>

  <packex-button icon="add_circle" (buttonClick)="onCreate.emit()"
                 type="secondary"
                 buttonPadding="py-2 px-3"
                 [disabled]="!showAddress"
                 class="w-auto mt-3">{{ createButtonTitle | transloco }}</packex-button>

  <div class="subtitle-2 mt-6 d-flex align-items-center">{{ recipientsTitle | transloco }}
    <packex-tooltip [text]="recipientTooltip | transloco" class="ms-2"></packex-tooltip>
  </div>
  <div class="caption text-primary-medium my-2" *ngFor="let email of emails">{{ email }}</div>

  <packex-recipients (recipientsChanged)="recipients = $event" class="recipients-wrapper mt-2"
                     [placeholder]="recipientsPlaceholder"
                     [initialRecipients]="recipients"></packex-recipients>
</packex-card>
