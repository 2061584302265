import { Address } from '@shared/models/address';
import { CartVoucher } from '@shared/models/cart';
import { Configuration } from '@shared/models/configuration';
import { DeliveryMethod } from '@shared/models/delivery-method';
import { Material } from '@shared/models/material';
import { Organization } from '@shared/models/organization';
import { PagedResult } from '@shared/models/paged-result';
import { PaymentMethod } from '@shared/models/paymentMethod';
import {
  ProductOption,
  ProductOptionType,
} from '@shared/models/product-option';
import { User } from '@shared/models/user';
import { Variant } from '@shared/models/variant';
import { ConfigurationAttribute } from '@shared/models/configuration-attribute-value';

export enum OrderStatus {
  CREATED = 'created',
  PROOF_PENDING = 'proof_pending',
  PRODUCTION = 'production',
  COMPLETED = 'completed',
  SHIPPED = 'shipped',
  CANCELLED = 'cancelled',
  ACCEPTED = 'accepted',
  PAYMENT_PENDING = 'pending_prepayment',
  PAYMENT_PENDING_INVOICE = 'pending_invoice',
  FSC_REVIEW_PENDING = 'fsc_review_pending',
  REWORK_PENDING = 'rework_pending',
  REWORK_CUSTOMER_PENDING = 'rework_pending_customer',
  PROOF_PENDING_CUSTOMER = 'proof_pending_customer',
  FSC_REVIEW_PENDING_CUSTOMER = 'fsc_review_pending_customer',
  PREPRESS_COMPLETED = 'prepress_completed',
  PREPRESS = 'prepress',
  INVOICED = 'invoiced',
}

export const ORDER_STATUS_CUSTOMER_ACTION_NEEDED = [
  OrderStatus.REWORK_CUSTOMER_PENDING,
  OrderStatus.PROOF_PENDING_CUSTOMER,
  OrderStatus.FSC_REVIEW_PENDING_CUSTOMER,
];

export type Invoice = {
  createdAt: string;
  id: string;
  invoiceDate: string;
  invoiceExternalReference: string;
  invoiceNumber: string;
  invoicingService: string;
  status: string;
  totalPrice: number;
  updatedAt: string;
};

export type Parcel = {
  trackingNumber: string;
  trackingLink?: string;
};

export type PrintData = {
  approvedBy: User;
  artworkUrl: string;
  artworkFilename: string;
  createdAt: string;
  createdBy: User;
  feedbackMailSentAt: string | null;
  id: string;
  isApproved: boolean;
  isCreatedByPackEx: boolean;
  isReviewPending: boolean;
  preflightResult: Variant['lastPreflightResult'];
  authorComment: string | null;
  reviewComment: string | null;
  reviewedAt: string;
  reviewedBy: User | null;
  updatedAt: string;
  version: number;
  status:
    | 'pending'
    | 'complete'
    | 'queued_for_preflight'
    | 'approved'
    | 'rejected';
};

export type PrintDataReview = {
  createdAt: string;
  id: string;
  printData: PrintData[];
  status: 'in_progress' | 'pending' | 'approved' | 'rejected';
  type: ProductOptionType;
  updatedAt: string;
  productOption: ProductOption;
};

export type OrderItemConfigurationAttribute = {
  id: string;
  name: string;
  description: string;
  sku: string;
  order: number;
  attribute: ConfigurationAttribute;
};

export type OrderItem = {
  discount: number;
  amount: number;
  cancellationReason: 'packex' | 'customer';
  customerArticleDescription: string | null;
  customerArticleNumber: string | null;
  customerComment: string | null;
  customerOrderReference: string | null;
  orderItemCategory: string;
  id: string;
  name: string;
  inventoryItemName: string;
  constructionName: string;
  price: number;
  unitPrice: number;
  unitPriceWithoutVoucher: number;
  netPriceWithChildren: number;
  netPriceWithChildrenWithoutVoucher: number;
  netPriceWithoutVoucher: number;
  productConfiguration: Partial<Configuration> & {
    constructionCatalogCode: string;
    material: Material & {
      materialGroupName: string | null;
    };
    blankArtwork: boolean;
  };
  tax: number | null;
  children: OrderItem[];
  status: OrderStatus;
  references: {
    inventoryItemId: string;
    materialId: string;
    variantId: string;
    constructionId: string;
    productOptionId: string;
  };
  orderItemNumber: string;
  deliveryMethod: DeliveryMethod;
  estimatedDeliveryDate: string;
  cancelledBy: User;
  parcels: Parcel[];
  whiteLabelShipping: boolean;
  attributes: OrderItemConfigurationAttribute[];
  printDataReview: PrintDataReview[];
};

export type CartOrderResponse = {
  redirectUrl?: string;
  order?: CartOrder;
};

export type CartOrder = {
  orderNumber: string;
  id: string;
  status: OrderStatus;
  invoiceRecipientEmails: string[];
  statusUpdateEmails: string[];
  createdAt: string;
  updatedAt: string;
  deliveryAddress: Address;
  billingAddress: Address;
  organization: Organization;
  orderedBy: User;
  orderItems: OrderItem[];
  totalGrossAmount: number;
  totalNetPrice: number;
  totalTax: number;
  totalNetPriceWithoutVoucher: number;
  discount: number;
  paymentMethod: PaymentMethod;
  invoices: Invoice[];
  voucher: CartVoucher | null;
};

export type PagedCartOrderResult = PagedResult<CartOrder>;
