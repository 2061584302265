<div [attr.data-testid]="'modal-cart-item-'+ item.name">
  <div class="cart-item" [ngClass]="{'first': index === 1 }">
    <div class="d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-lg-center" [formGroup]="form">
      <div class="d-flex flex-row me-3 w-100 w-md-66 align-items-center">
        <div class="position me-3">
          <span class="subtitle-2">{{ index }}</span>
        </div>
        <div class="subtitle-2 d-flex flex-column text-break">
          <span>{{ getVariantName(item) }}</span>
          <packex-download-button [url]="item.artworkUrl" type="open_in_tab"
                                  *ngIf="item.artworkUrl && item.originalArtworkFilename"
                                  class="display-contents">{{item.originalArtworkFilename}}</packex-download-button>
        </div>
      </div>
      <div
        class="d-flex flex-row justify-content-start justify-content-md-end mt-3 mt-lg-0 w-100">
        <div class="d-flex justify-content-between justify-content-lg-end align-items-center w-100">
          <mat-form-field appearance="outline" class="me-9 col-3 no-subscription">
            <input matInput type="number" formControlName="amount" class="input amount-input"
                   [attr.data-testid]="'modal-cart-item-amount-'+ item.name"
                   (blur)="onBlur()"
                   (keydown)="onKeyPress($event)"/>
          </mat-form-field>
          <div class="d-flex flex-column me-6">
            <div class="subtitle-2">
              {{ (item.price || 0) | formatPrice }}
            </div>
            <div class="caption text-primary-medium">
              {{ (item.unitPrice || 0) | formatPrice}} / {{ 'COMMON.PCS_ABBR' | transloco }}.
            </div>
          </div>
          <packex-button icon="delete" class="col-1" type="clear-icon" (buttonClick)="removeItem()" [disabled]="loading"
                         [attr.data-testid]="'remove-button-'+ item.name"></packex-button>
        </div>
      </div>
    </div>

    <div class="d-flex mt-3 ms-lg-6 text-error align-items-center" *ngIf="item.configurationError">
      <packex-icon icon="info_circle_error" class="me-2"></packex-icon>
      <p
        class="subtitle-2 text-error me-2 text-nowrap">{{ getConfigurationErrorField(item.configurationError) | transloco }}
        :</p>
      <p>{{ item.configurationError?.localizedMessage }}</p>
    </div>

    <div class="d-flex flex-wrap services-container mt-3 ms-lg-6" [formGroup]="productOptionsForm">
      <div [matTooltip]="getTooltipByProductOption(productOption) | transloco"
           [matTooltipDisabled]="!productOption.mandatory" *ngFor="let productOption of availableProductOptions">
        <mat-checkbox class="checkbox me-4 mb-1"
                      [formControlName]="productOption.sku"
                      [attr.data-testid]="'product-option-checkbox-'+ productOption.sku + '-'+ item.name"
        >
          <div class="d-flex flex-row">
            <div class="d-flex flex-column">
              <p class="d-flex flex-row text-primary mb-0 align-items-center"
                 [attr.data-testid]="'product-option-'+ productOption.sku +'-'+ item.name">{{ productOption.name }}
                <packex-tooltip [text]="productOption.description" class="ms-2"
                                [color]="productOptionsForm.get(productOption.sku)?.disabled ? 'grey' : 'primary'"></packex-tooltip>
              </p>
              <div *ngIf="productOption.price">
                <span class="caption text-primary-medium">
                  + {{ (productOption.price || null) | formatPrice}}
                </span>
                <span class="caption text-primary-medium" *ngIf="productOption.priceType === 'unit'"></span>
              </div>
            </div>
            <div class="ms-3"
                 *ngIf="productOption.completedInPreviousOrder && productOption.showHintIfCompletedInPreviousOrder">
              <mat-chip-option selected="true" color="primary" class="service-completed-label" disabled="true">
                <span class="caption d-flex">{{ 'PRODUCT_OPTIONS.COMPLETED_IN_PREVIOUS_ORDER' | transloco }}</span>
              </mat-chip-option>
            </div>
          </div>
        </mat-checkbox>
      </div>
    </div>

    <packex-well-notification severity="warning" icon="info_circle" [showNewText]="false" class="mt-5 ms-lg-6"
                              [showCloseButton]="false"
                              *ngIf="item.additionalProductionDays">
      <span class="text-primary-dark" [innerHTML]="getItemWellNotification()"></span>
    </packex-well-notification>

    <packex-well-notification severity="warning" icon="info_circle" [showNewText]="false" class="mt-5 ms-lg-6"
                              [showCloseButton]="false"
                              *ngIf="item.blankArtwork"
                              >
      <span class="text-primary-dark" [innerHTML]="'CART.BLANK_ARTWORK_COATING_HINT' | transloco"></span>
    </packex-well-notification>

  </div>
</div>
