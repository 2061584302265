import { Pipe, PipeTransform } from '@angular/core';
import { LANGUAGE } from '@app/core/services/language.service';
import { formatPrice } from '@app/utils/format';

@Pipe({
  name: 'formatPrice',
})
export class FormatPricePipe implements PipeTransform {
  transform(value: any, language: LANGUAGE = 'de'): any {
    return value ? formatPrice(value, language) : '-';
  }
}
