<packex-card *ngIf="config">
  <div class="d-flex justify-content-between flex-column h-100">
    <div class="wrapper d-flex flex-row">
      <div class="me-4">
        <img [src]="config.image"/>
      </div>
      <div class="d-flex flex-column">
        <div class="subtitle-2 mb-2 text-primary-dark">{{ config.headline | transloco }}</div>
        <div class="caption text-primary-dark">{{ config.caption | transloco}}</div>
      </div>
    </div>
    <packex-button type="secondary" icon="download" class="download mt-3 w-100"
                   (buttonClick)="download()">{{ config.downloadText | transloco}}</packex-button>
  </div>
</packex-card>
