import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CartService } from '@modules/cart/cart.service';
import { ModalCartService } from '@modules/cart/modal-cart.service';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { CartProduct } from '@shared/models/cart';
import { DrawerService } from '@shared/services/drawer.service';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';

@Component({
  selector: 'packex-cart-product',
  templateUrl: './cart-product.component.html',
})
export class CartProductComponent {
  @Input() product!: CartProduct;
  @Input() showIndex = false;
  /* reduced view for Cart Flyout*/
  @Input() reducedView = false;

  /* special view for cart-summary */
  @Input() summaryView = false;
  @Input() editAction?: () => void;

  constructor(
    private readonly dialog: MatDialog,
    private readonly modalCartService: ModalCartService,
    private readonly drawerService: DrawerService,
    private readonly cartService: CartService,
    private readonly gtm: GoogleTagService,
  ) {}

  public async editProduct(): Promise<void> {
    if (this.drawerService.isDrawerVisible) {
      await this.drawerService.close();
    }

    if (this.editAction) {
      this.editAction();
      return;
    }

    const modalCartItems = ModalCartService.mapCartItemsToModalCartItems(
      this.product.items,
    );

    this.modalCartService.addVariantToCartAndOpenCartModal(
      {
        ...this.product.items[0].variant.inventoryItem,
        material: this.product.items[0].configuration.material,
        deliveryMethod: this.product.items[0].deliveryMethod,
      },
      modalCartItems,
    );
  }

  public deleteAllItemsForProduct(product: CartProduct): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        id: 'DeleteAllItemsForProduct',
        data: {
          title: 'CART.DELETE_PRODUCT.TITLE',
          content: 'CART.DELETE_PRODUCT.CONTENT',
          primaryButtonText: 'COMMON.DELETE',
        },
      })
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.gtm.push('remove_from_cart');
          this.cartService.deleteItemsFromCart(product.items);
        }
      });
  }
}
