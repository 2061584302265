import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Variant } from '@shared/models/variant';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VariantsService {
  constructor(private readonly apiService: ApiService) {}

  public getVariants(inventoryId: string): Observable<Variant[]> {
    return this.apiService.get(`inventory/${inventoryId}/variants`);
  }

  public getVariant(variantId: string): Observable<Variant> {
    return this.apiService.get(`variants/${variantId}`);
  }

  public createVariant(
    formData: FormData,
  ): Observable<Variant | HttpErrorResponse> {
    const options = this.apiService.buildHttpOptions({
      responseType: 'json',
    });

    return this.apiService.post(`variants`, formData, options);
  }

  public updateVariantArtwork(variant: Variant, formData: FormData) {
    const options = this.apiService.buildHttpOptions({
      responseType: 'json',
    });

    return this.apiService.patch(`variants/${variant.id}`, formData, options);
  }

  public updateVariant(
    variantId: string,
    fields: Partial<Variant>,
  ): Observable<Variant | HttpErrorResponse> {
    return this.apiService.patch(`variants/${variantId}`, fields);
  }

  public deleteVariant(variantId: string): Observable<HttpResponse<Variant>> {
    return this.apiService.delete(`variants/${variantId}`);
  }

  public approveVariant(variant: Variant) {
    return this.apiService.get(`variants/${variant.id}/approve`);
  }

  public addOneOffProductOptionsIds(
    variantId: string,
    productOptionIds: string[],
  ) {
    return this.apiService.patch(`variants/${variantId}`, {
      addOneOffProductOptionsIds: [...productOptionIds],
    });
  }
}
