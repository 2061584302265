<div class="stepper" [ngClass]="{'icon-stepper': iconMode}">
  <div class="step" *ngFor="let step of steps; let i = index; let last = last" [ngClass]="{'current-step': isCurrentStep(i)}">
    <div class="step-header d-flex justify-content-center flex-column" (click)="stepClicked.emit(i)" >
      <ng-container *ngIf="iconMode">
        <div class="step-number caption" [ngClass]="{'bg-white border border-secondary-medium': stepIsCompleted(i)}">
          <packex-icon [icon]="step.icon" *ngIf="step.icon" [color]="getIconColor(i)"></packex-icon>
        </div>
      </ng-container>
      <ng-container *ngIf="!iconMode">
        <div class="step-number caption" *ngIf="!stepIsCompleted(i)">
          <span *ngIf="!step.icon">{{getStepNumberByIndex(i)}}</span>
        </div>
        <div class="step-number bg-white border border-secondary-medium" *ngIf="stepIsCompleted(i) && !isCurrentStep(i)">
          <packex-icon icon="check" size="s" color="secondary"></packex-icon>
        </div>
        <div class="step-number bg-secondary-medium" *ngIf="stepIsCompleted(i) && isCurrentStep(i)">
          <packex-icon icon="check" size="s" color="white"></packex-icon>
        </div>
      </ng-container>

      <div class="stepper-label subtitle-2 mt-2">{{ step.label | transloco }}</div>
    </div>
    <div class="horizontal-line" *ngIf="!last"></div>
  </div>
</div>
