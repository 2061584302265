export const environment = {
  production: true,
  api: {
    url: 'https://api.stage.packex.com',
    version: '1',
  },
  keycloak: {
    url: 'https://id.packex.com',
    realm: 'packex-stage-new',
    clientId: 'portal',
  },
  languages: ['de', 'en'],
  useBrowserLang: false,
  defaultLang: 'de',
  version: null,
  GTM_ID: 'GTM-NG88H2K',
  GTM_AUTH: 'ledpG90kVtEmxs0KW9lGpA',
  GTM_PREVIEW: 'env-10',
  environment: 'stage',
};
