import { Component, EventEmitter, Input, Output } from '@angular/core';

export type EmptyScreenConfig = {
  asset: string;
  title: string;
  content: string;
  rawContent?: string;
  button?: string;
  buttonIcon?: string;
};

@Component({
  selector: 'packex-empty-screen',
  templateUrl: './empty-screen.component.html',
  styleUrls: ['./empty-screen.component.scss'],
})
export class EmptyScreenComponent {
  @Input() config!: EmptyScreenConfig;

  @Output() onButtonClick = new EventEmitter();
}
