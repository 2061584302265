import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserNotification } from '@shared/models/notification';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'packex-header-notification',
  templateUrl: './header-notification.component.html',
  styleUrls: ['./header-notification.component.scss'],
})
export class HeaderNotificationComponent implements OnInit {
  constructor(
    private readonly notificationService: NotificationService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event: Event) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.notificationService.getUserNotifications();
      });
  }

  get notifications(): UserNotification[] {
    return this.notificationService.userNotifications$.getValue();
  }
}
