<packex-modal (onDismiss)="dismiss()">
    <h5 class="modal-title">{{ 'CART.REFERENCE_INFORMATIONS.MODAL.TITLE' | transloco }}</h5>
    <div class="modal-body">
        <div class="border border-grey-medium bg-grey-light p-3">
            <span class="subtitle-1 mb-2 d-block">{{ item.variant.inventoryItem.name }}</span>
            <span class="subtitle-2 text-primary-medium d-block word-break-all">{{ item.variant.name }}</span>
            <p class="mb-0 text-primary-medium d-flex">
                <span>{{ 'CONFIGURATION.FORMAT.TITLE' | transloco }}:&nbsp;</span>
                <span>
          {{ getFormattedFormat(item.variant.inventoryItem) }}
                    <span
                            *ngIf="item.variant.inventoryItem.construction.category !== 'label'">
            {{ 'CONFIGURATION.FORMAT.UNITS' | transloco }}
          </span>
          <span *ngIf="item.variant.inventoryItem.construction.category === 'label'">
            {{ 'CONFIGURATION.FORMAT.UNITS_LABEL' | transloco }}
          </span>
        </span>
            </p>
            <div class="mb-0 text-primary-medium d-flex">
                <span *ngIf="item.variant.inventoryItem.construction.category !== 'shipping_box'">{{ 'CONFIGURATION.MATERIAL.TITLE' | transloco }}
                    :&nbsp;</span>
                <span *ngIf="item.variant.inventoryItem.construction.category === 'shipping_box'">{{ 'CONFIGURATION.COLOR.TITLE' | transloco }}
                    :&nbsp;</span>
                <div>
                    <span>{{ item.configuration.material.materialGroup.name }}</span>
                    <ng-container *ngIf="showGrammage">
                        - {{ item.configuration.material.grammage }}
                        <span [innerHTML]="'CONFIGURATION.MATERIAL.UNIT' | transloco"></span>
                    </ng-container>
                </div>
            </div>
            <div class="mb-0 text-primary-medium d-flex" *ngIf="getCoating()">
                <span>{{ getCoating()?.attribute?.name }}:&nbsp;</span>
                <div>
                    {{ getCoating()?.name }}
                </div>
            </div>
            <ng-container *ngIf="item.variant.inventoryItem.construction.category === 'label'">
                <div class="mb-0 text-primary-medium d-flex">
                    <span>{{ 'CONFIGURATION.WINDING.TITLE' | transloco }}:&nbsp;</span>
                    <div>
                        {{ 'CONFIGURATION.WINDING.' + item.variant.inventoryItem.winding?.toUpperCase() | transloco }};&nbsp;
                        {{ item.variant.inventoryItem.rollDiameter }}mm ({{ 'CONFIGURATION.WINDING.ROLL' | transloco }})
                    </div>
                </div>
            </ng-container>
            <p class="mb-0 text-primary-medium d-flex" *ngIf="item.productOptions.length">
                <span>{{ 'CONFIGURATION.SERVICES.TITLE' | transloco }}:&nbsp;</span>
                <span>{{ getProductOptions(item) }}</span>
            </p>
        </div>

        <div class="d-flex justify-content-between mt-4">
            <packex-button icon="left" [disabled]="previousDisabled" type="clear-icon"
                           (buttonClick)="previous()"></packex-button>
            <p class="text-primary-medium mb-2">{{
                    'CART.REFERENCE_INFORMATIONS.MODAL.POSITION' | transloco: {
                        current: (currentItemIndex + 1),
                        total: cartItems.length
                    }
                }}</p>
            <packex-button icon="right" [disabled]="nextDisabled" type="clear-icon"
                           (buttonClick)="next()"></packex-button>
        </div>

        <form [formGroup]="form" class="d-flex flex-column">
            <mat-form-field appearance="outline" class="w-100 mt-3">
                <input matInput type="text"
                       formControlName="customerOrderReference"
                       [maxlength]="MAX_CHAR_LENGTH"
                       [placeholder]="'CART.REFERENCE_INFORMATIONS.MODAL.REFERENCE_ORDER_NR' | transloco"/>
                <mat-hint align="end" class="text-primary-dark">{{
                        'COMMON.CHARACTERS_LEFT' | transloco : {
                            left: (MAX_CHAR_LENGTH - (form.get('customerOrderReference')?.value || '').length),
                            max: MAX_CHAR_LENGTH
                        }
                    }}
                </mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100">
                <input matInput type="text"
                       [maxlength]="MAX_CHAR_LENGTH"
                       formControlName="customerArticleNumber"
                       [placeholder]="'CART.REFERENCE_INFORMATIONS.MODAL.REFERENCE_MATERIAL_NR' | transloco"/>
                <mat-hint align="end" class="text-primary-dark">{{
                        'COMMON.CHARACTERS_LEFT' | transloco : {
                            left: (MAX_CHAR_LENGTH - (form.get('customerArticleNumber')?.value || '').length),
                            max: MAX_CHAR_LENGTH
                        }
                    }}
                </mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100">
                <input matInput type="text"
                       [maxlength]="MAX_CHAR_LENGTH"
                       formControlName="customerArticleDescription"
                       [placeholder]="'CART.REFERENCE_INFORMATIONS.MODAL.REFERENCE_MATERIAL_ART' | transloco"/>
                <mat-hint align="end" class="text-primary-dark">{{
                        'COMMON.CHARACTERS_LEFT' | transloco : {
                            left: (MAX_CHAR_LENGTH - (form.get('customerArticleDescription')?.value || '').length),
                            max: MAX_CHAR_LENGTH
                        }
                    }}
                </mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100 textarea">
        <textarea matInput
                  rows="5"
                  cols="8"
                  formControlName="customerComment"
                  [placeholder]="'COMMON.COMMENT' | transloco"></textarea>
            </mat-form-field>
        </form>
    </div>

    <packex-button type="primary" (buttonClick)="onConfirm()"
                   class="w-auto">{{ 'COMMON.SAVE_AND_CONTINUE' | transloco }}
    </packex-button>
</packex-modal>
