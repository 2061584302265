import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from '@app/routes';
import { cartEmptyScreenConfig } from '@modules/cart/cart-empty-screen';
import { CartItemsComponent } from '@modules/cart/cart-items/cart-items.component';
import { CartService } from '@modules/cart/cart.service';
import { EmptyScreenConfig } from '@shared/components/empty-screen/empty-screen.component';

@Component({
  selector: 'packex-cart-item-list',
  templateUrl: './cart-item-list.component.html',
  styleUrls: ['./cart-item-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartItemListComponent
  extends CartItemsComponent
  implements OnInit
{
  emptyScreenConfig: EmptyScreenConfig = cartEmptyScreenConfig;

  constructor(cartService: CartService, private readonly router: Router) {
    super(cartService);
  }

  public async goToCatalogue() {
    await this.router.navigateByUrl(`/${ROUTES.CONSTRUCTIONS}`);
  }
}
