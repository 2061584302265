<svg viewBox="0 0 100 100" [ngStyle]="style">
  <defs>
    <linearGradient id="Gradient" x1="80%" y1="0%" x2="20%" y2="100%" >

      <stop offset="0%" stop-color="#43B9E3">
        <animate attributeName="stop-color" values="#43B9E3; #DCEEF7; #43B9E3" dur="4s" repeatCount="indefinite"></animate> <!-- Colours chosen are secondary  and secondary-light -->
      </stop>

      <stop offset="100%" stop-color="#DCEEF7">
        <animate attributeName="stop-color" values="#DCEEF7; #43B9E3; #DCEEF7" dur="4s" repeatCount="indefinite"></animate>
      </stop>

    </linearGradient>
  </defs>
  <circle class="circle" cx="50" cy="50" r="30" fill="none"></circle>
</svg>
