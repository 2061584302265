<packex-modal (onDismiss)="dismiss()">
  <h5 class="modal-title">
    <packex-icon icon="filter" class="me-2"></packex-icon>Filter
  </h5>
  <div class="modal-body">
    <packex-inventory-sidebar [showResetButton]="false"></packex-inventory-sidebar>
  </div>

  <packex-button type="tertiary" icon="refresh" (buttonClick)="resetFilter()" color="primary-dark" class="w-auto">
    {{ 'FILTER.RESET'  | transloco }}
  </packex-button>
  <packex-button (click)="onConfirm()" class="w-auto">{{'FILTER.APPLY' | transloco}}</packex-button>
</packex-modal>
