import { AfterViewInit, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CrossSellingDialogComponent } from '@shared/components/cross-selling-dialog/cross-selling-dialog.component';

@Component({
  selector: 'packex-inventory-page',
  templateUrl: './inventory-page.component.html',
  styleUrls: ['./inventory-page.component.scss'],
})
export class InventoryPageComponent implements AfterViewInit {
  constructor(private readonly dialog: MatDialog) {}

  ngAfterViewInit(): void {
    // this.dialog.open(CrossSellingDialogComponent);
  }
}
