import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { buildEventSource } from '@app/utils/eventSource.helper';
import { variantHasStatus } from '@app/utils/variant-helper';
import { Step } from '@shared/components/progress-stepper/progress-stepper.component';
import { Variant } from '@shared/models/variant';
import { BehaviorSubject } from 'rxjs';

const PREFLIGHT_DELAY_TIMEOUT = 3 * 60 * 1000;

@Component({
  selector: 'packex-print-data-upload-processing',
  templateUrl: './print-data-upload-processing.component.html',
  styleUrls: ['./print-data-upload-processing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintDataUploadProcessingComponent
  implements OnChanges, OnDestroy
{
  @Input() variant: Variant | null = null;
  @Output() fileCheckFinished = new EventEmitter();
  @Output() onError = new EventEmitter();
  @Output() onPreflightDelay = new EventEmitter();

  delayTimeout?: number;
  public eventSource?: EventSource;
  progress$ = new BehaviorSubject({ percent: 0, currentStep: 0 });
  public showDelayNote = false;

  stepperSteps: Step[] = [
    {
      label: 'INVENTORY.PRINT_DATA_UPLOAD.STEP_UPLOAD',
    },
    {
      label: 'INVENTORY.PRINT_DATA_UPLOAD.STEP_CHECK',
    },
    {
      label: 'INVENTORY.PRINT_DATA_UPLOAD.STEP_RESULT',
    },
  ];

  constructor(
    private readonly api: ApiService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
  ) {}

  ngOnChanges(): void {
    if (this.variant && !variantHasStatus(this.variant, 'complete')) {
      this.eventSource = buildEventSource(
        `${ApiService.apiUrl}/status-updates/subscribe/${this.variant.id}`,
      );

      this.eventSource.onmessage = ({ data }) => {
        if (data.status === 'error') {
          this.closeStream();
          this.onError.emit();
        } else {
          const parsedData = JSON.parse(data);
          const percent = parsedData.progress * 100;

          this.updateProgress(percent);
        }
      };

      if (!this.delayTimeout) {
        clearTimeout(this.delayTimeout);
      }
      this.delayTimeout = window.setTimeout(() => {
        this.onPreflightDelay.emit();
        this.showDelayNote = true;
      }, PREFLIGHT_DELAY_TIMEOUT);
    } else {
      this.fileCheckFinished.next(true);
    }
  }

  ngOnDestroy(): void {
    this.closeStream();
  }

  private updateProgress(percent: number) {
    this.zone.run(() => {
      this.progress$.next({
        currentStep: 1,
        percent,
      });

      if (percent === 100) {
        this.progress$.next({
          currentStep: 2,
          percent,
        });

        setTimeout(() => {
          this.closeStream();
          this.fileCheckFinished.next(true);
        }, 1500);
      }
      this.cdr.detectChanges();
    });
  }

  private closeStream(): void {
    this.eventSource?.close();
    if (this.delayTimeout) {
      clearTimeout(this.delayTimeout);
      this.delayTimeout = undefined;
    }
  }
}
