import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InventoryItemsService } from '@app/core/services/inventory-items.service';
import { VariantsService } from '@app/core/services/variants.service';
import { VariantApproveErrorDialogComponent } from '@modules/inventory/components/variant-approve-error-dialog/variant-approve-error-dialog.component';
import { VariantApproveWarningDialogComponent } from '@modules/inventory/components/variant-approve-warning-dialog/variant-approve-warning-dialog.component';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { Variant } from '@shared/models/variant';
import { Observable, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VariantApproveService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly inventoryItemsService: InventoryItemsService,
    private readonly variantsService: VariantsService,
  ) {}

  private variantHasErrors(variant: Variant): boolean {
    return !!variant.lastPreflightResult?.errors.length;
  }

  private variantHasWarnings(variant: Variant): boolean {
    return !!variant.lastPreflightResult?.warnings.length;
  }

  public showPreflightModalForVariant(variant: Variant): boolean {
    return this.variantHasErrors(variant) || this.variantHasWarnings(variant);
  }

  public openPreflightModal(variant: Variant): Observable<boolean> {
    if (!variant) return of(false);

    return this.variantsService.getVariant(variant.id).pipe(
      switchMap((variant) => {
        const component = this.variantHasErrors(variant)
          ? VariantApproveErrorDialogComponent
          : VariantApproveWarningDialogComponent;

        if (this.showPreflightModalForVariant(variant)) {
          return this.dialog
            .open(component, {
              data: {
                variant,
              },
            })
            .afterClosed()
            .pipe(
              tap((shownModal) => shownModal && this.updateInventoryItems()),
            );
        }

        return of(false);
      }),
    );
  }

  public openInProgressDialog(): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'VARIANT_IN_PROGRESS_DIALOG.TITLE',
        content: 'VARIANT_IN_PROGRESS_DIALOG.CONTENT',
        primaryButtonText: 'COMMON.FORWARD',
        hideSecondaryButton: true,
      },
    });
  }

  private updateInventoryItems(): void {
    this.inventoryItemsService.forceUpdate();
  }
}
