<packex-page class="size-xl px-0 px-md-6">
  <div class="px-3 px-md-0 d-flex flex-column" #scrollContainer>
    <h3 class="mt-4">{{ 'DEFAULT.MAIN_NAVIGATION.MY_PRODUCTS' | transloco }}</h3>
    <packex-divider></packex-divider>

    <packex-inventory-list-option-bar [allSelected]="allSelected"
                                      [selectedItemsLength]="selectedItems.length"
                                      (allSelectedChanged)="onSelectAll($event)"
                                      (deleteSelectedItems)="deleteSelectedItems()"
                                      (createLabelForSelectedItems)="createLabels()"
                                      (onCreateProduct)="createProduct()"></packex-inventory-list-option-bar>
  </div>

  <div class="d-flex">
    <packex-inventory-sidebar class="col-lg-3 col-md-4 p-0 d-none d-md-block" (onCreateProduct)="createProduct()"></packex-inventory-sidebar>

    <div class="col-12 col-lg-9 col-md-8 p-0 inventor-list-wrapper" #inventoryItemsContainer>
      <div class="m-3 m-md-4 inventory-items-container" [ngClass]="{'loading':loading}">
        <ng-container *ngIf="inventoryItems">
          <packex-inventory-list-item
            *ngFor="let inventoryItem of inventoryItems | paginate: { itemsPerPage, currentPage, totalItems }; let first = first; trackBy: trackByFn"
            [inventoryItem]="inventoryItem"
            [selected]="!!inventoryItem.selected"
            (selectionChanged)="onSelectionChanged($event, inventoryItem)"
            [isOpen]="first"></packex-inventory-list-item>
        </ng-container>


        <packex-loading-component *ngIf="loading" message="INVENTORY.LIST.LOADING"></packex-loading-component>

        <packex-empty-products-screen
          *ngIf="!loading && !inventoryItems?.length" [hasFilter]="isFilterSet"
          class="mt-9" (onButtonClick)="handleEmptyScreen()"></packex-empty-products-screen>

        <packex-pagination
          *ngIf="!loading && inventoryItems?.length"
          (paginationChanged)="paginationChanged($event)"
          [loading]="loading"
          [itemsPerPageLabel]="'PAGINATION.PRODUCTS_PER_PAGE'"
          [itemsPerPage]="itemsPerPage"
          [totalItems]="totalItems"
          [currentPage]="currentPage">
        </packex-pagination>
      </div>
    </div>
  </div>
</packex-page>
