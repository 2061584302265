import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '@shared/components/modal/modal.component';

export type ConfirmDialogData = {
  title: string;
  content?: string;
  rawContent?: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  hideSecondaryButton?: boolean;
};

@Component({
  selector: 'packex-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent extends ModalComponent {
  constructor(
    dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: ConfirmDialogData,
  ) {
    super(dialogRef);
  }
}
