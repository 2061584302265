import { Component, Input } from '@angular/core';

@Component({
  selector: 'packex-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
})
export class ProgressSpinnerComponent {
  @Input() size = '2.444rem';

  get style() {
    return {
      width: this.size,
      height: this.size,
    };
  }
}
