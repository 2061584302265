import { Component, ViewEncapsulation } from '@angular/core';
import {
  PackexSelectComponent,
  SelectOption,
} from '@shared/components/packex-select/packex-select.component';
import { ModalCartItem } from '@shared/models/cart';

export type ModalCartItemSelectOption = ModalCartItem & SelectOption;

@Component({
  selector: 'packex-modal-cart-item-select',
  templateUrl: './packex-select.component.html',
  styleUrls: ['./packex-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalCartItemSelectComponent extends PackexSelectComponent<ModalCartItemSelectOption> {}
