import { ComponentType } from '@angular/cdk/overlay';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CartService } from '@modules/cart/cart.service';
import { CreateBillingAddressModalComponent } from '@modules/cart/create-billing-address-modal/create-billing-address-modal.component';
import { CreateDeliveryAddressModalComponent } from '@modules/cart/create-delivery-address-modal/create-delivery-address-modal.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { Address } from '@shared/models/address';
import { AddressService } from '@shared/services/address.service';

export type ChooseAddressConfig = {
  title: string;
  createButtonText: string;
  snackBarCreateSuccess: string;
  snackBarError: string;
  snackBarSuccess: string;
  component: ComponentType<
    CreateDeliveryAddressModalComponent | CreateBillingAddressModalComponent
  >;
  confirmDialog: {
    title: string;
    content: string;
  };
  showVatId: boolean;
  showSetStandard?: boolean;
};

@Component({
  selector: 'packex-choose-address-modal',
  templateUrl: './choose-address-modal.component.html',
  styleUrls: ['./choose-address-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChooseAddressModalComponent
  extends ModalComponent
  implements OnInit
{
  addresses: Address[] = [];
  config: ChooseAddressConfig | null = null;

  constructor(
    dialogRef: MatDialogRef<ModalComponent>,
    public readonly addressService: AddressService,
    public readonly dialog: MatDialog,
    public readonly cartService: CartService,
    public readonly snackbarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      current: Address;
    },
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    this.loadAddresses();
  }

  public setConfig(config: ChooseAddressConfig) {
    this.config = config;
  }

  public loadAddresses() {
    this.addressService
      .load()
      .subscribe((addresses) => (this.addresses = addresses));
  }

  public chooseAddress(address: Address): void {
    throw new Error(`Implement in child class ${address}`);
  }

  public onCreate(address: Address): void {
    this.onConfirm(address);
  }

  public override onConfirm(address?: Address): void {
    this.dialogRef.close(address);
  }
}
