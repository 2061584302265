<packex-well-notification *ngIf="showLabelNotification" [showCloseButton]="false" class="d-block mt-3 mb-4">
  {{ 'LABELS.WELL_NOTIFICATION' | transloco }}
</packex-well-notification>

<mat-form-field class="w-100" appearance="fill">
  <mat-chip-grid #chipList aria-label="Label selection">
    <mat-chip
      color="accent"
      *ngFor="let label of labels"
      (removed)="remove(label)">
      {{label}}
      <button matChipRemove>
        <packex-icon icon="close_circle" color="primary"></packex-icon>
      </button>
    </mat-chip>
    <input
      [placeholder]="'LABELS.PLACEHOLDER' | transloco"
      (blur)="onBlur()"
      [formControl]="input"
      #labelInput
      maxlength="20"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="createLabel($event)"
      [attr.data-testid]="'create-label-input'">
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let label of filteredLabels$ | async" [value]="label" [attr.data-testid]="'create-label-option-'+ label"
                (click)="selected(label)">
      {{label}}
    </mat-option>
  </mat-autocomplete>
  <mat-hint align="end">{{ "COMMON.CHARACTERS_LEFT" | transloco: {
    left: (20 - (input.value?.length || 0)),
    max: 20
  }
    }}</mat-hint>
</mat-form-field>
