<packex-modal (onDismiss)="dismiss()" [isScrollable]="false">
  <h5 class="modal-title">{{ 'USER.CHANGE_PASSWORD.CHANGE_PASSWORD' | transloco }}</h5>

  <div class="modal-body">
    <form [formGroup]="form">
      <div class="mt-2 mb-1">
        <div class="subtitle-2 mb-3">{{ 'USER.CHANGE_PASSWORD.CONFIRM_CURRENT_PASSWORD' | transloco }}</div>
        <mat-form-field appearance="outline" class="w-100">
          <input matInput placeholder="{{'USER.CHANGE_PASSWORD.CURRENT_PASSWORD' | transloco}}*"
                 [type]="hideOldPassword ? 'password' : 'text'"
                 formControlName="oldPassword"
                 name="oldPassword" required>
          <packex-icon class="hovered-pointer me-2" matSuffix
                       color="grey"
                       (click)="hideOldPassword = !hideOldPassword"
                       [icon]="hideOldPassword ? 'hide' : 'show'"></packex-icon>
          <mat-error *ngIf="form.get('oldPassword')?.invalid">
            {{oldPasswordError | transloco}}
          </mat-error>
        </mat-form-field>

      </div>
      <div class="subtitle-2 mb-3">{{ 'USER.CHANGE_PASSWORD.ENTER_NEW_PASSWORD' | transloco }}</div>
      <packex-change-password-form [form]="form"></packex-change-password-form>
    </form>
  </div>

  <packex-button (buttonClick)="changePassword()"
                 class="w-auto"
                 [disabled]="form.invalid || passwordsNotEqual">{{ 'USER.CHANGE_PASSWORD.CHANGE_PASSWORD' | transloco }}</packex-button>
</packex-modal>
