<ng-container *ngIf="(cart$ | async) as cart">
  <div class="product-wrapper">
    <packex-empty-screen class="product-wrapper m-auto"
                         [config]="emptyScreenConfig"
                         (onButtonClick)="goToCatalogue()"
                         *ngIf="(cartProducts$ | async)?.length === 0"></packex-empty-screen>
    <packex-cart-product [product]="product" *ngFor="let product of cartProducts$ | async"
                         [showIndex]="true"
                         class="border-top border-light mb-4 box-shadow"></packex-cart-product>
  </div>
</ng-container>
