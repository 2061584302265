import { FilterItem } from '@modules/filter/models/filter-item';

export type FilterType =
  | 'lockingType'
  | 'topics'
  | 'type'
  | 'weight'
  | 'isPrivate';

export const filterTypes: FilterType[] = [
  'type',
  'topics',
  'lockingType',
  'weight',
  'isPrivate',
];

export interface FilterCategory extends FilterItem {
  isCategory: boolean;
  items: FilterItem[];
  type: FilterType;
}
