<packex-card class="w-100 mb-4">
  <h4 class="mb-6">{{ 'USER.ORGANIZATION.ORGANIZATION_DETAILS' | transloco }}</h4>
  <form [formGroup]="form">
    <mat-form-field appearance="outline" class="w-100">
      <input
        matInput
        type="text"
        [placeholder]="('USER.ORGANIZATION.NAME_OF_ORGANIZATION' | transloco) + '*'"
        formControlName="company"
      />
      <mat-error *ngIf="form.get('company')?.invalid">{{ 'FORM.ERROR.COMPANY' | transloco }}</mat-error>
    </mat-form-field>
  </form>

  <div class="subtitle-1 mt-3 mb-4">{{ 'ORGANIZATION.LANGUAGE_SETTING.TITLE' | transloco }}</div>
  <p class="mb-4">{{ 'ORGANIZATION.LANGUAGE_SETTING.DESCRIPTION' | transloco }}</p>

  <packex-organization-language-switch (currentChange)="languageChanged($event)"></packex-organization-language-switch>

  <div class="d-flex flex-column align-items-end mt-6">
    <packex-button class="w-auto" [disabled]="form.invalid"
                   (buttonClick)="save()">{{ 'COMMON.SAVE_CHANGES' | transloco }}</packex-button>
  </div>
</packex-card>
