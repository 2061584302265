import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  KeyCloakTokenResponse,
  UserService,
} from '@app/core/services/user.service';
import { KEYCLOAK_LS_KEY } from '@app/utils/keycloak-helper';
import { User } from '@shared/models/user';
import { firstValueFrom } from 'rxjs';
import { environment } from '@env';

@Component({
  selector: 'packex-registration-create-page',
  templateUrl: './registration-create-page.component.html',
  styleUrls: ['./registration-create-page.component.scss'],
})
export class RegistrationCreatePageComponent implements OnInit {
  loading = false;
  created = false;
  readonly storage = window.localStorage;

  constructor(private router: Router, private userService: UserService) {}

  ngOnInit(): void {
    this.created = false;
    this.loading = false;
  }

  public async registrationProcessDone(
    userRegistration: User & { password: string },
  ) {
    const keycloakResponse = await firstValueFrom<KeyCloakTokenResponse>(
      this.userService.keyCloakAuth(
        userRegistration.email as string,
        userRegistration.password,
      ),
    );

    if (keycloakResponse) {
      try {
        localStorage.setItem(
          KEYCLOAK_LS_KEY,
          JSON.stringify({
            token: keycloakResponse.access_token,
            refreshToken: keycloakResponse.refresh_token,
            idToken: keycloakResponse.id_token,
          }),
        );

        location.href = location.origin;
      } catch (e) {
        console.error(e);
      }
    } else {
      await this.router.navigate(['/user/verify-email'], {
        state: { data: { userRegistration } },
      });
    }
    this.loading = false;
  }

  public async navigateToIdentityProvider(provider: string) {
    const url = this.identityProviderUrl(provider);
    window.location.href = url;
  }

  public identityProviderUrl(provider: string) {
    return `${environment.keycloak.url}/realms/${environment.keycloak.realm}/protocol/openid-connect/auth?client_id=${environment.keycloak.clientId}&redirect_uri=${location.origin}/&response_type=code&scope=openid&kc_idp_hint=${provider}`;
  }
}
