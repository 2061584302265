import { Pipe, PipeTransform } from '@angular/core';
import { escapeRegExp } from 'lodash';

@Pipe({
  name: 'highlightSearch',
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: any, args: any): any {
    if (!args) {
      return value;
    }

    const regex = new RegExp(escapeRegExp(args), 'gi');
    const match = value.match(regex);

    if (!match) {
      return value;
    }

    return value.replace(
      regex,
      `<span class='highlight-text'>${match[0]}</span>`,
    );
  }
}
