<packex-card class="p-4 w-100 h-auto position-relative">
  <h4 class="card-title mb-6">{{ 'CART.REFERENCE_INFORMATIONS.TITLE' | transloco }}</h4>

  <p class="mb-6">{{ 'CART.REFERENCE_INFORMATIONS.DESCRIPTION' | transloco }}</p>

  <ng-container *ngFor="let item of items">
    <div class="border border-grey-medium p-3 mb-3">
      <div class="d-flex justify-content-between mb-3">
        <div>
          <span class="subtitle-2 text-primary-dark">{{ item.variant.inventoryItem.name }}</span>
          <p class="text-primary-medium">{{ item.variant.name }}</p>
        </div>
        <packex-button icon="edit" type="clear-icon" (buttonClick)="editReference(item)"
                       [matTooltip]="'CART.REFERENCE_INFORMATIONS.TOOLTIP' | transloco"></packex-button>
      </div>

      <packex-cart-item-reference [item]="item"></packex-cart-item-reference>

      <div *ngIf="item.customerComment" class="border-top border-grey-medium pt-2 mt-3">
        <p>{{ item.customerComment }}</p>
      </div>
    </div>
  </ng-container>

  <packex-button (buttonClick)="addInformation()" icon="add_circle"
                 [disabled]="!cartHasProducts"
                 buttonPadding="py-2 px-3"
                 type="secondary"
                 class="w-auto">{{ 'CART.REFERENCE_INFORMATIONS.ADD_BUTTON' | transloco }}</packex-button>
</packex-card>
