import { CartItem } from '@shared/models/cart';

export const hasReferenceCode = (cartItem: CartItem) => {
  return [
    cartItem.customerOrderReference,
    cartItem.customerArticleNumber,
    cartItem.customerArticleDescription,
    cartItem.customerComment,
  ].some((fieldToConsider: string) => !!fieldToConsider);
};
