import { CreateBillingAddressModalComponent } from '@modules/cart/create-billing-address-modal/create-billing-address-modal.component';
import { CreateDeliveryAddressModalComponent } from '@modules/cart/create-delivery-address-modal/create-delivery-address-modal.component';

export const billingAddressConfig = {
  component: CreateBillingAddressModalComponent,
  createButtonText: 'CART.BILLING.CREATE_BILLING_ADDRESS',
  snackBarError: 'CART.BILLING.DELETE_ADDRESS_ERROR',
  snackBarSuccess: 'CART.BILLING.DELETE_ADDRESS_SUCCESS',
  snackBarCreateSuccess: 'CART.BILLING.CREATE_ADDRESS_SUCCESS',
  title: 'CART.BILLING.SELECT_BILLING_ADDRESS',
  confirmDialog: {
    title: 'CART.BILLING.DELETE_ADDRESS_TITLE',
    content: 'CART.BILLING.DELETE_ADDRESS_DESCRIPTION',
  },
  showVatId: true,
};

export const deliveryAddressConfig = {
  component: CreateDeliveryAddressModalComponent,
  createButtonText: 'CART.DELIVERY.CREATE_DELIVERY_ADDRESS',
  snackBarError: 'CART.DELIVERY.DELETE_ADDRESS_ERROR',
  snackBarSuccess: 'CART.DELIVERY.DELETE_ADDRESS_SUCCESS',
  snackBarCreateSuccess: 'CART.DELIVERY.CREATE_ADDRESS_SUCCESS',
  title: 'CART.DELIVERY.SELECT_DELIVERY_ADDRESS',
  confirmDialog: {
    title: 'CART.DELIVERY.DELETE_ADDRESS_TITLE',
    content: 'CART.DELIVERY.DELETE_ADDRESS_DESCRIPTION',
  },
  showVatId: false,
};
