import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { ProductOption } from '@shared/models/product-option';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductOptionService {
  constructor(private readonly apiService: ApiService) {}

  public load(): Observable<ProductOption[]> {
    return this.apiService.get('product-options');
  }
}
