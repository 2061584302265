<h4 class="mb-3">{{ 'INVENTORY.PRINT_DATA_UPLOAD.HEADLINE' | transloco}}</h4>
<ng-container *ngIf="(state$ | async) !== 'showResult'">
  <p>{{ 'INVENTORY.PRINT_DATA_UPLOAD.DESCRIPTION' | transloco}}</p>

  <div class="my-3 drag-area"
       [class.droparea]="isDragOver"
       [class.dragarea]="!isDragOver"
       (dragenter)="onDragEnter($event)"
       (dragover)="onDragOver($event)"
       (dragleave)="onDragLeave($event)"
       (drop)="onDrop($event)"
       [class.active]="!!filename"
       [ngClass]="{ disabled: disabled }"
  >
    <div class="description d-flex flex-column" *ngIf="(state$ | async) === 'upload'">
      <ng-template #fscAccept>
        <h5 class="mb-5">{{ 'INVENTORY.PRINT_DATA_UPLOAD.FSC_ACCEPTANCE.TITLE' | transloco }}</h5>

        <p class="mb-5 w-50 m-auto"
           [innerHTML]="'INVENTORY.PRINT_DATA_UPLOAD.FSC_ACCEPTANCE.DESCRIPTION' | transloco"></p>

        <packex-button (buttonClick)="fscAccepted = true"
                       class="w-auto m-auto">{{ 'COMMON.ACCEPT' | transloco }}</packex-button>
      </ng-template>

      <ng-container *ngIf="!showFSCAcceptance; else fscAccept">
        <packex-icon icon="upload" class="d-flex justify-content-center mb-2" size="l" color="secondary"></packex-icon>
        <p class="mb-0">{{'FILE_UPLOAD.DESCRIPTION' | transloco}}</p>
        <a class="choose-file-button text-secondary-medium"
           (click)="chooseFile()">{{'FILE_UPLOAD.CHOOSE_FILE' | transloco}}</a><br/>
        <ng-container *ngIf="error">
          <p class="subtitle-2 text-error">{{ error.title | transloco: {fileSize: sizeMaxInMb} }}</p>
          <p class="text-error">{{ error.message | transloco }}</p>
        </ng-container>
      </ng-container>
      <input
        class="file-input"
        #fileUploader
        [disabled]="disabled"
        [accept]="acceptedFileTypes"
        type="file"
        [attr.data-testid]="'file-upload'"
        (change)="onFileSelected($event)"
      />
    </div>
    <packex-loading-component *ngIf="loading" class="mt-0" message="FILE_UPLOAD.FILE_IS_UPLOADING">

    </packex-loading-component>
    <packex-print-data-upload-processing
      *ngIf="showUploadProcess() | async"
      [variant]="currentVariant$ | async"
      (onError)="uploadErrorOccurred()"
      (onPreflightDelay)="handlePreflightDelay()"
      (fileCheckFinished)="showResult()"></packex-print-data-upload-processing>
  </div>

  <div>
    <packex-button type="secondary" (buttonClick)="orderBlanko()" class="w-auto"
                   [attr.data-testid]="'print-data-manager-order-blanko-button'"
                   [disabled]="blankoButtonDisabled"
                   [matTooltip]="blankoButtonTooltip | transloco"
                   [matTooltipDisabled]="blankoTooltipDisabled">
      {{ 'INVENTORY.PRINT_DATA_UPLOAD.ORDER_BLANKO_TEXT' | transloco}}
    </packex-button>
  </div>

</ng-container>

<packex-print-data-upload-result
  *ngIf="(state$ | async) === 'showResult'"
  [inventoryItemId]="inventoryItem?.id"
  (addVariantClicked)="addVariant()">

</packex-print-data-upload-result>
