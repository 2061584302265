import { Component, Input } from '@angular/core';

@Component({
  selector: 'packex-loading-component',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input() message?: string;
  @Input() iconSize = '12rem';
}
