<ng-container *ngIf="(state$ | async)?.loaded">
  <p class="caption text-primary-dark mb-5" *ngIf="hasErrors || hasWarnings"
     [innerHTML]="'INVENTORY.PRINT_DATA_UPLOAD.RESULT.ERROR_WARNING_MESSAGE' | transloco"></p>

  <packex-print-data-items-list
    [inventoryItemId]="inventoryItemId"
    [variants]="variants"
    (addVariantClicked)="addVariant()"></packex-print-data-items-list>

  <packex-well-notification
    class="mt-4"
    icon="variant"
    severity="info"
    [buttonText]="'INVENTORY.PRINT_DATA_UPLOAD.ITEMS_LIST.ADD_VARIANT' | transloco"
    buttonColor="primary-dark"
    (buttonClicked)="addVariant()">
    {{ 'INVENTORY.PRINT_DATA_UPLOAD.RESULT.WELL_NOTIFICATION_TEXT' | transloco }}
  </packex-well-notification>
</ng-container>

<packex-button icon="cart" [disabled]="!hasCompletedItems"
               class="w-auto align-self-end mt-4 w-auto"
               buttonPadding="py-2 px-3"
               (buttonClick)="addProductToCart()">{{ 'CART.ADD_TO_CART' | transloco }}</packex-button>
