import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {
  SnackbarComponent,
  SnackBarProperties,
} from '@shared/components/snackbar/snackbar.component';

export const DEFAULT_SNACKBAR_DURATION = 10000;

@Injectable()
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  private static getConfig(
    customConfig?: MatSnackBarConfig,
  ): MatSnackBarConfig {
    return {
      ...customConfig,
      duration: DEFAULT_SNACKBAR_DURATION,
    };
  }

  public show(data: SnackBarProperties, config?: MatSnackBarConfig): void {
    this.snackBar.openFromComponent(SnackbarComponent, {
      ...SnackBarService.getConfig(config),
      panelClass: 'bg-primary-dark',
      data: {
        ...data,
        icon: data.icon || 'check',
        buttons: data.buttons?.map((b) => ({
          ...b,
          color: 'tertiary',
        })) || [
          {
            label: 'COMMON.OKAY',
            color: 'tertiary',
            cb: () => this.dismiss(),
          },
        ],
      },
    });
  }

  public showError(data: SnackBarProperties, config?: MatSnackBarConfig): void {
    this.snackBar.openFromComponent(SnackbarComponent, {
      ...SnackBarService.getConfig(config),
      panelClass: 'bg-error',
      data: {
        ...data,
        icon: 'info_circle_error_white',
      },
    });
  }

  public dismiss(): void {
    this.snackBar.dismiss();
  }

  public showSimpleError(message: string): void {
    this.showError(<SnackBarProperties>{
      message,
      icon: 'info_circle_error_white',
      buttons: [
        {
          label: 'COMMON.OKAY',
          cb: () => this.dismiss(),
        },
      ],
    });
  }

  public showSimpleSuccess(message: string): void {
    this.show(<SnackBarProperties>{
      message,
      icon: 'check',
      buttons: [
        {
          label: 'COMMON.OKAY',
          cb: () => this.dismiss(),
        },
      ],
    });
  }
}
