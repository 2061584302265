import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private readonly apiService: ApiService) {}

  requestOffer(formData: FormData): Observable<unknown | HttpErrorResponse> {
    const options = this.apiService.buildHttpOptions({
      responseType: 'json',
    });

    return this.apiService.post('contact', formData, options);
  }
}
