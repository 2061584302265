<packex-modal  (onDismiss)="dismiss()" [isScrollable]="false">
  <h5 class="modal-title d-flex flex-row align-content-center">
    <packex-icon icon="label" class="me-2"></packex-icon>
    {{ 'LABELS.CREATE_LABELS' | transloco }}
  </h5>

  <div class="modal-body">
    <packex-create-label (labelsChanged)="labelsChanged($event)"
                         [inventoryItem]="dialogData.inventoryItem"></packex-create-label>
  </div>

  <packex-button (buttonClick)="onConfirm()" class="w-auto">{{ 'LABELS.ADD_LABEL' | transloco }}</packex-button>
</packex-modal>
