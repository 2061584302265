<packex-card class="p-4 w-100 h-auto position-relative d-flex flex-column">
  <h4 class="card-title mb-6">{{ 'CART.PAYMENT.TITLE' | transloco }}</h4>

  <p class="text-primary-medium my-6" *ngIf="onlyHasPrepaid">{{ 'CART.PAYMENT.ONLY_PREPAID' | transloco }}</p>

  <div class="p-3 w-100 mb-3 payment-method border-radius-4" *ngFor="let paymentMethod of availablePaymentOptions"
       [ngClass]="{'border-secondary-medium bg-secondary-light': isActive(paymentMethod), 'cursor-pointer': !isActive(paymentMethod)}"

       (click)="choosePaymentMethod(paymentMethod)">
    {{ getPaymentMethodTranslation(paymentMethod) | transloco }}
  </div>
</packex-card>
