<div class="inventory-sidebar-container me-4 mb-3">
  <packex-button class="w-100 mb-3 d-none d-lg-block" type="primary"
                 hideTextDown="md"
                 icon="add_circle" (buttonClick)="onCreateProduct.emit(true)">
    {{ 'INVENTORY.LIST.CREATE_NEW_PRODUCT' | transloco }}
  </packex-button>

  <div class="filter d-flex" *ngFor="let filter of filters" [ngClass]="{'active': filter.active}"
       (click)="setFilter(filter.type)">
    <packex-icon [icon]="filter.icon" class="me-2" [color]="filter.active ? 'white' : 'primary'"></packex-icon>
    {{ filter.label | transloco }}
  </div>

  <packex-inventory-label-filter
    class="d-block" [ngClass]="{'border-1 border-bottom border-grey-medium mb-2': showResetButton}"></packex-inventory-label-filter>

  <packex-button type="tertiary" icon="refresh" (buttonClick)="resetFilter()" color="primary-dark" class="w-auto"
                 *ngIf="showResetButton">
    {{ "FILTER.RESET"  | transloco }}
  </packex-button>
</div>
