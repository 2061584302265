import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LANGUAGE } from '@app/core/services/language.service';
import { SESSION_LANGUAGE } from '@app/utils/language.helper';
import { environment } from '@env';
import { catchError, Observable, throwError } from 'rxjs';

export const IMPERSONATE_KEY = 'impersonateUserId';
export const IMPERSONATE_HEADER = 'x-packex-user';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  storage = window.sessionStorage;

  static apiVersion = `v${environment.api.version}`;
  static apiUrl = `${environment.api.url}/${ApiService.apiVersion}`;

  constructor(private http: HttpClient) {}

  public buildHttpOptions(
    config: {
      contentType?: string;
      responseType: XMLHttpRequestResponseType;
    } = {
      contentType: 'application/json',
      responseType: 'json',
    },
  ): { headers: HttpHeaders; responseType: XMLHttpRequestResponseType } {
    let headers = new HttpHeaders({
      'accept-language':
        (this.storage.getItem(SESSION_LANGUAGE) as LANGUAGE) || 'de',
    });

    const impersonateKey = sessionStorage.getItem(IMPERSONATE_KEY);
    if (impersonateKey) {
      headers = headers.set(IMPERSONATE_HEADER, impersonateKey);
    }

    if (config.contentType) {
      headers = headers.set('Content-Type', config.contentType);
    }

    return {
      headers,
      responseType: config.responseType,
    };
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  public getRaw(url: string, options: any): Observable<any> {
    const mergedOptions = {
      ...options,
      observe: 'response',
    };

    return this.http
      .get(url, mergedOptions)
      .pipe(catchError(this.formatErrors));
  }

  get(path: string, options?: any): Observable<any> {
    const mergedOptions = {
      ...this.buildHttpOptions(),
      ...options,
    };

    return this.http
      .get(`${ApiService.apiUrl}/${path}`, mergedOptions)
      .pipe(catchError(this.formatErrors));
  }

  post(path: string, body: any, options?: any): Observable<any> {
    const mergedOptions = {
      ...this.buildHttpOptions(),
      ...options,
    };
    return this.http
      .post(`${ApiService.apiUrl}/${path}`, body, mergedOptions)
      .pipe(catchError(this.formatErrors));
  }

  patch(path: string, body: any, options?: any): Observable<any> {
    const mergedOptions = { ...this.buildHttpOptions(), ...options };
    return this.http
      .patch(`${ApiService.apiUrl}/${path}`, body, mergedOptions)
      .pipe(catchError(this.formatErrors));
  }

  delete(path: string, options?: any): Observable<any> {
    const mergedOptions = { ...this.buildHttpOptions(), ...options };
    return this.http
      .delete(`${ApiService.apiUrl}/${path}`, mergedOptions)
      .pipe(catchError(this.formatErrors));
  }
}
