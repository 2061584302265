<ng-container *ngIf="item">
  <div class="d-flex flex-row">
    <div *ngIf="!reducedView" class="position me-3 mt-2">
      <span class="subtitle-2">{{ index }}</span>
    </div>
    <div class="w-100 d-flex flex-column">
      <p class="text-primary-dark mb-0 text-break me-4">{{ getVariantName(item.variant) }}</p>
      <div class="d-flex flex-row mt-1">
      <span class="caption text-primary-medium">
        {{ item.configuration.amount }}&nbsp;{{ 'COMMON.PCS_ABBR' | transloco }}<span
        class="caption text-primary-medium" *ngIf="productOptions">;&nbsp;{{ productOptions }}</span>
      </span>
      </div>
      <packex-download-button [url]="item.variant.artworkUrl" class="display-contents">
        {{item.variant.originalArtworkFilename}}
      </packex-download-button>
    </div>
    <div class="d-flex flex-column">
      <p class="text-primary-dark mb-0">{{ item.price | formatPrice }}</p>
      <span class="caption mt-1">{{ item.unitPrice | formatPrice }}/{{ 'COMMON.PCS_ABBR' | transloco }}</span>
    </div>

    <packex-button icon="delete" (buttonClick)="deleteItem(item)" type="clear-icon"
                   [attr.data-testid]="'delete-button'"
                   class="ms-4"></packex-button>
  </div>
</ng-container>
