<packex-page>
  <packex-card class="h-auto">
    <h2 class="text-uppercase">{{ 'USER.CHANGE_PASSWORD.TITLE' | transloco}}</h2>
    <packex-divider></packex-divider>
    <packex-change-password-form [form]="form"></packex-change-password-form>

    <packex-button (buttonClick)="submit()" class="mt-3"
                   [disabled]="form.invalid || passwordsNotEqual">{{'USER.CHANGE_PASSWORD.CHANGE_PASSWORD' | transloco}}</packex-button>
  </packex-card>
</packex-page>
