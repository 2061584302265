<ng-container *ngIf="item">
  <div class="d-flex flex-row align-items-center">
    <div *ngIf="!reducedView" class="position me-3">
      <span class="subtitle-2">{{ index }}</span>
    </div>
    <div class="w-100 d-flex flex-column">
      <span class="subtitle-2 text-break me-3">{{ getVariantName(item.variant) }}</span>
      <div class="d-flex flex-row mt-1 flex-wrap">
        <span class="caption text-primary-medium">
          {{ item.configuration.amount }}&nbsp;{{ 'COMMON.PCS_ABBR' | transloco }}
          <ng-container *ngIf="productOptions">&semi;&nbsp;</ng-container>
        </span>
        <span class="caption text-primary-medium" *ngIf="productOptions">{{ productOptions }}</span>
      </div>
      <ng-container *ngIf="productOptionsHaveAdditionalCheckingDays">
        <packex-well-notification severity="warning"
                                  [showNewText]="false"
                                  [showCloseButton]="false"
                                  padding="px-2 py-1"
                                  width="w-auto"
                                  class="my-1 order-item-hint">
          <span
              class="caption text-primary-dark">+{{ additionalProductOptionsCheckingDays(item.productOptions) }}</span>
        </packex-well-notification>
      </ng-container>
      <ng-container *ngIf="item.variant.blankArtwork">
        <packex-well-notification severity="warning"
                                  [showNewText]="false"
                                  padding="px-2 py-1"
                                  width="w-auto" class="my-1 order-item-hint"
                                  size="small"
                                  [showCloseButton]="false"
        >
          <span class="text-primary-dark" [innerHTML]="'CART.BLANK_ARTWORK_COATING_HINT' | transloco"></span>
        </packex-well-notification>
      </ng-container>

      <packex-download-button *ngIf="item.variant.artworkUrl && item.variant.originalArtworkFilename"
                              [url]="item.variant.artworkUrl"
                              class="display-contents text-break me-3">{{ item.variant.originalArtworkFilename }}
      </packex-download-button>
    </div>
    <div class="d-flex flex-column align-items-end">
      <span class="subtitle-2">{{ item.price | formatPrice }}</span>
      <span class="caption text-primary-medium mt-1">{{ item.unitPrice | formatPrice }}
        /{{ 'COMMON.PCS_ABBR' | transloco }}</span>
    </div>

    <packex-button *ngIf="!summaryView" (buttonClick)="deleteItem(item)" icon="delete" type="clear-icon"
                   buttonPadding="p-0"
                   class="m-auto ms-4"></packex-button>
  </div>

  <div *ngIf="summaryView" class="d-flex flex-column ms-6 mt-2">
    <packex-cart-item-reference [item]="item"></packex-cart-item-reference>
  </div>

  <div *ngIf="summaryView && item.customerComment" class="border-top border-bottom border-grey-medium py-2 mt-2 ms-6">
    {{ item.customerComment }}
  </div>
</ng-container>
