<div class="d-flex justify-content-between flex-column flex-md-row">
  <packex-card class="w-100 me-0 me-md-4" [attr.data-testid]="'delivery-addresses'">
    <h4 class="mb-6">{{ 'USER.ORGANIZATION.DELIVERY_ADDRESSES' | transloco }}</h4>
    <packex-address-choose-list [config]="deliveryAddressConfig"
                                [addresses]="deliveryAddresses"
                                [current]="defaultDeliveryAddress"
                                (onLoad)="loadAddresses()"
                                (onSetStandard)="setStandardDeliveryAddress($event)"
    ></packex-address-choose-list>
  </packex-card>
  <packex-card class="w-100 mt-4 mt-md-0" [attr.data-testid]="'billing-addresses'">
    <h4 class="mb-6">{{ 'USER.ORGANIZATION.BILLING_ADDRESSES' | transloco }}</h4>
    <packex-address-choose-list [config]="billingAddressConfig"
                                [addresses]="billingAddresses"
                                [current]="defaultBillingAddress"
                                (onLoad)="loadAddresses()"
                                (onSetStandard)="setStandardBillingAddress($event)"
    ></packex-address-choose-list>
  </packex-card>
</div>
