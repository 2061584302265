import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Invitation } from '@shared/models/invitation';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvitationService {
  constructor(private readonly api: ApiService) {}

  public invite(email: string) {
    return this.api.post('invitations', {
      email,
    });
  }

  public load(): Observable<Invitation[]> {
    return this.api.get('invitations');
  }

  public loadInvitation(invitationId: string): Observable<Invitation> {
    return this.api.get(`invitations/${invitationId}`);
  }

  public accept(invitationId: string) {
    return this.api.post('invitations/accept', {
      invitationId,
    });
  }

  public decline(invitationId: string) {
    return this.api.delete(`invitations/${invitationId}`);
  }
}
