<form [formGroup]="form">
  <div formArrayName="recipients">
    <div *ngFor="let recipient of recipients.controls; let i = index;" class="d-flex flex-column">
      <div class="d-flex align-items-start">
        <mat-form-field appearance="outline" class="w-100 no-subscriptions">
          <input
            #inputElement
            matInput
            type="text"
            [placeholder]="placeholder | transloco"
            [formControlName]="i"
            (keydown)="onKeyDown($event, i)"
            [ngClass]="{'border-error': fieldHasError(recipient)}"
          />
          <mat-error *ngIf="fieldHasError(recipient) && recipient.errors?.['validateEmail']">
            {{ 'FORM.ERROR.EMAIL' | transloco }}
          </mat-error>
        </mat-form-field>
        <packex-button icon="close" (buttonClick)="removeRecipient(i)"
                       class="ms-2 mt-1 d-flex"
                       type="clear-icon" iconSize="s"
                       *ngIf="i !== (recipients.controls.length - 1)"></packex-button>
        <packex-button icon="add_circle" (buttonClick)="addRecipient('', true)"
                       class="w-auto ms-2 icon-only d-flex align-items-center" iconSize="s"
                       *ngIf="i === (recipients.controls.length - 1)"></packex-button>
      </div>
    </div>
  </div>
</form>
