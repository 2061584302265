import { Component, Input } from '@angular/core';

@Component({
  selector: 'packex-advertise-banner',
  templateUrl: './advertise-banner.component.html',
  styleUrls: ['./advertise-banner.component.scss'],
})
export class AdvertiseBannerComponent {
  @Input() asset = 'Provide a title';
  @Input() title = 'Provide a title';
  @Input() content = 'Provide a text';
  @Input() background? = 'white';
  @Input() buttonText = 'Button';
  @Input() borderRadius = true;

  @Input() size: 'xl' | 'l' | 's' = 'l';

  @Input() callback = () => {
    alert('Please provide a function');
  };
}
