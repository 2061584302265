import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfigurationsService } from '@app/core/services/configurations.service';
import { InventoryItemsService } from '@app/core/services/inventory-items.service';
import { ROUTES } from '@app/routes';
import {
  InventoryError,
  inventoryItemHasError,
} from '@app/utils/inventory-items.helper';
import { ModalCartService } from '@modules/cart/modal-cart.service';
import { ConfiguratorDialogSaveComponent } from '@modules/configurator/components/configurator-dialog-save/configurator-dialog-save.component';
import { FilterService } from '@modules/filter/services/filter.service';
import { InventoryDialogDeleteComponent } from '@modules/inventory/components/inventory-dialog-delete/inventory-dialog-delete.component';
import { INVENTORY_ROUTES } from '@modules/inventory/inventory-routing.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CreateLabelModalComponent } from '@shared/components/create-label-modal/create-label-modal.component';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { InventoryItem } from '@shared/models/inventory-item';
import { Label } from '@shared/models/label';

@UntilDestroy()
@Component({
  selector: 'packex-inventory-list-item',
  templateUrl: './inventory-list-item.component.html',
  styleUrls: ['./inventory-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryListItemComponent implements OnInit {
  @Input() inventoryItem!: InventoryItem;
  @Input() isOpen = false;
  @Input() selected = false;
  @Output() selectionChanged = new EventEmitter<boolean>();
  searchTerm?: string;

  labels: Label[] = [];

  protected readonly inventoryItemHasError = inventoryItemHasError;

  constructor(
    private dialog: MatDialog,
    private inventoryItemsService: InventoryItemsService,
    private configurationsService: ConfigurationsService,
    private router: Router,
    private readonly modalCartService: ModalCartService,
    private readonly snackbarService: SnackBarService,
    private readonly filterService: FilterService,
  ) {}

  ngOnInit() {
    this.labels = this.inventoryItem.labels.map((x) => ({ title: x })) || [];

    this.filterService.inventoryItemFilter
      .pipe(untilDestroyed(this))
      .subscribe(({ searchTerm }) => {
        this.isOpen = this.isOpen || !!searchTerm;
        this.searchTerm = searchTerm;
      });
  }

  get isOrderable(): boolean {
    return (
      this.inventoryItem.variants?.length > 0 &&
      this.inventoryItem.errors?.length === 0
    );
  }

  get canBeEdited(): boolean {
    return !this.inventoryItemHasError(
      this.inventoryItem,
      'CONSTRUCTION_ARCHIVED',
    );
  }

  get canAddPrintData(): boolean {
    let result = true;

    [
      'FIXED_MATERIAL_HAS_CHANGED',
      'MATERIAL_NOT_ACTIVE',
      'CONSTRUCTION_ARCHIVED',
    ].forEach((error: string) => {
      if (
        this.inventoryItemHasError(this.inventoryItem, error as InventoryError)
      ) {
        result = false;
      }
    });
    return result;
  }

  get orderButtonTooltip(): string {
    if (this.inventoryItem.errors?.length > 0) {
      switch (this.inventoryItem.errors[0]) {
        case 'FIXED_MATERIAL_HAS_CHANGED':
          return 'INVENTORY.ADJUST_MATERIAL_TO_ORDER';
        case 'MATERIAL_NOT_ACTIVE':
          return 'INVENTORY.ADJUST_MATERIAL_TO_ORDER';
        case 'CONSTRUCTION_ARCHIVED':
          return 'INVENTORY.ADJUST_CONSTRUCTION_TO_ORDER';
        case 'NO_ORDERABLE_VARIANTS':
          return 'INVENTORY.ADJUST_PRINT_DATA_TO_ORDER';
      }
    }

    return '';
  }

  get addPrintDataTooltip(): string {
    if (this.inventoryItem.errors?.length > 0) {
      switch (this.inventoryItem.errors[0]) {
        case 'FIXED_MATERIAL_HAS_CHANGED':
          return 'INVENTORY.ADJUST_MATERIAL_TO_ORDER';
        case 'MATERIAL_NOT_ACTIVE':
          return 'INVENTORY.ADJUST_MATERIAL_TO_ORDER';
        case 'CONSTRUCTION_ARCHIVED':
          return 'INVENTORY.ADJUST_CONSTRUCTION_TO_ORDER';
      }
    }

    return '';
  }

  get editProductTooltip(): string {
    if (!this.canBeEdited) {
      return 'INVENTORY.ADJUST_CONSTRUCTION_TO_ORDER';
    }

    return '';
  }

  public addToCart(): void {
    this.modalCartService.addVariantToCartAndOpenCartModal(this.inventoryItem);
  }

  public detailsOpened(): void {
    this.isOpen = true;
  }

  public detailsClosed(): void {
    this.isOpen = false;
  }

  public editProduct() {
    this.configurationsService
      .createByInventory(this.inventoryItem.id)
      .subscribe(async (configuration) => {
        await this.router.navigateByUrl(
          `${ROUTES.CONFIGURATOR}/${configuration.id}`,
        );
      });
  }

  public duplicateProduct(): void {
    this.handleInventoryItem(false, true);
  }

  public renameProduct(): void {
    this.handleInventoryItem(true, false);
  }

  public addPrintData(item: InventoryItem) {
    this.router
      .navigateByUrl(
        `${ROUTES.INVENTORY}/${item.id}/${INVENTORY_ROUTES.PRINT_DATA_MANAGER}`,
      )
      .then();
  }

  public deleteInventoryItem(): void {
    this.dialog
      .open(InventoryDialogDeleteComponent, {
        id: 'DeleteInventoryItem',
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((dialogResult) => {
        if (dialogResult && this.inventoryItem) {
          this.inventoryItemsService
            .delete(this.inventoryItem.id)
            .pipe(untilDestroyed(this))
            .subscribe({
              next: () => {
                this.snackbarService.showSimpleSuccess(
                  'INVENTORY.LIST.ITEM.PRODUCT_DELETED',
                );

                this.inventoryItemsService.forceUpdate();
              },
              error: (error) => {
                // TODO: FE notification for error on delete
                console.warn(
                  'could not delete item: ' +
                    (error && error.message ? error.message : 'unknown error'),
                );
              },
            });
        }
      });
  }

  private handleInventoryItem(rename: boolean, duplicate: boolean) {
    if (this.inventoryItem) {
      this.dialog
        .open(ConfiguratorDialogSaveComponent, {
          id: 'EditInventoryItem',
          data: {
            configuration: {
              inventoryItem: this.inventoryItem,
            },
            rename,
            duplicate,
          },
        })
        .afterClosed()
        .pipe(untilDestroyed(this))
        .subscribe((inventoryItemEdited: InventoryItem) => {
          if (inventoryItemEdited) {
            this.inventoryItemsService.forceUpdate();
          }
        });
    }
  }

  public editLabels(): void {
    this.dialog
      .open(CreateLabelModalComponent, {
        id: 'CreateLabelsInventoryItem',
        data: {
          inventoryItem: this.inventoryItem,
        },
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((stringArray: string[] | boolean) => {
        if (stringArray) {
          this.inventoryItemsService
            .updateLabels(this.inventoryItem?.id, stringArray as string[])
            .subscribe(() => {
              this.inventoryItemsService.forceUpdate();
              this.inventoryItemsService.loadLabels();
            });
        }
      });
  }

  public select(checked: boolean) {
    this.selectionChanged.emit(checked);
  }
}
