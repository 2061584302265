import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { VariantsService } from '@app/core/services/variants.service';
import { inventoryItemHasProductOption } from '@app/utils/inventory-items.helper';
import { INVENTORY_ROUTES } from '@modules/inventory/inventory-routing.module';
import { VariantApproveService } from '@modules/inventory/variant-approve.service';
import { translate } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { InventoryItem } from '@shared/models/inventory-item';
import { BRAILLE, FSC } from '@shared/models/product-option';
import { Variant } from '@shared/models/variant';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';
import {
  BehaviorSubject,
  catchError,
  combineLatestWith,
  filter,
  map,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';

export type PrintDataUploadState = 'upload' | 'uploading' | 'showResult';

export type FileError = {
  title: string;
  message: string;
};

@UntilDestroy()
@Component({
  selector: 'packex-print-data-upload',
  templateUrl: './print-data-upload.component.html',
  styleUrls: ['./print-data-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintDataUploadComponent implements OnChanges, OnInit {
  @ViewChild('fileUploader') fileUploaderRef!: ElementRef<HTMLInputElement>;
  @ViewChild('stepper') stepper!: MatStepper;

  @Input() inventoryItem?: InventoryItem;
  @Input() variant?: Variant | null;
  @Input() sizeMinInMb = 0;
  @Input() sizeMaxInMb = 70;

  @Input() acceptedFileTypes = 'pdf';
  @Input() acceptedFileExtensions: string[] = ['pdf'];
  @Input() description = '';
  @Input() disabled = false;

  @Output() fileChosen = new EventEmitter<File>();

  filename?: string;

  error: FileError | null = null;
  state$ = new BehaviorSubject<PrintDataUploadState>('showResult');

  /**
   * dragging in progress
   */
  isDragOver = false;

  public loading = false;

  public currentVariant$ = new BehaviorSubject<Variant | null>(null);
  public newVariant = false;
  public fscAccepted = false;

  constructor(
    private readonly variantsService: VariantsService,
    private readonly variantApproveService: VariantApproveService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly snackbarService: SnackBarService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private readonly gtm: GoogleTagService,
  ) {}

  ngOnInit() {
    this.fscAccepted = false;

    this.route.params.pipe(untilDestroyed(this)).subscribe(({ variantId }) => {
      this.newVariant = !(variantId && variantId !== INVENTORY_ROUTES.NEW);

      if (variantId && variantId === INVENTORY_ROUTES.NEW) {
        this.newVariant = true;
        this.currentVariant$.next(null);
        this.state$.next('upload');
      } else {
        if (!this.inventoryItem?.variants.length) {
          this.state$.next('upload');
        }
      }
    });
  }

  ngOnChanges() {
    if (this.variant) {
      this.state$.next('upload');
    }
  }

  get showFSCAcceptance(): boolean {
    if (!this.inventoryItem) return false;

    return (
      inventoryItemHasProductOption(this.inventoryItem, FSC) &&
      !this.fscAccepted
    );
  }

  get hasBraille(): boolean {
    if (!this.inventoryItem) return false;

    return inventoryItemHasProductOption(this.inventoryItem, BRAILLE);
  }

  get hasFSC(): boolean {
    if (!this.inventoryItem) return false;

    return inventoryItemHasProductOption(this.inventoryItem, FSC);
  }

  get blankoButtonTooltip(): string {
    const state: PrintDataUploadState = this.state$.getValue();

    switch (state) {
      case 'upload':
        if (this.hasBraille && this.hasFSC) {
          return 'INVENTORY.PRINT_DATA_UPLOAD.BLANKO_BRAILLE_FSC';
        } else if (this.hasBraille) {
          return 'INVENTORY.PRINT_DATA_UPLOAD.BLANKO_BRAILLE';
        } else if (this.hasFSC) {
          return 'INVENTORY.PRINT_DATA_UPLOAD.BLANKO_FSC';
        }
        return 'INVENTORY.PRINT_DATA_UPLOAD.BLANKO_TOOLTIP_UPLOAD';
      case 'uploading':
        return 'INVENTORY.PRINT_DATA_UPLOAD.BLANKO_TOOLTIP_UPLOADING';
      default:
        return '';
    }
  }

  get blankoTooltipDisabled(): boolean {
    if (this.newVariant && this.state$.getValue() === 'uploading') {
      return false;
    } else if (!this.hasBraille && !this.hasFSC && this.newVariant) {
      return true;
    } else if (this.blankoButtonDisabled) {
      return false;
    }

    return false;
  }

  get blankoButtonDisabled(): boolean {
    if (this.state$.getValue() === 'uploading') {
      return true;
    }

    return this.hasBraille || this.hasFSC || !this.newVariant;
  }

  public showUploadProcess(): Observable<boolean> {
    return this.currentVariant$.pipe(
      combineLatestWith(
        this.state$.pipe(
          filter((state) => state === 'uploading'),
          map(() => true),
        ),
      ),
      map(([variant, stateIsUploading]) => {
        return !!variant && stateIsUploading;
      }),
      tap((showUploadProcess) => (this.loading = !showUploadProcess)),
    );
  }

  /**
   * set the file via file selection
   * @param event
   */
  onFileSelected(event: Event): void {
    if (!this.disabled && event.target) {
      const files = (event.target as HTMLInputElement).files || [];
      this.setFile(files[0]);
    }
  }

  /**
   * Setzt den Dateinamen und übermittelt die Datei an die Parent-Komponente
   * @param file
   */
  private setFile(file: File): void {
    if (file) {
      this.checkFile(file)
        .pipe(
          catchError((error: FileError) => {
            this.filename = '';
            this.error = error;
            return of(error);
          }),
        )
        .subscribe(async (response: File | FileError) => {
          if (response instanceof File) {
            this.error = null;
            this.filename = file.name;
            this.loading = true;
            await this.uploadFile(file);
          }
        });
    }
  }

  private checkFile(file: File): Observable<File | FileError> {
    let error = null;
    if (this.sizeMinInMb && file.size < this.sizeMinInMb * 1024 * 1024) {
      error = {
        title: 'FILE_UPLOAD.FILE_TO_SMALL',
        message: '',
      };
    } else if (this.sizeMaxInMb && file.size > this.sizeMaxInMb * 1024 * 1024) {
      error = {
        title: 'FILE_UPLOAD.FILE_TO_BIG',
        message: 'FILE_UPLOAD.FILE_TOO_BIG_MESSAGE',
      };
    }

    if (!this.checkFileExtension(file.name)) {
      error = {
        title: 'FILE_UPLOAD.WRONG_FORMAT',
        message: 'FILE_UPLOAD.UPLOAD_CORRECT_FORMAT',
      };
    }

    if (error) {
      return throwError(error);
    }

    return of(file);
  }

  private checkFileExtension(fileName: string): boolean {
    if (this.acceptedFileExtensions) {
      return this.acceptedFileExtensions.some((suffix) => {
        return fileName.toLowerCase().endsWith(`.${suffix}`);
      });
    }
    return true;
  }

  /**
   * prevent default browser behaviour on dragging a file
   * @param event
   */
  onDragEnter(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  /**
   * prevent default browser behaviour on dragover a file
   * @param event
   */
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent): void {
    this.isDragOver = false;
    event.preventDefault();
    event.stopPropagation();

    if (!this.canDropFile) {
      return;
    }
    const file = event.dataTransfer?.files[0];
    if (file) this.setFile(file);
  }

  public chooseFile() {
    this.fileUploaderRef.nativeElement.click();
  }

  public orderBlanko(): void {
    const formData = new FormData();
    formData.set('name', translate('VARIANT.BLANK'));
    formData.set('blankArtwork', 'true');

    this.updateOrCreate(formData, async () => {
      await this.showResult();
    });
  }

  private async uploadFile(file: File) {
    this.state$.next('uploading');

    const formData = new FormData();
    formData.set('name', this.filename || 'filename.pdf');
    formData.append('artwork', file);
    formData.set('blankArtwork', 'false');

    this.updateOrCreate(formData);
  }

  private updateOrCreate(formData: FormData, cb?: () => void): void {
    if (this.variant) {
      this.updateVariant(this.variant, formData);
    } else {
      this.createVariant(formData, cb);
    }
  }

  private createVariant(formData: FormData, cb?: () => void): void {
    formData.set('inventoryItemId', this.inventoryItem?.id || '');

    this.variantsService.createVariant(formData).subscribe({
      next: (response: Variant | HttpErrorResponse) => {
        if (!(response instanceof HttpErrorResponse)) {
          this.gtm.push('add_variant');
          this.currentVariant$.next(response);
        }

        if (cb) {
          cb();
        }
      },
      error: () => {
        this.showError();
      },
    });
  }

  public updateVariant(variant: Variant, formData: FormData): void {
    formData.set('name', variant.name);

    this.variantsService
      .updateVariantArtwork(variant, formData)
      .pipe(switchMap(() => this.variantsService.getVariant(variant.id)))
      .subscribe({
        next: (variant: Variant) => {
          this.currentVariant$.next(variant);
        },
        error: () => {
          this.showError();
        },
      });
  }

  public addVariant() {
    this.router.navigate(['new'], {
      relativeTo: this.route.parent,
    });
  }

  public showResult() {
    if (!this.currentVariant$.getValue()) return;

    this.variantApproveService
      .openPreflightModal(this.currentVariant$.getValue() as Variant)
      .subscribe((shownModal) => {
        if (shownModal) {
          this.snackbarService.showSimpleSuccess(
            'INVENTORY.PRINT_DATA_UPLOAD.UPLOAD_SUCCESS',
          );
        }

        this.zone
          .run(async () => {
            await this.showResultCallback();
          })
          .then();
      });
  }

  public async showResultCallback() {
    this.currentVariant$.next(null);
    this.state$.next('showResult');

    this.cdr.markForCheck();
    this.cdr.detectChanges();

    if (this.variant || this.newVariant) {
      await this.router.navigate(
        [`../${INVENTORY_ROUTES.PRINT_DATA_MANAGER}`],
        {
          relativeTo: this.route.parent,
        },
      );
    }
  }

  private showDelay() {
    this.snackbarService.showSimpleError(
      'INVENTORY.PRINT_DATA_UPLOAD.ERROR_DELAY',
    );
    this.showResult();
  }

  private showError() {
    this.snackbarService.showSimpleError(
      'INVENTORY.PRINT_DATA_UPLOAD.ERROR_UNKNOWN',
    );
    this.showResult();
  }

  public handlePreflightDelay() {}

  public uploadErrorOccurred(): void {
    this.snackbarService.showSimpleError(
      'INVENTORY.PRINT_DATA_UPLOAD.FATAL_ERROR',
    );
    this.showResult();
  }

  public get canDropFile(): boolean {
    return this.state$.getValue() === 'upload';
  }
}
