<form [formGroup]="form">
  <mat-form-field appearance="outline" class="w-100">
    <input matInput placeholder="{{'USER.MODEL.PASSWORD' | transloco}}*" formControlName="password"
           [type]="hidePassword ? 'password' : 'text'"
           name="password" required>
    <packex-icon class="hovered-pointer me-2" matSuffix
                 color="grey"
                 (click)="hidePassword = !hidePassword" [icon]="hidePassword ? 'hide' : 'show'"></packex-icon>
    <mat-error
      *ngIf="form.controls['password'].invalid">{{'USER.CHANGE_PASSWORD.PASSWORD_COMPLEXITY' | transloco}}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-100">
    <input matInput placeholder="{{'USER.CHANGE_PASSWORD.REPEAT_PASSWORD' | transloco}}*"
           [type]="hideRepeatPassword ? 'password' : 'text'"
           formControlName="repeatPassword"
           name="repeatPassword" required>
    <packex-icon class="hovered-pointer me-2" matSuffix
                 color="grey"
                 (click)="hideRepeatPassword = !hideRepeatPassword" [icon]="hideRepeatPassword ? 'hide' : 'show'"></packex-icon>
    <mat-error
      *ngIf="form.controls['repeatPassword'].invalid">{{'USER.CHANGE_PASSWORD.PASSWORD_COMPLEXITY' | transloco}}</mat-error>
  </mat-form-field>
  <div class="text-error"
       *ngIf="form.valid && passwordsNotEqual">{{'USER.CHANGE_PASSWORD.PASSWORD_NOT_SAME' | transloco}}</div>
</form>
