<packex-modal (onDismiss)="dismiss()" size="md">
  <h5 class="modal-title">{{ 'LANGUAGE_SELECT_MODAL.TITLE' | transloco }}</h5>
  <div class="modal-body">
    <mat-radio-group [(ngModel)]="currentLanguage">
      <mat-radio-button [value]="languageOption.value" class="me-3 mb-3"
                        *ngFor="let languageOption of languageService.availableLanguages" color="primary">
        <span class="subtitle-2">{{ languageOption.title | transloco }}</span>

      </mat-radio-button>
    </mat-radio-group>
  </div>

  <packex-button (buttonClick)="save()" class="w-auto">{{ 'COMMON.SAVE' | transloco }}</packex-button>
</packex-modal>
