import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@app/core/services/user.service';
import { getFlag } from '@app/utils/flag';
import { fieldHasError } from '@app/utils/formHelper';
import { validatePhoneNumber } from '@app/utils/validators';
import { CartService } from '@modules/cart/cart.service';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { CountrySelectOption } from '@shared/components/packex-select/countries-select.component';
import { Address, CreateAddress } from '@shared/models/address';
import { AddressService } from '@shared/services/address.service';
import { AppConfigService } from '@shared/services/app-config.service';

@Component({
  selector: 'packex-create-delivery-address-modal',
  templateUrl: './create-delivery-address-modal.component.html',
  styleUrls: ['./create-delivery-address-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateDeliveryAddressModalComponent extends ModalComponent {
  form!: FormGroup;

  currentCountry: CountrySelectOption | null = null;
  countries: CountrySelectOption[] = [];

  constructor(
    dialogRef: MatDialogRef<CreateDeliveryAddressModalComponent>,
    private readonly addressService: AddressService,
    private readonly cartService: CartService,
    private readonly appConfigService: AppConfigService,
    private readonly userService: UserService,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      address?: Address;
    },
  ) {
    super(dialogRef);

    this.appConfigService.appConfig$.subscribe((appConfig) => {
      this.countries =
        appConfig?.countries.map((country) => ({
          ...country,
          svg: getFlag(country.isoCode),
        })) || [];

      if (this.dialogData?.address) {
        const currentCountry = this.countries.find(
          (x) => x.id === this.dialogData.address?.country.id,
        );

        this.currentCountry = currentCountry || null;
      }
    });

    this.createForm();

    if (!this.dialogData?.address) {
      this.userService.user$.subscribe((user) => {
        this.form.controls['company'].patchValue(user?.organization.name || '');
      });
    }
  }

  public createForm() {
    this.form = new FormGroup({
      company: new FormControl(
        this.dialogData?.address?.company || '',
        Validators.required,
      ),
      recipient: new FormControl(
        this.dialogData?.address?.recipient || '',
        Validators.required,
      ),
      street: new FormControl(
        this.dialogData?.address?.street || '',
        Validators.required,
      ),
      additionalAddress: new FormControl(
        this.dialogData?.address?.additionalAddress || '',
      ),
      postalCode: new FormControl(
        this.dialogData?.address?.postalCode || '',
        Validators.required,
      ),
      city: new FormControl(
        this.dialogData?.address?.city || '',
        Validators.required,
      ),
      countryId: new FormControl<CountrySelectOption | null>(
        this.dialogData?.address?.country || null,
        Validators.required,
      ),
      phone: new FormControl(this.dialogData?.address?.phone || '', [
        Validators.required,
        validatePhoneNumber,
      ]),
      vatId: new FormControl(this.dialogData?.address?.vatId || ''),
    });
  }

  public save(): void {
    let observable;
    if (!this.form.valid) return;

    const address: CreateAddress = {
      ...this.form.value,
      countryId: this.form.value.countryId?.id,
    } as unknown as CreateAddress;

    if (this.dialogData?.address) {
      observable = this.addressService.updateAddress(
        this.dialogData.address.id,
        address,
      );
    } else {
      observable = this.addressService.createAddress(address);
    }

    observable.subscribe(async (createdAddress: Address) => {
      await this.cartService.loadCart();
      this.dialogRef.close(createdAddress);
    });
  }

  public countryChanged(countrySelectOption: CountrySelectOption | null): void {
    this.form.controls['countryId'].patchValue(countrySelectOption);
  }

  get phoneFormField() {
    return this.form.get('phone');
  }

  public fieldHasError(formField: AbstractControl | null): boolean {
    return fieldHasError(formField);
  }
}
