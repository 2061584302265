<packex-page class="user-card-page">
  <packex-card class="h-auto mx-auto">
    <h2 class="text-uppercase">{{ 'USER.RESET_PASSWORD.TITLE' | transloco}}</h2>
    <packex-divider></packex-divider>

    <p class="mb-4">{{ 'USER.RESET_PASSWORD.CONTENT' | transloco }}</p>

    <form [formGroup]="form">
      <mat-form-field appearance="outline" class="w-100 mb-3">
        <input matInput [placeholder]="'USER.MODEL.EMAIL' | transloco" formControlName="email"
               name="email" required>
        <mat-error
          *ngIf="form.controls['email'].invalid">{{'FORM.ERROR.EMAIL' | transloco}}</mat-error>
      </mat-form-field>
    </form>

    <packex-button type="tertiary" (buttonClick)="backToLogin()" class="w-auto mb-3">{{ 'USER.RESET_PASSWORD.BACK_TO_LOGIN' | transloco }}</packex-button>

    <packex-button (buttonClick)="submit()"
                   [disabled]="form.invalid">{{ 'USER.RESET_PASSWORD.TITLE' | transloco }}</packex-button>
  </packex-card>

</packex-page>
