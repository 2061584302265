import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigurationsService } from '@app/core/services/configurations.service';
import { InventoryItemsService } from '@app/core/services/inventory-items.service';
import { CustomErrorStateMatcher } from '@app/utils/customErrorStateMatcher';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { Configuration } from '@shared/models/configuration';
import { LABEL } from '@shared/models/construction';
import { InventoryItem } from '@shared/models/inventory-item';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';

@UntilDestroy()
@Component({
  selector: 'packex-configurator-dialog-save',
  templateUrl: './configurator-dialog-save.component.html',
  styleUrls: ['./configurator-dialog-save.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ConfiguratorDialogSaveComponent
  extends ModalComponent
  implements OnInit
{
  rename = false;
  duplicate = false;
  configuration?: Configuration;

  saveForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });

  // NOTE: if name existing occurs, we keep already known invalid names in mind within the dialog for form valid state
  existingNames: string[] = [];
  private labels: string[] = [];
  isLoading = false;

  esMatcher = new CustomErrorStateMatcher();

  constructor(
    dialogRef: MatDialogRef<ConfiguratorDialogSaveComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      configuration?: Configuration;
      rename?: boolean;
      duplicate?: boolean;
      formatOrServiceChanged?: boolean;
    },
    private inventoryItemsService: InventoryItemsService,
    private configurationsService: ConfigurationsService,
    private readonly gtm: GoogleTagService,
  ) {
    super(dialogRef);
  }

  get inventoryItem(): InventoryItem | undefined {
    return this.dialogData.configuration?.inventoryItem;
  }

  public ngOnInit() {
    this.rename = this.dialogData.rename || false;
    this.duplicate = this.dialogData.duplicate || false;
    this.configuration = this.dialogData.configuration || undefined;

    this.labels = this.inventoryItem?.labels || [];

    if (this.inventoryItem?.name) {
      this.saveForm.get('name')?.patchValue(this.inventoryItem.name);

      this.existingNames = [this.inventoryItem?.name];

      this.saveForm.get('name')?.markAsDirty();
      this.saveForm.get('name')?.setErrors({ notUnique: true });
    } else {
      this.saveForm
        .get('name')
        ?.patchValue(
          this.configuration?.configurationTemplate?.name ||
            this.configuration?.construction.name ||
            '',
        );
    }
  }

  get submitButtonDisabled(): boolean {
    return this.saveForm.invalid || this.isLoading;
  }

  get getFormatOrServiceChangedDescription() {
    return this.configuration?.construction.category === LABEL
      ? 'CONFIGURATION.SAVE_DIALOG.FORMAT_LABEL_CHANGED'
      : 'CONFIGURATION.SAVE_DIALOG.FORMAT_SERVICE_CHANGED';
  }

  get description(): string {
    return this.rename
      ? 'INVENTORY.RENAME.DESCRIPTION'
      : 'CONSTRUCTION.NAME_HINT';
  }

  get nameLength(): number {
    return this.saveForm.get('name')?.value?.length || 0;
  }

  public submit(): void {
    const formData = this.saveForm.value;

    if (this.isLoading) {
      return;
    }

    if (formData && this.saveForm && this.saveForm.valid) {
      this.isLoading = true;
      if (!this.dialogData.formatOrServiceChanged && this.inventoryItem) {
        if (this.rename) {
          this.renameInventoryItem(this.inventoryItem, formData.name as string);
        } else {
          this.duplicateInventoryItem(
            this.inventoryItem,
            formData.name as string,
          );
        }
      } else if (this.configuration) {
        this.saveInventoryItem(this.configuration, formData.name as string);
      }
    }
  }

  private renameInventoryItem(
    inventoryItem: InventoryItem,
    name: string,
  ): void {
    this.inventoryItemsService
      .update(inventoryItem.id, { name, labels: this.labels })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.onConfirm();
        },
        error: (error: any) => {
          this.configurationSaveError(error, name);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  private duplicateInventoryItem(
    inventoryItem: InventoryItem,
    name: string,
  ): void {
    this.configurationsService
      .createByInventory(inventoryItem.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (createdConfiguration: Configuration) => {
          this.saveInventoryItem(createdConfiguration, name);
        },
        error: () => {
          this.dismiss();
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  private saveInventoryItem(configuration: Configuration, name: string): void {
    this.gtm
      .push('save_product', {
        construction_catalog: configuration.construction?.catalog,
        construction_code: configuration.construction?.catalogCode,
        construction_name: configuration.construction?.name,
      })
      .then(() => {});

    this.inventoryItemsService
      .create(configuration.id, name, this.labels)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.dialogRef.close({ inventoryItem: result });
        },
        error: (error: any) => {
          this.isLoading = false;
          this.configurationSaveError(error, name);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  private configurationSaveError(error: any, name: string) {
    if (error && error.statusCode === 409) {
      this.existingNames.push(name);
      this.nameChanged();
    } else {
      this.dismiss();
    }
  }

  public nameChanged(): void {
    const currentName = this.saveForm.get('name')?.getRawValue();

    const currentNameExists = this.existingNames.find(
      (name) => name === currentName,
    );

    if (currentNameExists) {
      this.saveForm.get('name')?.setErrors({ notUnique: true });
    }
  }

  public labelsChanged($event: any): void {
    this.labels = $event;
  }
}
