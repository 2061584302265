import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FilterService } from '@modules/filter/services/filter.service';
import { ConstructionType } from '@shared/models/construction';

export interface CarouselSlide {
  asset: string;
  bg?: string;
  title: string;
  content: string;
  buttonText: string;
  callback: () => void;
}

@Component({
  selector: 'packex-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CarouselComponent {
  @Input() size: 'xl' | 'l' | 's' = 'l';
  @Input() borderRadius = true;
  @Input() slides: CarouselSlide[] = [
    {
      asset: 'assets/images/marketing/marketing-etiketten.png',
      bg: '#AEBEBC',
      title: 'MARKETING.LABEL.TITLE',
      content: 'MARKETING.LABEL.CONTENT',
      buttonText: 'MARKETING.LABEL.BUTTON',
      callback: async () => {
        await this.filterService.addMarketingFilter(ConstructionType.Label);
      },
    },
    {
      asset: 'assets/images/marketing/marketing-shipping-box.png',
      bg: '#dceef7',
      title: 'MARKETING.SHIPPING_BOX.TITLE',
      content: 'MARKETING.SHIPPING_BOX.CONTENT',
      buttonText: 'MARKETING.SHIPPING_BOX.BUTTON',
      callback: async () => {
        await this.filterService.addMarketingFilter(
          ConstructionType.ShippingBox,
        );
      },
    },
  ];

  constructor(private readonly filterService: FilterService) {}
}
