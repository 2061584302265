import { Component, Input } from '@angular/core';
import {
  variantIsOrderable,
  getTooltipForNotOrderableVariant,
} from '@app/utils/variant-helper';
import { VariantApproveService } from '@modules/inventory/variant-approve.service';
import { ItemsDataSource } from '@shared/models/print-data-manager-item-source';

@Component({
  selector: 'packex-print-data-item-status',
  templateUrl: './print-data-item-status.component.html',
  styleUrls: ['./print-data-item-status.component.scss'],
})
export class PrintDataItemStatusComponent {
  @Input() element!: ItemsDataSource;

  protected readonly variantIsOrderable = variantIsOrderable;
  protected readonly getTooltipForNotOrderableVariant =
    getTooltipForNotOrderableVariant;

  constructor(private readonly variantApproveService: VariantApproveService) {}

  public openPreflightModal(): void {
    this.variantApproveService.openPreflightModal(this.element).subscribe();
  }

  public openInProgressDialog(): void {
    this.variantApproveService.openInProgressDialog();
  }
}
