<packex-page>
  <div class="row m-0 mt-4 mt-md-5">
    <div class="col-md-4 offset-md-4">
      <packex-card [shadowed]="true">
        <h1 class="color-primary">{{'USER.REGISTRATION.TITLE' | transloco}}</h1>
        <packex-divider></packex-divider>
        <p [innerHTML]="'USER.REGISTRATION.ACCOUNT_EXISTS' | transloco: {uriLogin:'user/registration/login'}" class="mb-2"></p>
        <packex-registration-complete-form></packex-registration-complete-form>
      </packex-card>
    </div>
  </div>
</packex-page>
