import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { ROUTES } from '@app/routes';
import { cartEmptyScreenConfig } from '@modules/cart/cart-empty-screen';
import { CartItemsComponent } from '@modules/cart/cart-items/cart-items.component';
import { CART_STEPS } from '@modules/cart/cart-routing.module';
import { CartService } from '@modules/cart/cart.service';
import { EmptyScreenConfig } from '@shared/components/empty-screen/empty-screen.component';
import { DrawerService } from '@shared/services/drawer.service';

@Component({
  selector: 'packex-cart-flyout',
  templateUrl: './cart-flyout.component.html',
  styleUrls: ['./cart-flyout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartFlyoutComponent
  extends CartItemsComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('flyout') drawer?: MatSidenav;
  clickedInside = false;
  emptyScreenConfig: EmptyScreenConfig = cartEmptyScreenConfig;

  constructor(
    cartService: CartService,
    public readonly drawerService: DrawerService,
    private readonly router: Router,
  ) {
    super(cartService);
  }

  ngAfterViewInit() {
    if (this.drawer) {
      this.drawerService.setDrawer(this.drawer);
    }
  }

  async goToCartPage() {
    await this.router.navigateByUrl(`/${ROUTES.CART}/${CART_STEPS['CART']}`);
    await this.closeDrawer();
  }

  public onKeyDown($event: KeyboardEvent) {
    if ($event.key === 'Esc') {
      this.closeDrawer();
    }
  }

  private async closeDrawer() {
    await this.drawerService.close();
  }

  @HostListener('click')
  clicked() {
    this.clickedInside = true;
  }

  @HostListener('document:click')
  onClick() {
    this.handleClick();
    this.clickedInside = false;
  }

  private handleClick(): void {
    if (!this.clickedInside && this.drawerService.isDrawerVisible) {
      this.closeDrawer();
    }
  }

  public async goToMyProducts() {
    await this.router.navigateByUrl(`/${ROUTES.INVENTORY}`);
    await this.closeDrawer();
  }

  public deleteAllProducts() {
    this.cartProducts$.subscribe((products) => {
      const cartItems = products.map(({ items }) => items).flat();

      this.cartServiceInstance.deleteItemsFromCart(cartItems);
    });
  }
}
