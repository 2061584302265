<mat-form-field appearance="fill" class="packex-select" *transloco="let t;">
  <mat-label *ngIf="label" class="text-primary-dark">
    {{ label | conditionalPipe:translatesValues:t }}
  </mat-label>
  <mat-select [value]="current" [disabled]="disabled" [compareWith]="compareFn" class="d-flex align-items-center">
    <mat-select-trigger class="d-flex">
      <ng-container *ngIf="current">
        <ng-container *ngIf="current.svg">
          {{current.svg}}
        </ng-container>
        <packex-icon *ngIf="current.icon" [icon]="current.icon" class="me-3"></packex-icon>
        {{ current?.['name'] || (current.title | transloco) }}&nbsp;{{
        current.titleAppendix && current.titleAppendix | conditionalPipe:translatesValues:t
        }}
      </ng-container>
    </mat-select-trigger>
    <mat-form-field appearance="outline" *ngIf="showAutocomplete" class="ms-3 me-3">
      <input matInput
             [formControl]="filterControl"
             type="text"
             placeholder="Search">
    </mat-form-field>
    <mat-option [value]="null" *ngIf="showPlaceholder">{{ label | conditionalPipe:translatesValues:t }}</mat-option>
    <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option"
                [ngClass]="{'hide': !option.visible, 'd-flex flex-row': option.icon}" (click)="onCurrentChange(option)">
      <ng-container *ngIf="option.svg">
        {{option.svg}}
      </ng-container>
      <packex-icon *ngIf="option.icon" [icon]="option.icon" class="me-3"></packex-icon>
      {{ option['name'] || option.title | conditionalPipe:translatesValues:t }}&nbsp;{{
      option.titleAppendix && option.titleAppendix | conditionalPipe:translatesValues:t
      }}
    </mat-option>
  </mat-select>
</mat-form-field>
