<packex-modal (onDismiss)="dismiss()">
  <h5
    class="modal-title">{{ 'INVENTORY.BATCH_DELETE_DIALOG.TITLE' | transloco : {amount: this.dialogData.inventoryItems.length} }}</h5>

  <div class="modal-body">
    <p>{{ 'INVENTORY.BATCH_DELETE_DIALOG.CONTENT' | transloco : {amount: this.dialogData.inventoryItems.length} }}</p>

    <ul class="my-6">
      <li *ngFor="let inventoryItem of dialogData.inventoryItems" class="subtitle-2">
        {{ inventoryItem.name }}
      </li>
    </ul>

    <p class="mb-1">{{ 'INVENTORY.DELETE_DIALOG.HINT_BOTTOM' | transloco }}</p>
  </div>

  <packex-button (buttonClick)="deleteInventoryItems()" class="w-auto">{{ 'COMMON.DELETE' | transloco }}</packex-button>
  <packex-button type="secondary" (buttonClick)="dismiss()" class="w-auto">{{ 'COMMON.CANCEL' | transloco }}</packex-button>
</packex-modal>
