import { Component, ViewEncapsulation } from '@angular/core';
import { LanguageService } from '@app/core/services/language.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'packex-complete-user',
  templateUrl: './complete-user.component.html',
  styleUrls: ['./complete-user.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompleteUserComponent {
  constructor(
    private readonly keycloakService: KeycloakService,
    private readonly languageService: LanguageService,
  ) {
    keycloakService.isLoggedIn().then(async (loggedIn) => {
      if (!loggedIn) {
        await this.keycloakService.login({
          locale: this.languageService.currentLanguage(),
        });
      }
    });
  }
}
