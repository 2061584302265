import { Component, ViewEncapsulation } from '@angular/core';
import { HeaderComponent } from '@app/core/components/header/header.component';
import { RequestOfferModalComponent } from '@modules/constructions/components/request-offer-modal/request-offer-modal.component';
import { CONSTRUCTION } from '@modules/constructions/pages/constructions-page/constructions-page.component';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'packex-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MobileMenuComponent extends HeaderComponent {
  public openMusterboxLink(): void {
    window.open(translate('EXIT_INTENT.SAMPLE_BOX_LINK'), '_blank');
  }

  public openRequestConstructionModal(): void {
    this.dialog.open(RequestOfferModalComponent, {
      data: {
        type: CONSTRUCTION,
      },
    });
  }
}
