import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LanguageService } from '@app/core/services/language.service';
import { UserService } from '@app/core/services/user.service';
import { validateEmail, validatePassword } from '@app/utils/validators';
import { environment } from '@env';
import { User } from '@shared/models/user';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';

@Component({
  selector: 'packex-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegistrationFormComponent implements OnInit {
  hidePass = true;
  registrationForm: FormGroup;
  environment: any;
  created: boolean | undefined;
  userRegistration: User | undefined;

  @Input() loading = false;
  @Input() email?: string;
  @Output() onSuccess: EventEmitter<User & { password: string }> =
    new EventEmitter();

  constructor(
    private userService: UserService,
    private readonly gtm: GoogleTagService,
    private languageService: LanguageService,
  ) {
    this.environment = environment;
    this.registrationForm = new FormGroup({
      email: new FormControl(
        '',
        Validators.compose([Validators.required, validateEmail]),
      ),
      password: new FormControl(
        '',
        Validators.compose([Validators.required, validatePassword]),
      ),
      marketingAcceptance: new FormControl(true),
    });
  }

  ngOnInit() {
    if (this.email) {
      this.registrationForm.get('email')?.patchValue(this.email);
    }
  }

  public submit(): void {
    const formData = this.registrationForm ? this.registrationForm.value : null;
    if (formData && this.registrationForm.valid) {
      this.userRegistration = <User>formData;
      this.registrationSubmit();
    }
  }

  private registrationSubmit(): void {
    this.loading = true;

    this.userService
      .createRegistrationProcess({
        ...this.userRegistration,
        preferredLanguage: this.languageService.getSessionLanguage(),
      } as User)
      .subscribe({
        next: () => {
          this.gtm.push('sign_up');
          this.onSuccess.emit({
            ...this.userRegistration,
            password: this.registrationForm.get('password')?.value || '',
          } as unknown as User & { password: string });
        },
        error: (e) => {
          if (e.statusCode === 409) {
            this.registrationForm.get('email')?.setErrors({ exists: true });
          }
          this.loading = false;
        },
      });
  }
}
