<div [class]="textStyle" *ngIf="!itemView">
  <div class="d-flex flex-column mb-2">
    <p class="text-primary-medium">{{ 'CART.REFERENCE_INFORMATIONS.MODAL.REFERENCE_ORDER_NR_SHORT' | transloco }}:</p>
    <p class="text-break">{{ item.customerOrderReference || '-' }}</p>
  </div>
  <div class="d-flex flex-column mb-2">
    <p class="text-primary-medium">{{ 'CART.REFERENCE_INFORMATIONS.MODAL.REFERENCE_MATERIAL_NR_SHORT' | transloco }}:</p>
    <p class="text-break">{{ item.customerArticleNumber || '-' }}</p>
  </div>
  <div class="d-flex flex-column mb-3">
    <p class="text-primary-medium">{{ 'CART.REFERENCE_INFORMATIONS.MODAL.REFERENCE_MATERIAL_ART_SHORT' | transloco }}:</p>
    <p class="text-break">{{ item.customerArticleDescription || '-' }}</p>
  </div>
</div>

<div [class]="textStyle" *ngIf="itemView">
  <div class="d-flex flex-column">
    <span class="caption text-primary-medium">{{ 'CART.REFERENCE_INFORMATIONS.MODAL.REFERENCE_ORDER_NR_SHORT' | transloco }}:</span>
    <span class="caption text-break">{{ item.customerOrderReference || '-' }}</span>
  </div>
  <div class="d-flex flex-column">
    <span class="caption text-primary-medium">{{ 'CART.REFERENCE_INFORMATIONS.MODAL.REFERENCE_MATERIAL_NR_SHORT' | transloco }}:</span>
    <span class="caption text-break">{{ item.customerArticleNumber || '-' }}</span>
  </div>
  <div class="d-flex flex-column">
    <span class="caption text-primary-medium">{{ 'CART.REFERENCE_INFORMATIONS.MODAL.REFERENCE_MATERIAL_ART_SHORT' | transloco }}:</span>
    <span class="caption text-break">{{ item.customerArticleDescription || '-' }}</span>
  </div>
</div>
