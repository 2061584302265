import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { additionalProductOptionsCheckingDays } from '@app/utils/productOption.helper';
import { getVariantName } from '@app/utils/variant-helper';
import { CartService } from '@modules/cart/cart.service';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { CartItem } from '@shared/models/cart';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';

@Component({
  selector: 'packex-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
})
export class CartItemComponent {
  @Input() item!: CartItem;
  @Input() index?: number;
  @Input() reducedView = false;
  /* special view for cart-summary */
  @Input() summaryView = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly cartService: CartService,
    private readonly gtm: GoogleTagService,
  ) {}

  public deleteItem(item: CartItem): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        id: 'DeleteCartItem',
        data: {
          title: 'CART.DELETE_VARIANT.TITLE',
          content: 'CART.DELETE_VARIANT.CONTENT',
          primaryButtonText: 'COMMON.DELETE',
        },
      })
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.gtm.push('remove_from_cart');
          this.cartService.deleteItemsFromCart([item]);
        }
      });
  }

  public get productOptions(): string {
    return this.item.productOptions.map((po) => po.name).join('; ');
  }

  public get productOptionsHaveAdditionalCheckingDays(): boolean {
    return !!this.item.productOptions.filter(
      (productOption) => productOption.extraProductionDays > 0,
    ).length;
  }

  protected readonly getVariantName = getVariantName;
  protected readonly additionalProductOptionsCheckingDays =
    additionalProductOptionsCheckingDays;
}
