import { Component, OnInit } from '@angular/core';
import { VariantApproveErrorDialogComponent } from '@modules/inventory/components/variant-approve-error-dialog/variant-approve-error-dialog.component';
import { PREPRESS_CHECK } from '@shared/models/product-option';

@Component({
  selector: 'packex-variant-approve-warning-dialog',
  templateUrl:
    '../variant-approve-error-dialog/variant-approve-error-dialog.component.html',
})
export class VariantApproveWarningDialogComponent
  extends VariantApproveErrorDialogComponent
  implements OnInit
{
  override PRODUCT_OPTION_SKU = PREPRESS_CHECK;

  override showNoticeCheckbox = true;

  public override get title() {
    return 'APPROVE_VARIANT.WARNING_TITLE';
  }

  public override get description() {
    return 'APPROVE_VARIANT.WARNING_DESCRIPTION';
  }

  public override get notification() {
    return 'APPROVE_VARIANT.WARNING_NOTIFICATION';
  }

  public override get tooltip() {
    return 'APPROVE_VARIANT.WARNING_TOOLTIP';
  }

  override get formIsValid() {
    return (
      !!this.form.controls.notice_accepted.value ||
      !!this.form.controls.po_checked.value
    );
  }

  override accept() {
    if (this.loading) return;

    if (this.form.controls.notice_accepted.value) {
      this.loading = true;

      this.variantsService.approveVariant(this.variant).subscribe({
        next: () => {
          this.loading = false;
          super.accept();
        },
        error: () => {
          this.loading = false;
        },
      });
    } else {
      this.loading = false;
      super.accept();
    }
  }
}
