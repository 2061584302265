import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EmptyScreenConfig } from '@shared/components/empty-screen/empty-screen.component';

@Component({
  selector: 'packex-empty-products-screen',
  template:
    '<packex-empty-screen [config]="config" (onButtonClick)="onButtonClick.emit()"></packex-empty-screen>',
})
export class EmptyProductsScreenComponent {
  @Input() hasFilter = false;
  @Output() onButtonClick = new EventEmitter();

  get asset(): string {
    return this.hasFilter ? 'no_products_found.svg' : 'no_products_created.svg';
  }

  get title(): string {
    return this.hasFilter
      ? 'INVENTORY.LIST.NO_SEARCH_RESULTS.TITLE'
      : 'INVENTORY.LIST.NO_PRODUCTS.TITLE';
  }

  get buttonText(): string {
    return this.hasFilter
      ? 'INVENTORY.LIST.NO_SEARCH_RESULTS.BUTTON'
      : 'INVENTORY.LIST.NO_PRODUCTS.BUTTON';
  }

  get description(): string {
    return this.hasFilter
      ? 'INVENTORY.LIST.NO_SEARCH_RESULTS.DESCRIPTION'
      : 'INVENTORY.LIST.NO_PRODUCTS.DESCRIPTION';
  }

  get config(): EmptyScreenConfig {
    return {
      asset: this.asset,
      title: this.title,
      content: this.description,
      button: this.buttonText,
    };
  }
}
