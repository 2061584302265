import { IconColor } from '@shared/components/icon/icon.component';
import { CartItem } from '@shared/models/cart';
import { CartOrder, OrderItem, OrderStatus } from '@shared/models/cart-order';
import { PaymentMethod } from '@shared/models/paymentMethod';
import { groupBy, orderBy } from 'lodash';

export const groupCartItems = (items: CartItem[]) => {
  const groupedItems = groupBy(
    orderBy(items, 'sortingPosition', 'desc'),
    'variant.inventoryItem.id',
  );

  return Object.keys(groupedItems).map((key) => ({
    name: groupedItems[key][0].variant.inventoryItem.name,
    estimatedProductionDays: groupedItems[key][0].estimatedProductionDays,
    estimatedShippingDays: groupedItems[key][0].estimatedShippingDays,
    deliveryMethod: groupedItems[key][0].deliveryMethod.name,
    deliveryCountry: groupedItems[key][0].deliveryCountry,
    items: groupedItems[key],
  }));
};

export const canCancelOrder = (order: CartOrder) => {
  const currentDate = new Date().getTime();
  const orderDate = new Date(order.createdAt).getTime();

  const diff = currentDate - orderDate;
  const diffInMinutes = diff / (1000 * 60);

  return diffInMinutes < 60;
};

export const getOrderStatusIcon = (
  status: OrderStatus | undefined,
  order: CartOrder,
) => {
  switch (status) {
    case OrderStatus.PAYMENT_PENDING_INVOICE:
      if (order.paymentMethod.isPrepaid) {
        return {
          icon: 'incoming_order',
          color: 'secondary' as IconColor,
        };
      }

      return {
        icon: 'check',
        color: 'secondary' as IconColor,
      };
    case OrderStatus.CREATED:
      return {
        icon: 'incoming_order',
        color: 'secondary' as IconColor,
      };
    case OrderStatus.ACCEPTED:
      return {
        icon: 'check',
        color: 'secondary' as IconColor,
      };
    case OrderStatus.PROOF_PENDING:
    case OrderStatus.FSC_REVIEW_PENDING:
    case OrderStatus.REWORK_PENDING:
      return {
        icon: 'proof',
        color: 'secondary' as IconColor,
      };
    case OrderStatus.PREPRESS:
    case OrderStatus.PRODUCTION:
      return {
        icon: 'production',
        color: 'secondary' as IconColor,
      };
    case OrderStatus.CANCELLED:
      return {
        icon: 'cancelled',
      };
    case OrderStatus.SHIPPED:
      return {
        icon: 'shipping',
        color: 'secondary' as IconColor,
      };
    case OrderStatus.INVOICED:
    case OrderStatus.COMPLETED:
      return {
        icon: 'check',
        color: 'secondary' as IconColor,
      };
    case OrderStatus.REWORK_CUSTOMER_PENDING:
    case OrderStatus.FSC_REVIEW_PENDING_CUSTOMER:
    case OrderStatus.PROOF_PENDING_CUSTOMER:
      return {
        icon: 'error_warning',
        color: 'warning' as IconColor,
      };
    default:
      return {
        icon: 'check',
        color: 'secondary' as IconColor,
      };
  }
};

export const getStatusTextByStatus = (
  status: OrderStatus | undefined,
  order: CartOrder,
) => {
  switch (status) {
    case OrderStatus.PAYMENT_PENDING_INVOICE:
      if (order.paymentMethod.isPrepaid) {
        return 'ORDER_STATUS.PAYMENT_PENDING_INVOICE';
      }
      return 'ORDER_STATUS.ORDER_SHIPPED';
    case OrderStatus.CANCELLED:
      return 'ORDER_STATUS.ORDER_CANCELLED';
    case OrderStatus.SHIPPED:
      return 'ORDER_STATUS.ORDER_SHIPPED';
    case OrderStatus.INVOICED:
    case OrderStatus.COMPLETED:
      return 'ORDER_STATUS.ORDER_COMPLETED';
    case OrderStatus.PROOF_PENDING:
    case OrderStatus.REWORK_PENDING:
    case OrderStatus.FSC_REVIEW_PENDING:
      return 'ORDER_STATUS.ORDER_PROOF_PENDING';
    case OrderStatus.PREPRESS:
    case OrderStatus.PRODUCTION:
      return 'ORDER_STATUS.ORDER_PRODUCTION';
    case OrderStatus.CREATED:
      return 'ORDER_STATUS.ORDER_CREATED';
    case OrderStatus.ACCEPTED:
      return 'ORDER_STATUS.ORDER_ACCEPTED';
    case OrderStatus.PAYMENT_PENDING:
      return 'ORDER_STATUS.PAYMENT_PENDING';
    case OrderStatus.REWORK_CUSTOMER_PENDING:
    case OrderStatus.FSC_REVIEW_PENDING_CUSTOMER:
    case OrderStatus.PROOF_PENDING_CUSTOMER:
      return `PRINT_DATA_REVIEW_TYPE.${status}`;
    default:
      return '';
  }
};

export const getStatusDescriptionByStatus = (
  item: OrderItem | undefined,
  order: CartOrder,
) => {
  switch (item?.status) {
    case OrderStatus.PAYMENT_PENDING_INVOICE:
      if (order.paymentMethod.isPrepaid) {
        return 'ORDER_STATUS_DESCRIPTION.PAYMENT_PENDING_INVOICE';
      }

      return 'ORDER_STATUS_DESCRIPTION.SENT';
    case OrderStatus.CANCELLED:
      if (item.cancellationReason === 'packex')
        return 'ORDER_STATUS_DESCRIPTION.CANCELLED';
      else return 'ORDER_STATUS_DESCRIPTION.CUSTOMER_CANCELLED';
    case OrderStatus.SHIPPED:
      return 'ORDER_STATUS_DESCRIPTION.SENT';
    case OrderStatus.INVOICED:
    case OrderStatus.COMPLETED:
      return 'ORDER_STATUS_DESCRIPTION.COMPLETED';
    case OrderStatus.PAYMENT_PENDING:
      return 'ORDER_STATUS_DESCRIPTION.PAYMENT_PENDING';
    case OrderStatus.PROOF_PENDING:
    case OrderStatus.REWORK_PENDING:
    case OrderStatus.FSC_REVIEW_PENDING:
      return 'ORDER_STATUS_DESCRIPTION.PROOF_PENDING';
    case OrderStatus.PREPRESS:
    case OrderStatus.PRODUCTION:
      return 'ORDER_STATUS_DESCRIPTION.PRODUCTION';
    case OrderStatus.CREATED:
      return 'ORDER_STATUS_DESCRIPTION.CREATED';
    case OrderStatus.ACCEPTED:
      return 'ORDER_STATUS_DESCRIPTION.ACCEPTED';
    case OrderStatus.REWORK_CUSTOMER_PENDING:
      return 'ORDER_STATUS_DESCRIPTION.CHECK_PRINT_DATA';
    case OrderStatus.FSC_REVIEW_PENDING_CUSTOMER:
    case OrderStatus.PROOF_PENDING_CUSTOMER:
      return 'ORDER_STATUS_DESCRIPTION.PROOF_PENDING_CUSTOMER';
    default:
      return '';
  }
};

export const getPaymentMethodTranslation = (
  paymentMethod: PaymentMethod,
): string => {
  return `CART.PAYMENT.${paymentMethod.handler.toUpperCase()}`;
};

export const paymentMethodOnlyPrepaidAvailable = (
  paymentMethods: PaymentMethod[],
): boolean => {
  return paymentMethods.length === 1 && paymentMethods[0].isPrepaid;
};
