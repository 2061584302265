<packex-modal (onDismiss)="dismiss()">
  <h5 class="modal-title">
    <ng-container *ngIf="inventoryItem; else createTemplate">
      <span *ngIf="dialogData.duplicate">{{ 'CONFIGURATION.SAVE_DIALOG.TITLE_DUPLICATE' | transloco }}</span>
      <span
        *ngIf="dialogData.rename">{{ 'CONFIGURATION.SAVE_DIALOG.TITLE_RENAME' | transloco }}</span>
      <span
        *ngIf="!dialogData.rename && !dialogData.duplicate">{{ 'CONFIGURATION.SAVE_DIALOG.TITLE_NEW' | transloco }}</span>
    </ng-container>
    <ng-template #createTemplate>
      <span>{{ 'CONFIGURATION.SAVE_DIALOG.TITLE' | transloco }}</span>
    </ng-template>
  </h5>

  <div class="modal-body">
    <form class="packex-form" [formGroup]="saveForm" (ngSubmit)="submit()" *ngIf="saveForm">
      <p class="mb-4" [innerHTML]="getFormatOrServiceChangedDescription | transloco"
         *ngIf="dialogData.formatOrServiceChanged && inventoryItem"></p>
      <p class="mb-4">{{ description | transloco  }}</p>
      <p>
        <mat-form-field appearance="outline" class="w-100 mb-4">
          <input (ngModelChange)="nameChanged()"
                 matInput
                 [attr.data-testid]="'configurator-save-dialog-input'"
                 [placeholder]="'CONSTRUCTION.MODEL.NAME_PLACEHOLDER'  | transloco"
                 [errorStateMatcher]="esMatcher"
                 formControlName="name"
                 name="name"
                 maxlength="100"
                 required>
          <mat-error *ngIf="saveForm.get('name')?.hasError('required')">
            {{ 'CONSTRUCTION.ERROR.NAME' | transloco}}
          </mat-error>
          <mat-error *ngIf="saveForm.get('name')?.hasError('notUnique')" [attr.data-testid]="'configurator-name-exists-error'">
            {{ 'CONSTRUCTION.ERROR.NAME_EXISTS' | transloco}}
          </mat-error>
          <mat-hint align="end">{{ "COMMON.CHARACTERS_LEFT" | transloco: {
            left: (100 - nameLength),
            max: 100
          }
            }}</mat-hint>
        </mat-form-field>
      </p>
    </form>

    <div class="d-flex flex-row align-content-center subtitle-1">
      <packex-icon icon="label" class="me-2"></packex-icon>
      {{ 'LABELS.CREATE_LABELS' | transloco }}
    </div>

    <packex-create-label (labelsChanged)="labelsChanged($event)" [inventoryItem]="inventoryItem"
                         [showLabelNotification]="true"></packex-create-label>
  </div>

  <packex-button buttonType="submit" (buttonClick)="dismiss()"
                 [disabled]="isLoading"
                 class="w-auto"
                 type="secondary">{{"COMMON.CANCEL" | transloco}}</packex-button>
  <packex-button [disabled]="submitButtonDisabled"
                 [attr.data-testid]="'configurator-save-dialog-save-button'"
                 class="w-auto"
                 (buttonClick)="submit()">{{"COMMON.SAVE" | transloco}}</packex-button>
</packex-modal>
