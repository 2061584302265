import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartService } from '@modules/cart/cart.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Address } from '@shared/models/address';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'packex-cart-address-card',
  templateUrl: './cart-address-card.component.html',
  styleUrls: ['./cart-address-card.component.scss'],
})
export class CartAddressCardComponent implements OnInit {
  @Input() address!: Address | null;
  @Input() header!: string;
  @Input() emails: string[] = [];
  @Input() recipientsTitle!: string;
  @Input() recipientsPlaceholder!: string;
  @Input() showAddress = true;
  @Input() recipients: string[] = [];
  @Input() editButtonTooltip!: string;
  @Input() createButtonTitle!: string;
  @Input() recipientTooltip!: string;
  @Input() showVatId = true;

  @Output() onEdit = new EventEmitter();
  @Output() onCreate = new EventEmitter();
  @Output() onSave = new EventEmitter<string[]>();

  constructor(private readonly cartService: CartService) {}

  ngOnInit() {
    this.cartService.saveEmitter$
      .pipe(
        untilDestroyed(this),
        filter((value: boolean) => value),
      )
      .subscribe(() => {
        this.onSave.emit(this.recipients);
      });
  }
}
