import { CountrySelectOption } from '@shared/components/packex-select/countries-select.component';
import { Configuration } from '@shared/models/configuration';
import { DeliveryCountry } from '@shared/models/delivery-country';
import { InventoryItem } from '@shared/models/inventory-item';
import { Material } from '@shared/models/material';
import { MaterialGroup } from '@shared/models/material-group';

export const configurationHasPrice = (
  configuration: Configuration | undefined,
): boolean => {
  return (
    !!configuration && !!configuration.totalPrice && !!configuration.unitPrice
  );
};

export const isMaterialInMaterialGroup = (
  materialGroup: MaterialGroup,
  materialId?: string | null,
): boolean => {
  return (
    materialGroup.materials.findIndex(
      (material) => material.id === materialId,
    ) !== -1
  );
};

export const formattedFormat = (entity: Configuration | InventoryItem) => {
  if (['label', 'leaflet'].includes(entity.construction.category)) {
    return `${entity.a} x ${entity.h}`;
  }
  return `${entity.a} x ${entity.b} x ${entity.h}`;
};

export const getMaterialGroup = (
  materialGroups: MaterialGroup[],
  material: Material,
): MaterialGroup | undefined => {
  return materialGroups.find((x) =>
    x.materials.find((m) => m.id === material.id),
  );
};

export const mapDeliveryCountryToSelectOption = (
  country: DeliveryCountry | CountrySelectOption | null,
): CountrySelectOption | null => {
  return country
    ? {
        ...country,
        name: `${country.name}, (${country.isoCode.toUpperCase()})`,
        visible: country.visible || true,
        identifier: country.id,
      }
    : null;
};

export const variantsPerUnit = (configuration?: Configuration) => {
  const amount = configuration?.amount || 0;
  const numberOfVariants = configuration?.numberOfVariants || 0;
  return Math.round(amount / numberOfVariants);
};
