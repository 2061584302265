<packex-card class="p-4 w-100 h-auto position-relative d-flex flex-column mb-4">
  <div class="d-flex flex-column flex-md-row w-100">
    <div class="w-100 w-md-50 me-3">
      <div class="subtitle-1 border-bottom border-grey-medium pb-2 d-flex justify-content-between">
        <span>{{ 'CART.DELIVERY.SHIPPING_ADDRESS' | transloco }}</span>
        <packex-button type="clear-icon" (buttonClick)="goToAddress()" icon="edit"></packex-button>
      </div>
      <packex-address [address]="cartServiceInstance.cartValue.deliveryAddress!"
                      [showVatId]="false"
                      class="d-block mt-2"></packex-address>

      <div class="subtitle-2 mt-3 d-flex">
        <span>{{'CART.DELIVERY.WHITE_LABEL_SHIPPING_LABEL' | transloco: {whiteLabel: cartServiceInstance.cartValue.whiteLabelShipping} }}</span>
        <packex-tooltip
          [text]="'CART.DELIVERY.WHITE_LABEL_SHIPPING_TOOLTIP_SUMMARY' | transloco : {whiteLabel: cartServiceInstance.cartValue.whiteLabelShipping}"
          class="ms-2"></packex-tooltip>
      </div>

      <ng-container *ngIf="deliveryRecipients.length">
        <div class="subtitle-2 mt-3">{{ 'CART.DELIVERY.SHIPPING_RECIPIENTS' | transloco }}</div>
        <p *ngFor="let recipient of deliveryRecipients" class="mb-0">{{ recipient }}</p>
      </ng-container>
    </div>
    <div class="w-100 w-md-50 ms-md-3 mt-6 mt-md-0">
      <div class="subtitle-1 border-bottom border-grey-medium pb-2 d-flex justify-content-between">
        <span>{{ 'CART.BILLING.BILLING_ADDRESS' | transloco }}</span>
        <packex-button type="clear-icon" (buttonClick)="goToAddress()" icon="edit"></packex-button>
      </div>
      <packex-address [address]="cartServiceInstance.cartValue.billingAddress!"
                      class="d-block mt-2"></packex-address>

      <ng-container *ngIf="billingRecipients.length">
        <div class="subtitle-2 mt-2">{{ 'CART.BILLING.BILLING_RECIPIENTS' | transloco }}</div>
        <p *ngFor="let recipient of billingRecipients" class="mb-0">{{ recipient }}</p>
      </ng-container>
    </div>
  </div>

  <div class="mt-6" *ngIf="cartServiceInstance.cartValue.paymentMethod">
    <div class="d-flex flex-column">
      <div class="subtitle-1 border-bottom border-grey-medium pb-2 ">{{ 'CART.PAYMENT.TITLE' | transloco }}</div>
      <div class="pt-2">
        {{ getPaymentMethodTranslation(cartServiceInstance.cartValue.paymentMethod) | transloco }}
      </div>
    </div>
  </div>
</packex-card>

<ng-container *ngIf="(cart$ | async) as cart">
  <div class="product-wrapper">
    <packex-cart-product [product]="product" *ngFor="let product of cartProducts$ | async"
                         [editAction]="goToCheckoutPage.bind(this)"
                         [showIndex]="true"
                         [summaryView]="true"
                         class="border-top border-light mb-4 box-shadow"></packex-cart-product>
  </div>
</ng-container>
