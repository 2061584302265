import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { InventoryListComponent } from '@modules/inventory/components/inventory-list/inventory-list.component';
import { InventoryPageComponent } from '@modules/inventory/pages/inventory-page/inventory-page.component';
import { PrintDataManagerPageComponent } from '@modules/inventory/print-data-manager-page/print-data-manager-page.component';

export const INVENTORY_ROUTES = {
  LIST: 'list',
  PRINT_DATA_MANAGER: 'print-data-manager',
  CONFIGURATOR_INVENTORY: 'configurator',
  NEW: 'new',
};

const inventoryRoutes: Routes = [
  {
    path: '',
    component: InventoryPageComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'DEFAULT.MAIN_NAVIGATION.MY_PRODUCTS',
    },
    children: [
      {
        path: INVENTORY_ROUTES.CONFIGURATOR_INVENTORY + '/:inventoryItemId',
        loadChildren: () =>
          import('../configurator/configurator.module').then(
            (m) => m.ConfiguratorModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        redirectTo: INVENTORY_ROUTES.LIST,
        pathMatch: 'full',
      },
      {
        path: INVENTORY_ROUTES.LIST,
        component: InventoryListComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'DEFAULT.MAIN_NAVIGATION.MY_PRODUCTS',
          pageTitle: 'PAGES.MY_PRODUCTS',
        },
      },
      {
        path: `:id/${INVENTORY_ROUTES.PRINT_DATA_MANAGER}`,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: {
            alias: 'inventoryItem',
          },
          pageTitle: 'PAGES.PRINT_DATA_MANAGER',
        },
        children: [
          {
            path: ':variantId',
            component: PrintDataManagerPageComponent,
            canActivate: [AuthGuard],
            data: {
              breadcrumb: {
                alias: 'variantItem',
              },
            },
          },
          {
            path: '',
            component: PrintDataManagerPageComponent,
            canActivate: [AuthGuard],
            data: {
              breadcrumb: {
                alias: 'inventoryItem',
              },
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(inventoryRoutes)],
  exports: [RouterModule],
})
export class InventoryRoutingModule {}
