import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { InventoryItemsService } from '@app/core/services/inventory-items.service';
import { xmlResponseToSafeSvgUrl } from '@app/utils/svgXMLToSvgUrl';
import { InventoryItem } from '@shared/models/inventory-item';
import { InViewportAction } from 'ng-in-viewport';
import { BehaviorSubject, catchError, of } from 'rxjs';

@Component({
  selector: 'packex-inventory-item-dieline-svg',
  templateUrl: './inventory-item-dieline-svg.component.html',
  styleUrls: ['./inventory-item-dieline-svg.component.scss'],
})
export class InventoryItemDielineSvgComponent {
  @Input() inventoryItem: InventoryItem | null = null;
  imageSvg$ = new BehaviorSubject<SafeResourceUrl>('');
  loading$ = new BehaviorSubject<boolean>(true);

  emptyDieline = 'assets/images/empty_dieline.png';

  constructor(
    private readonly inventoryItemsService: InventoryItemsService,
    private readonly sanitizer: DomSanitizer,
  ) {}

  public load(): void {
    if (this.inventoryItem) {
      this.inventoryItemsService
        .getDielineSvg(this.inventoryItem)
        .pipe(
          catchError(() => {
            this.imageSvg$.next(this.emptyDieline);
            this.loading$.next(false);
            return of(null);
          }),
        )
        .subscribe((response) => {
          if (response) {
            const image = this.sanitizer.bypassSecurityTrustResourceUrl(
              xmlResponseToSafeSvgUrl(response),
            );

            this.imageSvg$.next(image);
            this.loading$.next(false);
          }
        });
    }
  }

  public onIntersection($event: InViewportAction): void {
    if ($event.visible) {
      this.load();
    }
  }
}
