<div class="w-100">
  <ngb-carousel *ngIf="slides"
                [showNavigationArrows]="false"
                [showNavigationIndicators]="slides.length > 1"
                [interval]="5000"
                [keyboard]="true"
                [pauseOnHover]="true"
                [wrap]="true">
    <ng-template ngbSlide *ngFor="let slide of slides">
      <packex-advertise-banner [borderRadius]="borderRadius"
                               [title]="slide.title"
                               [asset]="slide.asset"
                               [content]="slide.content"
                               [buttonText]="slide.buttonText"
                               [background]="slide.bg"
                               [size]="size"
                               [callback]="slide.callback"></packex-advertise-banner>
    </ng-template>
  </ngb-carousel>
</div>
