import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InvitationService } from '@app/core/services/invitation.service';
import { NotificationService } from '@app/core/services/notification.service';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { getLabelByTopic } from '@app/utils/notifications.helper';
import { validateEmail } from '@app/utils/validators';
import { translate } from '@ngneat/transloco';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { Notification, NotificationTopic } from '@shared/models/notification';
import { AppConfigService } from '@shared/services/app-config.service';

@Component({
  selector: 'packex-organization-invite-with-status-updates-modal',
  templateUrl: './organization-invite-with-status-updates-modal.component.html',
  styleUrls: ['./organization-invite-with-status-updates-modal.component.scss'],
})
export class OrganizationInviteWithStatusUpdatesModalComponent extends ModalComponent {
  form = new FormGroup({});
  topics: NotificationTopic[] = [];

  constructor(
    dialogRef: MatDialogRef<OrganizationInviteWithStatusUpdatesModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      title: string;
      description: string;
      settingsDescription: string;
      button: string;
      settingsHint?: string;
      notification?: Notification;
      invite: boolean;
    },
    private readonly notificationService: NotificationService,
    private readonly invitationService: InvitationService,
    private readonly appConfig: AppConfigService,
    private readonly snackbarService: SnackBarService,
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    this.appConfig.appConfig$.subscribe((appConfig) => {
      this.topics = appConfig?.notificationTopics || [];

      this.topics.forEach((topic) => {
        const value = this.dialogData.notification?.id
          ? this.dialogData.notification.topics.includes(topic)
          : true;
        this.form.addControl(topic, new FormControl(value));
      });
    });

    const emailValue = this.dialogData.notification?.email || '';

    this.form.addControl(
      'email',
      new FormControl(
        emailValue,
        Validators.compose([validateEmail, Validators.required]),
      ),
    );
  }

  public getStatusLabel(topic: NotificationTopic): string {
    return getLabelByTopic(topic);
  }

  get allStatusChecked() {
    return Boolean(
      !this.topics
        .map((status) => this.form.get(status)?.value)
        .some((value) => !value),
    );
  }

  public onToggle(): void {
    const value = !this.allStatusChecked;

    this.topics.forEach((topic) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.form.get(topic)?.patchValue(value);
    });
  }

  private getCheckedTopics() {
    return this.topics.filter((topic) => {
      return this.form.get(topic)?.value;
    });
  }

  public submit(): void {
    const email = this.form.get('email')?.value;
    const topics = this.getCheckedTopics();

    if (this.dialogData.notification && this.dialogData.notification.id) {
      this.notificationService
        .update(this.dialogData.notification.id, topics)
        .subscribe(() => {
          this.onConfirm();
        });
    } else if (this.dialogData.notification?.user) {
      this.notificationService
        .subscribeUser(this.dialogData.notification.user.id as string, topics)
        .subscribe(() => {
          this.onConfirm();
        });
    } else if (email) {
      if (this.dialogData.invite) {
        this.invitationService.invite(email).subscribe({
          next: () => this.inviteSuccess(email),
          error: (err) => {
            if (err && err.statusCode === 409) {
              this.form.get('email')?.setErrors({ exists: true });
            } else {
              this.inviteFailed();
            }
          },
        });
      } else if (email) {
        this.notificationService.subscribeEmail(email, topics).subscribe({
          next: () => {
            this.snackbarService.show({
              message: '',
              rawMessage: translate('ORGANIZATION.EDIT_NOTIFICATIONS.SUCCESS', {
                email,
              }),
            });
            this.onConfirm();
          },
          error: () => {
            this.snackbarService.showSimpleError(
              'ORGANIZATION.EDIT_NOTIFICATIONS.EXISTS_ERROR',
            );
          },
        });
      }
    }
  }

  public inviteSuccess(email: string) {
    this.snackbarService.show({
      message: '',
      rawMessage: translate('ORGANIZATION.INVITE_COLLEAGUES.SUCCESS', {
        email,
      }),
    });

    this.onConfirm();
  }

  public inviteFailed() {
    this.snackbarService.showSimpleError(
      'ORGANIZATION.INVITE_COLLEAGUES.FAILURE',
    );
  }
}
