<mat-table class="items-list" [dataSource]="invitations" *ngIf="invitations.length">
  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef>
      <span
        class="subtitle-2 text-primary-medium">{{ 'INVITATIONS.OUTSTANDING' | transloco }}</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <p class="my-3 my-md-0 text-break text-grey-dark">{{ element.email }}</p>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="send">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element" class="flex-wrap">
      <packex-button type="tertiary" (buttonClick)="sendInvitation(element)"
                     [disabled]="disabled"
                     disabledIconColor="grey"
                     color="secondary"
                     class="w-auto align-self-start align-self-md-center mt-3 mt-md-0"
                     icon="send">
          <span
            class="d-none d-md-block">{{ 'ORGANIZATION.SEND_INVITATION.RESEND_INVITATION_BUTTON' | transloco }}</span>
      </packex-button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef>&nbsp;</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <packex-button icon="delete" (buttonClick)="deleteInvitation(element)" class="w-4 h-4" type="clear-icon"
                     buttonPadding="p-3"></packex-button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
