import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CartProductComponent } from '@modules/cart/cart-product/cart-product.component';
import { CartService } from '@modules/cart/cart.service';
import { ModalCartService } from '@modules/cart/modal-cart.service';
import { DrawerService } from '@shared/services/drawer.service';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';

@Component({
  selector: 'packex-cart-flyout-product',
  templateUrl: './cart-flyout-product.component.html',
  styleUrls: ['./cart-flyout-product.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartFlyoutProductComponent extends CartProductComponent {
  constructor(
    dialog: MatDialog,
    modalCartService: ModalCartService,
    drawerService: DrawerService,
    cartService: CartService,
    gtm: GoogleTagService,
  ) {
    super(dialog, modalCartService, drawerService, cartService, gtm);
  }
}
