import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RoutingDataUtilityService {
  getSnapshotData(key: string, activatedRoute: ActivatedRoute): any {
    if (
      activatedRoute &&
      activatedRoute.root &&
      activatedRoute.root.firstChild
    ) {
      const snapShotData = activatedRoute.root.firstChild.snapshot.firstChild
        ? activatedRoute.root.firstChild.snapshot.firstChild.data
        : activatedRoute.root.firstChild.snapshot.data;
      if (snapShotData && snapShotData[key]) {
        return snapShotData[key];
      }
    }
    return null;
  }
}
