<packex-page>
  <div class="content-wrapper">
    <packex-card class="h-auto m-auto mt-3">
      <h2 class="text-uppercase">{{ 'USER.REGISTRATION.SUCCESS.TITLE' | transloco}}</h2>
      <packex-divider></packex-divider>
      <p [innerHTML]="'USER.REGISTRATION.SUCCESS.MAIL_SENT' | transloco: {email: user?.email}"></p>
      <p class="mt-3" [innerHTML]="'USER.REGISTRATION.SUCCESS.PROGRESS_HINT' | transloco"></p>
      <p class="mt-9">
        <span [innerHTML]="'USER.REGISTRATION.SUCCESS.MISSING_VERIFICATION' | transloco"></span>&nbsp;
        <a href="javascript:void(0)" (click)="onResend()"><span
          class="icon-label-microsoft">{{'USER.REGISTRATION.SUCCESS.RESEND_VERIFICATION' | transloco}}</span></a>
      </p>
    </packex-card>
  </div>
</packex-page>
