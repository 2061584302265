import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { CoreModule } from '@app/core/core.module';
import { FilterModule } from '@modules/filter/filter.module';
import { InventoryItemHeaderComponent } from '@modules/inventory/components/inventory-item-header/inventory-item-header.component';
import { InventoryLabelFilterComponent } from '@modules/inventory/components/inventory-label-filter/inventory-label-filter.component';
import { InventoryRoutingModule } from '@modules/inventory/inventory-routing.module';
import { PrintDataManagerPageComponent } from '@modules/inventory/print-data-manager-page/print-data-manager-page.component';
import { VariantApproveService } from '@modules/inventory/variant-approve.service';
import { MaterialModule } from '@shared/material.module';
import { PackexModule } from '@shared/packex.module';
import { SharedModule } from '@shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BatchDeleteInventoryItemsDialogComponent } from './components/batch-delete-inventory-items-dialog/batch-delete-inventory-items-dialog.component';
import { EditVariantComponent } from './components/edit-variant/edit-variant.component';
import { EmptyProductsScreenComponent } from './components/empty-products-screen/empty-products-screen.component';
import { InventoryBatchOperationsComponent } from './components/inventory-batch-operations/inventory-batch-operations.component';
import { InventoryDialogDeleteComponent } from './components/inventory-dialog-delete/inventory-dialog-delete.component';
import { InventoryItemDetailCardComponent } from './components/inventory-item-detail-card/inventory-item-detail-card.component';
import { InventoryItemMobileFilterComponent } from './components/inventory-item-mobile-filter/inventory-item-mobile-filter.component';
import { InventoryListItemDetailsComponent } from './components/inventory-list-item-details/inventory-list-item-details.component';
import { InventoryListItemComponent } from './components/inventory-list-item/inventory-list-item.component';
import { InventoryListOptionBarComponent } from './components/inventory-list-option-bar/inventory-list-option-bar.component';
import { InventoryListSortComponent } from './components/inventory-list-sort/inventory-list-sort.component';
import { InventoryListComponent } from './components/inventory-list/inventory-list.component';
import { InventorySidebarComponent } from './components/inventory-sidebar/inventory-sidebar.component';
import { InventoryLabelComponent } from './components/label/label.component';
import { PrintDataItemStatusComponent } from './components/print-data-item-status/print-data-item-status.component';
import { PrintDataItemsListComponent } from './components/print-data-items-list/print-data-items-list.component';
import { PrintDataUploadProcessingComponent } from './components/print-data-upload-processing/print-data-upload-processing.component';
import { PrintDataUploadResultComponent } from './components/print-data-upload-result/print-data-upload-result.component';
import { PrintDataUploadComponent } from './components/print-data-upload/print-data-upload.component';
import { TemplatesListComponent } from './components/templates-list/templates-list.component';
import { VariantApproveErrorDialogComponent } from './components/variant-approve-error-dialog/variant-approve-error-dialog.component';
import { VariantApproveWarningDialogComponent } from './components/variant-approve-warning-dialog/variant-approve-warning-dialog.component';
import { InventoryPageComponent } from './pages/inventory-page/inventory-page.component';

@NgModule({
  declarations: [
    InventoryPageComponent,
    InventoryListItemComponent,
    InventorySidebarComponent,
    InventoryListComponent,
    InventoryListItemDetailsComponent,
    InventoryDialogDeleteComponent,
    PrintDataManagerPageComponent,
    TemplatesListComponent,
    PrintDataUploadComponent,
    PrintDataUploadProcessingComponent,
    PrintDataItemsListComponent,
    PrintDataUploadResultComponent,
    InventoryItemDetailCardComponent,
    InventoryLabelFilterComponent,
    InventoryLabelComponent,
    InventoryListSortComponent,
    PrintDataItemStatusComponent,
    EditVariantComponent,
    InventoryListOptionBarComponent,
    VariantApproveErrorDialogComponent,
    VariantApproveWarningDialogComponent,
    EmptyProductsScreenComponent,
    EmptyProductsScreenComponent,
    InventoryItemMobileFilterComponent,
    InventoryBatchOperationsComponent,
    BatchDeleteInventoryItemsDialogComponent,
    InventoryItemHeaderComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    MaterialModule,
    PackexModule,
    FilterModule,
    SharedModule,
    NgxSkeletonLoaderModule,
    FormsModule,
    NgxPaginationModule,
    MatTableModule,
    MatStepperModule,
    MatGridListModule,
    ReactiveFormsModule,
    InventoryRoutingModule,
    MatLegacyChipsModule,
  ],
  providers: [VariantApproveService],
  exports: [
    InventoryLabelComponent,
    InventoryItemHeaderComponent,
    InventoryItemHeaderComponent,
    InventoryItemDetailCardComponent,
  ],
})
export class InventoryModule {}
