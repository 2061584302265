import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'packex-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelComponent {
  @Input() showAction = false;
  @Input() showTitle = false;
  @Input() showDescription = false;
  @Input() showTopnav = false;
  @Input() bodyDividerHide = false;
  @Input() shadowed = false;
  @Input() expanded = false;
  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();

  panelOpened() {
    this.opened.emit();
  }

  panelClosed() {
    this.closed.emit();
  }
}
