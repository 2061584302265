import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { InventoryItem } from '@shared/models/inventory-item';

@Component({
  selector: 'packex-batch-delete-inventory-items-dialog',
  templateUrl: './batch-delete-inventory-items-dialog.component.html',
  styleUrls: ['./batch-delete-inventory-items-dialog.component.scss'],
})
export class BatchDeleteInventoryItemsDialogComponent extends ModalComponent {
  constructor(
    dialogRef: MatDialogRef<BatchDeleteInventoryItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: { inventoryItems: InventoryItem[] },
  ) {
    super(dialogRef);
  }

  deleteInventoryItems() {
    super.onConfirm();
  }
}
