import { Component, OnInit } from '@angular/core';
import { InventoryItemsService } from '@app/core/services/inventory-items.service';
import { FilterService } from '@modules/filter/services/filter.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Label } from '@shared/models/label';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'packex-inventory-label-filter',
  templateUrl: './inventory-label-filter.component.html',
})
export class InventoryLabelFilterComponent implements OnInit {
  labels$?: Observable<Label[]>;

  constructor(
    private readonly inventoryItemsService: InventoryItemsService,
    private readonly filterService: FilterService,
  ) {}

  ngOnInit() {
    this.labels$ = this.inventoryItemsService.getLabels();

    this.inventoryItemsService.loadLabels();

    this.inventoryItemsService
      .getDataUpdatedEmitter()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.inventoryItemsService.loadLabels());
  }

  public toggleLabel(label: Label): void {
    label.active
      ? this.deActivateLabelFilter(label)
      : this.setLabelFilter(label);
  }

  private setLabelFilter(label: Label): void {
    this.filterService.activateLabelFilter(label);
  }

  private deActivateLabelFilter(label: Label): void {
    this.filterService.deActivateLabelFilter(label);
  }
}
