<packex-page>
  <packex-card class="h-auto m-auto mt-3">
    <h2 class="text-uppercase">{{ 'USER.VERIFY.VERIFY_TITLE' | transloco}}</h2>
    <packex-divider></packex-divider>
    <p [innerHTML]="'USER.VERIFY.VERIFY_CONTENT' | transloco : {email: user?.email}"></p>

    <packex-button type="tertiary" (buttonClick)="resendVerificationEmail()" [disabled]="loading"
                   class="w-auto mt-4">{{ 'USER.VERIFY.VERIFY_BUTTON' | transloco }}</packex-button>
  </packex-card>
</packex-page>
