import { Dimensions } from '@shared/models/dimensions';
import { MaterialGroup } from '@shared/models/material-group';
import { ProductOption } from '@shared/models/product-option';
import { ConfigurationTemplate } from './configuration-template';

export enum ConstructionType {
  Leaflet = 'leaflet',
  Label = 'label',
  ShippingBox = 'shipping_box',
  FoldingBox = 'folding_box',
  CardingBox = 'carding_box',
  Slipcase = 'slipcase',
  BottleBox = 'bottle_box',
  PillowBox = 'pillow_box',
  Display = 'display',
  PouchBox = 'pouch_box',
  Calendar = 'calendar',
}

export const SHIPPING_BOX = 'shipping_box';
export const LABEL = 'label';
export const FOLDING_BOX = 'folding_box';
export const LEAFLET = 'leaflet';

export type ConstructionCategory =
  | 'label'
  | 'shipping_box'
  | 'folding_box'
  | 'leaflet';

export interface Construction {
  id: string;
  catalog: string;
  catalogCode: string;
  category: ConstructionCategory;
  name: string;
  description: string;
  shortDescription: string;
  summary: string;
  imagePath: string;
  constructionPath: string;
  defaultConfiguration: Dimensions;
  minimumConfiguration: Dimensions;
  maximumConfiguration: Dimensions;
  type: ConstructionType;
  sortOrder: 0;
  erpaCode: string;
  createdAt: string;
  updatedAt: string;
  supportedProductOptions: ProductOption[];
  materialGroups: MaterialGroup[];
  availableMaterialGroups: MaterialGroup[];
  imageUrl: string;
  attributes: string[];
  fixedMeasures: boolean;
  isFreelyConfigurable: boolean;
  configurationTemplates: ConfigurationTemplate[];
  trackingName: string;
}
