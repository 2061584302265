<div class="d-flex flex-column justify-content-center">
  <span class="subtitle-1 mb-2" [ngClass]="{'subtitle-2': reducedView}" >{{ inventoryItem.name }}</span>
  <p class="text-primary-medium mb-0 caption" *ngIf="!reducedView">
    {{ getFormattedFormat(inventoryItem) }}
    <span
      *ngIf="inventoryItem.construction.category !== 'label'">{{ 'CONFIGURATION.FORMAT.UNITS' | transloco }}</span>
    <span
      *ngIf="inventoryItem.construction.category === 'label'">{{ 'CONFIGURATION.FORMAT.UNITS_LABEL' | transloco }}</span>
  </p>
  <p class="text-primary-medium mb-0 caption" *ngIf="!reducedView">
    {{ inventoryItem.material?.materialGroup?.name || cartProduct.items[0].configuration.material.materialGroup.name }}
    <ng-container *ngIf="showGrammage">
      <span>- {{ inventoryItem.material?.grammage }}&nbsp;</span>
      <span [innerHTML]="'CONFIGURATION.MATERIAL.UNIT' | transloco"></span>
    </ng-container>
  </p>
    <p class="text-primary-medium caption" *ngIf="!reducedView && getCoating()">
        {{ getCoating()?.name }}
    </p>
  <ng-container *ngIf="inventoryItem.construction.category === 'label' && !reducedView">

    <p class="text-primary-medium caption">
      {{ 'CONFIGURATION.WINDING.' + inventoryItem.winding?.toUpperCase() | transloco }};&nbsp;
      {{ inventoryItem.rollDiameter }}mm ({{ 'CONFIGURATION.WINDING.ROLL' | transloco }})
    </p>
  </ng-container>
  <p class="text-primary-medium caption" *ngIf="cartProduct">
        {{ cartProduct.deliveryMethod }} {{ 'CART.DELIVERY.SHIPPING' | transloco }}
    <ng-container *ngIf="!reducedView">
      {{ 'CONFIGURATION.SHIPPING.ESTIMATED_PRODUCTION_DAYS' | transloco :{days: cartProduct.estimatedProductionDays} }}
      +
      {{ 'CONFIGURATION.SHIPPING.ESTIMATED_DELIVERY_DAYS' | transloco :  {days: cartProduct.estimatedShippingDays} }}
      ({{cartProduct.deliveryCountry.isoCode?.toUpperCase()}})
    </ng-container>
  </p>
</div>
