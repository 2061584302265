<div (click)="clickLabel(label)" *ngIf="label"
     class="d-flex {{customClass}}"
     [ngClass]="{'active': label.active, 'passive': passive, 'no-icon': !label.active}"
     [ngStyle]="{
      margin
     }"
     [matTooltip]="label.title"
     [matTooltipDisabled]="label.title.length <= 20"
     [attr.data-testid]="'label-'+ label.title"
>
  <packex-icon *ngIf="label.active"
               icon="check"
               color="secondary"
               size="m"
               class="me-1 float-start"></packex-icon>
  <ng-content></ng-content>
  {{ label.title }}
</div>
