import { Component } from '@angular/core';
import { ROUTES } from '@app/routes';

@Component({
  selector: 'packex-change-password-success',
  templateUrl: './change-password-success.component.html',
  styleUrls: ['./change-password-success.component.scss'],
})
export class ChangePasswordSuccessComponent {
  myProductsLink = ROUTES.INVENTORY;
}
