import { Component, Input } from '@angular/core';
import { DownloadService } from '@shared/services/download.service';

@Component({
  selector: 'packex-download-button',
  template: ` <button
    [disabled]="buttonDisabled | async"
    (click)="download()"
    class="p-0 text-{{ color }} {{ textType }} text-wrap"
  >
    <ng-content></ng-content>
  </button>`,
  styles: [
    `
      :host {
        word-break: break-all;

        &.display-contents {
          button {
            display: contents;
          }
        }

        &:hover {
          text-decoration: underline;
          text-decoration-color: var(--secondary-medium);
        }

        button[disabled] {
          color: var(--grey-medium) !important;
          cursor: not-allowed;
        }
      }

      .caption {
        text-decoration: none;
      }
    `,
  ],
})
export class DownloadButtonComponent {
  @Input() url?: string;
  @Input() color = 'secondary-medium';
  @Input() type: 'download' | 'open_in_tab' = 'download';
  @Input() textType = 'caption';
  @Input() fileName?: string;

  constructor(private readonly downloadService: DownloadService) {}

  get buttonDisabled() {
    return this.downloadService.downloadInProgress;
  }

  public download(): void {
    if (this.url) {
      this.downloadService.download(
        this.url,
        this.type,
        'application/pdf',
        this.fileName,
      );
    }
  }
}
