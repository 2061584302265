import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

export type SnackBarProperties = {
  icon?: string;
  buttons?: {
    label: string;
    cb?: () => void;
    color?: string;
  }[];
  rawMessage?: string;
  message: string;
};

/**
 * @author Kai-S. Birkenstock
 *
 * Packex Snackbar Component
 *
 * to use include SnackbarService.ts in your desired component and call either show() or showError()
 *
 * Currently the snackbar provides material icons (will be replaced in the future with ico-icons).
 * You can provide a translation key in the message which will be translated by the snackbar component.
 *
 * The snackbar can contain zero or multiple buttons with a custom callback function.
 *
 * You can close the snackbar with snackbarService.dismiss() (see in example below).
 *
 * e.g.
 * this.snackbarService.show({
 *       icon: 'warning',
 *       message: 'INVENTORY.DELETE_DIALOG.TITLE',
 *       buttons: [
 *         {
 *           label: 'COMMON.CLOSE',
 *           cb: () => this.snackbarService.dismiss(),
 *         },
 *         {
 *           label: 'COMMON.OKAY',
 *           cb: () => {
 *             alert('foobar');
 *           },
 *         },
 *       ],
 *     });
 *
 * NOTE: pass rawMessage in case for non translated value, will avoid missing translation key warning
 */

@Component({
  selector: 'packex-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarProperties) {}

  get hasOneButton() {
    return this.data.buttons && this.data.buttons.length === 1;
  }

  get hasMultipleButtons() {
    return this.data.buttons && this.data.buttons.length > 1;
  }
}
