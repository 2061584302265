import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'packex-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent {
  /** If set to true removes padding from the breadcrumb; used in several components (e.g. Configurator Detail **/
  @Input() inline = false;
}
