<mat-table class="items-list" [dataSource]="dataSource" *ngIf="dataSource">
  <ng-container matColumnDef="user">
    <mat-header-cell *matHeaderCellDef>
      <span
        class="subtitle-2 text-primary-medium">{{ 'COMMON.NAME' | transloco }}</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <packex-user-info [firstName]="element.user.firstName" [lastName]="element.user.lastName"
                        [disabled]="!element.user.isEmailVerified"
                        [subtitle]="element.user.email"></packex-user-info>
      <div class="d-flex d-md-none flex-wrap flex-row">
        <packex-inventory-label [label]="{title: label | transloco}" *ngFor="let label of element.topicLabels"
                                [passive]="true"></packex-inventory-label>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef>
      <span
        class="subtitle-2 text-primary-medium">{{ 'ADDRESS.EMAIL' | transloco }}</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <p class="my-3 my-md-0 text-break text-primary-dark">{{ element.email }}</p>
      <div class="d-flex d-md-none flex-wrap flex-row">
        <packex-inventory-label [label]="{title: label | transloco}" *ngFor="let label of element.topicLabels"
                                [passive]="true"></packex-inventory-label>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="topics">
    <mat-header-cell *matHeaderCellDef>
      <span
        class="subtitle-2 text-primary-medium">{{ 'ORGANIZATION.SUBSCRIBED_STATUS_UPDATES' | transloco }}</span>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" class="flex-wrap">
      <ng-container *ngIf="element.user; else labels">
        <ng-container *ngIf="element.user.isEmailVerified; else sendInvitationButton">
          <ng-container *ngTemplateOutlet="labels"></ng-container>
        </ng-container>
        <ng-template #sendInvitationButton>
          <packex-button *ngIf="showResendButton" type="tertiary" (buttonClick)="resendInvitation(element)"
                         color="secondary"
                         class="w-auto align-self-start align-self-md-center mt-3 mt-md-0"
                         icon="send">
          <span
            class="d-none d-md-block">{{ 'ORGANIZATION.SEND_INVITATION.RESEND_INVITATION_BUTTON' | transloco }}</span>
          </packex-button>
        </ng-template>
      </ng-container>
      <ng-template #labels>
        <ng-container *ngIf="!element.topicLabels.length">
          <packex-button type="tertiary" (buttonClick)="changeStatusUpdates(element)"
                         color="primary-dark"
                         class="w-auto align-self-center"
                         icon="notification_settings">
          <span
            class="d-none d-md-block">{{ 'ORGANIZATION.CHANGE_STATUS_UPDATES' | transloco }}</span>
          </packex-button>
        </ng-container>
        <packex-inventory-label [label]="{title: label | transloco}" *ngFor="let label of element.topicLabels"
                                class="d-none d-md-block"
                                [passive]="true"></packex-inventory-label>
      </ng-template>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef>&nbsp;</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button [matMenuTriggerFor]="menu">
        <packex-icon icon="kebab"></packex-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="changeStatusUpdates(element)"
                disableRipple>{{'ORGANIZATION.CHANGE_STATUS_UPDATES' | transloco}}</button>
        <button mat-menu-item (click)="deleteNotification(element)"
                disableRipple>{{'ACTIONS.DELETE' | transloco}}</button>
      </mat-menu>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
