import { Component, Input } from '@angular/core';
import { LABEL, SHIPPING_BOX } from '@shared/models/construction';
import { InventoryItem } from '@shared/models/inventory-item';

@Component({
  selector: 'packex-inventory-item-detail-card',
  templateUrl: './inventory-item-detail-card.component.html',
  styleUrls: ['./inventory-item-detail-card.component.scss'],
})
export class InventoryItemDetailCardComponent {
  @Input() inventoryItem: InventoryItem | null = null;

  contentVisible = false;

  get showGrammage() {
    return ![LABEL, SHIPPING_BOX].includes(
      this.inventoryItem?.construction?.category || '',
    );
  }

  toggleContent() {
    this.contentVisible = !this.contentVisible;
  }

  public getCoating() {
    return this.inventoryItem?.configurationAttributeValues[0];
  }
}
