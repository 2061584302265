<div class="option-bar mt-2 d-none d-lg-flex align-items-center">
  <packex-search placeholder="INVENTORY.SEARCH.PLACEHOLDER"
                 class="col-lg-3 col-md-4 pe-4"></packex-search>
  <div class="d-none d-xl-flex batch-section ms-4 me-auto align-items-center">
    <mat-checkbox class="mt-1" [checked]="allSelected"
                  (change)="allSelectedChanged.emit($event.checked)"></mat-checkbox>

    <span *ngIf="!allSelected && selectedItemsLength"
          class="subtitle-2">{{ 'COMMON.NUM_SELECTED' | transloco : {amount: selectedItemsLength} }}</span>
    <span *ngIf="!allSelected && !selectedItemsLength"
          class="text-grey-dark">{{ 'COMMON.SELECT_ALL' | transloco }}</span>
    <span *ngIf="allSelected" class="subtitle-2">{{ 'COMMON.ALL_SELECTED' | transloco }}</span>

    <packex-inventory-batch-operations [options]="batchOperations" [showPlaceholder]="true"
                                       label="COMMON.OPTIONS"
                                       [disabled]="!selectedItemsLength"
                                       (currentChange)="onBatchOperationSelected($event)"
                                       class="ms-4 d-flex no-subscription"></packex-inventory-batch-operations>
  </div>

  <packex-inventory-list-sort class="ms-auto me-5 d-none d-lg-flex"></packex-inventory-list-sort>
</div>

<div class="option-bar mt-2 d-flex d-lg-none pe-3">
  <div class="d-flex d-lg-none flex-column w-100">
    <div class="divider py-2 d-flex w-100">
      <packex-search placeholder="INVENTORY.SEARCH.PLACEHOLDER"
                     class="w-100"></packex-search>
      <packex-button class="ms-4 w-auto icon-only" type="primary"
                     icon="add_circle" (buttonClick)="onCreateProduct.emit(true)">
      </packex-button>
    </div>
    <div class="d-flex justify-content-between justify-content-md-end py-2 align-items-center w-100">
      <packex-button type="clear-icon" icon="filter" (buttonClick)="openFilter()"
                     class="col-2 me-3 d-flex d-md-none"></packex-button>
      <packex-inventory-list-sort class="d-flex"></packex-inventory-list-sort>
    </div>
  </div>

  <div class="py-2 justify-content-between d-none d-lg-flex w-100">
    <div class="d-flex w-66">
      <packex-search placeholder="INVENTORY.SEARCH.PLACEHOLDER"
                     class="w-100"></packex-search>
      <packex-button class="ms-4 w-100" type="primary"
                     icon="add_circle" (buttonClick)="onCreateProduct.emit(true)">
        {{ 'INVENTORY.LIST.CREATE_NEW_PRODUCT' | transloco }}
      </packex-button>
    </div>
    <div class="d-flex">
      <packex-inventory-list-sort class="d-flex"></packex-inventory-list-sort>
    </div>
  </div>
</div>
