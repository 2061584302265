import { HttpErrorResponse } from '@angular/common/http';
import { Address } from '@shared/models/address';
import { Configuration } from '@shared/models/configuration';
import { DeliveryCountry } from '@shared/models/delivery-country';
import { DeliveryMethod } from '@shared/models/delivery-method';
import { InventoryItem } from '@shared/models/inventory-item';
import { PaymentMethod } from '@shared/models/paymentMethod';
import { ProductOption } from '@shared/models/product-option';
import { ReferenceCode } from '@shared/models/referenceCode';
import { Variant } from '@shared/models/variant';

export type DeliveryType = 'economy' | 'priority' | 'express';

export enum CartSteps {
  CART,
  ADDRESSES,
  PAYMENT,
  ORDER,
}

export type CartProduct = {
  name: string;
  deliveryCountry: DeliveryCountry;
  deliveryMethod: string;
  estimatedProductionDays: number;
  estimatedShippingDays: number;
  items: CartItem[];
};

export type CartItem = {
  id: string;
  configuration: Configuration;
  deliveryCountry: DeliveryCountry;
  deliveryMethod: DeliveryMethod;
  estimatedProductionDays: number;
  estimatedShippingDays: number;
  createdAt: string;
  price: number;
  unitPrice: number;
  updatedAt: string;
  variant: Variant & {
    inventoryItem: InventoryItem;
  };
  productOptions: ProductOption[];
  customerOrderReference: string;
  customerArticleNumber: string;
  customerArticleDescription: string;
  customerComment: string;
  additionalProductionDays: number | null;
  availableDeliveryMethods: DeliveryMethod[];
  imageUrl: string;
};

export type ModalCartItem = Variant & {
  configuration: Partial<Configuration>;
  configurationError?: {
    fields: [
      {
        field: string;
        type: string;
        limit: number;
        relatedFields: string[];
      },
    ];
    localizedMessage?: string;
    message?: string;
    name?: string;
    status?: number;
    response: HttpErrorResponse;
  };
  price?: number;
  unitPrice?: number;
  productOptions?: ProductOption[];
  additionalProductionDays: number | null;
  availableDeliveryMethods?: DeliveryMethod[];
  variant?: Variant;
};

export type ModalCart = {
  deliveryMethodId: string;
  items: ModalCartItem[];
  loading: boolean;
  loaded: boolean;
};

export type CartVoucher = {
  code: string;
  discount: number;
  type: 'percentage';
};

export type Cart = {
  availablePaymentMethods: PaymentMethod[];
  billingAddress: Address | null;
  deliveryAddress: Address | null;
  grossPrice: number;
  netPrice: number;
  netPriceWithoutVoucher: number;
  netDiscountValue: number;
  taxRate: number;
  taxes: number;
  items: CartItem[];
  step: number;
  invoiceRecipientEmails: string[];
  statusUpdateEmails: string[];
  referenceCodes: ReferenceCode[];
  paymentMethod: PaymentMethod | null;
  voucher: CartVoucher | null;
  whiteLabelShipping: boolean;
};

export const initialModalCart: ModalCart = {
  deliveryMethodId: '',
  items: [],
  loading: false,
  loaded: false,
};

export const initialCart: Cart = {
  availablePaymentMethods: [],
  billingAddress: null,
  deliveryAddress: null,
  grossPrice: 0,
  items: [],
  netPrice: 0,
  netPriceWithoutVoucher: 0,
  netDiscountValue: 0,
  taxRate: 0,
  taxes: 0,
  invoiceRecipientEmails: [],
  statusUpdateEmails: [],
  referenceCodes: [],
  step: 0,
  paymentMethod: null,
  voucher: null,
  whiteLabelShipping: false,
};
