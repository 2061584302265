import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'conditionalPipe',
})
export class ConditionalPipe implements PipeTransform {
  transform(value: any, condition: boolean, transformFunction: any): any {
    if (condition) {
      return transformFunction(value);
    } else {
      return value;
    }
  }
}
