import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { InventoryItemsService } from '@app/core/services/inventory-items.service';
import { mapLabelsToStringArray } from '@app/utils/inventory-items.helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InventoryItem } from '@shared/models/inventory-item';
import { BehaviorSubject, map, startWith } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'packex-create-label',
  templateUrl: './create-label.component.html',
  styleUrls: ['./create-label.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateLabelComponent implements OnInit {
  input = new FormControl('');

  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredLabels$ = new BehaviorSubject<string[]>([]);
  allLabels: string[] = [];
  labels: string[] = [];

  @Input() inventoryItem?: InventoryItem;
  @Input() showLabelNotification = false;
  @Output() labelsChanged = new EventEmitter<string[]>();
  @ViewChild('labelInput') labelInput?: ElementRef<HTMLInputElement>;

  constructor(private readonly inventoryItemsService: InventoryItemsService) {}

  public ngOnInit() {
    this.inventoryItemsService.labels$
      .pipe(untilDestroyed(this))
      .subscribe((labels) => {
        this.allLabels = mapLabelsToStringArray(labels);
      });

    this.inventoryItemsService.loadLabels();

    this.labels = this.inventoryItem?.labels || [];

    this.input.valueChanges
      .pipe(
        untilDestroyed(this),
        startWith(''),
        map((value) => this._filter(value || '')),
      )
      .subscribe((labels) => this.filteredLabels$.next(labels));
  }

  public createLabel(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.add(value);
    }

    // Clear the input value
    event.chipInput?.clear();
  }

  private labelExists(value: string) {
    return !!this.labels.find((label) => label === value);
  }

  private hasLabel(value: string): boolean {
    return !!this.allLabels.find((label) => label === value);
  }

  private add(value: string): void {
    value = value.trim();

    if (value && !this.labelExists(value)) {
      this.labels.push(value);

      this.onLabelsChanged();
    }

    this.input.patchValue('');
    (this.labelInput?.nativeElement as unknown as HTMLInputElement).value = '';
  }

  public remove(label: string): void {
    const index = this.labels.indexOf(label);

    if (index >= 0) {
      this.labels.splice(index, 1);
    }

    this.onLabelsChanged();

    this.filteredLabels$.next(this._filter(''));
  }

  public selected(label: string): void {
    this.input.patchValue('');
    (this.labelInput?.nativeElement as unknown as HTMLInputElement).value = '';

    this.add(label);

    this.onLabelsChanged();
  }

  private onLabelsChanged(): void {
    this.labelsChanged.emit(Array.from(new Set([...this.labels])));
  }

  public onBlur(): void {
    setTimeout(() => {
      const value = this.input.value?.trim();

      if (value && !this.hasLabel(value)) {
        this.add(value);
      }
    }, 0);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allLabels.filter(
      (option) =>
        option.toLowerCase().includes(filterValue) && !this.labelExists(option),
    );
  }
}
