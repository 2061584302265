import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RouterModule } from '@angular/router';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { AccordionStepComponent } from '@shared/components/accordion/accordion-step/accordion-step.component';
import { AccordionComponent } from '@shared/components/accordion/accordion.component';
import { AddressChooseListComponent } from '@shared/components/address-choose-list/address-choose-list.component';
import { AddressComponent } from '@shared/components/address/address.component';
import { AdvertiseBannerComponent } from '@shared/components/advertise-banner/advertise-banner.component';
import { CarouselComponent } from '@shared/components/carousel/carousel.component';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { CreateLabelModalComponent } from '@shared/components/create-label-modal/create-label-modal.component';
import { CreateLabelComponent } from '@shared/components/create-label/create-label.component';
import { CustomTranslateComponent } from '@shared/components/custom-translate/custom-translate.component';
import { EmptyScreenComponent } from '@shared/components/empty-screen/empty-screen.component';
import { ExitIntentModalComponent } from '@shared/components/exit-intent-modal/exit-intent-modal.component';
import { ExitIntentModalTriggerComponent } from '@shared/components/exit-itent-modal-trigger/exit-intent-modal-trigger.component';
import { FileUploadProcessingComponent } from '@shared/components/file-upload-processing/file-upload-processing.component';
import { FileUploadComponent } from '@shared/components/file-upload/file-upload.component';
import { InventoryItemDielineSvgComponent } from '@shared/components/inventory-item-dieline-svg/inventory-item-dieline-svg.component';
import { LanguageSelectModalComponent } from '@shared/components/language-select-modal/language-select-modal.component';
import { ListSortComponent } from '@shared/components/list-sort/list-sort.component';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { OnboardingDialogComponent } from '@shared/components/onboarding-dialog/onboarding-dialog.component';
import { OrderItemDielineSvgComponent } from '@shared/components/order-item-dieline-svg/order-item-dieline-svg.component';
import { CountriesSelectComponent } from '@shared/components/packex-select/countries-select.component';
import { LanguageSelectComponent } from '@shared/components/packex-select/language-select.component';
import { ModalCartItemSelectComponent } from '@shared/components/packex-select/modal-cart-item-select.component';
import { PackexSelectComponent } from '@shared/components/packex-select/packex-select.component';
import { ProgressBarComponent } from '@shared/components/progress-bar/progress-bar.component';
import { ProgressSpinnerComponent } from '@shared/components/progress-spinner/progress-spinner.component';
import { ProgressStepperComponent } from '@shared/components/progress-stepper/progress-stepper.component';
import { SearchComponent } from '@shared/components/search/search.component';
import { SnackbarComponent } from '@shared/components/snackbar/snackbar.component';
import { TooltipComponent } from '@shared/components/tooltip/tooltip.component';
import { BreadcrumbComponent } from '@shared/components/ui/breadcrumb/breadcrumb.component';
import { ButtonComponent } from '@shared/components/ui/button/button.component';
import { DividerComponent } from '@shared/components/ui/divider/divider.component';
import { DownloadButtonComponent } from '@shared/components/ui/download-button/download-button.component';
import { DownloadCardComponent } from '@shared/components/ui/download-card/download-card.component';
import { LabelComponent } from '@shared/components/ui/label/label.component';
import { ListComponent } from '@shared/components/ui/list/list.component';
import { SliderComponent } from '@shared/components/ui/slider/slider.component';
import { WellNotificationComponent } from '@shared/components/well-notification/well-notification.component';
import { NumbersOnlyDirective } from '@shared/directives/numbers-only.directive';
import { MaterialModule } from '@shared/material.module';
import { FormatPricePipe } from '@shared/pipes/format-price.pipe';
import { HighlightSearchPipe } from '@shared/pipes/highlight-search.pipe';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { InViewportModule } from 'ng-in-viewport';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { IconComponent } from './components/icon/icon.component';
import { CardComponent } from './components/ui/card/card.component';
import { ExpansionPanelComponent } from './components/ui/expansion-panel/expansion-panel.component';
import { PageComponent } from './components/ui/page/page.component';
import { PaginationComponent } from './components/ui/pagination/pagination.component';
import { UserInfoComponent } from './components/ui/user-info/user-info.component';
import { ConditionalPipe } from '@shared/pipes/conditional.pipe';
import { ConfiguratorInputComponent } from '@shared/components/ui/configurator-input/configurator-input.component';
import { CrossSellingDialogComponent } from '@shared/components/cross-selling-dialog/cross-selling-dialog.component';
import { ScaledImgComponent } from '@shared/components/scaled-img/scaled-img.component';

@NgModule({
  declarations: [
    SliderComponent,
    LabelComponent,
    ListComponent,
    DividerComponent,
    BreadcrumbComponent,
    AccordionComponent,
    AccordionStepComponent,
    ButtonComponent,
    NumbersOnlyDirective,
    CardComponent,
    PageComponent,
    PaginationComponent,
    ExpansionPanelComponent,
    UserInfoComponent,
    IconComponent,
    DownloadCardComponent,
    DownloadButtonComponent,
    ProgressBarComponent,
    ProgressStepperComponent,
    WellNotificationComponent,
    SnackbarComponent,
    CreateLabelComponent,
    CreateLabelModalComponent,
    CustomTranslateComponent,
    SafeHtmlPipe,
    PackexSelectComponent,
    TooltipComponent,
    InventoryItemDielineSvgComponent,
    OrderItemDielineSvgComponent,
    ConfirmDialogComponent,
    ModalCartItemSelectComponent,
    CountriesSelectComponent,
    LanguageSelectComponent,
    LanguageSelectModalComponent,
    ModalComponent,
    SearchComponent,
    ListSortComponent,
    EmptyScreenComponent,
    HighlightSearchPipe,
    AddressComponent,
    AddressChooseListComponent,
    ProgressSpinnerComponent,
    LoadingComponent,
    OnboardingDialogComponent,
    FormatPricePipe,
    AdvertiseBannerComponent,
    CarouselComponent,
    ExitIntentModalComponent,
    ExitIntentModalTriggerComponent,
    FileUploadComponent,
    FileUploadProcessingComponent,
    ConditionalPipe,
    ConfiguratorInputComponent,
    CrossSellingDialogComponent,
    ScaledImgComponent,
  ],
  imports: [
    MaterialModule,
    FormsModule,
    RouterModule,
    CommonModule,
    NgxSkeletonLoaderModule,
    TranslocoModule,
    NgxPaginationModule,
    BreadcrumbModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    InViewportModule,
    NgbCarouselModule,
    NgOptimizedImage,
  ],
  exports: [
    SliderComponent,
    LabelComponent,
    ListComponent,
    DividerComponent,
    IconComponent,
    ButtonComponent,
    BreadcrumbComponent,
    AccordionComponent,
    AccordionStepComponent,
    NumbersOnlyDirective,
    CardComponent,
    PageComponent,
    PaginationComponent,
    ExpansionPanelComponent,
    UserInfoComponent,
    SnackbarComponent,
    DownloadCardComponent,
    DownloadButtonComponent,
    ProgressBarComponent,
    ProgressStepperComponent,
    WellNotificationComponent,
    CreateLabelComponent,
    CreateLabelModalComponent,
    CustomTranslateComponent,
    SafeHtmlPipe,
    PackexSelectComponent,
    TooltipComponent,
    ModalCartItemSelectComponent,
    CountriesSelectComponent,
    InventoryItemDielineSvgComponent,
    OrderItemDielineSvgComponent,
    ConfirmDialogComponent,
    ModalComponent,
    SearchComponent,
    ListSortComponent,
    EmptyScreenComponent,
    HighlightSearchPipe,
    AddressComponent,
    AddressChooseListComponent,
    ProgressSpinnerComponent,
    LoadingComponent,
    OnboardingDialogComponent,
    FormatPricePipe,
    AdvertiseBannerComponent,
    CarouselComponent,
    LanguageSelectComponent,
    LanguageSelectModalComponent,
    ExitIntentModalComponent,
    ExitIntentModalTriggerComponent,
    FileUploadComponent,
    FileUploadProcessingComponent,
    ConditionalPipe,
    ConfiguratorInputComponent,
    CrossSellingDialogComponent,
    ScaledImgComponent,
  ],
})
export class PackexModule {}
