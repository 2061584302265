<div class="mobile-menu menu bg-white">
  <ng-container *ngIf="!headerElementsVisible.product">
    <ul>
      <li class="justify-content-between">
        <a [routerLink]="'constructions'"
           class="d-flex button-navigation text-primary-dark pe-0">{{ 'DEFAULT.MAIN_NAVIGATION.PRODUCTS' | transloco }}
        </a>
        <packex-icon icon="right" class="mx-3 cursor-pointer"
                     (click)="toggleProductsFoldout()"></packex-icon>
      </li>

      <li><a [routerLink]="'inventory'"
             class="button-navigation text-primary-dark">{{ 'DEFAULT.MAIN_NAVIGATION.MY_PRODUCTS' | transloco }}</a>
      </li>
      <li><a [routerLink]="'orders'"
             class="button-navigation text-primary-dark">{{ 'DEFAULT.MAIN_NAVIGATION.MY_ORDERS' | transloco }}</a>
      </li>
      <li *ngIf="!loggedIn"><a (click)="login()"
             class="button-navigation text-primary-dark">{{ 'COMMON.LOGIN' | transloco }}</a>
      </li>
    </ul>
    <div class="border-top border-grey-medium d-flex p-3 ps-8">
      <div (click)="openMusterboxLink()">
        <img alt="{{ 'PRODUCT_FOLDOUT.SAMPLE_BOX' | transloco }}" class="product-foldout-image mb-2"
             src="assets/images/menu/musterbox.jpg"/>
        <p class="text-primary-medium">{{ 'PRODUCT_FOLDOUT.SAMPLE_BOX' | transloco }}</p>
      </div>
      <div class="ms-4" (click)="openRequestConstructionModal()">
        <img alt="{{ 'PRODUCT_FOLDOUT.CUSTOM_CONSTRUCTION' | transloco }}" class="product-foldout-image mb-2"
             src="assets/images/menu/marketing.jpg"/>
        <p class="text-primary-medium">{{ 'PRODUCT_FOLDOUT.CUSTOM_CONSTRUCTION' | transloco }}</p>
      </div>
    </div>

    <div class="border-top border-grey-medium d-flex p-3 ps-8">
      <packex-header-language></packex-header-language>
    </div>
  </ng-container>
  <ng-container *ngIf="headerElementsVisible.product">
    <div class="d-flex products-foldout-toggle align-items-center">
      <packex-icon icon="left" class="ms-3 cursor-pointer"
                   (click)="toggleProductsFoldout()"></packex-icon>
      <a [routerLink]="'constructions'"
         class="d-flex button-navigation text-primary-dark pe-0">
        {{ 'DEFAULT.MAIN_NAVIGATION.PRODUCTS' | transloco }}
      </a>
    </div>
    <packex-products-foldout class="position-relative ms-8 d-block"
                             [mobileView]="true"></packex-products-foldout>
  </ng-container>
</div>
