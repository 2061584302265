import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InvitationService } from '@app/core/services/invitation.service';
import { NotificationService } from '@app/core/services/notification.service';
import { UserService } from '@app/core/services/user.service';
import { OrganizationInviteWithStatusUpdatesModalComponent } from '@modules/organization/organization-invite-with-status-updates-modal/organization-invite-with-status-updates-modal.component';
import { Invitation } from '@shared/models/invitation';
import { Notification } from '@shared/models/notification';
import { User } from '@shared/models/user';
import { orderBy } from 'lodash';
import { combineLatestWith } from 'rxjs';

@Component({
  selector: 'packex-organization-colleagues',
  templateUrl: './organization-colleagues.component.html',
  styleUrls: ['./organization-colleagues.component.scss'],
})
export class OrganizationColleaguesComponent implements OnInit {
  notificationsWithUsers?: Notification[];
  notificationsWithOutUsers?: Notification[];

  invitations: Invitation[] = [];

  constructor(
    private readonly userService: UserService,
    private readonly notificationService: NotificationService,
    private readonly invitationService: InvitationService,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.loadInvitations();
  }

  public loadInvitations() {
    this.invitationService.load().subscribe((invitations) => {
      this.invitations = orderBy(invitations, 'email', 'asc');

      this.loadNotifications();
    });
  }

  public loadNotifications() {
    this.notificationsWithUsers = undefined;
    this.notificationsWithOutUsers = undefined;

    this.notificationService
      .load()
      .pipe(combineLatestWith(this.userService.loadUsers()))
      .subscribe(([notifications, users]) => {
        this.notificationsWithUsers = users.map((user: User) => {
          const notification = user.notifications;
          return {
            id: notification?.id || null,
            email: user.email || '',
            user,
            topics: notification?.topics || [],
          };
        });

        this.notificationsWithOutUsers =
          notifications.filter(
            (notification: Notification) => !notification.user,
          ) || [];
      });
  }

  public inviteColleague(): void {
    this.dialog
      .open(OrganizationInviteWithStatusUpdatesModalComponent, {
        data: {
          title: 'ORGANIZATION.INVITE_COLLEAGUES.TITLE',
          description: 'ORGANIZATION.INVITE_COLLEAGUES.DESCRIPTION',
          settingsDescription:
            'ORGANIZATION.INVITE_COLLEAGUES.SETTINGS_DESCRIPTION',
          settingsHint: 'ORGANIZATION.INVITE_COLLEAGUES.SETTINGS_HINT',
          button: 'ORGANIZATION.INVITE_COLLEAGUES.BUTTON',
          invite: true,
        },
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) this.loadInvitations();
      });
  }

  public inviteStatusReceiver(): void {
    this.dialog
      .open(OrganizationInviteWithStatusUpdatesModalComponent, {
        data: {
          title: 'ORGANIZATION.INVITE_STATUS_RECEIVER.TITLE',
          description: 'ORGANIZATION.INVITE_STATUS_RECEIVER.DESCRIPTION',
          settingsDescription:
            'ORGANIZATION.INVITE_STATUS_RECEIVER.SETTINGS_DESCRIPTION',
          button: 'ORGANIZATION.INVITE_STATUS_RECEIVER.BUTTON',
          invite: false,
        },
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) this.loadInvitations();
      });
  }
}
