<ng-container *ngIf="inventoryItem">
  <div class="wrapper">
    <packex-inventory-item-dieline-svg [inventoryItem]="inventoryItem"
                                       class="mt-4 mb-4 col-3 col-md-6 m-auto me-3 me-md-auto"></packex-inventory-item-dieline-svg>
    <div class="col-6 col-md-12 d-flex flex-column justify-content-center align-items-start">
      <span class="subtitle-1 text-break inventory-item-name">{{ inventoryItem.name }}</span>
      <p
        class="caption text-primary-light">{{inventoryItem.sku}}</p>
    </div>
    <div class="col-3 align-items-center justify-content-center toggle-content-button">
      <packex-button (click)="toggleContent()" icon="down" type="clear-icon"></packex-button>
    </div>
  </div>

  <div [ngClass]="{'d-block': contentVisible, 'd-none': !contentVisible}" class="content">
    <div
      class="subtitle-2 text-primary-medium mb-2 mt-4">{{ 'INVENTORY.LIST.ITEM.DETAILS.DIMENSIONS' | transloco }}</div>
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-between mb-1">
        <div class="subtitle-2">{{ 'CONFIGURATION.FORMAT.WIDTH' | transloco }}</div>
        <div class="caption text-primary-medium">{{inventoryItem.a}} mm</div>
      </div>
      <div class="d-flex justify-content-between mb-1" *ngIf="inventoryItem.construction.category !== 'label'">
        <div class="subtitle-2">{{ 'CONFIGURATION.FORMAT.DEPTH' | transloco }}</div>
        <div class="caption text-primary-medium">{{inventoryItem.b}} mm</div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="subtitle-2">{{ 'CONFIGURATION.FORMAT.HEIGHT' | transloco }}</div>
        <div class="caption text-primary-medium">{{inventoryItem.h}} mm</div>
      </div>
    </div>

    <div class="subtitle-2 text-primary-medium mb-2 mt-4">
      <span
        *ngIf="inventoryItem.construction.category !== 'shipping_box'">{{ 'INVENTORY.LIST.ITEM.DETAILS.MATERIAL' | transloco }}</span>
      <span
        *ngIf="inventoryItem.construction.category === 'shipping_box'">{{ 'CONFIGURATION.COLOR.TITLE' | transloco }}</span>
    </div>
    <div class="subtitle-2 mb-1">{{ inventoryItem.material.materialGroup.name }}</div>
    <div class="caption text-primary-medium" *ngIf="showGrammage">{{ inventoryItem.material.grammage }} g/m2</div>

    <ng-container *ngIf="inventoryItem.configurationAttributeValues.length">
      <div class="subtitle-2 text-primary-medium mb-2 mt-4">
        <span>{{ getCoating()?.attribute?.name }}</span>
      </div>
      <div class="subtitle-2">
        {{ getCoating()?.name }}
      </div>
    </ng-container>

    <ng-container *ngIf="inventoryItem.winding">
      <div class="subtitle-2 text-primary-medium mb-2 mt-4">
        <span>{{ 'CONFIGURATION.WINDING.TITLE' | transloco }}</span>
      </div>
      <div class="subtitle-2">
        {{ 'CONFIGURATION.WINDING.' + inventoryItem.winding?.toUpperCase() | transloco }}
      </div>
      <span class="caption text-primary-medium">{{ inventoryItem.rollDiameter }}mm ({{ 'CONFIGURATION.WINDING.ROLL' | transloco }})</span>
    </ng-container>

    <ng-container *ngIf="inventoryItem.productOptions.length">
      <div
        class="subtitle-2 text-primary-medium mb-2 mt-4">{{ 'INVENTORY.LIST.ITEM.DETAILS.SERVICES' | transloco }}</div>
      <div class="subtitle-2 mb-1" *ngFor="let service of inventoryItem.productOptions">{{ service.name }}</div>
    </ng-container>
  </div>
</ng-container>
