import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PreviousRouteService {
  private previousUrl = '';
  private currentUrl = '';

  constructor(private readonly router: Router) {
    this.currentUrl = this.router.url;

    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise(),
      )
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects;
        this.currentUrl = events[1].urlAfterRedirects;
      });
  }

  get getPreviousUrl(): string {
    return this.previousUrl;
  }
}
