<div class="{{bgColor}} d-flex {{padding}} {{width}} {{sizeClass}}" *ngIf="visible">
  <packex-icon *ngIf="icon" [icon]="icon" size="l" class="me-2 align-self-start" [color]="iconColor"></packex-icon>
  <div class="content">
    <div class="w-100" [ngClass]="{'w-100': !showCloseButton}">
      <span class="subtitle-2 text-uppercase" [ngClass]="textColor" *ngIf="showNewText">{{ 'COMMON.NEW' | transloco }}:&nbsp;</span>
      <ng-content></ng-content>
    </div>
    <packex-button *ngIf="buttonText"
                   class="mt-3 d-block w-auto"
                   buttonPadding="py-2 px-3"
                   type="secondary"
                   [color]="buttonColor"
                   (buttonClick)="buttonClicked.emit(true)">
      {{ buttonText }}
    </packex-button>
  </div>
  <packex-icon icon="close" size="m" (click)="close()" class="ms-6 cursor-pointer align-self-start"
               *ngIf="showCloseButton"></packex-icon>
</div>
