import { FilterItem } from '@modules/filter/models/filter-item';
import { ConstructionType } from '@shared/models/construction';

export const mapProductFilterType = (
  type: ConstructionType,
  selectedItems: string[],
): FilterItem => {
  const name = `PRODUCT_FILTER.TYPE.${type}`;
  const active = selectedItems.includes(name);

  switch (type) {
    case ConstructionType.Label:
      return {
        image: `/assets/images/category-images/etiketten.png`,
        name,
        type,
        backgroundColor: '#40A8CD',
        active,
      };
    case ConstructionType.Leaflet:
      return {
        image: `/assets/images/category-images/beipackzettel.png`,
        name,
        type,
        backgroundColor: '#8E87A5',
        active,
      };
    case ConstructionType.Calendar:
      return {
        image: `/assets/images/category-images/calendar.png`,
        name,
        type,
        backgroundColor: '#8F91CB',
        active,
      };
    case ConstructionType.ShippingBox:
      return {
        image: `/assets/images/category-images/versandverpackung.png`,
        name,
        type,
        backgroundColor: '#DCEEF7',
        active,
      };
    case ConstructionType.FoldingBox:
      return {
        image: `/assets/images/category-images/faltschachteln.png`,
        name,
        type,
        backgroundColor: '#f5cd77',
        active,
      };
    case ConstructionType.CardingBox:
      return {
        image: `/assets/images/category-images/krempelschachteln.png`,
        name: `PRODUCT_FILTER.TYPE.${type}`,
        type,
        backgroundColor: '#afa5ce',
        active,
      };
    case ConstructionType.Slipcase:
      return {
        image: `/assets/images/category-images/schuber.png`,
        name: `PRODUCT_FILTER.TYPE.${type}`,
        type,
        backgroundColor: '#45829d',
        active,
      };
    case ConstructionType.BottleBox:
      return {
        image: `/assets/images/category-images/flaschenschachtel.png`,
        name: `PRODUCT_FILTER.TYPE.${type}`,
        type,
        backgroundColor: '#353463',
        active,
      };
    case ConstructionType.PillowBox:
      return {
        image: `/assets/images/category-images/kissenschachtel.png`,
        name: `PRODUCT_FILTER.TYPE.${type}`,
        type,
        backgroundColor: '#fcf2de',
        active,
      };
    case ConstructionType.Display:
      return {
        image: `/assets/images/category-images/thekendisplay.png`,
        name: `PRODUCT_FILTER.TYPE.${type}`,
        type,
        backgroundColor: '#bddced',
        active,
      };
    case ConstructionType.PouchBox:
      return {
        image: `/assets/images/category-images/beutelschachtel.png`,
        name: `PRODUCT_FILTER.TYPE.${type}`,
        type,
        backgroundColor: '#6db8d2',
        active,
      };
    default:
      return {
        image: `/assets/images/category-images/unknown.png`,
        name: `COMMON.UNKNOWN`,
        type,
        backgroundColor: '#fff',
        active,
      };
  }
};

export const mapProductFilterTopic = (
  topic: string,
  selectedItems: string[],
): FilterItem => {
  const name = `PRODUCT_FILTER.TOPICS.${topic}`;
  const active = selectedItems.includes(name);

  return {
    name,
    type: topic,
    active,
  };
};

export const mapProductFilterLocking = (type: string): FilterItem => {
  return {
    name: `PRODUCT_FILTER.LOCKING_TYPE.${type}`,
    type,
    active: false,
  };
};

export const mapProductFilterWeight = (type: ConstructionType): FilterItem => {
  return {
    name: `PRODUCT_FILTER.WEIGHT.${type}`,
    type,
    active: false,
  };
};
