<packex-modal  (onDismiss)="dismiss()">
  <h5 class="modal-title">{{ config?.title | transloco }}</h5>

  <div class="modal-body">
    <packex-address-choose-list [config]="config"
                                [addresses]="addresses"
                                [current]="dialogData.current"
                                (onLoad)="loadAddresses()"
                                (onCreate)="onCreate($event)"
                                (onChoose)="chooseAddress($event)"
    ></packex-address-choose-list>
  </div>
</packex-modal>
