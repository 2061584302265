import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserverService } from '@app/core/services/breakpoint-observer.service';
import { ROUTES } from '@app/routes';
import { CART_STEPS, CartStep } from '@modules/cart/cart-routing.module';
import { CartService } from '@modules/cart/cart.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Step } from '@shared/components/progress-stepper/progress-stepper.component';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';
import { TitleService } from '@shared/services/title.service';
import { distinctUntilChanged, map } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'packex-cart-page',
  templateUrl: './cart-page.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class CartPageComponent implements OnInit {
  @ViewChild('scrollTo') scrollTo?: ElementRef;
  loading$ = this.cartService.orderCreating$;

  currentStep = 0;

  steps: Step[] = [
    {
      label: 'CART.CART',
    },
    {
      label: 'CART.ADDRESS_AND_ADDITIONAL_DATA.TITLE',
    },
    {
      label: 'CART.PAYMENT.TITLE',
    },
    {
      label: 'CART.CHECK_AND_ORDER.TITLE',
    },
  ];

  constructor(
    public readonly cartService: CartService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private breakpointObserver: BreakpointObserverService,
    private readonly titleService: TitleService,
    private readonly snackbarService: SnackBarService,
    private readonly gtm: GoogleTagService,
  ) {}

  ngOnInit() {
    this.route.params.pipe(untilDestroyed(this)).subscribe(({ step }) => {
      this.titleService.setTitle(`PAGES.CART.${step.toUpperCase()}`);
      this.currentStep = this.getStepNumberByCartStep(step);

      if (this.currentStep === 0) {
        this.gtm.push('begin_checkout');
      }
      // not persist to api
      this.cartService.update({ step: this.currentStep }, false);

      document.body.scrollIntoView();
    });

    this.cartService.cart
      .pipe(
        untilDestroyed(this),
        map((cart) => cart.step),
        distinctUntilChanged(),
      )
      .subscribe(async (step) => {
        await this.cartService.loadCart();
        this.changeUrlByStepNumber(step);
      });

    this.cartService.orderCreateError$
      .pipe(untilDestroyed(this))
      .subscribe((hasError) => {
        if (hasError) {
          this.snackbarService.showSimpleError('ORDER.CREATE_ERROR');
          this.cartService.orderCreateError$.next(false);
        }
      });
  }

  get cartTitle(): string {
    return this.steps[this.currentStep].label || 'CART.CART';
  }

  private changeUrlByStepNumber(step: number) {
    const cartStep: CartStep = this.getStepByStepNumber(step);

    if (cartStep !== this.route.snapshot.params['step']) {
      this.router.navigateByUrl(`${ROUTES.CART}/${cartStep}`).then();
    }
  }

  private getStepNumberByCartStep(cartStep: CartStep): number {
    return Object.values(CART_STEPS).indexOf(cartStep);
  }

  private getStepByStepNumber(index: number): CartStep {
    return Array.from(Object.values(CART_STEPS))[index] as CartStep;
  }

  private backToProductList(): void {
    this.router.navigateByUrl(`/${ROUTES.INVENTORY}`);
  }

  public onStepClicked($event: number): void {
    this.cartService.update(
      {
        step: $event,
      },
      false,
    );
  }

  public back(): void {
    const step = this.cartService.cartValue.step;

    if (step === 0) {
      this.backToProductList();
    } else {
      this.onStepClicked(step - 1);
    }
  }
}
