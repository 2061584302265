import { SelectOption } from '@shared/components/packex-select/packex-select.component';

export type SortOrder = 'DESC' | 'ASC';
export type SortOptionType = 'createdAt' | 'updatedAt' | 'name';

export interface SortOption extends SelectOption {
  sort: SortOptionType;
  order: SortOrder;
  default?: boolean;
  identifier?: string;
}

export const defaultInventoryItemSort: SortOption = {
  title: 'SORT.UPDATED_AT',
  order: 'DESC',
  sort: 'updatedAt',
  titleAppendix: 'SORT.ORDER_DESC',
  identifier: 'SORT.UPDATED_AT.DESC',
  visible: true,
};

export const defaultOrderSort: SortOption = {
  title: 'SORT.UPDATED_AT',
  order: 'DESC',
  sort: 'createdAt',
  titleAppendix: 'SORT.ORDER_DESC',
};
