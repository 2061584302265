import { Component, OnInit } from '@angular/core';
import { OrganizationService } from '@app/core/services/organization.service';
import { UserService } from '@app/core/services/user.service';
import {
  billingAddressConfig,
  deliveryAddressConfig,
} from '@shared/address-config';
import { ChooseAddressConfig } from '@modules/cart/choose-address-modal/choose-address-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { Address } from '@shared/models/address';
import { AddressService } from '@shared/services/address.service';
import { tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'packex-organization-addresses',
  templateUrl: './organization-addresses.component.html',
  styleUrls: ['./organization-addresses.component.scss'],
})
export class OrganizationAddressesComponent implements OnInit {
  billingAddressConfig: ChooseAddressConfig = {
    ...billingAddressConfig,
    showSetStandard: true,
  };
  deliveryAddressConfig: ChooseAddressConfig = {
    ...deliveryAddressConfig,
    showSetStandard: true,
  };

  billingAddresses: Address[] = [];
  deliveryAddresses: Address[] = [];

  defaultBillingAddress?: Address;
  defaultDeliveryAddress?: Address;

  constructor(
    private readonly addressService: AddressService,
    private readonly organizationService: OrganizationService,
    private readonly userService: UserService,
    private readonly snackbarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.userService.user$.pipe(untilDestroyed(this)).subscribe(() => {
      this.loadAddresses();
    });
  }

  public reload(): void {
    this.userService.load().subscribe();
  }

  public loadAddresses(): void {
    this.addressService
      .load()
      .pipe(
        tap((addresses) => {
          this.billingAddresses = addresses.filter(
            (address: Address) => address.validBillingAddress,
          );
          this.deliveryAddresses = addresses.filter(
            (address: Address) => !address.validBillingAddress,
          );

          this.defaultBillingAddress = this.billingAddresses.find(
            (x) =>
              x.id === this.userService.user?.organization.billingAddress?.id,
          );

          this.defaultDeliveryAddress = this.deliveryAddresses.find(
            (x) =>
              x.id === this.userService.user?.organization.deliveryAddress?.id,
          );
        }),
      )
      .subscribe();
  }

  public setStandardDeliveryAddress(address: Address): void {
    this.organizationService
      .setDefaultDeliveryAddress(address.id)
      .subscribe(() => {
        this.snackbarService.showSimpleSuccess(
          'USER.ORGANIZATION.DELIVERY_ADDRESS_CHANGED',
        );
        this.reload();
      });
  }

  public setStandardBillingAddress(address: Address) {
    this.organizationService
      .setDefaultBillingAddress(address.id)
      .subscribe(() => {
        this.snackbarService.showSimpleSuccess(
          'USER.ORGANIZATION.BILLING_ADDRESS_CHANGED',
        );
        this.reload();
      });
  }
}
