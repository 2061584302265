<packex-modal size="lg" (onDismiss)="dismiss()">
  <h5 class="modal-title">{{ 'CROSS-SELLING.TITLE' | transloco }}</h5>
  <div class="modal-body">
    <div class="options_container">
      <div *ngFor="let category of categories">
        <img [src]="category.image" alt="category.title" [style]="{ 'background-color': category.backgroundColor}">
        <h6>{{ 'PRODUCT_FILTER.TYPE.' + category.title | transloco }}</h6>
        <p>{{ 'CROSS-SELLING.' + category.description | transloco }}</p>
        <packex-button (click)="openCategory(category)">{{ ('CROSS-SELLING.' + category.title + '_BUTTON').toUpperCase() | transloco }}
        </packex-button>
      </div>
    </div>
  </div>
</packex-modal>
