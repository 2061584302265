import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { UserService } from '@app/core/services/user.service';
import { filterTypes } from '@modules/filter/models/filter-category';
import { FilterService } from '@modules/filter/services/filter.service';
import { PagedResult } from '@shared/models/paged-result';
import { Product } from '@shared/models/product';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    private readonly apiService: ApiService,
    private readonly filterService: FilterService,
    private readonly userService: UserService,
  ) {}

  public findAll(): Observable<PagedResult<Product>> {
    const params = filterTypes
      .map((type) => {
        const activeFilter = this.filterService.getActiveFilterNameByType(type);
        const filterType = type === 'weight' ? 'maxWeight' : type;
        if (activeFilter.length) {
          return `filters[${filterType}]=${activeFilter.join(',')}`;
        } else if (type === 'isPrivate' && this.userService.user) {
          const isPrivateFilter = this.filterService.getIsPrivateFilter();
          return `filters[${filterType}]=${isPrivateFilter?.active}`;
        }
        return null;
      })
      .filter((x) => !!x);

    params.push('limit=1000');
    return this.apiService.get(`products?${params.join('&')}`);
  }
}
