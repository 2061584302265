import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { validatePassword } from '@app/utils/validators';

export type ChangePasswordForm = {
  oldPassword?: FormControl;
  password: FormControl;
  repeatPassword: FormControl;
};

@Component({
  selector: 'packex-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
})
export class ChangePasswordFormComponent {
  @Input() form = new FormGroup<ChangePasswordForm>({
    password: new FormControl('', validatePassword),
    repeatPassword: new FormControl('', validatePassword),
  });

  hideOldPassword = true;
  hidePassword = true;
  hideRepeatPassword = true;

  get passwordsNotEqual(): boolean {
    return (
      this.form.get('password')?.value !==
      this.form.get('repeatPassword')?.value
    );
  }
}
