import { Component, OnInit } from '@angular/core';
import { LanguageService } from '@app/core/services/language.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'packex-user-login',
  template: '',
})
export class UserLoginComponent implements OnInit {
  constructor(
    private readonly keycloakService: KeycloakService,
    private readonly languageService: LanguageService,
  ) {}

  ngOnInit() {
    (async () => {
      await this.keycloakService.login({
        locale: this.languageService.currentLanguage(),
      });
    })();
  }
}
