<div class="modal modal-{{size}}" [ngClass]="{'buttons-display-row': showButtonsInRow }">
  <div class="modal-dialog modal-{{size}} modal-fullscreen-{{startFullScreen}}-down" [ngClass]="{'modal-dialog-scrollable': isScrollable}">
    <div class="modal-content">
      <div class="modal-header">
        <ng-container *ngIf="showTitle">
          <ng-content select=".modal-title"></ng-content>
        </ng-container>
        <packex-button class="ms-4 close-btn" (buttonClick)="onDismiss.emit(false)" icon="close"
                       [attr.data-testid]="'close-button'"
                       type="clear-icon"></packex-button>
      </div>

      <ng-content select=".modal-body"></ng-content>

      <div class="modal-footer box-shadow" *ngIf="showButtons">
        <ng-content select="packex-button[type=secondary]"></ng-content>
        <ng-content select="packex-button[type=tertiary]"></ng-content>
        <ng-content select="packex-button"></ng-content>
      </div>
    </div>
  </div>
</div>
