import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';
import { UserNotification } from '@shared/models/notification';
import { map, Observable } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'packex-notification-foldout',
  templateUrl: './notification-foldout.component.html',
  styleUrls: ['./notification-foldout.component.scss'],
})
export class NotificationFoldoutComponent implements OnInit {
  @Output() close = new EventEmitter();

  private entered = false;
  notifications: UserNotification[] = [];

  constructor(
    private readonly notificationService: NotificationService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.getNotifications().subscribe(
      (notifications) => (this.notifications = notifications),
    );
  }

  private getNotifications(): Observable<UserNotification[]> {
    const getDaysPassed = (date: Date) => {
      return moment().diff(date, 'days');
    };

    return this.notificationService.userNotifications$.asObservable().pipe(
      map((notifications) =>
        notifications.map((notification) => ({
          ...notification,
          daysPassed: getDaysPassed(new Date(notification.createdAt)),
        })),
      ),
    );
  }

  public async handleNotificationClick(notification: UserNotification) {
    await this.router.navigateByUrl(notification.targetPath);
    this.close.emit();
  }

  @HostListener('mouseover')
  public onMouseOver() {
    this.entered = true;
  }

  @HostListener('mouseleave')
  public onMouseLeave() {
    if (this.entered) {
      this.close.emit();
    }
  }
}
