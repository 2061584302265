import { Component } from '@angular/core';
import {
  getPaymentMethodTranslation,
  paymentMethodOnlyPrepaidAvailable,
} from '@app/utils/cart.helper';
import { CartService } from '@modules/cart/cart.service';
import { PaymentMethod } from '@shared/models/paymentMethod';

@Component({
  selector: 'packex-cart-payment',
  templateUrl: './cart-payment.component.html',
  styleUrls: ['./cart-payment.component.scss'],
})
export class CartPaymentComponent {
  getPaymentMethodTranslation = getPaymentMethodTranslation;
  constructor(private readonly cartService: CartService) {}

  get availablePaymentOptions() {
    return this.cartService.cartValue.availablePaymentMethods;
  }

  public isActive(paymentMethod: PaymentMethod): boolean {
    return this.cartService.cartValue.paymentMethod?.id === paymentMethod.id;
  }

  public choosePaymentMethod(paymentMethod: PaymentMethod): void {
    this.cartService.update({ paymentMethod }, true);
  }

  public get onlyHasPrepaid(): boolean {
    return paymentMethodOnlyPrepaidAvailable(this.availablePaymentOptions);
  }
}
