import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';
import { UserService } from '@app/core/services/user.service';
import { ROUTES, USER_ROUTES } from '@app/routes';
import { translate } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EmptyScreenConfig } from '@shared/components/empty-screen/empty-screen.component';
import { NotificationTopic } from '@shared/models/notification';
import { combineLatestWith } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'packex-unsubscribe-notification',
  templateUrl: './unsubscribe-notification.component.html',
  styleUrls: ['./unsubscribe-notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UnsubscribeNotificationComponent implements OnInit {
  notificationId?: string;
  topic?: NotificationTopic;

  config: EmptyScreenConfig = {
    asset: 'unsubscribe.svg',
    title: 'ORGANIZATION.UNSUBSCRIBE_NOTIFICATION.TITLE',
    content: '',
    rawContent: '',
    button: 'USER.ORGANIZATION.PAGE_TITLE',
  };

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly userService: UserService,
  ) {}

  ngOnInit() {
    this.activatedRoute.params
      .pipe(untilDestroyed(this), combineLatestWith(this.userService.load()))
      .subscribe(([{ notificationId, topic }, user]) => {
        this.notificationId = notificationId;
        this.topic = topic;

        this.config.rawContent = translate(
          'ORGANIZATION.UNSUBSCRIBE_NOTIFICATION.CONTENT',
          {
            topic: translate(`ORDER_STATUS.${topic.toUpperCase()}`),
            email: user.email,
          },
        );
        this.unsubscribe();
      });
  }

  private unsubscribe() {
    if (this.notificationId && this.topic) {
      this.notificationService
        .unsubscribe(this.notificationId, this.topic)
        .subscribe();
    }
  }

  public submit(): void {
    this.router
      .navigateByUrl(`${ROUTES.USER}/${USER_ROUTES.ORGANIZATION}`)
      .then();
  }
}
