import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@app/core/services/user.service';

@Component({
  selector: 'packex-post-received',
  templateUrl: './post-received.component.html',
  styleUrls: ['./post-received.component.scss'],
})
export class PostReceivedComponent implements OnInit {
  email?: string;

  constructor(
    private readonly userService: UserService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(({ email }) => (this.email = email));
  }

  public resend(): void {
    this.userService.resetPassword(this.email as string).subscribe(() => {});
  }
}
