import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

export type PaginationChangedEvent = {
  page: number;
  itemsPerPage: number;
};

const ITEMS_PER_PAGE_KEY = 'itemsPerPage';

@Component({
  selector: 'packex-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaginationComponent {
  @Input() paginationId: string | undefined;
  @Input() loading = false;
  @Input() autoHide = false;
  @Input() itemsPerPageLabel: string | undefined;
  @Input() totalItems?: number;
  @Input() currentPage?: number;
  @Input() itemsPerPage: number = PaginationComponent.getDefaultItemsPerPage();
  @Output() paginationChanged = new EventEmitter<PaginationChangedEvent>();
  ITEMS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100];

  static getDefaultItemsPerPage(): number {
    const defaultItemsPerPage = localStorage.getItem(ITEMS_PER_PAGE_KEY);
    return defaultItemsPerPage ? parseInt(defaultItemsPerPage) : 10;
  }

  pageChanged(newPage: number) {
    this.paginationChanged.emit({
      page: newPage,
      itemsPerPage: this.itemsPerPage,
    });
  }

  onItemsPerPageChanged() {
    localStorage.setItem(ITEMS_PER_PAGE_KEY, this.itemsPerPage.toString());
    this.currentPage = 1;
    this.pageChanged(this.currentPage);
  }
}
