<packex-page>
  <div class="content-wrapper py-4">
    <h3 class="text-primary">{{ 'USER.ACCOUNT.PAGE_TITLE' | transloco }}</h3>
    <packex-divider></packex-divider>

    <packex-card [formGroup]="form" class="w-100 h-auto mt-6 p-3 p-md-4">
      <h4 class="mb-6">{{ 'USER.ACCOUNT.PERSONAL_DATA' | transloco }}</h4>

      <div class="d-flex flex-column flex-md-row flex-wrap">
        <div class="w-100 w-md-50">
          <div class="d-flex flex-column pe-0 pe-md-4 mb-1">
            <span class="subtitle-2 mb-2">{{'USER.MODEL.FIRST_NAME' | transloco}}</span>
            <mat-form-field appearance="outline" class="w-100">
              <input [readonly]="loading" matInput placeholder="{{'USER.MODEL.FIRST_NAME' | transloco}}*"
                     formControlName="firstName"
                     name="firstName" required>
              <mat-error
                *ngIf="form.controls['lastName'].invalid">{{'FORM.ERROR.LAST_NAME' | transloco}}</mat-error>
            </mat-form-field>
          </div>
          <div class="d-flex flex-column pe-0 pe-md-4 mb-1">
            <span class="subtitle-2 mb-2">{{'USER.MODEL.LAST_NAME' | transloco}}</span>
            <mat-form-field appearance="outline" class="w-100">
              <input [readonly]="loading" matInput placeholder="{{'USER.MODEL.LAST_NAME' | transloco}}*"
                     formControlName="lastName"
                     name="lastName" required>
              <mat-error
                *ngIf="form.controls['lastName'].invalid">{{'FORM.ERROR.LAST_NAME' | transloco}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="w-100 w-md-50">
          <div class="d-flex flex-column mb-1">
            <span class="subtitle-2 mb-2">{{'USER.MODEL.DEPARTMENT' | transloco}}</span>
            <mat-form-field appearance="outline" class="w-100">
              <input [readonly]="loading" matInput placeholder="{{'USER.MODEL.DEPARTMENT' | transloco}}"
                     formControlName="department"
                     name="department">
            </mat-form-field>
          </div>
          <div class="d-flex flex-column mb-1">
            <span class="subtitle-2 mb-2">{{'USER.MODEL.POSITION' | transloco}}</span>
            <mat-form-field appearance="outline" class="w-100">
              <input [readonly]="loading" matInput placeholder="{{'USER.MODEL.POSITION' | transloco}}"
                     formControlName="jobTitle"
                     name="jobTitle">
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex flex-column w-100 w-md-50 pe-0 pe-md-4 mb-1">
          <span class="subtitle-2 mb-2">{{'USER.MODEL.EMAIL' | transloco}}</span>
          <mat-form-field appearance="outline" class="w-100">
            <input [readonly]="loading" matInput placeholder="{{'USER.MODEL.EMAIL' | transloco}}*"
                   formControlName="email"
                   name="email" required>
            <mat-error
              *ngIf="form.controls['email'].invalid">{{'FORM.ERROR.EMAIL' | transloco}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex flex-column w-100 w-md-50 mb-4">
        <span class="subtitle-2 mb-2">{{'USER.MODEL.PASSWORD' | transloco}}</span>
        <div class="d-flex align-items-center justify-content-between">
          <div>********</div>
          <packex-button type="tertiary" class="w-auto" color="primary-dark"
                         (buttonClick)="openChangePasswordModal()">{{ 'USER.ACCOUNT.CHANGE_PASSWORD' | transloco }}</packex-button>
        </div>
      </div>

      <div class="d-flex flex-column w-100 w-md-50 mb-1 pe-0 pe-md-4 mb-6 mb-md-0">
        <span class="subtitle-2 mb-2">{{'USER.ACCOUNT.LANGUAGE_SETTING' | transloco}}</span>
        <div class="d-flex align-items-center justify-content-between">
          <packex-language-select class="w-100"></packex-language-select>
        </div>
      </div>

      <div class="d-flex justify-content-between w-100 flex-row">
        <div class="d-none d-md-block w-75">&nbsp;</div>
        <div class="d-flex flex-column align-items-end w-100">
          <packex-button (buttonClick)="save()" class="w-100 w-md-auto mb-4"
                         [disabled]="form.invalid">{{ 'COMMON.SAVE_CHANGES' | transloco }}</packex-button>
          <packex-button type="tertiary" class="w-auto justify-content-end" color="error"
                         (buttonClick)="deleteAccount()">{{ 'USER.ACCOUNT.DELETE_ACCOUNT' | transloco }}</packex-button>
        </div>
      </div>
    </packex-card>
  </div>
</packex-page>
