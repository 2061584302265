import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FilterService } from '@modules/filter/services/filter.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent,
  tap,
} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'packex-search',
  template: ` <mat-form-field class="search-input no-subscription">
    <packex-icon matPrefix icon="search" size="s"></packex-icon>
    <input
      #input
      matInput
      type="text"
      [placeholder]="placeholder | transloco"
      [(ngModel)]="searchTerm"
      (blur)="onSearch()"
      name="searchTerm"
      [attr.data-testid]="'inventory-search'"
    />
    <button
      *ngIf="searchTerm"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="clearSearch()"
    >
      <packex-icon icon="close" size="s"></packex-icon>
    </button>
  </mat-form-field>`,
  styleUrls: ['search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements AfterViewInit, OnDestroy {
  @Output() search = new EventEmitter<string>();
  @Input() placeholder = '';
  @ViewChild('input') input!: ElementRef;

  searchTerm = '';

  constructor(private readonly filterService: FilterService) {}

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        filter(
          () => this.searchTerm.length >= 3 || this.searchTerm.length == 0,
        ),
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => {
          this.onSearch();
        }),
      )
      .subscribe();

    this.filterService.inventoryItemFilter
      .pipe(untilDestroyed(this))
      .subscribe(({ searchTerm }) => (this.searchTerm = searchTerm));
  }

  ngOnDestroy() {
    this.searchTerm = '';
  }

  public onSearch(): void {
    this.filterService.setSearch(this.searchTerm);
    this.search.emit(this.searchTerm);
  }

  public clearSearch(): void {
    this.filterService.resetInventoryItemFilter();
    this.onSearch();
  }
}
