import { Component, Input } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { InventoryItem } from '@shared/models/inventory-item';
import { DownloadService } from '@shared/services/download.service';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';

export type DownloadCardType = 'dieline' | 'guideline';

export interface DownloadCardConfig {
  image: string;
  headline: string;
  caption: string;
  downloadText: string;
  type?: DownloadCardType;
  url?: string;
}

@Component({
  selector: 'packex-download-card',
  templateUrl: './download-card.component.html',
  styleUrls: ['./download-card.component.scss'],
})
export class DownloadCardComponent {
  @Input() config!: DownloadCardConfig;
  @Input() inventoryItem!: InventoryItem;

  constructor(
    private readonly downloadService: DownloadService,
    private readonly gtm: GoogleTagService,
  ) {}

  public download(): void {
    if (this.config.type) {
      this.gtm
        .push('download', {
          type: this.config.type,
        })
        .then(() => {});
    }
    switch (this.config.type) {
      case 'dieline': {
        const url = `${ApiService.apiUrl}/inventory/${this.inventoryItem?.id}/dieline?type=pdf`;
        const fileName = `${this.inventoryItem.name}_B${this.inventoryItem.a}_T${this.inventoryItem.b}_H${this.inventoryItem.h}.pdf`;
        this.downloadService.download(
          url,
          'download',
          'application/pdf',
          fileName,
        );
        break;
      }
      case 'guideline':
        if (this.config.url) {
          const fileName = this.config.url.substring(
            this.config.url.lastIndexOf('/'),
          );

          this.downloadService.download(
            this.config.url,
            'download',
            'application/zip',
            fileName,
          );
          break;
        } else {
          return;
        }
      default:
        break;
    }
  }
}
