<packex-cart-address-card *ngIf="cart | async as cart"
                          [address]="equalsBillingAddress ? (cart.billingAddress || cart.deliveryAddress)  : cart.deliveryAddress"
                          [showVatId]="false"
                          [recipients]="cart.statusUpdateEmails"
                          [emails]="emails"
                          header="CART.DELIVERY.SHIPPING_ADDRESS"
                          recipientsTitle="CART.DELIVERY.SHIPPING_RECIPIENTS"
                          recipientsPlaceholder="CART.DELIVERY.STATUS_RECIPIENT_PLACEHOLDER"
                          recipientTooltip="CART.DELIVERY.RECIPIENT_TOOLTIP"
                          editButtonTooltip="CART.DELIVERY.EDIT_BUTTON_TOOLTIP"
                          createButtonTitle="CART.DELIVERY.CREATE_DELIVERY_ADDRESS"
                          (onSave)="save($event)"
                          (onEdit)="edit()"
                          (onCreate)="create()">
  <p *ngIf="showMissingAddressError" class="text-error">{{ 'CART.DELIVERY.MISSING_ADDRESS_ERROR' | transloco }}</p>

  <div class="custom-content mt-3 d-flex flex-row align-items-center">
    <mat-checkbox class="checkbox me-2" [(ngModel)]="cart.whiteLabelShipping" (change)="onWhitelabelShippingChanged()"
                  [attr.data-testid]="'white-label-shipping-checkbox'"></mat-checkbox>
    <div class="subtitle-2">{{'CART.DELIVERY.WHITE_LABEL_SHIPPING_LABEL' | transloco : {whiteLabel: true} }}</div>
    <packex-tooltip [text]="'CART.DELIVERY.WHITE_LABEL_SHIPPING_TOOLTIP' | transloco" class="ms-2"></packex-tooltip>
  </div>

  <mat-slide-toggle class="mb-6" [ngModel]="equalsBillingAddress" *ngIf="cart.billingAddress"
                    (change)="onChangeEqualBillingAddress($event.checked)">
    <span class="subtitle-2 ms-2">{{ 'CART.DELIVERY.EQUAL_TO_BILLING_ADDRESS' | transloco }}</span>
  </mat-slide-toggle>
</packex-cart-address-card>
