import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { downloadBlob } from '@app/utils/downloadBlob';
import { openFileInTab } from '@app/utils/openFileInTab';
import * as contentDisposition from 'content-disposition';
import { ContentDisposition } from 'content-disposition';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  downloadInProgress = new BehaviorSubject<boolean>(false);

  constructor(private readonly api: ApiService) {}

  public download(
    url: string,
    type: 'download' | 'open_in_tab' = 'download',
    contentType: string,
    downloadFileName?: string,
  ) {
    this.downloadInProgress.next(true);

    if (url) {
      this.api
        .getRaw(
          url,
          this.api.buildHttpOptions({
            contentType,
            responseType: 'blob',
          }),
        )
        .subscribe((response) => {
          let disposition: ContentDisposition | undefined;
          const blob = response.body;

          const dispositionHeader = response.headers.get('content-disposition');

          if (dispositionHeader) {
            disposition = contentDisposition.parse(
              dispositionHeader.replace(/[\s()]/g, ''),
            );
          }

          const fileName =
            downloadFileName || disposition?.parameters['filename'];

          if (type === 'download') {
            this.downloadBlob(blob, fileName);
          } else if (type === 'open_in_tab') {
            this.openFileInTab(blob);
          }

          this.downloadInProgress.next(false);
        });
    }
  }

  public downloadBlob(blob: Blob, fileName?: string) {
    return downloadBlob(blob, fileName);
  }

  public openFileInTab(blob: Blob) {
    return openFileInTab(blob);
  }
}
