import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  private drawer?: MatDrawer;
  public drawerVisible$ = new BehaviorSubject<boolean>(false);

  public setDrawer(drawer: MatDrawer) {
    this.drawer = drawer;
  }

  public async open() {
    this.setDrawerVisible(true);
    await this.drawer?.open();
  }

  public async close() {
    this.setDrawerVisible(false);
    await this.drawer?.close();
  }

  public async toggle() {
    this.setDrawerVisible(this.drawer?.opened || false);
    await this.drawer?.toggle();
  }

  private setDrawerVisible(bool: boolean): void {
    // set a timeout of 1ms to prevent closing the drawer
    // through click outside component trigger
    setTimeout(() => {
      this.drawerVisible$.next(bool);
    }, 1);
  }

  public get isDrawerVisible(): boolean {
    return this.drawerVisible$.getValue();
  }
}
