<packex-page class="py-4">
  <ng-container *ngIf="order">
    <div class="bg-primary-dark d-flex ps-6 align-items-center">
      <packex-icon color="white" icon="check_circle" class="me-4"></packex-icon>
      <h4 class="text-white my-3" [attr.data-testid]="'order-received-title'">{{ 'ORDER.RECEIVED_TITLE' | transloco }}</h4>
    </div>

    <packex-card class="p-4 w-100 h-auto position-relative d-flex flex-column mb-4">
      <p>{{ 'ORDER.YOUR_ORDER_NUMBER' | transloco }}:&nbsp;<span class="subtitle-2">{{ order.orderNumber }}</span></p>
      <p class="mb-6">{{ 'ORDER.THX_FOR_ORDERING' | transloco }}</p>

      <div class="d-flex flex-column flex-md-row w-100">
        <div class="w-100 w-md-50 me-3">
          <div class="subtitle-1 border-bottom border-grey-medium pb-2 d-flex justify-content-between">
            <span>{{ 'CART.DELIVERY.SHIPPING_ADDRESS' | transloco }}</span>
          </div>
          <packex-address [address]="order.deliveryAddress"
                          [showVatId]="false"
                          class="d-block mt-2"></packex-address>

          <div class="subtitle-2 mt-2 mb-3">{{'CART.DELIVERY.WHITE_LABEL_SHIPPING_LABEL' | transloco : {whiteLabel: order.orderItems[0].whiteLabelShipping} }}</div>

          <ng-container *ngIf="statusUpdateEmails.length">
            <div class="subtitle-2 mt-2">{{ 'CART.DELIVERY.SHIPPING_RECIPIENTS' | transloco }}</div>
            <p *ngFor="let recipient of statusUpdateEmails" class="mb-0">{{ recipient }}</p>
          </ng-container>
        </div>
        <div class="w-100 w-md-50 ms-md-3 mt-6 mt-md-0">
          <div class="subtitle-1 border-bottom border-grey-medium pb-2 d-flex justify-content-between">
            <span>{{ 'CART.BILLING.BILLING_ADDRESS' | transloco }}</span>
          </div>
          <packex-address [address]="order.billingAddress" class="d-block mt-2"></packex-address>

          <div class="subtitle-2 mt-2">{{ 'CART.BILLING.BILLING_RECIPIENTS' | transloco }}</div>
          <p *ngFor="let recipient of invoiceRecipientEmails" class="mb-0">{{ recipient }}</p>
        </div>
      </div>

      <div class="d-flex flex-column flex-md-row w-100 mt-6">
        <div class="w-100 w-md-50 me-3 d-flex flex-column">
          <div class="subtitle-1 border-bottom border-grey-medium pb-2 ">{{ 'CART.PAYMENT.TITLE' | transloco }}</div>
          <div class="pt-2">
            {{ getPaymentMethodTranslation(order.paymentMethod) | transloco }}
          </div>
        </div>
        <div class="w-100 w-md-50 ms-md-3 mt-6 mt-md-0 d-flex flex-column">
          <div class="subtitle-1 border-bottom border-grey-medium pb-2 ">{{ 'ORDER.TOTAL' | transloco }}
            <span class="caption">{{ 'CONFIGURATION.PRICE.INCLUDING_PRINT_AND_SHIPPING' | transloco }}</span></div>
          <div *ngIf="order.voucher" class="py-2 border-bottom border-grey-medium">
            <div class="d-flex justify-content-between mb-1">
              <p class="text-primary-medium">{{ 'CART.VOUCHER.PREVIOUS_TOTAL'| transloco }}</p>
              <p class="text-primary-medium">{{ order.totalNetPriceWithoutVoucher | formatPrice }}</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="text-primary-medium subtitle-2">{{ 'CART.VOUCHER.CODE'| transloco }}:&nbsp;
                <span>{{ order.voucher.code }}</span>&nbsp;
                <span *ngIf="order.voucher.type === 'percentage'">({{ order.voucher.discount * 100 }}%)</span>
              </p>
              <p class="text-primary-medium subtitle-2">{{ order.discount | formatPrice }}</p>
            </div>
          </div>
          <div class="pt-2 d-flex justify-content-between">
            <h3>{{ 'CART.TOTAL' | transloco }}</h3>
            <div class="d-flex align-items-end flex-column">
              <h3>{{ order.totalNetPrice | formatPrice }} ({{ 'COMMON.NET_PRICE' | transloco }})</h3>
              <div class="caption text-primary-medium">{{ order.totalGrossAmount | formatPrice }}
                ({{ 'COMMON.GROSS_PRICE' | transloco }})
              </div>
            </div>
          </div>
        </div>
      </div>
    </packex-card>

    <packex-carousel class="my-4"></packex-carousel>

    <packex-card class="p-4 w-100 h-auto position-relative d-flex mb-6">
      <h5 class="mb-6">{{ 'ORDER.HOW_TO_CONTINUE' | transloco }}</h5>
      <div class="d-flex flex-row">
        <div class="d-flex flex-column">
          <div class="circle bg-primary-dark me-4">
            <packex-icon icon="mail" color="white"></packex-icon>
          </div>
          <div class="border-top border-grey-medium continue-divider"></div>
        </div>
        <p class="w-75">
          {{ 'ORDER.STEP_MAIL' | transloco }}
          <span class="subtitle-2 d-block mt-4">{{ order.orderedBy.email }}</span>
        </p>
      </div>
      <div class="d-flex flex-row mt-4">
        <div class="d-flex flex-column">
          <div class="circle bg-primary-dark me-4">
            <packex-icon icon="eco_friendly" color="white"></packex-icon>
          </div>
          <div class="border-top border-grey-medium continue-divider"></div>
        </div>
        <p class="w-75">{{ 'ORDER.STEP_ECO_FRIENDLY' | transloco }}</p>
      </div>
      <div class="d-flex flex-row mt-4">
        <div class="circle bg-primary-dark me-4">
          <packex-icon icon="shipping" color="white"></packex-icon>
        </div>
        <p class="w-75">{{ 'ORDER.STEP_SHIPPING' | transloco }}</p>
      </div>

      <div class="subtitle-2 mt-6">{{ 'ORDER.BEST_WISHES' | transloco }}</div>
    </packex-card>

    <div class="mt-6">
      <h4 class="mb-6">{{ 'ORDER.FAQ.TITLE' | transloco }}</h4>
      <packex-accordion>
        <packex-accordion-step [expanded]="true">
          <span class="title">{{ 'ORDER.FAQ.ORDER_STATUS.TITLE' | transloco }}</span>
          <div [innerHTML]="'ORDER.FAQ.ORDER_STATUS.CONTENT' | transloco"></div>
        </packex-accordion-step>
        <packex-accordion-step>
          <span class="title">{{ 'ORDER.FAQ.ORDER_RECEIVED.TITLE' | transloco }}</span>
          <div [innerHTML]="'ORDER.FAQ.ORDER_RECEIVED.CONTENT' | transloco"></div>
        </packex-accordion-step>
        <packex-accordion-step>
          <span class="title">{{ 'ORDER.FAQ.CANCEL_ORDER.TITLE' | transloco }}</span>
          <div [innerHTML]="'ORDER.FAQ.CANCEL_ORDER.CONTENT' | transloco"></div>
        </packex-accordion-step>
        <packex-accordion-step>
          <span class="title">{{ 'ORDER.FAQ.ORDER_INVOICE.TITLE' | transloco }}</span>
          <div [innerHTML]="'ORDER.FAQ.ORDER_INVOICE.CONTENT' | transloco"></div>
        </packex-accordion-step>
        <packex-accordion-step>
          <span class="title">{{ 'ORDER.FAQ.ORDER_TRACKING.TITLE' | transloco }}</span>
          <div [innerHTML]="'ORDER.FAQ.ORDER_TRACKING.CONTENT' | transloco"></div>
        </packex-accordion-step>
        <packex-accordion-step>
          <span class="title">{{ 'ORDER.FAQ.ORDER_DELIVERY.TITLE' | transloco }}</span>
          <div [innerHTML]="'ORDER.FAQ.ORDER_DELIVERY.CONTENT' | transloco"></div>
        </packex-accordion-step>
        <packex-accordion-step>
          <span class="title">{{ 'ORDER.FAQ.CONTACT.TITLE' | transloco }}</span>
          <div [innerHTML]="'ORDER.FAQ.CONTACT.CONTENT' | transloco"></div>
        </packex-accordion-step>

      </packex-accordion>
    </div>
  </ng-container>
</packex-page>
