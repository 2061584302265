import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconColor, IconSize } from '@shared/components/icon/icon.component';

export type NotificationSeverity =
  | 'info'
  | 'warning'
  | 'danger'
  | 'primary-dark';

export type NotificationSize = 'small' | 'medium';

@Component({
  selector: 'packex-well-notification',
  templateUrl: './well-notification.component.html',
  styleUrls: ['./well-notification.component.scss'],
})
export class WellNotificationComponent {
  visible = true;

  @Input() severity: NotificationSeverity = 'warning';
  @Input() icon?: string;

  @Input() buttonText = '';
  @Input() buttonColor = '';
  @Input() showCloseButton = true;
  @Input() padding = 'p-3';
  @Input() width = 'w-100';
  @Input() showNewText = true;
  @Input() size: NotificationSize = 'medium';
  @Output() buttonClicked = new EventEmitter();

  public close(): void {
    this.visible = false;
  }

  public get bgColor() {
    switch (this.severity) {
      case 'info':
        return 'bg-tertiary';
      default:
        return `bg-${this.severity}`;
    }
  }

  get textColor() {
    if (this.severity === 'primary-dark') {
      return 'text-white';
    }

    return 'text-secondary-dark';
  }

  get iconColor(): IconColor {
    if (this.severity === 'primary-dark') {
      return 'white';
    }

    return 'primary';
  }

  public get sizeClass(): string {
    return this.size === 'small' ? 'caption' : '';
  }
}
