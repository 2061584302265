import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InventoryItemMobileFilterComponent } from '@modules/inventory/components/inventory-item-mobile-filter/inventory-item-mobile-filter.component';
import { BatchOperationSelectOption } from '@modules/inventory/components/inventory-batch-operations/inventory-batch-operations.component';

@Component({
  selector: 'packex-inventory-list-option-bar',
  templateUrl: './inventory-list-option-bar.component.html',
  styleUrls: ['./inventory-list-option-bar.component.scss'],
})
export class InventoryListOptionBarComponent {
  @Output() onCreateProduct = new EventEmitter();

  @Input() allSelected = false;
  @Input() selectedItemsLength = 0;
  @Output() allSelectedChanged = new EventEmitter<boolean>();

  @Output() deleteSelectedItems = new EventEmitter();
  @Output() createLabelForSelectedItems = new EventEmitter();

  batchOperations: BatchOperationSelectOption[] = [
    {
      type: 'addLabel',
      title: 'LABELS.ADD_LABEL',
    },
    {
      type: 'delete',
      title: 'COMMON.DELETE',
    },
  ];

  constructor(private readonly dialog: MatDialog) {}

  public openFilter(): void {
    this.dialog.open(InventoryItemMobileFilterComponent);
  }

  public onBatchOperationSelected(
    batchOperation: BatchOperationSelectOption | null,
  ): void {
    if (batchOperation) {
      switch (batchOperation.type) {
        case 'addLabel':
          return this.createLabelForSelectedItems.emit();
        case 'delete':
          return this.deleteSelectedItems.emit();
        default:
          return;
      }
    }
  }
}
