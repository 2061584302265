import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root',
})
export class BreakpointObserverService {
  private _isMobile = false;
  private _isTablet = false;
  private _isDesktop = false;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe('(max-width: 640px)')
      .subscribe((state: BreakpointState) => {
        this._isMobile = state.matches;
      });

    this.breakpointObserver
      .observe('(min-width: 640px) and (max-width: 1024px)')
      .subscribe((state: BreakpointState) => {
        this._isTablet = state.matches;
      });

    this.breakpointObserver
      .observe('(min-width: 1024px)')
      .subscribe((state: BreakpointState) => {
        this._isDesktop = state.matches;
      });
  }

  isMobile() {
    return this._isMobile;
  }

  isTablet() {
    return this._isTablet;
  }

  isDesktop(): boolean {
    return this._isDesktop;
  }
}
