import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VariantsService } from '@app/core/services/variants.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { HttpError } from '@shared/models/error';
import { Variant } from '@shared/models/variant';
import { catchError, throwError } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'packex-edit-variant',
  templateUrl: './edit-variant.component.html',
  styleUrls: ['./edit-variant.component.scss'],
})
export class EditVariantComponent extends ModalComponent implements OnInit {
  form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });

  // NOTE: if name existing occurs, we keep already known invalid names in mind within the dialog for form valid state
  existingNames: string[] = [];
  // NOTE: initially we assume the input as unique, may get changed after save in case
  currentNameIsUnique = true;

  constructor(
    dialogRef: MatDialogRef<EditVariantComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      variant?: Variant;
    },
    private readonly variantsService: VariantsService,
    private readonly snackBarService: SnackBarService,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    this.form.get('name')?.patchValue(this.dialogData.variant?.name);
  }

  public submit() {
    if (this.dialogData?.variant && this.form.valid) {
      this.saveVariant({
        ...this.dialogData.variant,
        name: this.form.value.name,
      });
    }
  }

  private saveVariant(variant: Variant) {
    this.variantsService
      .updateVariant(variant.id, this.form.value)
      .pipe(
        untilDestroyed(this),
        catchError((error: HttpError) => {
          if (error.statusCode === 409) {
            this.existingNames.push(this.form.value.name);
            this.updateNames();
          }

          this.showUpdateVariantError();
          return throwError(() => error);
        }),
      )
      .subscribe((response: Variant | HttpErrorResponse) => {
        if (!(response instanceof HttpErrorResponse)) {
          this.showUpdateVariantSuccess();
          this.onConfirm();
        }
      });
  }

  private showUpdateVariantSuccess(): void {
    this.snackBarService.showSimpleSuccess('EDIT_VARIANT.UPDATE_SUCCESS');
  }

  private showUpdateVariantError(): void {
    this.snackBarService.showSimpleError('EDIT_VARIANT.UPDATE_ERROR');
  }

  public updateNames(): void {
    const currentName = this.form?.controls['name'].getRawValue();

    this.currentNameIsUnique = !this.existingNames.find(
      (name) => name === currentName,
    );
    if (!this.currentNameIsUnique) {
      this.form?.controls['name'].setErrors({ incorrect: true });
    }
    this.form?.get('name')?.markAsTouched();
  }
}
