import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { environment } from '@env';
import { Invitation } from '@shared/models/invitation';
import { User } from '@shared/models/user';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';
import { KEYCLOAK_LS_KEY } from '@app/utils/keycloak-helper';

export interface KeyCloakTokenResponse {
  access_token: string;
  refresh_token: string;
  id_token: string;
}

export type UpdateUserDto = Partial<User> & {
  organizationName?: string;
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user$ = new BehaviorSubject<User | null>(null);
  private userRegistration: User | undefined;
  userLoaded = false;

  constructor(
    private apiService: ApiService,
    private readonly keycloakService: KeycloakService,
    private readonly http: HttpClient,
    private readonly googleTagService: GoogleTagService,
  ) {
    this.keycloakService.isLoggedIn().then((loggedIn) => {
      if (loggedIn) this.load().subscribe();
    });
  }

  public load() {
    return this.getMe().pipe(
      tap((user: User) => {
        this.user$.next(user);
        this.userLoaded = true;
      }),
    );
  }

  public loadUsers(): Observable<User[]> {
    return this.apiService.get('users');
  }

  get user(): User | null {
    return this.user$.getValue();
  }

  get userCompleted(): boolean {
    return !!this.user?.lastName && !!this.user.firstName;
  }

  public getPendingInvitation(): Invitation | undefined {
    return this.user?.pendingInvitations.find(
      (invitation) => !invitation.isExpired,
    );
  }

  public createRegistrationProcess(
    registrationData: User | undefined,
  ): Observable<boolean> {
    this.userRegistration = registrationData;
    return this.apiService.post('users', registrationData);
  }

  public getCreatedUserRegistration(): User {
    return <User>this.userRegistration;
  }

  private getMe(): Observable<User> {
    return this.apiService.get('users/me').pipe(
      tap(async (user) => {
        await this.googleTagService.setUserId(user.id ?? null);
      }),
    );
  }

  public updateUser(userData: UpdateUserDto): Observable<User> {
    return this.apiService
      .patch(`users/${userData.id}`, {
        ...userData,
      })
      .pipe(switchMap(() => this.load()));
  }

  public updateOrganization(data: {
    name?: string;
    preferredLanguage?: string;
  }) {
    return this.apiService.patch('organizations/me', data);
  }

  public changePassword(token: string, password: string) {
    return this.apiService.post(`users/reset-password`, {
      token,
      password,
    });
  }

  public resetPassword(email: string) {
    return this.apiService.post('users/request-reset-password', {
      email,
    });
  }

  public patchPassword(userId: string, oldPassword: string, password: string) {
    return this.apiService.patch(`users/${userId}`, {
      oldPassword,
      password,
    });
  }

  public sendVerificationEmail() {
    return this.apiService.get('users/resend-verification');
  }

  public verify(token: string): Observable<User> {
    return this.apiService.get(`users/verify/${token}`);
  }

  public keyCloakAuth(
    username: string,
    password: string,
  ): Observable<KeyCloakTokenResponse> {
    const formData = new URLSearchParams();
    formData.set('username', username);
    formData.set('password', password);
    formData.set('grant_type', 'password');
    formData.set('scope', 'openid');
    formData.set('client_id', environment.keycloak.clientId);

    return this.http.post<KeyCloakTokenResponse>(
      `${environment.keycloak.url}/realms/${environment.keycloak.realm}/protocol/openid-connect/token`,
      formData.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  }

  async logout() {
    const savedToken = localStorage.getItem(KEYCLOAK_LS_KEY);
    if (savedToken) {
      localStorage.removeItem(KEYCLOAK_LS_KEY);
    }
    await this.googleTagService.setUserId(null);
    await this.keycloakService.logout(window.location.origin);
  }
}
