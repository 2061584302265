<ng-container *transloco="let t">
    <packex-page>
        <div class="packex-not-found-container">
            <div class="row">
                <div class="col-10 offset-1 col-md-8 offset-md-2 col-xl-6 offset-xl-3 text-center not-found-content">
                    <img class="img-fluid" src="../../../../assets/images/404.svg" title="{{ t(title??'NOT_FOUND.DEFAULT_TITLE') }}" alt="PackEx GmbH">
                    <h2 class="text-center text-uppercase text-secondary-medium">{{ t(title??'NOT_FOUND.DEFAULT_TITLE') }}</h2>
                    <packex-divider class="m-auto" colorClass="bg-secondary-medium"></packex-divider>
                    <p class="text-secondary-medium">{{ t(hint??'NOT_FOUND.DEFAULT_HINT') }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <packex-button class="w-auto mt-2 d-flex justify-content-center" (buttonClick)="navigateBack()">{{ t('NOT_FOUND.BACK_TO_PRODUCTS') }}</packex-button>
                </div>
            </div>
        </div>
    </packex-page>
</ng-container>
