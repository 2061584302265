<packex-page class="size-xl mb-10">
  <packex-breadcrumb class="mt-3 mb-3"></packex-breadcrumb>

  <h3>{{ 'INVENTORY.TEMPLATES.PRINT_DATA_MANAGER' | transloco }}</h3>
  <packex-divider></packex-divider>
  <p class="mb-7 mt-2">{{ 'INVENTORY.TEMPLATES.DESCRIPTION' | transloco }}</p>

  <div class="subtitle-1">{{ 'INVENTORY.TEMPLATES.TEMPLATES' | transloco }}</div>
  <ng-container *ngIf="inventoryItem$ | async as inventoryItem;">
    <packex-templates-list [inventoryItem]="inventoryItem"></packex-templates-list>
  </ng-container>

  <div class="d-flex flex-column flex-md-row" *ngIf="inventoryItem$ | async as inventoryItem;">
    <div class="me-0 me-md-4 w-100 w-md-75">
      <packex-print-data-upload [inventoryItem]="inventoryItem" [variant]="variant$ | async"
                                class="mb-4">
      </packex-print-data-upload>
      <packex-carousel class="d-none d-md-flex"></packex-carousel>
    </div>
    <packex-inventory-item-detail-card [inventoryItem]="inventoryItem" class="box-shadow mb-4 w-100 w-md-25">
    </packex-inventory-item-detail-card>
    <packex-carousel class="d-flex d-md-none"></packex-carousel>
  </div>
</packex-page>
