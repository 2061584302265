<packex-modal  (onDismiss)="dismiss()" [isScrollable]="false">
  <h5 class="modal-title">{{ dialogData.title | transloco }}</h5>

  <p class="modal-body d-inline" [innerHTML]="dialogData.content | transloco" *ngIf="dialogData.content"></p>
  <p class="modal-body d-inline" [innerHTML]="dialogData.rawContent" *ngIf="dialogData.rawContent"></p>

  <packex-button type="secondary" (click)="dismiss()" *ngIf="!dialogData.hideSecondaryButton" class="w-auto">
    {{ dialogData.secondaryButtonText ? (dialogData.secondaryButtonText | transloco) : 'COMMON.CANCEL' | transloco }}
  </packex-button>

  <packex-button (click)="onConfirm()" class="w-auto" [attr.data-testid]="'confirm-dialog-primary-button'">
    {{ dialogData.primaryButtonText | transloco }}
  </packex-button>
</packex-modal>
