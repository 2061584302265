<packex-modal (onDismiss)="dismiss()" size="lg">
  <h5 class="modal-title">{{ title | transloco }}</h5>

  <div class="modal-body">
    <packex-loading-component *ngIf="(loading$ | async) === true"
                              class="w-100 mb-10"
                              message="CONSTRUCTIONS.REQUEST_OFFER.SENDING"></packex-loading-component>
    <ng-container *ngIf="(loading$ | async) === false">
      <p class="mb-4" [innerHTML]="description | transloco"></p>

      <form [formGroup]="form" *ngIf="form">
        <ng-container *ngIf="!loggedIn">
          <div class="subtitle-2 mb-3">{{ 'CONSTRUCTIONS.REQUEST_OFFER.PERSONAL' | transloco }}</div>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput type="text"
                   formControlName="company"
                   [placeholder]="('ADDRESS.COMPANY' | transloco) + '*'"/>
            <mat-error *ngIf="form.controls['company'].invalid">{{'FORM.ERROR.COMPANY' | transloco}}</mat-error>
          </mat-form-field>
          <div class="d-flex flex-row">
            <mat-form-field appearance="outline" class="w-50 me-3">
              <input matInput type="text"
                     formControlName="firstname"
                     [placeholder]="('ADDRESS.FIRSTNAME' | transloco) + '*'"/>
              <mat-error *ngIf="form.controls['firstname'].invalid">{{'FORM.ERROR.FIRST_NAME' | transloco}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-50">
              <input matInput type="text"
                     formControlName="lastname"
                     [placeholder]="('ADDRESS.LASTNAME' | transloco) + '*'"/>
              <mat-error *ngIf="form.controls['lastname'].invalid">{{'FORM.ERROR.LAST_NAME' | transloco}}</mat-error>
            </mat-form-field>
          </div>
          <div class="d-flex flex-row">
            <mat-form-field appearance="outline" class="w-50 me-3">
              <input matInput type="text"
                     formControlName="email"
                     [placeholder]="('ADDRESS.EMAIL' | transloco) + '*'"/>
              <mat-error *ngIf="form.controls['email'].invalid">{{'FORM.ERROR.EMAIL' | transloco}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-50">
              <input matInput type="text"
                     formControlName="phone"
                     [placeholder]="('ADDRESS.PHONE' | transloco) + '*'"/>
              <mat-error *ngIf="form.controls['phone'].invalid">{{'FORM.ERROR.PHONE' | transloco}}</mat-error>
            </mat-form-field>
          </div>
        </ng-container>

        <div class="subtitle-2 mb-3">{{ 'CONSTRUCTIONS.REQUEST_OFFER.REQUEST' | transloco }}</div>
        <div class="d-flex flex-column flex-lg-row">
          <mat-form-field appearance="outline" class="w-100 w-xl-75 me-3 mb-3 mb-md-0">
            <input matInput type="text"
                   formControlName="subject"
                   [placeholder]="('CONSTRUCTIONS.REQUEST_OFFER.SUBJECT' | transloco) + '*'"/>
            <mat-error *ngIf="form.controls['subject'].invalid">{{'FORM.ERROR.SUBJECT' | transloco}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100 w-xl-25">
            <input matInput type="text"
                   formControlName="estimatedAmount"
                   [placeholder]="('CONSTRUCTIONS.REQUEST_OFFER.ESTIMATED_AMOUNT' | transloco)"/>
          </mat-form-field>
        </div>
        <mat-form-field appearance="outline" class="w-100 textarea">
        <textarea matInput
                  formControlName="message"
                  rows="5"
                  maxlength="500"
                  [placeholder]="('CONSTRUCTIONS.REQUEST_OFFER.MESSAGE' | transloco)"></textarea>
          <mat-error
            *ngIf="form.controls['message'].invalid">{{'FORM.ERROR.MAXLENGTH' | transloco: {length: 500} }}</mat-error>
          <mat-hint align="end">{{ "COMMON.CHARACTERS_LEFT" | transloco: {
            left: (500 - form.controls['message'].value.length),
            max: 500
          }
            }}</mat-hint>
        </mat-form-field>
        <ng-container *ngIf="showFileSection">
          <div class="subtitle-2 mb-3">{{ 'CONSTRUCTIONS.REQUEST_OFFER.UPLOAD' | transloco }}</div>
          <p class="mb-3">{{ uploadDescription | transloco }}</p>

          <packex-button type="secondary" icon="add_circle" class="mb-1"
                         [disabled]="files.length >= 5"
                         (buttonClick)="chooseFile()">{{ 'CONSTRUCTIONS.REQUEST_OFFER.CHOOSE_FILE' | transloco }}</packex-button>
          <input type="file"
                 multiple="multiple"
                 #fileUploader
                 maxlength="5"
                 class="d-none"
                 accept="application/pdf, .pdf, .PDF, .cf2, .CF2, .x4, .X4, .gmt, .GMT, .jpg, .jpeg, .JPG, .JPEG, image/jpeg, image/jpg"
                 (change)="onFileSelected($event)">
          <mat-error
            *ngIf="!fileUploadValid">{{'CONSTRUCTIONS.REQUEST_OFFER.WRONG_FORMAT' | transloco }}</mat-error>
          <div class="caption d-flex justify-content-between text-primary-medium">
            <span>{{ 'CONSTRUCTIONS.REQUEST_OFFER.FILE_TYPES' | transloco }}</span>
            <span>{{ 'CONSTRUCTIONS.REQUEST_OFFER.MAX_FILES' | transloco }}</span>
          </div>

          <div class="file-list">
            <div class="file-entry d-flex justify-content-between w-100 border-bottom border-grey-medium py-2"
                 *ngFor="let file of files; let i = index">
              <div>
                <p>{{ file.name }}</p>
                <p class="caption text-primary-medium">{{ convertFileSize(file.size) }}</p>
              </div>
              <div class="align-items-center d-flex">
                <packex-button type="icon" class="icon-only" (buttonClick)="removeFile(i)"
                               icon="delete"></packex-button>
              </div>
            </div>
          </div>
        </ng-container>

        <mat-checkbox formControlName="privacy" [color]="'primary'" *ngIf="!loggedIn"
                      class="mr-1 mt-3 mb-6">
          <p [innerHTML]="'CONSTRUCTIONS.REQUEST_OFFER.ACCEPT_PRIVACY' | transloco"></p>
        </mat-checkbox>
      </form>
    </ng-container>
  </div>

  <packex-button [disabled]="(loading$ | async) === true || !formIsValid" *ngIf="form && (loading$ | async) !== true"
                 class="w-auto ms-auto"
                 (click)="requestOffer()">{{ buttonText | transloco }}</packex-button>
</packex-modal>
