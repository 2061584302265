<div *ngFor="let address of addresses"
     class="d-flex border border-grey-medium p-2 mb-3 cursor-pointer border-radius-4 address-wrapper"
     [ngClass]="{'active-address': address.id === current?.id}" [attr.data-testid]="'address-card'">
  <packex-address [address]="address" class="w-100"
                  [showVatId]="!!config?.showVatId"
                  (click)="chooseAddress(address)"></packex-address>
  <button [matMenuTriggerFor]="menu" class="menu-button">
    <packex-icon icon="kebab"></packex-icon>
  </button>
  <mat-menu #menu="matMenu" [attr.data-testid]="'kebab-menu-button'">
    <button mat-menu-item disableRipple
            (click)="edit(address)">{{ 'COMMON.EDIT' | transloco }}</button>
    <button mat-menu-item disableRipple *ngIf="config?.showSetStandard"
            [attr.data-testid]="'action-set-standard'"
            (click)="setStandard(address)">{{ 'COMMON.SET_STANDARD' | transloco }}</button>
    <button mat-menu-item disableRipple
            (click)="delete(address)">{{ 'COMMON.DELETE' | transloco }}</button>
  </mat-menu>
</div>

<packex-button type="secondary" (buttonClick)="create()"
               [attr.data-testid]="'address-create-button'"
               icon="add_circle">{{ config?.createButtonText | transloco }}</packex-button>
