<ng-container *ngIf="(cart$ | async) as cart">
  <div class="d-flex justify-content-between pb-2 header">
    <p class="d-flex mb-0">
      {{ 'CART.ITEMS' | transloco }} ({{ productsCount }})
      <packex-button [icon]="detailVisible ? 'up' : 'down'" (buttonClick)="toggleDetailView()"
                     class="ms-2"
                     type="clear-icon"></packex-button>
    </p>
    <p class="mb-0">{{ cart.netPrice | formatPrice }}</p>
  </div>
  <div class="d-flex flex-column justify-content-between">
    <ng-container *ngIf="detailVisible">
      <div *ngFor="let product of cartProducts$ | async" class="py-3 border-bottom">
        <div class="subtitle-2 mb-3">{{ product.name }}</div>

        <div class="d-flex justify-content-between" *ngFor="let item of product.items">
          <p class="mb-1 text-break me-3">{{item.variant.name}}</p>
          <p class="mb-1">{{ item.price | formatPrice }}</p>
        </div>
      </div>
    </ng-container>

    <div class="d-flex justify-content-between border-bottom py-3" *ngIf="(hasProductsWithExtraProductionDays() | async) === true">
      <packex-well-notification padding="px-2 py-1" [showCloseButton]="false" [showNewText]="false">
        {{ 'PRODUCT_OPTIONS.HINT_CART_OVERVIEW' | transloco: {productsCount: (cartProducts$ | async)?.length} }}
      </packex-well-notification>
    </div>

    <div class="d-flex justify-content-between border-bottom py-3 flex-column">
      <div class="d-flex justify-content-between">
        <p class="mb-0">{{ 'CART.DELIVERY.SHIPPING_COUNTRY' | transloco }}</p>
        <span class="subtitle-2">{{ deliveryCountry }}</span>
      </div>
      <div class="text-primary-medium mt-3 mx-1" *ngIf="(countryChanged | async) === true">
        {{ 'CART.DELIVERY.ZONE_CHANGED' | transloco }}
      </div>
    </div>

    <div class="d-flex justify-content-between border-bottom py-3 flex-column">
      <div class="d-flex justify-content-between">
        <p class="mb-0">{{ 'CART.PAYMENT.TITLE' | transloco }}</p>
        <span class="subtitle-2">{{ getPaymentMethodTranslation(cart.paymentMethod!) | transloco}}</span>
      </div>
      <p class="text-primary-medium mt-3 mx-1" *ngIf="onlyHasPrepaid">{{ 'CART.PAYMENT.ONLY_PREPAID' | transloco }}</p>
    </div>
  </div>

  <packex-cart-voucher></packex-cart-voucher>

  <div class="d-flex justify-content-between pt-2 mb-1">
    <h4>{{ 'CART.TOTAL' | transloco }}</h4>
    <h4>{{ cart.netPrice | formatPrice }}</h4>
  </div>
  <p class="caption mb-1">{{ 'CONFIGURATION.PRICE.INCLUDING_PRINT_AND_SHIPPING' | transloco }}</p>
  <p class="caption mb-3 text-primary-medium">{{ 'CART.PLUS_TAX' | transloco }}</p>

  <packex-button (buttonClick)="proceed()"
                 [attr.data-testid]="'cart-proceed-button'"
                 [matTooltip]="'CART.CONFIRM_AGB_HOVER' | transloco"
                 [matTooltipDisabled]="!proceedDisabled"
                 [disabled]="(loading$ | async) || proceedDisabled">{{ buttonText | transloco }}</packex-button>

  <div class="mt-3 d-flex flex-row" *ngIf="cartServiceInstance.isOrderStep">
    <mat-checkbox class="checkbox me-2" [(ngModel)]="legalConfirmed"
                  [attr.data-testid]="'legal-confirm-checkbox'"></mat-checkbox>
    <div [innerHTML]="'CART.AGB_TEXT' | transloco"></div>
  </div>
</ng-container>
