<packex-modal (onDismiss)="dismiss()" [isScrollable]="false">
  <h5 class="modal-title">{{'INVENTORY.DELETE_DIALOG.TITLE' | transloco}}</h5>

  <div class="modal-body">
    <p>{{ 'INVENTORY.DELETE_DIALOG.HINT_TOP' | transloco }}</p>
    <p>{{ 'INVENTORY.DELETE_DIALOG.HINT_BOTTOM' | transloco }}</p>
  </div>

  <packex-button type="secondary" (click)="dismiss()" class="w-auto">{{ 'COMMON.CANCEL' | transloco }}</packex-button>
  <packex-button (click)="onConfirm()" class="w-auto">{{'COMMON.DELETE' | transloco}}</packex-button>

</packex-modal>
