import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LanguageService } from '@app/core/services/language.service';
import { UserService } from '@app/core/services/user.service';
import { ROUTES } from '@app/routes';
import { validateEmail } from '@app/utils/validators';
import { USER_PASSWORD_ROUTES } from '@modules/user/user-routing.module';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'packex-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  form = new FormGroup({
    email: new FormControl(
      '',
      Validators.compose([Validators.required, validateEmail]),
    ),
  });

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly keycloakService: KeycloakService,
    private readonly languageService: LanguageService,
  ) {}

  public submit(): void {
    if (this.form.invalid) return;

    this.userService
      .resetPassword(this.form.value.email as string)
      .subscribe(() => {
        this.redirect();
      });
  }

  public async redirect() {
    await this.router.navigateByUrl(
      `${ROUTES.USER}/${USER_PASSWORD_ROUTES.POST_RECEIVED}?email=${this.form.value.email}`,
    );
  }

  public async backToLogin() {
    await this.keycloakService.login({
      locale: this.languageService.currentLanguage(),
    });
  }
}
