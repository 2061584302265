import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NotificationService } from '@app/core/services/notification.service';
import { UserService } from '@app/core/services/user.service';
import { getPaymentMethodTranslation } from '@app/utils/cart.helper';
import { CartItemsComponent } from '@modules/cart/cart-items/cart-items.component';
import { CartService } from '@modules/cart/cart.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'packex-cart-order-summary',
  templateUrl: './cart-order-summary.component.html',
  styleUrls: ['../cart-item-list/cart-item-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartOrderSummaryComponent
  extends CartItemsComponent
  implements OnInit
{
  billingEmails: string[] = [];
  deliveryEmails: string[] = [];

  getPaymentMethodTranslation = getPaymentMethodTranslation;

  constructor(
    private readonly userService: UserService,
    private readonly notificationService: NotificationService,
    cartService: CartService,
  ) {
    super(cartService);
  }

  override ngOnInit() {
    super.ngOnInit();

    this.notificationService
      .getNotificationEmails(
        this.notificationService.filterForBillingEmails,
        this.billingRecipients,
      )
      .subscribe((emails) => (this.billingEmails = emails));

    this.notificationService
      .getNotificationEmails(
        this.notificationService.filterForDeliveryEmails,
        this.deliveryRecipients,
      )
      .subscribe((emails) => (this.deliveryEmails = emails));
  }

  get deliveryRecipients(): string[] {
    return this.cartServiceInstance.cartValue.statusUpdateEmails.concat(
      this.deliveryEmails,
    );
  }

  get billingRecipients(): string[] {
    return this.cartServiceInstance.cartValue.invoiceRecipientEmails.concat(
      this.billingEmails,
    );
  }

  public goToAddress(): void {
    this.cartServiceInstance.update(
      {
        step: 1,
      },
      false,
    );
  }

  public goToCheckoutPage(): void {
    this.cartServiceInstance.update(
      {
        step: 0,
      },
      false,
    );
  }
}
