import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LANGUAGE, LanguageService } from '@app/core/services/language.service';
import { ModalComponent } from '@shared/components/modal/modal.component';

@Component({
  selector: 'packex-language-select-modal',
  templateUrl: './language-select-modal.component.html',
})
export class LanguageSelectModalComponent extends ModalComponent {
  public currentLanguage: LANGUAGE = this.languageService.currentLanguage();
  constructor(
    dialogRef: MatDialogRef<LanguageSelectModalComponent>,
    public readonly languageService: LanguageService,
  ) {
    super(dialogRef);
  }

  public save() {
    this.languageService.switchLanguage(this.currentLanguage);

    this.dismiss();
  }
}
