import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LanguageService } from '@app/core/services/language.service';
import { LanguageSelectModalComponent } from '@shared/components/language-select-modal/language-select-modal.component';

@Component({
  selector: 'packex-header-language',
  templateUrl: './header-language.component.html',
  styleUrls: ['./header-language.component.scss'],
})
export class HeaderLanguageComponent {
  constructor(
    public readonly languageService: LanguageService,
    private readonly dialog: MatDialog,
  ) {}

  public openLanguageSwitch() {
    this.dialog.open(LanguageSelectModalComponent);
  }

  public getLanguage() {
    return `LANGUAGES.${this.languageService.currentLanguage().toUpperCase()}`;
  }
}
