import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import {
  Notification,
  NotificationTopic,
  UserNotification,
} from '@shared/models/notification';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public userNotifications$ = new BehaviorSubject<UserNotification[]>([]);
  constructor(private readonly api: ApiService) {}

  public getUserNotifications(): void {
    this.api
      .get('notifications/inbox')
      .subscribe((notifications) =>
        this.userNotifications$.next(notifications),
      );
  }

  public load(): Observable<Notification[]> {
    return this.api.get('notifications');
  }

  public subscribeEmail(email: string, topics: NotificationTopic[]) {
    return this.api.post('notifications', {
      email,
      topics,
    });
  }

  public update(id: string, topics: NotificationTopic[]) {
    return this.api.patch(`notifications/${id}`, { topics });
  }

  public subscribeUser(userId: string, topics: NotificationTopic[]) {
    return this.api.post('notifications', {
      userId,
      topics,
    });
  }

  public deleteNotification(id: string) {
    return this.api.delete(`notifications/${id}`);
  }

  public unsubscribe(notificationId: string, topic: NotificationTopic) {
    return this.api.patch(`notifications/${notificationId}/unsubscribe`, {
      topic,
    });
  }

  public filterForDeliveryEmails(
    exclude: string[],
    notification: Notification,
  ) {
    return (
      !exclude.includes(notification.email || notification.user?.email || '') &&
      ((!notification.topics.includes('invoice_created') &&
        notification.topics.length > 0) ||
        (notification.topics.includes('invoice_created') &&
          notification.topics.length > 1))
    );
  }

  public filterForBillingEmails(exclude: string[], notification: Notification) {
    return (
      !exclude.includes(notification.email || notification.user?.email || '') &&
      notification.topics.includes('invoice_created')
    );
  }

  public getNotificationEmails(
    filter: (a1: string[], notification: Notification) => boolean,
    exclude: string[],
  ): Observable<string[]> {
    return this.load().pipe(
      map((notifications) => {
        return notifications
          .filter((notification: Notification) => filter(exclude, notification))
          .map(
            (notification) =>
              notification.email || notification.user?.email || '',
          )
          .filter((email) => !!email);
      }),
    );
  }
}
