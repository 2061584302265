import { Component, Input } from '@angular/core';
import { CartItem } from '@shared/models/cart';
import { OrderItem } from '@shared/models/cart-order';

@Component({
  selector: 'packex-cart-item-reference',
  templateUrl: './cart-item-reference.component.html',
})
export class CartItemReferenceComponent {
  @Input() item!: CartItem | OrderItem;
  @Input() textStyle = 'text-primary-dark';

  @Input() itemView = false;
}
