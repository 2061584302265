import { LANGUAGE } from '@app/core/services/language.service';
import { environment } from '@env';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakInitOptions } from 'keycloak-js';

export const KEYCLOAK_LS_KEY = 'keycloakAuthentication';

export const loginWithKeycloak = async (
  keycloakService: KeycloakService,
  language: LANGUAGE,
) => {
  window.history.pushState(
    window.history.state,
    document.title,
    document.location.href,
  );

  await keycloakService.login({
    redirectUri: window.location.href,
    locale: language,
  });
};

export const initializeKeycloak = (keycloak: KeycloakService) => {
  let tokens = null;
  const options = {
    onLoad: 'check-sso',
    checkLoginIframe: false,
  };

  const keycloakAuth = localStorage.getItem(KEYCLOAK_LS_KEY);

  if (keycloakAuth) {
    tokens = JSON.parse(keycloakAuth);
  }

  const initOptions = (tokens
    ? {
        ...options,
        ...tokens,
      }
    : options) as unknown as KeycloakInitOptions;

  return async () => {
    await keycloak.init({
      config: environment.keycloak,
      initOptions: initOptions,
      enableBearerInterceptor: true,
      bearerExcludedUrls: [
        'https://packex2live.blob.core.windows.net/public/guides',
      ],
    });

    // If the user is logged in, store the tokens in the local storage to avoid back and forth
    // between the app and keycloak on reload
    if (await keycloak.isLoggedIn()) {
      const payload = {
        token: keycloak.getKeycloakInstance().token,
        refreshToken: keycloak.getKeycloakInstance().refreshToken,
        idToken: keycloak.getKeycloakInstance().idToken,
      };
      localStorage.setItem(KEYCLOAK_LS_KEY, JSON.stringify(payload));
    }
  };
};
