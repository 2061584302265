import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { USER_ROUTES } from '@app/routes';
import { ChangePasswordSuccessComponent } from '@modules/user/components/change-password-success/change-password-success.component';
import { ChangePasswordComponent } from '@modules/user/components/change-password/change-password.component';
import { CompleteEmailVerificationComponent } from '@modules/user/components/complete-email-verification/complete-email-verification.component';
import { PostReceivedComponent } from '@modules/user/components/post-received/post-received.component';
import { ResetPasswordComponent } from '@modules/user/components/reset-password/reset-password.component';
import { UnsubscribeNotificationComponent } from '@modules/user/components/unsubscribe-notification/unsubscribe-notification.component';
import { UserAcceptInvitationComponent } from '@modules/user/components/user-accept-invitation/user-accept-invitation.component';
import { SetPasswordComponent } from '@modules/user/components/set-password/set-password.component';
import { UserAccountComponent } from '@modules/user/components/user-account/user-account.component';
import { UserLoginComponent } from '@modules/user/components/user-login.component';
import { RegistrationCompletePageComponent } from '@modules/user/pages/registration-complete-page/registration-complete-page.component';
import { RegistrationPageComponent } from '@modules/user/pages/registration-page/registration-page.component';
import { RegistrationSuccessPageComponent } from '@modules/user/pages/registration-success-page/registration-success-page.component';
import { OrganizationComponent } from '../organization/organization/organization.component';
import { CompleteUserComponent } from './components/complete-user/complete-user.component';
import { RegistrationCreatePageComponent } from './pages/registration-create-page/registration-create-page.component';

export const USER_PASSWORD_ROUTES = {
  SET: 'set-password',
  CHANGE: 'change-password',
  CHANGE_SUCCESS: 'change-password-success',
  RESET: 'reset-password',
  POST_RECEIVED: 'you-got-mail',
};

const routes: Routes = [
  {
    path: USER_ROUTES.REGISTRATION,
    component: RegistrationPageComponent,
    data: { hideHeader: true },
    children: [
      { path: '', redirectTo: USER_ROUTES.CREATE, pathMatch: 'full' },
      {
        path: USER_ROUTES.CREATE,
        component: RegistrationCreatePageComponent,
        data: { hideHeader: true, pageTitle: 'PAGES.REGISTRATION' },
      },
      {
        path: USER_ROUTES.REGISTRATION_SUCCESS,
        component: RegistrationSuccessPageComponent,
        data: { hideHeader: true, pageTitle: 'PAGES.REGISTRATION' },
      },
      {
        path: USER_ROUTES.REGISTRATION_COMPLETE,
        component: RegistrationCompletePageComponent,
        data: { hideHeader: true, pageTitle: 'PAGES.REGISTRATION' },
      },
      {
        path: USER_ROUTES.LOGIN,
        component: UserLoginComponent,
        data: {
          hideHeader: true,
          pageTitle: 'PAGES.LOGIN',
        },
      },
    ],
  },
  {
    path: USER_ROUTES.USER_COMPLETE,
    component: CompleteUserComponent,
    data: { hideHeader: true, pageTitle: 'PAGES.ACCOUNT' },
  },
  {
    path: USER_ROUTES.VERIFY,
    component: CompleteEmailVerificationComponent,
    data: { hideHeader: true, pageTitle: 'PAGES.ACCOUNT' },
  },
  {
    path: USER_PASSWORD_ROUTES.SET,
    component: SetPasswordComponent,
    data: { hideHeader: true, pageTitle: 'PAGES.LOGIN' },
  },
  {
    path: `${USER_PASSWORD_ROUTES.CHANGE}/:token`,
    component: ChangePasswordComponent,
    data: { hideHeader: true, pageTitle: 'PAGES.ACCOUNT' },
  },
  {
    path: USER_PASSWORD_ROUTES.CHANGE_SUCCESS,
    component: ChangePasswordSuccessComponent,
    data: { hideHeader: true, pageTitle: 'PAGES.ACCOUNT' },
  },
  {
    path: USER_PASSWORD_ROUTES.RESET,
    component: ResetPasswordComponent,
    data: { hideHeader: true, pageTitle: 'PAGES.ACCOUNT' },
  },
  {
    path: USER_PASSWORD_ROUTES.POST_RECEIVED,
    component: PostReceivedComponent,
    data: { hideHeader: true, pageTitle: 'PAGES.ACCOUNT' },
  },
  {
    path: USER_ROUTES.ACCOUNT,
    component: UserAccountComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'PAGES.ACCOUNT',
    },
  },
  {
    path: USER_ROUTES.ORGANIZATION,
    component: OrganizationComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'PAGES.ORGANIZATION',
    },
  },
  {
    path: `${USER_ROUTES.UNSUBSCRIBE}/:notificationId/:topic`,
    component: UnsubscribeNotificationComponent,
    data: {
      hideHeader: true,
      pageTitle: 'PAGES.ACCOUNT',
    },
  },
  {
    path: `${USER_ROUTES.INVITATION}/:invitationId`,
    component: UserAcceptInvitationComponent,
    data: { hideHeader: true, pageTitle: 'PAGES.ORGANIZATION' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
