import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CartFlyoutItemComponent } from '@modules/cart/cart-flyout-item/cart-flyout-item.component';
import { CartFlyoutProductComponent } from '@modules/cart/cart-flyout-product/cart-flyout-product.component';
import { CartRoutingModule } from '@modules/cart/cart-routing.module';
import { CartVoucherComponent } from '@modules/cart/cart-voucher/cart-voucher.component';
import { ModalCartService } from '@modules/cart/modal-cart.service';
import { InventoryModule } from '@modules/inventory/inventory.module';
import { SharedModule } from '@shared/shared.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CartAdditionalInformationsModalComponent } from './cart-additional-informations-modal/cart-additional-informations-modal.component';
import { CartAddressAndInformationsComponent } from './cart-address-and-informations/cart-address-and-informations.component';
import { CartAddressCardComponent } from './cart-address-card/cart-address-card.component';
import { CartBillingAddressCardComponent } from './cart-billing-address-card/cart-billing-address-card.component';
import { CartDeliveryAddressCardComponent } from './cart-delivery-address-card/cart-delivery-address-card.component';
import { CartDeliveryComponent } from './cart-delivery/cart-delivery.component';
import { CartFlyoutComponent } from './cart-flyout/cart-flyout.component';
import { CartItemListComponent } from './cart-item-list/cart-item-list.component';
import { CartItemReferenceComponent } from './cart-item-reference/cart-item-reference.component';
import { CartItemThumbnailComponent } from './cart-item-thumbnail/cart-item-thumbnail.component';
import { CartItemComponent } from './cart-item/cart-item.component';
import { CartItemsComponent } from './cart-items/cart-items.component';
import { CartModalComponent } from './cart-modal/cart-modal.component';
import { CartOrderSummaryComponent } from './cart-order-summary/cart-order-summary.component';
import { CartPageComponent } from './cart-page/cart-page.component';
import { CartPaymentComponent } from './cart-payment/cart-payment.component';
import { CartPriceOverviewComponent } from './cart-price-overview/cart-price-overview.component';
import { CartProductComponent } from './cart-product/cart-product.component';
import { CartReferenceInformationsComponent } from './cart-reference-informations/cart-reference-informations.component';
import { CartReferencesComponent } from './cart-references/cart-references.component';
import { CartTotalComponent } from './cart-total/cart-total.component';
import { ChooseAddressModalComponent } from './choose-address-modal/choose-address-modal.component';
import { ChooseBillingAddressModalComponent } from './choose-address-modal/choose-billing-address-modal/choose-billing-address-modal.component';
import { ChooseDeliveryAddressModalComponent } from './choose-address-modal/choose-delivery-address-modal/choose-delivery-address-modal.component';
import { CreateBillingAddressModalComponent } from './create-billing-address-modal/create-billing-address-modal.component';
import { CreateDeliveryAddressModalComponent } from './create-delivery-address-modal/create-delivery-address-modal.component';
import { ModalCartItemComponent } from './modal-cart-item/modal-cart-item.component';
import { OrderReceivedComponent } from './order-received/order-received.component';
import { RecipientsComponent } from './recipients/recipients.component';
import { PaymentProcessingComponent } from '@modules/cart/payment-processing/payment-processing.component';

@NgModule({
  declarations: [
    CartModalComponent,
    ModalCartItemComponent,
    CartDeliveryComponent,
    CartTotalComponent,
    CartFlyoutComponent,
    CartItemComponent,
    CartProductComponent,
    CartFlyoutProductComponent,
    CartFlyoutItemComponent,
    CartItemThumbnailComponent,
    CartPageComponent,
    CartItemListComponent,
    CartItemsComponent,
    CartAddressAndInformationsComponent,
    CartPriceOverviewComponent,
    CartPaymentComponent,
    CartDeliveryAddressCardComponent,
    CartBillingAddressCardComponent,
    CartAddressCardComponent,
    CartReferenceInformationsComponent,
    CreateDeliveryAddressModalComponent,
    ChooseDeliveryAddressModalComponent,
    ChooseBillingAddressModalComponent,
    CreateBillingAddressModalComponent,
    CartAdditionalInformationsModalComponent,
    RecipientsComponent,
    CartOrderSummaryComponent,
    CartReferencesComponent,
    ChooseAddressModalComponent,
    CartItemReferenceComponent,
    OrderReceivedComponent,
    CartVoucherComponent,
    PaymentProcessingComponent,
  ],
  imports: [
    MatIconModule,
    ReactiveFormsModule,
    SharedModule,
    CartRoutingModule,
    FormsModule,
    NgxSkeletonLoaderModule,
    InventoryModule,
  ],
  exports: [
    CartModalComponent,
    CartFlyoutComponent,
    CartAddressCardComponent,
    CartItemReferenceComponent,
  ],
  providers: [ModalCartService],
})
export class CartModule {}
