import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { CreateDeliveryAddressModalComponent } from '@modules/cart/create-delivery-address-modal/create-delivery-address-modal.component';

@Component({
  selector: 'packex-create-billing-address-modal',
  templateUrl: './create-billing-address-modal.component.html',
  styleUrls: ['./create-billing-address-modal.component.scss'],
})
export class CreateBillingAddressModalComponent extends CreateDeliveryAddressModalComponent {
  override createForm() {
    super.createForm();

    this.form.controls['vatId'].addValidators(Validators.required);
    this.form.updateValueAndValidity();
  }
}
