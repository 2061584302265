import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  InventoryFilterType,
  InventoryItemsFilter,
} from '@modules/filter/models/inventory-items-filter';
import { FilterService } from '@modules/filter/services/filter.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export type ProductFilter = {
  icon: string;
  type: InventoryFilterType;
  label: string;
  active?: boolean;
};

const AVAILABLE_FILTER: ProductFilter[] = [
  {
    icon: 'all_products',
    type: 'all',
    label: 'INVENTORY.FILTER.ALL_PRODUCTS',
  },
  {
    icon: 'missing_artwork',
    type: 'missing-artwork',
    label: 'INVENTORY.FILTER.MISSING_PRINT_DATA',
  },
  {
    icon: 'orderable_products',
    type: 'orderable',
    label: 'INVENTORY.FILTER.READY_TO_ORDER',
  },
];

@UntilDestroy()
@Component({
  selector: 'packex-inventory-sidebar',
  templateUrl: './inventory-sidebar.component.html',
  styleUrls: ['./inventory-sidebar.component.scss'],
})
export class InventorySidebarComponent implements OnInit {
  @Output() onCreateProduct = new EventEmitter();
  @Input() showResetButton = true;

  filters = AVAILABLE_FILTER;

  constructor(private readonly filterService: FilterService) {}

  ngOnInit(): void {
    this.filterService.inventoryItemFilter
      .pipe(untilDestroyed(this))
      .subscribe((inventoryItemsFilter: InventoryItemsFilter) => {
        this.filters = AVAILABLE_FILTER.map((productFilter) => ({
          ...productFilter,
          active: inventoryItemsFilter.productFilter === productFilter.type,
        }));
      });
  }

  setFilter(filter: InventoryFilterType): void {
    this.filterService.setProductFilter(filter);
  }

  resetFilter(): void {
    this.filterService.resetProductFilter();
  }
}
