import { ROUTES_CONSTRUCTIONS } from '@modules/constructions/constructions-routing.module';

export const ROUTES = {
  CONSTRUCTIONS: 'constructions',
  CONFIGURATOR: 'constructions/' + ROUTES_CONSTRUCTIONS.CONFIGURATOR,
  INVENTORY: 'inventory',
  USER: 'user',
  NOT_FOUND: '404',
  CART: 'cart',
  ORDERS: 'orders',
};

export const USER_ROUTES = {
  REGISTRATION: 'registration',
  CREATE: 'create',
  REGISTRATION_COMPLETE: 'complete',
  REGISTRATION_SUCCESS: 'success',
  LOGIN: 'login',
  USER_COMPLETE: 'complete',
  VERIFY: 'verify-email',
  CHANGE_PASSWORD: 'change-password',
  RESET_PASSWORD: 'reset-password',
  ACCOUNT: 'account',
  ORGANIZATION: 'organization',
  UNSUBSCRIBE: 'unsubscribe',
  INVITATION: 'invite',
};
