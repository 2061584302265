import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InventoryItemsService } from '@app/core/services/inventory-items.service';
import { getVariantName } from '@app/utils/variant-helper';
import { INVENTORY_ROUTES } from '@modules/inventory/inventory-routing.module';
import { translate } from '@ngneat/transloco';
import { InventoryItem } from '@shared/models/inventory-item';
import { Variant } from '@shared/models/variant';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'packex-print-data-manager',
  templateUrl: './print-data-manager-page.component.html',
  styleUrls: ['./print-data-manager-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintDataManagerPageComponent implements AfterViewInit {
  inventoryItem$ = new BehaviorSubject<InventoryItem | null>(null);
  variant$ = new BehaviorSubject<Variant | null>(null);

  constructor(
    private inventoryItemsService: InventoryItemsService,
    private route: ActivatedRoute,
    public breadcrumbService: BreadcrumbService,
  ) {}

  ngAfterViewInit(): void {
    this.breadcrumbService.set(
      '@inventoryItem',
      `${translate('INVENTORY.ITEM_LOADING')}...`,
    );

    this.breadcrumbService.set(
      '@variantItem',
      `${translate('VARIANT.ITEM_LOADING')}...`,
    );

    this.getInventoryItem();
  }

  public getInventoryItem() {
    this.route.params
      .pipe(
        switchMap(({ id, variantId }) =>
          this.inventoryItemsService.findOne(id).pipe(
            tap((inventoryItem) => {
              if (variantId && variantId === INVENTORY_ROUTES.NEW) {
                this.breadcrumbService.set(
                  '@variantItem',
                  translate('VARIANT.NEW_VARIANT'),
                );
              } else if (variantId) {
                const variant = inventoryItem.variants.find(
                  (x: Variant) => x.id === variantId,
                );

                if (variant) {
                  this.variant$.next(variant);
                  this.breadcrumbService.set(
                    '@variantItem',
                    getVariantName(variant),
                  );
                }
              }
            }),
          ),
        ),
        tap((inventoryItem) => {
          this.breadcrumbService.set('@inventoryItem', inventoryItem.name);
        }),
      )
      .subscribe((inventoryItem) => this.inventoryItem$.next(inventoryItem));
  }
}
