import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { ROUTES } from '@app/routes';

@UntilDestroy()
@Component({
  selector: 'packex-verify-email',
  template: '',
})
export class VerifyEmailComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly snackbarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe((params) => {
      if (params['token']) {
        this.verify(params['token']);
      }
    });
  }

  private verify(token: string) {
    this.userService.verify(token).subscribe({
      next: () => {
        this.snackbarService.showSimpleSuccess('USER.VERIFY.EMAIL_VERIFY');
        this.router.navigateByUrl('/');
      },
      error: () => {
        this.snackbarService.showSimpleError('USER.VERIFY.EMAIL_VERIFY_ERROR');
        this.router.navigateByUrl('/');
      },
    });
  }
}
