<packex-page>
  <packex-card *ngIf="invitation && !isLoggedIn">
    <h2
      class="text-uppercase">{{ 'USER.ACCEPT_INVITATION.WELCOME' | transloco }}</h2>

    <packex-divider></packex-divider>

    <p class="mb-3" [innerHTML]="'USER.ACCEPT_INVITATION.REGISTRATION_TEXT' | transloco : {user: inviteUser(), organization: invitation.invitedBy.organization.name }"></p>

    <p class="mb-3"><strong>{{ invitation.email }}</strong></p>

    <packex-registration-form [email]="invitation.email" (onSuccess)="registrationProcessDone($event)"></packex-registration-form>
  </packex-card>

  <packex-card class="h-auto" *ngIf="invitation && isLoggedIn && !invitationInvalid">
    <h2
      class="text-uppercase">{{ invitation.invitedBy.organization.name}} {{ 'USER.ACCEPT_INVITATION.HEADER' | transloco}}</h2>
    <packex-divider></packex-divider>

    <p
      [innerHTML]="'USER.ACCEPT_INVITATION.DESCRIPTION' | transloco: {user: inviteUser(), organization: invitation.invitedBy.organization.name}"></p>

    <packex-button (buttonClick)="decline()"
                   type="secondary"
                   class="mt-3">{{'USER.ACCEPT_INVITATION.DONT_JOIN' | transloco}}</packex-button>
    <packex-button (buttonClick)="accept()" class="mt-3">{{'USER.ACCEPT_INVITATION.JOIN' | transloco}}</packex-button>
  </packex-card>

  <packex-empty-screen *ngIf="invitationInvalid" [config]="emptyScreen"></packex-empty-screen>
</packex-page>
