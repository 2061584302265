import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, BehaviorSubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'packex-custom-translate',
  template: `
    <div #container style="display: none;">
      <ng-content></ng-content>
    </div>
    <span #target [innerHTML]="translation | safeHtml"></span>
  `,
})
export class CustomTranslateComponent implements OnInit, AfterViewInit {
  @Input() key?: string;

  @Input() params?: string;

  @ViewChild('container') private container?: ElementRef<HTMLElement>;
  @ViewChild('target') private target?: ElementRef<HTMLElement>;

  translation?: string;

  private initialized$ = new BehaviorSubject<boolean>(false);

  constructor(
    private transloco: TranslocoService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    const parameters = this.params?.split(',').map((p) => p.trim());
    const parameterObject: any = {};
    parameters?.forEach((p) => {
      parameterObject[p] = `<span data-param="${p}"></span>`;
    });
    combineLatest([this.transloco.langChanges$, this.initialized$])
      .pipe(
        filter((lang, initialized) => !!initialized),
        switchMap(() =>
          this.transloco.selectTranslate(
            this.key as unknown as string,
            parameterObject,
          ),
        ),
      )
      .subscribe((value) => {
        this.translation = value;
        setTimeout(() => {
          // Allow everything to render inside the param containers
          parameters?.forEach((param) => this.renderParam(param));
        }, 0);
      });
  }

  ngAfterViewInit(): void {
    this.initialized$.next(true);
    this.cdr.detectChanges();
  }

  renderParam(param: string) {
    const containerSlot = this.container?.nativeElement.querySelector(
      `[data-param="${param}"]`,
    );
    const targetSlot = this.target?.nativeElement.querySelector(
      `[data-param="${param}"]`,
    );

    if (targetSlot && containerSlot) {
      targetSlot.innerHTML = '';
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < containerSlot.children.length; i++) {
        const clone = containerSlot.children[i].cloneNode(true);
        targetSlot.appendChild(clone);
      }
    }
  }
}
