import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { SortOption } from '@shared/models/sort-option';
import { isEqual } from 'lodash';
import { BehaviorSubject, combineLatestWith, startWith } from 'rxjs';

export interface SelectOption {
  title?: string;
  name?: string;
  titleAppendix?: string;
  icon?: string;
  svg?: string;
  visible?: boolean;
  identifier?: string;
}

@Component({
  selector: 'packex-select',
  templateUrl: './packex-select.component.html',
  styleUrls: ['./packex-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PackexSelectComponent<T extends SelectOption> implements OnInit {
  _current = new BehaviorSubject<T | null>(null);
  options$ = new BehaviorSubject<T[]>([]);

  @Input() set options(options: T[]) {
    this.options$.next(options);
  }

  @Input() label = '';
  @Input() disabled = false;
  @Input() showPlaceholder = false;
  @Input() showAutocomplete = false;
  @Output() currentChange = new EventEmitter<T | null>();
  @Input() translatesValues = true;

  public filterControl = new FormControl('');

  public filteredOptions$ = new BehaviorSubject<T[]>([]);

  ngOnInit() {
    this.filterControl.valueChanges
      .pipe(startWith(''), combineLatestWith(this.options$))
      .subscribe(([value, options]) => {
        this.filteredOptions$.next(
          options.map((option) => {
            option.visible = this.getSearchableOptionValue(option)
              .toLocaleLowerCase()
              .includes((value || '').toLowerCase());
            return option;
          }),
        );
      });
  }

  getSearchableOptionValue(option: T): string {
    let value = '';

    if (option.name) {
      value = option.name;
    }

    if (option.title) {
      value = translate(option.title);
    }

    if (option.titleAppendix) {
      value = `${value} ${translate(option.titleAppendix)}`;
    }
    return value;
  }

  get current(): T | undefined | null {
    return this._current.getValue();
  }

  @Input() set current(value: T | undefined | null) {
    if (!isEqual(value, this.current) && value !== null) {
      this._current.next(
        this.options$.getValue().find((x) => isEqual(x, value)) ||
          value ||
          null,
      );

      if (this.showPlaceholder) {
        // weird change detection issue with angular material when options are reset
        // set a timeout of 1ms to assure the placeholder is shown after setting new options / emitting a selected value
        setTimeout(() => {
          this._current.next(null);
        }, 1);
      }

      this.currentChange.emit(value);
    }
  }

  compareFn(a: SortOption, b: SortOption) {
    if (a && b) {
      return a.identifier
        ? a.identifier === b.identifier
        : a.name
        ? a.name === b.name
        : a.title == b.title;
    }
    return false;
  }

  public updateCurrent(value: T | null) {
    this.current = value;
  }

  public onCurrentChange(option: T): void {
    this.current = option;
  }
}
