import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export type ModalSize = 'sm' | 'md' | 'lg' | 'xl';

@Component({
  selector: 'packex-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent {
  @Input() size: ModalSize = 'md';
  @Input() showButtonsInRow = false;

  @Input() noPadding = false;
  @Input() showTitle = true;
  @Input() showButtons = true;
  @Input() isScrollable = true;
  @Input() startFullScreen: ModalSize = 'sm';

  @Output() onDismiss = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<ModalComponent>) {}

  public onConfirm(): void {
    this.dialogRef.close(true);
  }

  public dismiss() {
    this.dialogRef.close(undefined);
  }
}
