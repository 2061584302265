import { Component, OnInit, ViewChild } from '@angular/core';
import { FilterService } from '@modules/filter/services/filter.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  PackexSelectComponent,
  SelectOption,
} from '@shared/components/packex-select/packex-select.component';
import {
  defaultInventoryItemSort,
  SortOption,
} from '@shared/models/sort-option';
import { isEqual } from 'lodash';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

const DEFAULT_SORT = {
  ...defaultInventoryItemSort,
  titleAppendix: `SORT.ORDER_${defaultInventoryItemSort.order}`,
};

@UntilDestroy()
@Component({
  selector: 'packex-inventory-list-sort',
  templateUrl: './inventory-list-sort.component.html',
})
export class InventoryListSortComponent implements OnInit {
  sortOptions: SortOption[] = [];
  currentSort = new BehaviorSubject<SortOption>(DEFAULT_SORT);

  @ViewChild(PackexSelectComponent) select!: PackexSelectComponent<SortOption>;

  constructor(private readonly filterService: FilterService) {
    this.sortOptions = [
      DEFAULT_SORT,
      {
        title: 'SORT.UPDATED_AT',
        titleAppendix: 'SORT.ORDER_ASC',
        order: 'ASC',
        sort: 'updatedAt',
        identifier: 'SORT.UPDATED_AT.ASC',
      },
      {
        title: 'COMMON.NAME',
        titleAppendix: 'SORT.ORDER_ASC',
        order: 'ASC',
        sort: 'name',
        identifier: 'SORT.NAME.ASC',
      },
      {
        title: 'COMMON.NAME',
        titleAppendix: 'SORT.ORDER_DESC',
        order: 'DESC',
        sort: 'name',
        identifier: 'SORT.NAME.DESC',
      },
    ];
  }

  ngOnInit() {
    this.filterService.inventoryItemFilter
      .pipe(distinctUntilChanged(isEqual), untilDestroyed(this))
      .subscribe((filter) => {
        this.select && this.select.updateCurrent(filter.sortOption);
      });
  }

  public currentChanged(value: SelectOption | null): void {
    const sortOption = value as unknown as SortOption;
    this.currentSort.next(sortOption);
    this.filterService.updateSort(sortOption);
  }
}
