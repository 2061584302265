import { Label } from '@app/shared/models/label';
import { InventoryItem } from '@shared/models/inventory-item';
import { ProductOptionType } from '@shared/models/product-option';

export type InventoryBatchOperation = 'delete' | 'addLabel';
export type InventoryError =
  | 'MATERIAL_NOT_ACTIVE'
  | 'FIXED_MATERIAL_HAS_CHANGED'
  | 'NO_ORDERABLE_VARIANTS'
  | 'CONSTRUCTION_ARCHIVED';

export const mapLabels = (stringArray?: string[]): Label[] => {
  return stringArray?.map((x) => ({ title: x })) || [];
};

export const mapLabelsToStringArray = (labels: Label[]): string[] => {
  return labels.map((label: Label) => label.title);
};

export const inventoryItemHasProductOption = (
  inventoryItem: InventoryItem,
  productOption: ProductOptionType,
) => {
  return inventoryItem.productOptions
    .map(({ sku }) => sku)
    .includes(productOption);
};

export const inventoryItemHasError = (
  inventoryItem: InventoryItem,
  error: InventoryError,
): boolean => {
  return inventoryItem.errors.includes(error);
};
