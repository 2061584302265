import { Component, Input } from '@angular/core';
import { Address } from '@shared/models/address';

@Component({
  selector: 'packex-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent {
  @Input() address!: Address;
  @Input() showVatId = true;
}
