import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Label } from '@app/shared/models/label';

/**
 * @param passive boolean
 * if true the background color will be secondary-light and no hover effects
 *
 */

@Component({
  selector: 'packex-inventory-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class InventoryLabelComponent {
  @Input() label?: Label;
  @Input() passive = false;
  @Input() customClass = '';
  @Input() margin = '0.5rem 0.5rem 0.5rem 0';
  @Output() labelClicked = new EventEmitter<Label>();

  clickLabel(label: any) {
    this.labelClicked.emit(label);
  }
}
