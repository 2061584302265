import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  ErrorStateMatcher,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import { TranslocoModule } from '@ngneat/transloco';
import { MaterialModule } from '@shared/material.module';
import { PackexModule } from '@shared/packex.module';

@NgModule({
  imports: [CommonModule, PackexModule, FormsModule, TranslocoModule],
  exports: [CommonModule, MaterialModule, PackexModule, TranslocoModule],
  providers: [
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  ],
})
export class SharedModule {}
