import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {
  getPaymentMethodTranslation,
  paymentMethodOnlyPrepaidAvailable,
} from '@app/utils/cart.helper';
import { CartItemsComponent } from '@modules/cart/cart-items/cart-items.component';
import { CartService } from '@modules/cart/cart.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CartProduct } from '@shared/models/cart';
import { flatten } from 'lodash';
import { BehaviorSubject, map, Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'packex-cart-price-overview',
  templateUrl: './cart-price-overview.component.html',
  styleUrls: ['./cart-price-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartPriceOverviewComponent
  extends CartItemsComponent
  implements OnInit
{
  private _detailViewOpen = false;
  public currentDeliveryCountry: string | null = null;

  public countryChanged = new BehaviorSubject<boolean>(false);
  public legalConfirmed = false;

  public loading$ = this.cartServiceInstance.orderCreating$.asObservable();

  protected readonly getPaymentMethodTranslation = getPaymentMethodTranslation;

  constructor(private readonly router: Router, cartService: CartService) {
    super(cartService);
  }

  override ngOnInit() {
    super.ngOnInit();

    this.cart$.pipe(untilDestroyed(this)).subscribe(({ deliveryAddress }) => {
      if (
        this.currentDeliveryCountry &&
        deliveryAddress &&
        this.currentDeliveryCountry !== deliveryAddress.country.id
      ) {
        this.currentDeliveryCountry = deliveryAddress.country.id;
        this.countryChanged.next(true);
      } else if (!this.currentDeliveryCountry && deliveryAddress) {
        this.currentDeliveryCountry = deliveryAddress.country.id;
      }
    });
  }

  public toggleDetailView(): void {
    this._detailViewOpen = !this._detailViewOpen;
  }

  get buttonText() {
    return this.cartServiceInstance.isOrderStep
      ? 'CART.ORDER'
      : 'COMMON.FORWARD';
  }

  get detailVisible(): boolean {
    return this._detailViewOpen;
  }

  get productsCount(): number {
    return this.cartProducts$
      .getValue()
      .map((product) => product.items.length)
      .reduce((sum, value) => sum + value, 0);
  }

  get proceedDisabled(): boolean {
    const hasProducts = !!this.cartProducts$.getValue().length;
    const isOrderStep = this.cartServiceInstance.isOrderStep;

    return !hasProducts || (isOrderStep && !this.legalConfirmed);
  }

  public hasProductsWithExtraProductionDays(): Observable<boolean> {
    return this.cartProducts$.pipe(
      map((products) => products.map((product: CartProduct) => product.items)),
      map((items) => flatten(items)),
      map((items) => flatten(items.map((item) => item.productOptions))),
      map((productOptions) => {
        return productOptions.filter(
          (productOption) => productOption.extraProductionDays > 0,
        );
      }),
      map((productOptions) => !!productOptions.length),
    );
  }

  async proceed(): Promise<void> {
    this.cartServiceInstance.proceed();
  }

  public get onlyHasPrepaid(): boolean {
    return paymentMethodOnlyPrepaidAvailable(
      this.cartServiceInstance.cartValue.availablePaymentMethods,
    );
  }
}
