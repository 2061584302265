<div class="packex-user-info-container" [ngClass]="{disabled}">
  <div class="packex-user-info m-xl-auto d-flex flex-row">
    <div class="user-avatar subtitle-2 me-2 text-break" *ngIf="!hideProfilePicture">
      {{firstNameChar| uppercase}}{{lastNameChar| uppercase}}
    </div>
    <div *ngIf="!onlyInitials">
      <div class="caption name" [attr.data-testid]="'user-info-name'">{{firstName}} {{lastName}}</div>
      <div class="caption subtitle mt-1" *ngIf="subtitle" [attr.data-testid]="'user-info-subtitle'">{{subtitle}}</div>
      <div class="caption subtitle mt-1 text-break" *ngIf="showEmail && user">{{ user.email }}</div>
    </div>
  </div>
</div>
