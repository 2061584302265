<div *ngIf="product" class="d-flex flex-column">
  <div class="d-flex flex-row product-header" [ngClass]="{  'pb-3': !reducedView}">
    <packex-cart-item-thumbnail [cartItem]="product.items[0]" class="w-25"></packex-cart-item-thumbnail>
    <div class="d-flex flex-column w-100">
      <packex-inventory-item-header
        [cartProduct]="product"
        [inventoryItem]="product.items[0].variant.inventoryItem"></packex-inventory-item-header>
      <packex-button type="tertiary" (click)="editProduct()" color="secondary"
                     buttonPadding="px-0"
                     class="align-self-start w-auto">{{ 'COMMON.EDIT' | transloco }}</packex-button>
    </div>
    <packex-button *ngIf="!reducedView && !summaryView" icon="delete"
                   (buttonClick)="deleteAllItemsForProduct(product)"
                   buttonPadding="p-0"
                   type="clear-icon"></packex-button>
  </div>
  <packex-cart-item *ngFor="let item of product.items; let i = index"
                    [reducedView]="reducedView"
                    [summaryView]="summaryView"
                    [item]="item" [index]="(i + 1)" class="mt-3"></packex-cart-item>
</div>
