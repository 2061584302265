import { Component, ViewEncapsulation } from '@angular/core';
import { LANGUAGE, LanguageService } from '@app/core/services/language.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  PackexSelectComponent,
  SelectOption,
} from '@shared/components/packex-select/packex-select.component';

@UntilDestroy()
@Component({
  selector: 'packex-language-select',
  templateUrl: './packex-select.component.html',
  styleUrls: ['./packex-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LanguageSelectComponent extends PackexSelectComponent<
  { value: LANGUAGE } & SelectOption
> {
  constructor(private readonly languageService: LanguageService) {
    super();

    this.options$.next(this.languageService.availableLanguages);

    this.current = this.languageService.availableLanguages.find(
      (option) => option.value === this.languageService.currentLanguage(),
    );

    this._current.pipe(untilDestroyed(this)).subscribe((option) => {
      if (
        option &&
        option.value &&
        this.languageService.currentLanguage() !== option.value
      ) {
        this.languageService.switchLanguage(option.value);
      }
    });
  }
}
