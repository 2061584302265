import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CartItemComponent } from '@modules/cart/cart-item/cart-item.component';
import { CartService } from '@modules/cart/cart.service';
import { GoogleTagService } from '@shared/services/google-tag-manager.service';

@Component({
  selector: 'packex-cart-flyout-item',
  templateUrl: './cart-flyout-item.component.html',
  styleUrls: ['../cart-item/cart-item.component.scss'],
})
export class CartFlyoutItemComponent extends CartItemComponent {
  constructor(
    dialog: MatDialog,
    cartService: CartService,
    gtm: GoogleTagService,
  ) {
    super(dialog, cartService, gtm);
  }
}
