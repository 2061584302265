<button
    class="packex-button button-{{ type }}
        {{ hideTextDown ? 'hide-text-down-' + hideTextDown : '' }}
        {{ color ? 'text-' + color : '' }}
        {{ focus ? 'focus' : '' }}
        {{ background ? 'bg-' + background : '' }}
        {{ buttonPadding }}"
    (click)="onButtonClick()"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [type]="buttonType"
    [disabled]="disabled ? 'disabled':''">
  <packex-icon *ngIf="icon || faIcon" [icon]="icon" [faIcon]="faIcon" [color]="iconColor" [size]="iconSize"
               class="me-2"></packex-icon>
  <span class="button-text"><ng-content></ng-content></span>
</button>
