import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponent } from '@app/core/components/footer/footer.component';
import { HeaderNotificationComponent } from '@app/core/components/header-notification/header-notification.component';
import { HeaderComponent } from '@app/core/components/header/header.component';
import { HeaderLanguageComponent } from '@app/core/components/header-language/header-language.component';
import { MobileMenuComponent } from '@app/core/components/mobile-menu/mobile-menu.component';
import { NotificationFoldoutComponent } from '@app/core/components/notification-foldout/notification-foldout.component';
import { ProductsFoldoutComponent } from '@app/core/components/products-foldout/products-foldout.component';
import { UserFoldoutComponent } from '@app/core/components/user-foldout/user-foldout.component';
import { ApiService } from '@app/core/services/api.service';
import { ConfigurationsService } from '@app/core/services/configurations.service';
import { ConstructionsService } from '@app/core/services/constructions.service';
import { LanguageService } from '@app/core/services/language.service';
import { ProductService } from '@app/core/services/product.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { MaterialModule } from '@shared/material.module';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    HeaderLanguageComponent,
    UserFoldoutComponent,
    ProductsFoldoutComponent,
    MobileMenuComponent,
    HeaderNotificationComponent,
    NotificationFoldoutComponent,
  ],
  providers: [
    ApiService,
    LanguageService,
    ConfigurationsService,
    ConstructionsService,
    ProductService,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NgbModule,
    HeaderLanguageComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslocoModule,
    RouterModule,
    SharedModule,
    NgbModule,
    NgOptimizedImage,
  ],
})
export class CoreModule {}
