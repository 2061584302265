<packex-modal  (onDismiss)="dismiss()" [isScrollable]="false">
  <h5 class="modal-title">{{ 'EDIT_VARIANT.DIALOG_TITLE' | transloco }}</h5>

  <form class="packex-for md-flex flex-column modal-body" [formGroup]="form" (ngSubmit)="submit()" *ngIf="form">
    <p>{{ 'EDIT_VARIANT.NAME_HINT' | transloco }}</p>
    <p class="mt-4">
      <mat-form-field appearance="outline" class="w-100">
        <input (ngModelChange)="updateNames()"
               matInput
               placeholder="{{ dialogData.variant?.name }}"
               [attr.data-testid]="'edit-variant-input'"
               formControlName="name"
               name="name"
               maxlength="100"
               required>
        <mat-error *ngIf="form.controls['name'].invalid">
          <ng-container *ngIf="!currentNameIsUnique">
            {{'CONSTRUCTION.ERROR.NAME_EXISTS' | transloco}}
          </ng-container>
          <ng-container *ngIf="currentNameIsUnique">
            {{'CONSTRUCTION.ERROR.NAME' | transloco}}
          </ng-container>
        </mat-error>
        <mat-hint align="end">{{ 'COMMON.CHARACTERS_LEFT' | transloco : {
          left: (100 - (form.get('name')?.value || '').length),
          max: 100
        } }}</mat-hint>
      </mat-form-field>
    </p>
  </form>

  <packex-button [disabled]="!form.valid" type="primary" class="w-auto" (buttonClick)="submit()" [attr.data-testid]="'edit-variant-save-button'">{{ 'COMMON.SAVE' | transloco }}</packex-button>
</packex-modal>
